import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    position: "absolute",
    width: "100%",
    maxHeight: "200px",
    bottom: 20,
    left: 0,
    right: 0,
  },
  RowData: { width: "100%", textAlign: "right", fontSize: 8 },
});

const DDTimeStampSection = ({ DDReportData }) => {
  return (
    <View fixed style={{ ...styles.row }}>
      <Text style={{ ...styles.RowData }}>
        Report created by {DDReportData.CreatedBy} at{" "}
        {DDReportData.ReportCreated}
      </Text>
    </View>
  );
};

export default DDTimeStampSection;
