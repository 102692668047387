import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "react-bootstrap";
import moment from "moment";

export default function CollectionDateConfirm(SuppliedCollectionDate) {
  return new Promise((resolve, reject) => {
    const CollectionDate = moment(SuppliedCollectionDate, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    const Today = moment.utc();

    if (moment(CollectionDate, "DD-MM-YYYY").isSameOrAfter(Today, "day")) {
      resolve(true);
    } else {
      confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: true,
        customUI: ({ onClose }) => {
          return (
            <div className="ConfirmAlert">
              <div className="AlertBody">
                <h1>The Collection Date is in the past.</h1>
                Are you sure you wish to continue with that date?
                <div className="ButtonGroup">
                  <Button
                    onClick={() => {
                      resolve(true);
                      onClose();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      reject(true);
                      onClose();
                    }}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          );
        },
      });
    }
  });
}
