import React, { Component } from "react";
import { AppContext } from "../../libs/contextLib";
import BootstrapTable from "../react-bootstrap-table2";
import filterFactory from "react-bootstrap-table2-filter";
import OverlayFormatter from "../../libs/OverlayFormatter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Decrypt } from "../../libs/Crypto";

class DDSetupTable extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.rows) {
      let accountReference = {
          dataField: "accountReference",
          text: "Account Reference",
          sort: true,
        },
        accountName = {
          dataField: "accountName",
          text: "Account Name",
          sort: true,
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
        sortCode = {
          dataField: "sortCode",
          text: "Sort Code",
          formatter: (cellContent, row) => {
            return Decrypt(cellContent);
          },
        },
        accountNumber = {
          dataField: "accountNumber",
          text: "Account Number",
          formatter: (cellContent, row) => {
            return Decrypt(cellContent);
          },
        },
        mandateStatus = {
          dataField: "mandateStatus",
          text: "Current Status",
          sort: true,
        },
        mandateStatusListId = {
          dataField: "mandateStatusListId",
          isDummyField: true,
          text: "Next Status",
          formatter: (cellContent, row) => {
            let a = {};
            this.props.MandateStatusLists.forEach((element) => {
              a[element.id] = [element.content];
            });
            let SetNextMandate;
            if (a[row.sysmandateStatusListId][0] !== "0C - Cancellation") {
              SetNextMandate = a[row.sysmandateStatusListId + 1];
            } else {
              SetNextMandate = a[row.mandateStatusListId - 1];
            }
            return SetNextMandate;
          },
        };
      let columns;

      if (this.props.BankDetailsNeeded) {
        columns = [
          accountReference,
          accountName,
          sortCode,
          accountNumber,
          mandateStatus,
          mandateStatusListId,
        ];
      } else {
        columns = [
          accountReference,
          accountName,
          mandateStatus,
          mandateStatusListId,
        ];
      }

      var ColumnList = columns;

      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {size} Results
        </span>
      );
      let AddDDSetupPage = 1;
      if (sessionStorage.getItem("AddDDSetupPage")) {
        AddDDSetupPage = sessionStorage.getItem("AddDDSetupPage");
      }
      const paginationOptions = {
        page: parseInt(AddDDSetupPage),
        onPageChange: (page) => {
          sessionStorage.setItem("AddDDSetupPage", page);
        },
        hidePageListOnlyOnePage: true,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
          {
            text: "10",
            value: 10,
          },
          {
            text: "50",
            value: 50,
          },
          {
            text: "100",
            value: 100,
          },
          {
            text: "All",
            value: this.props.rows.length,
          },
        ],
      };

      const rowEvents = {
        onClick: (e, row, rowIndex) => {},
      };

      const defaultSorted = [
        {
          dataField: "company",
          order: "asc",
        },
      ];

      return (
        <div className="DDListTable">
          <BootstrapTable
            bootstrap4
            responsive
            hover
            keyField="accountReference"
            data={this.props.rows}
            rowEvents={rowEvents}
            columns={ColumnList}
            filter={filterFactory()}
            pagination={paginationFactory(paginationOptions)}
            defaultSorted={defaultSorted}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

DDSetupTable.contextType = AppContext;
export default DDSetupTable;
