import moment from "moment";

function CSVContentSection(TableData) {
  var output = "",
    header = "",
    content = "";

  header += "Account Reference,";
  header += "Account Name,";
  header += TableData.ProductName !== "Sage Intacct" ? "Posted URN," : "";
  header += "Collection Total,";
  header += "Tran Date,";
  header +=
    TableData.ProductName !== "Sage Intacct" ? "Reference," : "Inv No.,";
  header += "Tran Type,";
  header += "Due Date,";
  header +=
    TableData.ProductName !== "Sage Intacct" ? "2nd Reference," : "Reference,";
  header += "Tran Amt.,";
  header += "OS Amt.,";
  header += "To Collect";

  TableData.ReportListData.forEach((Element) => {
    Element.ddListItems.forEach((ChildElement) => {
      //   Add ScheduleList Rows
      if (ChildElement.currentHistoryCollectionAmount !== 0) {
        header += ",Schedule Date,";
        header += "DD Run,";
        header += "Schedule Amount,";
        header += "Collection Amount,";

        ChildElement.theScheduleList.forEach((ScheduleElement) => {
          content += Element.accountReference + ",";
          content += Element.accountName + ",";
          content +=
            TableData.ProductName !== "Sage Intacct"
              ? Element.sageURN + ","
              : "";
          content += Element.collectionTotal.toFixed(2) + ",";
          content += ChildElement.transactionDate + ",";
          content += ChildElement.invoiceNumber + ",";
          content += ChildElement.transactionType + ",";
          content += ChildElement.dueDate + ",";
          content += ChildElement.reference + ",";
          content += ChildElement.baseAmount.toFixed(2) + ",";
          content += ChildElement.outstandingAmount.toFixed(2) + ",";
          content += ChildElement.collectionAmount.toFixed(2) + ",";
          content +=
            moment(ScheduleElement.scheduleDate).format("DD-MM-YYYY") + ",";
          content += ScheduleElement.ddRun + ",";
          content += ScheduleElement.scheduleAmount.toFixed(2) + ",";
          content += ScheduleElement.collectionAmount
            ? ScheduleElement.collectionAmount.toFixed(2)
            : "0.00,";
          content += "\r\n";
        });
      } else {
        content += Element.accountReference + ",";
        content += Element.accountName + ",";
        content +=
          TableData.ProductName !== "Sage Intacct" ? Element.sageURN + "," : "";
        content += Element.collectionTotal.toFixed(2) + ",";
        content += ChildElement.transactionDate + ",";
        content += ChildElement.invoiceNumber + ",";
        content += ChildElement.transactionType + ",";
        content += ChildElement.dueDate + ",";
        content += ChildElement.reference + ",";
        content += ChildElement.baseAmount.toFixed(2) + ",";
        content += ChildElement.outstandingAmount.toFixed(2) + ",";
        content += ChildElement.collectionAmount.toFixed(2);
        content += "\r\n";
      }
    });
  });
  output = header + "\r\n" + content;
  return output;
}

export default CSVContentSection;
