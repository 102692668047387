import React from "react";
import { OverlayTrigger, Tooltip, Button, Row, Col } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import withRouter from "../libs/withRouter";

function TooltipActionButtonPlacedAbove(props) {
  return (
    <>
      <Row className="PlacedAboveContent">
        <Col sm={12} className="TooltipActionButton">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="TooltipActionButtonTooltip" className="">
                {props.ToolTipLabel}
              </Tooltip>
            }
          >
            <Link to={props.LinkTo.pathname} state={props.LinkTo.state}>
              <Button variant="primary">
                <span>{props.Icon ? props.Icon : <FaPlus />}</span>
                <span>{props.Label}</span>
              </Button>
            </Link>
          </OverlayTrigger>
        </Col>
      </Row>
    </>
  );
}
export default withRouter(TooltipActionButtonPlacedAbove);
