import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./scss/index.scss";
import App from "./App";
import { AppProvider } from "./libs/contextLib";
import IsLoading from "./components/IsLoading";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <>
    <IsLoading />
    <BrowserRouter>
      <AppProvider>
        <App />
      </AppProvider>
    </BrowserRouter>
  </>
);
