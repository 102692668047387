import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { handleValidation } from "../handleValidation";

class FromDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(e) {
    if (this.props.schema) {
      let value = e.target.value;

      let items = {
        name: this.props.name,
        data: value,
        schema: this.props.schema,
      };
      handleValidation(items)
        .then((response) => {
          this.setState({ errorList: "" });
          this.props.value(this.props.name, value);
        })
        .catch((e) => {
          this.setState({ errorList: e });
        });
    } else {
      this.props.value(this.props.name, e.target.value);
    }
  }

  handleBlur(e) {
    if (this.props.schema) {
      let value = e.target.value;
      let items = {
        name: this.props.name,
        data: value,
        schema: this.props.schema,
      };
      handleValidation(items)
        .then((response) => {
          this.setState({ errorList: "" });
          this.props.value(this.props.name, e.target.value);
        })
        .catch((e) => {
          this.setState({ errorList: e });
        });
    }
  }

  render() {
    const name = this.props.name;
    return !this.props.hidden ? (
      <Form.Group className={"FormGroup"} as={Row} controlId={this.props.id}>
        <Form.Label column sm={this.props.LabelColumnAmount}>
          {this.props.Label}
        </Form.Label>
        <Col sm={this.props.InputColumnAmount}>
          <Form.Control
            required={this.props.required}
            as="select"
            onChange={this.handleChange}
            value={this.props.placeholder || ""}
            disabled={this.props.disabled}
            onBlur={this.handleBlur}
            className="form-select"
          >
            {this.props.itemContent.map((itemContent) => (
              <option key={itemContent.id} value={itemContent.id || ""}>
                {itemContent.content}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col>
          {this.state.errorList && this.state.errorList[name] ? (
            <div className="invalid-tooltip typeError">
              {this.state.errorList[name]}
            </div>
          ) : null}
          {!this.state.errorList && this.props.errorList[name] ? (
            <div className="invalid-tooltip typeError">
              {this.props.errorList[name]}
            </div>
          ) : null}
        </Col>
      </Form.Group>
    ) : null;
  }
}

export default FromDropdown;
