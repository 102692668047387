import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaTimesCircle, FaChevronDown, FaChevronRight } from "react-icons/fa";
import { MdOutlineErrorOutline } from "react-icons/md";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import OverlayFormatter from "../../libs/OverlayFormatter";
import { SetObserver } from "../../libs/Observer";
import { PaginationDisplay } from "../PaginationComponents";

class ErrorLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RunErrorColumns: [
        {
          dataField: "Reason",
          text: "Error(s)",
          sort: true,
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
      ],
      CustomerErrorColumns: [
        {
          dataField: "CustomerID",
          text: "CustomerID",
          sort: true,
        },
      ],
      LineErrorColumns: [
        {
          dataField: "CustomerID",
          text: "CustomerID",
          sort: true,
        },
        {
          dataField: "Reference",
          text:
            this.props.ProductName === "Sage Intacct" ? "Inv No." : "Reference",
          sort: true,
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
        {
          dataField: "TransactionID",
          text: "TransactionID",
          sort: true,
        },
      ],
      URNLineErrorColumns: [
        {
          dataField: "CustomerID",
          text: "CustomerID",
          sort: true,
        },
        {
          dataField: "Reference",
          text:
            this.props.ProductName === "Sage Intacct" ? "Inv No." : "Reference",
          sort: true,
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
        {
          dataField: "URN",
          text: "URN",
          sort: true,
        },
      ],
    };
  }

  componentDidMount() {
    SetObserver();
  }

  RenderPopup() {
    return (
      <Popup
        className="OmittedCustomers"
        trigger={
          <Button variant="danger">
            <MdOutlineErrorOutline /> Run Error List
          </Button>
        }
        modal
        nested
        closeOnDocumentClick={false}
      >
        {(Close) => (
          <div className="Modal">
            <button className="close" onClick={Close}>
              <FaTimesCircle />
            </button>
            <div className="Title"></div>
            <div className="content">
              {this.props.ErrorLog.RunErrorList &&
              this.props.ErrorLog.RunErrorList.length >= 1
                ? this.ErrorTable(
                    this.props.ErrorLog.RunErrorList,
                    this.state.RunErrorColumns,
                    "Reason",
                    "Reason",
                    "Errors with the entire Direct Debit Run"
                  )
                : null}
              {this.props.ErrorLog.CustomerErrorList &&
              this.props.ErrorLog.CustomerErrorList.length >= 1
                ? this.ErrorTable(
                    this.props.ErrorLog.CustomerErrorList,
                    this.state.CustomerErrorColumns,
                    "CustomerID",
                    "CustomerID",
                    "Errors with an Individual or Multiple Customers"
                  )
                : null}
              {this.props.ErrorLog.LineErrorList &&
              this.props.ErrorLog.LineErrorList.length >= 1
                ? this.ErrorTable(
                    this.props.ErrorLog.LineErrorList,
                    this.state.LineErrorColumns,
                    "TransactionID",
                    "CustomerID",
                    "Errors with an Individual or Multiple Transactions"
                  )
                : null}
              {this.props.ErrorLog.URNLineErrorList &&
              this.props.ErrorLog.URNLineErrorList.length >= 1
                ? this.ErrorTable(
                    this.props.ErrorLog.URNLineErrorList,
                    this.state.URNLineErrorColumns,
                    "URN",
                    "CustomerID",
                    "Errors with the Sales Allocations"
                  )
                : null}
            </div>
          </div>
        )}
      </Popup>
    );
  }

  ErrorTable(Data, Columns, Key, DefaultSort, Title) {
    const sizePerPage = 10,
      defaultSorted = [
        {
          dataField: DefaultSort,
          order: "asc",
        },
      ];

    let expandRow = {};
    if (Object.keys(Data[0]).length > 1) {
      expandRow = {
        renderer: (row) => (
          <div className="react-bootstrap-table">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th>Reason(s)</th>
                </tr>
              </thead>
              <tbody>
                {row.Reason.map((item, i) => (
                  <tr key={i}>
                    <td>{item}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ),
        onlyOneExpanding: true,
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: () => {
          // return Title;
          return null;
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="CommitInfoTooltip" className="">
                    Reason(s)
                  </Tooltip>
                }
              >
                <Button className="ButtonRemove">
                  <FaChevronDown />
                </Button>
              </OverlayTrigger>
            );
          }
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="CommitInfoTooltip" className="">
                  Reason(s)
                </Tooltip>
              }
            >
              <Button className="ButtonRemove">
                <FaChevronRight />
              </Button>
            </OverlayTrigger>
          );
        },
        expandColumnPosition: "left",
        parentClassName: "parentExpand",
      };
    }
    return PaginationDisplay(
      defaultSorted,
      sizePerPage,
      Key,
      Columns,
      Data,
      Title,
      expandRow
    );
  }

  render() {
    return <>{this.RenderPopup()}</>;
  }
}

export default ErrorLog;
