import React, { Component } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const minuteSeconds = 5;
const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const timerProps = {
  isPlaying: true,
  size: 90,
  strokeWidth: 4,
};
const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div>{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const remainingTime = 5000;

    return (
      <div className="CountdownWrapper">
        <p>Refreshing in: </p>
        <CountdownCircleTimer
          {...timerProps}
          colors={[["#3aaf0c"]]}
          duration={minuteSeconds}
          onComplete={(totalElapsedTime) => ({
            shouldRepeat: true,
            delay: [remainingTime - totalElapsedTime > 0],
          })}
        >
          {({ elapsedTime }) =>
            renderTime("seconds", getTimeSeconds(elapsedTime))
          }
        </CountdownCircleTimer>
      </div>
    );
  }
}

export default Countdown;
