import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { FaArrowCircleLeft } from "react-icons/fa";
import OutputMessage from "../components/OutputMessage";
import AddNewCompany from "./CompanyList/AddNewCompany";
import withRouter from "../libs/withRouter";

class IsError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Row>
          <Col>
            {this.props.errorList ? (
              <>
                <OutputMessage errorList={this.props.errorList} />
              </>
            ) : (
              <p>No data found</p>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props.errorList[0] === "No active companies found" ? (
              <div className="noData">
                <AddNewCompany />
              </div>
            ) : (
              <div className="Link" onClick={() => this.props.navigate(-1)}>
                <FaArrowCircleLeft /> Back to previous page
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(IsError);
