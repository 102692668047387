const Colours = {
  white: "#fff",
  green: "#3aaf0c",
  red: "#bc3636",
  greenBackground: "#ebf7e6",
  textColour: "#222222",
  grey: "#F5F5F5",
  darkGrey: "#999999",
  borderGrey: "#ced4da",
};
export default Colours;
