export default function StringList(array) {
  var a = [];
  array.forEach((element, i) => {
    if (i === array.length - 1) {
      a += "or " + element;
    } else {
      if (i === array.length - 2) {
        a += element + " ";
      } else {
        a += element + ", ";
      }
    }
  });
  return a;
}
