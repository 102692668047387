import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./Pages/NotFound";
import NoAccess from "./Pages/NoAccess";
import Login from "./Pages/Login";
import CompanyList from "./Pages/CompanyList";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import CompanyListDetails from "./components/CompanyList/CompanyListDetails";
import ResetPassword from "./components/Login/ResetPassword";
import CompanyListAddNew from "./components/CompanyList/CompanyListAddNew";
import BankDetails from "./components/CustomerList/BankDetails";
import BankDetailsImport from "./components/CustomerList/BankDetailsImport";
import UserDetails from "./components/UserList/UserDetails";
import NewUser from "./components/UserList/NewUser";
import DDRun from "./components/DDList/DDRun";
import DDRunNew from "./components/DDList/DDRunNew";
import NewSite from "./components/SiteSettings/NewSite";
import DDList from "./Pages/DDList";
import SiteSettings from "./Pages/SiteSettings";
import UserList from "./Pages/UserList";
import IPWhiteList from "./Pages/IPWhiteList";
import CustomerList from "./Pages/CustomerList";
import DDSetupFile from "./Pages/DDSetupFile";
import ARUDD from "./Pages/ARUDD";
import ARUDDResults from "./components/ARUDD/ARUDDResults";
import NewSiteRequest from "./components/SiteSettings/NewSiteRequest";
import { AppContext } from "./libs/contextLib";

class RouteList extends Component {
  static contextType = AppContext;
  render() {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <AuthenticatedRoute exact path="/">
              <DDList />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/CompanyList"
          element={
            <AuthenticatedRoute exact path="/CompanyList">
              <CompanyList />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/CompanyListDetails"
          element={
            <AuthenticatedRoute exact path="/CompanyListDetails">
              <CompanyListDetails />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/CompanyListAddNew"
          element={
            <AuthenticatedRoute exact path="/CompanyListAddNew">
              <CompanyListAddNew />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/BankDetails"
          element={
            <AuthenticatedRoute exact path="/BankDetails">
              <BankDetails />
            </AuthenticatedRoute>
          }
        />
        {this.context.SettingsList.CanImportBankDetails ||
        this.context.SettingsList.IsAdmin ? (
          <Route
            path="/BankDetailsImport"
            element={
              <AuthenticatedRoute exact path="/BankDetailsImport">
                <BankDetailsImport />
              </AuthenticatedRoute>
            }
          />
        ) : null}
        <Route
          path="/UserDetails"
          element={
            <AuthenticatedRoute exact path="/UserDetails">
              <UserDetails />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/NewUser"
          element={
            <AuthenticatedRoute exact path="/NewUser">
              <NewUser />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/IPWhiteList"
          element={
            <AuthenticatedRoute exact path="/IPWhiteList">
              <IPWhiteList />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/DDList"
          element={
            <AuthenticatedRoute exact path="/DDList">
              <DDList />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/DDRun"
          element={
            <AuthenticatedRoute exact path="/DDRun">
              <DDRun />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="/DDRunNew"
          element={
            <AuthenticatedRoute exact path="/DDRunNew">
              <DDRunNew />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/DDSetupFile"
          element={
            <AuthenticatedRoute exact path="/DDSetupFile">
              <DDSetupFile />
            </AuthenticatedRoute>
          }
        />
        {this.context.SettingsList.CanImportARUDDFile ||
        this.context.SettingsList.IsAdmin ? (
          <>
            <Route
              path="/ARUDD"
              element={
                <AuthenticatedRoute exact path="/ARUDD">
                  <ARUDD />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/ARUDDResults"
              element={
                <AuthenticatedRoute exact path="/ARUDDResults">
                  <ARUDDResults />
                </AuthenticatedRoute>
              }
            />
          </>
        ) : null}
        <Route
          path="/SiteSettings"
          element={
            <AuthenticatedRoute exact path="/SiteSettings">
              <SiteSettings />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/CustomerList"
          element={
            <AuthenticatedRoute exact path="/CustomerList">
              <CustomerList />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/UserList"
          element={
            <AuthenticatedRoute exact path="/UserList">
              <UserList />
            </AuthenticatedRoute>
          }
        />

        {this.context.SettingsList.IsSuperAdmin ? (
          <Route path="/NewSite" element={<NewSite />} />
        ) : null}

        <Route
          path="/NoAccess"
          element={
            <AuthenticatedRoute exact path="/NoAccess">
              <NoAccess />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/Login"
          element={
            <UnauthenticatedRoute exact path="/Login">
              <Login />
            </UnauthenticatedRoute>
          }
        />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/NewSiteRequest" element={<NewSiteRequest />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}
RouteList.contextType = AppContext;
export default RouteList;
