import React, { Component } from "react";
import { AppContext } from "../../libs/contextLib";
import { Row, Col, Button } from "react-bootstrap";
import { FaTimesCircle, FaSave } from "react-icons/fa";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import GetDatetime from "../../libs/GetDatetime";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import { SetObserver } from "../../libs/Observer";
import ARUDDImportTable from "./ARUDDImportTable";
import { Decrypt } from "../../libs/Crypto";

let blankState = {
    errorList: [],
    ParsedErrorList: [],
    OutputList: [],
    NoSelections: false,
  },
  ReverseStatus = "Reverse?",
  SelectionsNotPossible = true;
class ARUDDFileProcessor extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
  }

  componentDidMount() {
    this.loadData();
    SetObserver();
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.DDRejectionHeaderInfo) !==
      JSON.stringify(this.props.DDRejectionHeaderInfo)
    ) {
      this.setState({ errorList: [] }, () => {
        this.props.errorList(null);
        if (this.state.errorList.length <= 0) this.loadData();
      });
    }
  }

  async loadData() {
    try {
      if (!this.props.XMLData.BACSDocument) {
        throw new Error(
          "Invalid Rejection File Format - Missing BACSDocument Element."
        );
      } else {
        let XMLData =
          this.props.XMLData.BACSDocument.Data[0].ARUDD[0].Advice[0];

        let CustomerList = this.props.CustomerList;
        // console.log(XMLData);
        // console.log(CustomerList);

        let TableJSON = [];
        let TableKey = 0;
        let ProductName = sessionStorage.getItem("productName");

        for (let XMLElement of XMLData.OriginatingAccountRecords) {
          for (let OriginatingAccountRecords of XMLElement.OriginatingAccountRecord) {
            for (let ReturnedDebitItem of OriginatingAccountRecords.ReturnedDebitItem) {
              var customerFound = false;
              for (let CustomerElement of CustomerList) {
                let item = ReturnedDebitItem.PayerAccount[0].$;
                if (
                  item.number === Decrypt(CustomerElement.accountNumber) &&
                  item.sortCode.replaceAll("-", "") ===
                    Decrypt(CustomerElement.sortCode) &&
                  ReturnedDebitItem.$.valueOf ===
                    CustomerElement.value.toFixed(2)
                ) {
                  let Status = ReverseStatus;
                  if (
                    (ProductName === "Sage Intacct" &&
                      (CustomerElement.postedTransactionID === null ||
                        CustomerElement.postedTransactionID === false)) ||
                    (ProductName === "Sage 200c Standard" &&
                      (CustomerElement.sageURN === null ||
                        CustomerElement.sageURN === false))
                  ) {
                    Status = "Not posted to Sage";
                  }
                  if (CustomerElement.isReversed) {
                    Status = "Has been marked as Reversed";
                  }

                  TableJSON.push({
                    TableKey: TableKey++,
                    Tick: Status === ReverseStatus ? false : true,
                    Status: Status,
                    ACRef: CustomerElement.acRef,
                    ACName: CustomerElement.acName,
                    TransactionID: CustomerElement.transactionID,
                    Ref: ReturnedDebitItem.$.ref,
                    ReturnCode: ReturnedDebitItem.$.returnCode,
                    ReturnDescription: ReturnedDebitItem.$.returnDescription,
                    Date: ReturnedDebitItem.$.originalProcessingDate,
                    Value: ReturnedDebitItem.$.valueOf,

                    CurrencySymbol:
                      this.props.DDRejectionHeaderInfo.CurrencySymbol,
                    DDListCustomersID: CustomerElement.ddListCustomersID,
                    OriginalProcessingDate:
                      ReturnedDebitItem.$.originalProcessingDate,
                    OriginalCurrency: ReturnedDebitItem.$.currency,
                    PayerAccountNumber: CustomerElement.accountNumber,
                    PayerSortCode: CustomerElement.sortCode,
                    BankName: item.bankName,
                    BranchName: item.branchName,
                  });
                  customerFound = true;
                  break;
                }
              }
              if (!customerFound) {
                TableJSON.push({
                  TableKey: TableKey++,
                  Tick: false,
                  Status: "Not Found",
                  ACRef: "",
                  ACName: "",
                  TransactionID: "",
                  Ref: ReturnedDebitItem.$.ref,
                  ReturnCode: ReturnedDebitItem.$.returnCode,
                  ReturnDescription: ReturnedDebitItem.$.returnDescription,
                  Date: ReturnedDebitItem.$.originalProcessingDate,
                  Value: ReturnedDebitItem.$.valueOf,

                  CurrencySymbol: "",
                  DDListCustomersID: "",
                  OriginalProcessingDate: "",
                  OriginalCurrency: "",
                  PayerAccountNumber: "",
                  PayerSortCode: "",
                  BankName: "",
                  BranchName: "",
                });
              }
            }
          }
        }

        // console.log(TableJSON);

        this.setState({ OutputList: TableJSON });
      }
    } catch (e) {
      var message = apiError("API Get: ", e);
      this.setState((previousState) => ({
        errorList: [...previousState.errorList, message],
      }));
      this.props.errorList(message);
    }
  }

  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      // this.loadData();
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    let output = this.state;
    let DDRejectionHeaderInfo = this.props.DDRejectionHeaderInfo;
    let SiteID = sessionStorage.getItem("siteID");
    let DateTime = GetDatetime();
    let outputJSON = {};
    let ListItems = [];
    let ListTransactionIDs = [];
    let RejectionTotal = 0;

    outputJSON["CompanyID"] = DDRejectionHeaderInfo.CompanyID;
    outputJSON["DDListHeaderID"] = DDRejectionHeaderInfo.DDListHeaderID;
    outputJSON["FileName"] = DDRejectionHeaderInfo.FileName;
    outputJSON["RejectionDate"] = DDRejectionHeaderInfo.RejectionDate;
    outputJSON["DateTimeUpdated"] = DateTime;
    outputJSON["DateTimeCreated"] = DateTime;
    outputJSON["SiteID"] = SiteID;

    output.OutputList.forEach((element) => {
      if (element.Status === ReverseStatus) {
        SelectionsNotPossible = false;
      }
      if (element.Tick) {
        let Value = parseFloat(element.Value);
        RejectionTotal = parseFloat(RejectionTotal) + Value;
        ListItems.push({
          DDListCustomersID: element.DDListCustomersID,
          RejectionReasonCode: element.ReturnCode,
          RejectionReasonName: element.ReturnDescription,
          OriginalProcessingDate: element.OriginalProcessingDate,
          OriginalValue: Value,
          OriginalCurrency: element.OriginalCurrency,
          PayerAccountNumber: element.PayerAccountNumber,
          PayerSortCode: element.PayerSortCode,
          Reference: element.Ref,
          BankName: element.BankName,
          BranchName: element.BranchName,
        });
        element.TransactionID.forEach((ID) => {
          ListTransactionIDs.push(ID);
        });
      }
    });
    outputJSON["RejectionTotal"] = RejectionTotal.toFixed(2);
    outputJSON["ListItems"] = ListItems;
    outputJSON["ListTransactionIDs"] = ListTransactionIDs;
    // console.log(outputJSON);

    if (ListItems.length <= 0) {
      var message = "You must select at least one transaction to reverse.";
      if (SelectionsNotPossible) {
        message = "No possible transactions found to reverse.";
      }
      this.setState({ NoSelections: true, NoSelectionsMessage: message });
      return;
    }

    await trackPromise(
      axios
        .post("api/DdrejectionHeaders", outputJSON)
        .then(async (response) => {
          let newDDrejectionHeaderId = response.data.ddrejectionHeaderId;

          await trackPromise(
            Promise.all([
              axios.get(
                this.context.INTACCT_API_BASE_URL +
                  "/api/ARReverse/?siteID=" +
                  SiteID +
                  "&SYSCompanyID=" +
                  DDRejectionHeaderInfo.CompanyID +
                  "&DDRejectionHeaderID=" +
                  newDDrejectionHeaderId +
                  "&accessToken=" +
                  this.context.SageAccessToken,
                {
                  crossdomain: true,
                }
              ),
            ]).then((responses) => {
              if (responses[0] && responses[0] !== undefined) {
                this.props.navigate("/ARUDDResults", {
                  state: {
                    PostFeedback: [
                      `The Rejection File (${DDRejectionHeaderInfo.FileName}) has been processed.`,
                    ],
                  },
                });
                this.resetForm();
                this.props.FormReset("FormReset", true);
              }
            })
          ).catch((e) => {
            var message = apiError("API SyncDDList: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
            this.props.errorList(message);
          });
        })
        .catch((e) => {
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
          var message = apiError("PostAPI: ", e);
          this.props.errorList(message);
        })
    );
  }

  handleFormReset(e) {
    e.preventDefault();
    this.props.FormReset("FormReset", true);
  }

  handleCallback = (name, theData) => {
    // console.log(name);
    // console.log(theData);
    var pattForSubmit = new RegExp("ForSubmit");
    this.setState((state) => {
      if (pattForSubmit.test(name)) {
        state["OutputList"] = theData;
      } else {
        state[name] = theData;
      }
      state["NoSelections"] = false;
      return state;
    });
  };

  render() {
    return (
      <div className="ARUDDFileProcessor">
        {this.state.OutputList.length >= 1 &&
        this.state.errorList.length <= 0 ? (
          <>
            <ARUDDImportTable
              rows={this.state.OutputList}
              ForSubmit={this.handleCallback}
            />
            <div className="row BlankMargin"></div>
            <div
              className={this.state.OutputList.length >= 5 ? "FormSave" : ""}
            >
              <Row>
                <Col sm={5}></Col>
                <Col sm={7} className="IconBtn">
                  <Button variant="primary" onClick={this.handleSubmit}>
                    <FaSave /> Save
                  </Button>

                  {this.state.NoSelections ? (
                    <div className="invalid-tooltip">
                      {this.state.NoSelectionsMessage}
                    </div>
                  ) : null}
                  <Button variant="primary" onClick={this.handleFormReset}>
                    <FaTimesCircle /> Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

ARUDDFileProcessor.contextType = AppContext;
export default withRouter(ARUDDFileProcessor);
