import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../libs/contextLib";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FaFileDownload,
  FaArrowCircleLeft,
  FaTimesCircle,
} from "react-icons/fa";
import { CSVReader, CSVDownloader } from "react-papaparse";
import OutputMessage from "../OutputMessage";
import BankDetailsImportDBHandle from "./BankDetailsImportDBHandle";
import withRouter from "../../libs/withRouter";

const Headers = [
  "Account_Reference",
  "Account_Name",
  "Sort_Code",
  "Account_Number",
  "IBAN",
  "BIC_/_Swift_Number",
  "Additional_Reference",
  "Mandate_Type",
  "Mandate_Reference",
  "Mandate_Signature_Date",
];
let BlankState = {
  UploadedFile: null,
  errorList: [],
  ParsedData: null,
  AddedSuccess: false,
};
const buttonRef = React.createRef();
class BankDetailsImport extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = BlankState;
    let CompanyDetails = JSON.parse(sessionStorage.getItem("CompanyDetails"));
    this.state.CompanyName = CompanyDetails.CompanyName;
    this.state.Filename = this.state.CompanyName + "-bankFormatOutput";
  }
  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    let params = this.props.location.state;
    if (params !== undefined && params) {
      let content = params.content;
      let DownloadData = this.GetFile(content);
      this.setState({
        DownloadData,
        MustPassModulusCheck: content[0].mustPassModulusCheck,
      });
    }
  }
  handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnFileLoad = (Input, fileInfo) => {
    try {
      if (Input.length <= 0) {
        throw new Error("File Supplied is Empty.");
      }
      if (!fileInfo.name.includes(this.state.CompanyName)) {
        throw new Error("File supplied doesn't match with this company.");
      }
      // if (fileInfo.name !== this.state.Filename + ".csv") {
      //   throw new Error(
      //     "Wrong file supplied - file name may have been changed."
      //   );
      // }
      if (fileInfo.size >= 5000000) {
        throw new Error("Filesize exeeds amount limit.");
      }
      if (Object.keys(Input[0].data).length > 10) {
        throw new Error("File exceeds column limit.");
      }

      if (JSON.stringify(Headers) !== JSON.stringify(Input[0].meta.fields)) {
        throw new Error(`Headers not correctly set. Should be:\n ${Headers}`);
      }

      this.setState({
        ParsedData: Input,
      });
    } catch (e) {
      this.setState((previousState) => ({
        errorList: [...previousState.errorList, e.message],
      }));
    }
  };

  handleOnError = (error, file, inputElem, reason) => {
    var message = error + "|" + file + "|" + inputElem + "|" + reason;
    this.setState((previousState) => ({
      errorList: [...previousState.errorList, message],
    }));
  };
  handleError = (message) => {
    this.setState((previousState) => ({
      errorList: [...previousState.errorList, message],
    }));
  };

  handleOnRemoveFile = (data) => {
    this.setState({
      ParsedData: data,
      errorList: [],
    });
  };
  handleRemoveFile = (e) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  GetFile(content) {
    let SortedData = content.sort((a, b) =>
      a.accountReference > b.accountReference
        ? 1
        : b.accountReference > a.accountReference
        ? -1
        : 0
    );
    var Output;
    Output = Headers + "\n";
    SortedData.forEach((element) => {
      if (!element.hasBankDetails) {
        Output += element.accountReference + ",";
        Output += element.accountName.replaceAll(",", "") + ",";
        Output += ",";
        Output += ",";
        Output += ",";
        Output += ",";
        Output += ",";
        Output += ",";
        Output += ",";
        Output += "\n";
      }
    });

    return Output;
  }

  render() {
    return (
      <div className="BankDetailsImport">
        <h1>{this.state.CompanyName} - Customer Bank Details Import</h1>

        <Row>
          <Col>
            <Link to="/CustomerList">
              <FaArrowCircleLeft /> Back to Customer List
            </Link>
          </Col>
        </Row>
        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
          PostFeedback={this.state.PostFeedback}
        />
        <div className="detailsContent">
          <>
            <Row>
              <Col>
                First, download template file - &nbsp;
                <CSVDownloader
                  data={this.state.DownloadData}
                  type="button"
                  filename={this.state.Filename}
                  bom={true}
                  className="btn btn-primary"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="CommitInfoTooltip" className="">
                        Download Template File
                      </Tooltip>
                    }
                  >
                    {/* To fix the overlayTrigger createRefs warning */}
                    <div>
                      <FaFileDownload />
                    </div>
                  </OverlayTrigger>
                </CSVDownloader>
              </Col>
            </Row>
            <Row>
              <Col className="col-form-label" sm={5}>
                Then populate the file with the customer bank details and upload
                here -
              </Col>
              <Col className="CSVReader">
                <CSVReader
                  ref={buttonRef}
                  config={{
                    header: true,
                    skipEmptyLines: true,
                  }}
                  onDrop={this.handleOnFileLoad}
                  onError={this.handleOnError}
                  addRemoveButton
                  noProgressBar
                  onRemoveFile={this.handleOnRemoveFile}
                >
                  {({ file }) => (
                    <Row>
                      {!file ? (
                        <Col
                          onClick={this.handleOpenDialog}
                          style={{
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          Drop template file here or click to upload.
                        </Col>
                      ) : (
                        <>
                          <Col>{file && file.name}</Col>
                          <div
                            className="CloseButton close cross"
                            onClick={this.handleRemoveFile}
                          >
                            <FaTimesCircle />
                          </div>
                        </>
                      )}
                    </Row>
                  )}
                </CSVReader>
              </Col>
            </Row>
            {this.state.ParsedData ? (
              <BankDetailsImportDBHandle
                rowData={this.state.ParsedData}
                MustPassModulusCheck={this.state.MustPassModulusCheck}
                errorList={this.handleError}
              />
            ) : null}
          </>
        </div>
      </div>
    );
  }
}

BankDetailsImport.contextType = AppContext;
export default withRouter(BankDetailsImport);
