import { Row, Col, Form } from "react-bootstrap";

const FormLabelbox = (props) => {
  return !props.hidden ? (
    <Form.Group className={"FormGroup"} as={Row} controlId={props.name}>
      <Form.Label column sm={props.LabelColumnAmount}>
        {props.Label}
      </Form.Label>
      {props.InputColumnAmount > 0 ? (
        <Col sm={props.InputColumnAmount}>
          <div className="form-control-plaintext">{props.placeholder}</div>
        </Col>
      ) : null}
    </Form.Group>
  ) : null;
};

export default FormLabelbox;
