import axios from "axios";
import apiError from "../../../libs/apiError";
import { saveAs } from "file-saver";
import { pdf /*,PDFViewer */ } from "@react-pdf/renderer";
import ReportSetup from "./ReportSetup";
import PDFTemplate from "./PDF/PDFTemplate";
import CSVHeaderSection from "./CSV/CSVHeaderSection";
import CSVContentSection from "./CSV/CSVContentSection";

function DDReport(DDReportData) {
  return new Promise((resolve, reject) => {
    ReportSetup()
      .then(async (r) => {
        await Promise.all([
          axios.get(
            "api/DdlistHeaders/GetDDReportContent/" +
              DDReportData.SiteID +
              "/" +
              DDReportData.CompanyID +
              "/" +
              DDReportData.DDListHeaderID +
              "/" +
              r.ShowZeros
          ),
        ])
          .then(async (responses) => {
            if (responses[0] && responses[0] !== undefined) {
              var ReportListData = responses[0].data;
            } else {
              throw new Error("No Report Data Found");
            }
            let ProductName = sessionStorage.getItem("productName"),
              theDDReportData = {
                ...DDReportData,
                ReportListData,
                ProductName,
              };

            //to output file
            let blob, LineEnding;
            if (r.OutputType === "PDF") {
              blob = await pdf(
                <PDFTemplate PDFTemplateData={theDDReportData} />
              ).toBlob();
              LineEnding = ".pdf";

              // Only do on Dev
              // resolve(
              //   <PDFViewer width="1000" height="600">
              //     <PDFTemplate PDFTemplateData={theDDReportData} />
              //   </PDFViewer>
              // );
            } else if (r.OutputType === "CSV") {
              let CSVOutput =
                CSVHeaderSection(theDDReportData) +
                CSVContentSection(theDDReportData);
              blob = new Blob([CSVOutput], {
                type: "text/plain;charset=utf-8",
              });
              LineEnding = ".csv";
            }
            saveAs(
              blob,
              `Report-${theDDReportData.CollectionListName}-${theDDReportData.CollectionDate}${LineEnding}`
            );
          })
          .catch((e) => {
            var message = apiError("GetDDReportContent: ", e);
            reject(message);
          });
      })
      .catch((e) => {
        var message = apiError("ReportSetup: ", e);
        reject(message);
      });
  });
}

export default DDReport;
