import {
  Paragraph,
  Document,
  Packer,
  TextRun,
  BorderStyle,
  Table,
  TableCell,
  TableRow,
  TableBorders,
  WidthType,
  ImageRun,
  TextWrappingType,
  HorizontalPositionRelativeFrom,
  HorizontalPositionAlign,
  VerticalPositionRelativeFrom,
  VerticalPositionAlign,
} from "docx";
import moment from "moment";
import RawStyles from "./styles.xml";
import JSZip from "jszip";

import { saveAs } from "file-saver";
import axios from "axios";
import apiError from "../../../libs/apiError";
import { getDimensions, ConvertNumToString } from "../../../libs/Variables";

const Borders = {
  top: {
    style: BorderStyle.SINGLE,
    size: 2,
  },
  bottom: {
    style: BorderStyle.NONE,
  },
  left: {
    style: BorderStyle.NONE,
  },
  right: {
    style: BorderStyle.NONE,
  },
};

const GetCustomerLetterElement = (Account, string) => {
  if (string)
    return string
      .replace("{FirstName}", Account[0].contactFirstName)
      .replace("{LastName}", Account[0].contactLastName)
      .replace("{CollectionDate}", Account[0].collectionDate);
};

const ColumnWidth = (TableLength) => ({
  size: 100 / TableLength,
  type: WidthType.PERCENTAGE,
});

function GenerateNotificationLetters(props) {
  return new Promise(async (resolve, reject) => {
    await Promise.all([
      axios.get(
        "api/DdlistCustomers/GetDdlistCustomerDetailed/" +
          props.SiteID +
          "/" +
          props.CompanyID +
          "/" +
          props.DDListHeaderID
      ),
      axios.get(
        "api/SyscompanySettings/GetCompanyLetterSettings/" +
          props.SiteID +
          "/" +
          props.CompanyID
      ),
    ])
      .then(async (responses) => {
        if (
          responses[0] &&
          responses[0] !== undefined &&
          responses[1] &&
          responses[1] !== undefined
        ) {
          let SetCustomerData = [],
            CustomerData = responses[0].data,
            CompanySettings = responses[1].data[0];

          CustomerData.forEach((element) => {
            if (element.collectionAmount !== 0) {
              var last =
                Object.keys(SetCustomerData)[
                  Object.keys(SetCustomerData).length - 1
                ];

              if (element.accountReference === last) {
                SetCustomerData[element.accountReference].push(element);
              } else {
                SetCustomerData[element.accountReference] = [element];
              }
            }
          });
          if (SetCustomerData) {
            SetCustomerData.forEach((element) => {
              element.sort((a, b) =>
                a.invoiceNumber.localeCompare(b.invoiceNumber)
              );

              element.forEach((InvoiceElement) => {
                if (InvoiceElement.theScheduleList.length >= 1) {
                  InvoiceElement.theScheduleList.sort((a, b) =>
                    a.scheduleDate.localeCompare(b.scheduleDate)
                  );
                }
              });
            });

            await SetLetterDocument(
              props,
              SetCustomerData,
              CompanySettings
            ).catch((e) => {
              throw new Error(e);
            });
          }
        } else {
          throw new Error("No Customer Summary data found");
        }
      })
      .catch((e) => {
        var message = apiError("Build Letter: ", e);
        reject(message);
      });
    resolve("Document sent for download.");
  });
}

export default GenerateNotificationLetters;

const SetLetterDocument = async (props, CustomerData, CompanySettings) => {
  return new Promise(async (resolve, reject) => {
    let doc,
      filelist = [];

    const zip = new JSZip();
    try {
      var CustomerDataLength = CustomerData.filter(
        (value) => Object.keys(value).length !== 0
      ).length;
      var CustomerCounter = 0;

      CustomerData.forEach(async (Account) => {
        var addressSection = new Paragraph({
          children: [
            new TextRun({ text: Account[0].accountName, break: 0 }),
            new TextRun({
              text: Account[0].addressLine1,
              break: Account[0].addressLine1 ? 1 : 0,
            }),
            new TextRun({
              text: Account[0].addressLine2,
              break: Account[0].addressLine2 ? 1 : 0,
            }),
            new TextRun({
              text: Account[0].addressLine3,
              break: Account[0].addressLine3 ? 1 : 0,
            }),
            new TextRun({
              text: Account[0].addressLine4,
              break: Account[0].addressLine4 ? 1 : 0,
            }),
            new TextRun({
              text: Account[0].county,
              break: Account[0].county ? 1 : 0,
            }),
            new TextRun({
              text: Account[0].city,
              break: Account[0].city ? 1 : 0,
            }),
            new TextRun({
              text: Account[0].country,
              break: Account[0].country ? 1 : 0,
            }),
            new TextRun({
              text: Account[0].postCode,
              break: Account[0].postCode ? 1 : 0,
            }),
          ],
        });

        const TitleSection = new Paragraph({
          text: "Advance Notification of Direct Debit Collections",
          style: "Heading1",
        });

        // let EmailSection = new Paragraph({
        //   text: Account[0].contactEmail ? Account[0].contactEmail : "",
        //   style: "Heading2",
        // });
        let AccountTable = new Table({
          borders: TableBorders.NONE,
          rows: [
            // new TableRow({
            //   children: [
            //     new TableCell({
            //       children: [new Paragraph("Creditor ID:")],
            //     }),
            //     new TableCell({
            //       children: [new Paragraph("")],
            //     }),
            //   ],
            // }),
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      text: "Authorisation attribute:",
                      style: "Strong1",
                    }),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph({ text: Account[0].accountReference }),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      text: "Collection date:",
                      style: "Strong1",
                    }),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph({ text: Account[0].collectionDate }),
                  ],
                }),
              ],
            }),
          ],
        });

        let LetterOpening = CompanySettings.letterOpening
          ? CompanySettings.letterOpening
          : `Dear {FirstName},`;

        LetterOpening = GetCustomerLetterElement(Account, LetterOpening);

        let DefaultSalutationText = `We have asked our bank to debit the invoices below from your account on {CollectionDate}.`;
        var SalutationText = DefaultSalutationText;
        if (
          CompanySettings.useLetterTextDefault &&
          CompanySettings.letterText
        ) {
          SalutationText = CompanySettings.letterText;
        }
        SalutationText = GetCustomerLetterElement(
          Account,
          SalutationText
        ).split("\n");

        let SalutationChildren = [
          new TextRun({
            text: LetterOpening,
            break: 1,
          }),
        ];
        SalutationText.forEach((Text) => {
          SalutationChildren.push(
            new TextRun({
              text: Text.trim(),
              break: 1,
            })
          );
        });

        let Salutation = new Paragraph({
          children: SalutationChildren,
        });

        var SetScheduleText = `Please find below a schedule of the collections to be made from your nominated bank account for payment of fees for the period shown.\n`;

        if (!CompanySettings.useScheduleText && CompanySettings.scheduleText) {
          SetScheduleText = CompanySettings.scheduleText;
        }
        SetScheduleText = GetCustomerLetterElement(
          Account,
          SetScheduleText
        ).split("\n");

        let ScheduleChildren = [];
        SetScheduleText.forEach((Text) => {
          ScheduleChildren.push(
            new TextRun({
              text: Text.trim(),
              break: 1,
            })
          );
        });

        let ScheduleText = new Paragraph({
          children: ScheduleChildren,
        });

        let InvoiceTableColumns = new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "Document No",
                  style: "Strong1",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Document Type",
                  style: "Strong1",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Document Date",
                  style: "Strong1",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Document Total",
                  style: "BoldRight",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Outstanding Amount",
                  style: "BoldRight",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "To Collect",
                  style: "BoldRight",
                }),
              ],
            }),
          ],
          tableHeader: true,
        });

        let ScheduleTableColumns = new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "Collection Date",
                  style: "Strong1",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Schedule Ref",
                  style: "Strong1",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Reference",
                  style: "Strong1",
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Amount",
                  style: "BoldRight",
                }),
              ],
            }),
          ],
          tableHeader: true,
        });

        let InvoiceTable,
          ScheduleTable,
          InvoiceTableRows = [InvoiceTableColumns],
          ScheduleTableRows = [ScheduleTableColumns],
          DocumentTotal = 0,
          OutstandingAmountTotal = 0,
          CollectedTotal = 0,
          ScheduleTotal = 0,
          SchedulesIncluded = false;

        Account.forEach((CustomerElement) => {
          if (CustomerElement.theScheduleList.length >= 1) {
            SchedulesIncluded = true;
            CustomerElement.theScheduleList.forEach((ScheduleElement) => {
              // if (ScheduleElement.collectionAmount !== 0) {

              ScheduleTableRows.push(
                new TableRow({
                  children: [
                    new TableCell({
                      width: ColumnWidth(ScheduleTableColumns.length),
                      children: [
                        new Paragraph({
                          text: moment(ScheduleElement.scheduleDate).format(
                            "DD-MM-YYYY"
                          ),
                        }),
                      ],
                    }),
                    new TableCell({
                      width: ColumnWidth(ScheduleTableColumns.length),
                      children: [
                        new Paragraph({ text: ScheduleElement.ddRun }),
                      ],
                    }),
                    new TableCell({
                      width: ColumnWidth(ScheduleTableColumns.length),
                      children: [
                        new Paragraph({ text: ScheduleElement.docNo }),
                      ],
                    }),
                    new TableCell({
                      width: ColumnWidth(ScheduleTableColumns.length),
                      children: [
                        new Paragraph({
                          text: ConvertNumToString(
                            ScheduleElement.collectionAmount
                          ),
                          style: "NormalRight",
                        }),
                      ],
                    }),
                  ],
                })
              );
              // }
              ScheduleTotal = ScheduleTotal + ScheduleElement.collectionAmount;
            });
          }

          InvoiceTableRows.push(
            new TableRow({
              children: [
                new TableCell({
                  width: ColumnWidth(InvoiceTableColumns.length),
                  children: [
                    new Paragraph({ text: CustomerElement.invoiceNumber }),
                  ],
                }),
                new TableCell({
                  width: ColumnWidth(InvoiceTableColumns.length),
                  children: [
                    new Paragraph({ text: CustomerElement.transactionType }),
                  ],
                }),

                new TableCell({
                  width: ColumnWidth(InvoiceTableColumns.length),
                  children: [
                    new Paragraph({ text: CustomerElement.transactionDate }),
                  ],
                }),
                new TableCell({
                  width: ColumnWidth(InvoiceTableColumns.length),
                  children: [
                    new Paragraph({
                      text: ConvertNumToString(CustomerElement.baseAmount),
                      style: "NormalRight",
                    }),
                  ],
                }),
                new TableCell({
                  width: ColumnWidth(InvoiceTableColumns.length),
                  children: [
                    new Paragraph({
                      text: ConvertNumToString(
                        CustomerElement.outstandingAmount
                      ),
                      style: "NormalRight",
                    }),
                  ],
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      text: ConvertNumToString(
                        CustomerElement.collectionAmount
                      ),
                      style: "NormalRight",
                    }),
                  ],
                }),
              ],
            })
          );
          DocumentTotal = DocumentTotal + CustomerElement.baseAmount;
          OutstandingAmountTotal =
            OutstandingAmountTotal + CustomerElement.outstandingAmount;
          CollectedTotal = CollectedTotal + CustomerElement.collectionAmount;
        });

        InvoiceTableRows.push(
          new TableRow({
            children: [
              new TableCell({
                borders: Borders,
                children: [
                  new Paragraph({
                    text: `Totals for Customer ${Account[0].accountName}`,
                    style: "Strong1",
                  }),
                ],
                columnSpan: [3],
              }),
              new TableCell({
                borders: Borders,
                children: [
                  new Paragraph({
                    text: ConvertNumToString(DocumentTotal),
                    style: "NormalRight",
                  }),
                ],
              }),
              new TableCell({
                borders: Borders,
                children: [
                  new Paragraph({
                    text: ConvertNumToString(OutstandingAmountTotal),
                    style: "NormalRight",
                  }),
                ],
              }),
              new TableCell({
                borders: Borders,
                children: [
                  new Paragraph({
                    text: ConvertNumToString(CollectedTotal),
                    style: "NormalRight",
                  }),
                ],
              }),
            ],
          })
        );

        InvoiceTable = new Table({
          borders: TableBorders.NONE,
          rows: InvoiceTableRows,
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
        });

        let image;
        if (CompanySettings.letterImage) {
          let letterImage = CompanySettings.letterImage;
          let imgDimensions = await getDimensions(letterImage);

          image = new Paragraph({
            children: [
              new ImageRun({
                data: Buffer.from(
                  letterImage.replace(/data.*base64,/, ""),
                  "base64"
                ),
                transformation: {
                  width: imgDimensions.width,
                  height: imgDimensions.height,
                },
                wrap: {
                  type: TextWrappingType.SQUARE,
                  side: TextWrappingType.BOTH_SIDES,
                },
                floating: {
                  horizontalPosition: {
                    relative: HorizontalPositionRelativeFrom.MARGIN,
                    align: HorizontalPositionAlign.RIGHT,
                  },
                  verticalPosition: {
                    relative: VerticalPositionRelativeFrom.MARGIN,
                    align: VerticalPositionAlign.TOP,
                  },
                },
              }),
            ],
          });
        }

        var newSection = {
          children: [
            addressSection,
            image,
            TitleSection,
            // EmailSection,
            AccountTable,
            Salutation,
            InvoiceTable,
          ],
        };

        if (SchedulesIncluded) {
          ScheduleTableRows.push(
            new TableRow({
              children: [
                new TableCell({
                  borders: Borders,
                  children: [
                    new Paragraph({
                      text: `Total Schedule Collection Amount`,
                      style: "Strong1",
                    }),
                  ],
                  columnSpan: [3],
                }),

                new TableCell({
                  borders: Borders,
                  children: [
                    new Paragraph({
                      text: ConvertNumToString(ScheduleTotal),
                      style: "NormalRight",
                    }),
                  ],
                }),
              ],
            })
          );

          ScheduleTable = new Table({
            borders: TableBorders.NONE,
            rows: ScheduleTableRows,
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
          });
          newSection.children.push(ScheduleText, ScheduleTable);
        }

        await axios.get(RawStyles).then((response) => {
          doc = new Document({
            externalStyles: response.data,
            sections: [newSection],
          });

          Packer.toBlob(doc)
            .then((blob) => {
              filelist.push({
                theFilename: `${props.CollectionListName}-${
                  Account[0].accountReference
                }-${
                  Account[0].contactEmail
                    ? Account[0].contactEmail
                    : "No_Email_Address_Supplied"
                }-Notification_Letter.docx`,
                theBlob: blob,
              });
            })
            .then(() => {
              CustomerCounter++;
              if (CustomerCounter === CustomerDataLength) {
                filelist.forEach((element) => {
                  zip.file(element.theFilename, element.theBlob);
                });
                zip.generateAsync({ type: "blob" }).then((content) => {
                  saveAs(
                    content,
                    `${props.CollectionListName}-Notification_Letters.zip`
                  );
                });
                resolve("Document sent for download.");
              }
            });
        });
      });
    } catch (e) {
      reject(e);
    }
  });
};
