import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { AppContext } from "../libs/contextLib";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FaSignInAlt } from "react-icons/fa";
import { AppConsumer } from "../libs/contextLib";
import * as yup from "yup";
import moment from "moment";
import FormInputBox from "../components/Form/FormInputbox";
import FormCheck from "../components/Form/FormCheck";
import SetupMFA from "../components/Login/SetupMFA";
import axios from "axios";
import apiError from "../libs/apiError";
import withRouter from "../libs/withRouter";
import { trackPromise } from "react-promise-tracker";
import ipRangeCheck from "ip-range-check";
import { getIPRange } from "get-ip-range";
import queryString from "query-string";
import SetSettingsList from "../components/SetSettingsList";
import OutputMessage from "../components/OutputMessage";
import {
  GetSageLogin,
  GetSageAccessToken,
} from "../components/Login/SageLogin";
import {
  passRegex,
  PasswordRequirements,
  emailRegex,
  emailRegexMessage,
  inputRegex,
  inputRegexMessage,
} from "../libs/Variables";

const schemaPassword = yup
    .string()
    .required("Password is required")
    .min(12, "Password does not meet requirements")
    .max(160, "Password does not meet requirements")
    .matches(passRegex, "Password does not meet requirements"),
  schemaUsername = yup
    .string()
    .required("Username is required")
    .matches(emailRegex, emailRegexMessage)
    .max(100, "Username must be less than 100 characters"),
  schemaSiteName = yup
    .string()
    .required("Site Name is required")
    .matches(inputRegex, "Site Name" + inputRegexMessage)
    .max(100, "Site Name must be less than 100 characters");

const schemaLogin = yup.object().shape({
    password: schemaPassword,
    username: schemaUsername,
    siteName: schemaSiteName,
  }),
  schemaPasswordReset = yup.object({
    newPassword: schemaPassword,
  });

const LabelColumnAmount = 2,
  InputColumnAmount = 8;

class Login extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    let setSiteName = sessionStorage.getItem("siteName");
    this.state = {
      UserOBJ: null,
      newPassword: "",
      username: "",
      password: "",
      siteName: setSiteName,
      errorList: [],
      authErrorList: [],
      CheckMFA: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleCompleteLogin = this.handleCompleteLogin.bind(this);

    if (this.props.location.state && this.props.location.state !== undefined) {
      this.state.PasswordResetSuccess =
        this.props.location.state.PasswordResetSuccess;
    }
  }

  async getIP() {
    const type = {
      v4: {
        httpsUrl: "https://api.ipify.org/",
      },
      v6: {
        httpsUrl: "https://api64.ipify.org/",
      },
    };

    const getIp = async (version) => {
      const data = type[version];
      try {
        const response = await fetch(data.httpsUrl);
        const ip = response.text();
        return ip;
      } catch (e) {
        return null;
      }
    };

    let ipv4 = await getIp("v4");
    let ipv6 = await getIp("v6");
    this.setState({ IP4: ipv4, IP6: ipv6 });
  }
  componentDidMount() {
    this.getIP();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.context.SAGE_CLIENT_ID) {
      this.checkSageAccessToken();
    }
  }

  handleCallback = (name, theData) => {
    this.setState((state) => {
      var pattErrorList = new RegExp("errorList");
      if (pattErrorList.test(name)) {
        state.errorList = [...state.errorList, theData];
      } else {
        state[name] = theData;
      }
      return state;
    });
  };

  async checkSageAccessToken() {
    let parsed = queryString.parse(window.location.search);
    if (parsed.code) {
      await GetSageAccessToken(
        parsed.code,
        this.context.SAGE_CLIENT_ID,
        this.context.SAGE_CLIENT_SECRET
      )
        .then((SageAccessToken) => {
          this.context.setSageAccessToken(SageAccessToken);
          sessionStorage.setItem("SageAccessToken", SageAccessToken);
          this.props.navigate(parsed.state);
        })
        .catch((e) => {
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, e.error_description],
          }));
        });
    }
  }

  async FinishLogin(Obj) {
    this.GetUserSettings();
    let now = moment().format("DD-MM-YYYY HH:mm:ss");
    sessionStorage.setItem("LoggedInTime", now);
    this.context.setUsr(Obj.attributes.email);

    await axios
      .put("api/UserSites/UpdateUserLastLoggedIn/" + this.state.username)
      .then(() => {})
      .catch((e) => {
        var message = apiError("UpdateUserLastLoggedInAPI: ", e);
        if (!this.state.authErrorList.includes(message)) {
          this.setState((previousState) => ({
            authErrorList: [...previousState.authErrorList, message],
          }));
        }
      });
  }

  async handleCompleteLogin(e) {
    e.preventDefault();
    await trackPromise(
      Auth.confirmSignIn(
        this.state.UserOBJ,
        this.state.MFACode,
        "SOFTWARE_TOKEN_MFA"
      )
        .then(async (response) => {
          if (this.state.RememberDevice) {
            try {
              await Auth.rememberDevice();
            } catch (e) {
              throw new Error(e);
            }
          }
          this.FinishLogin(response);
        })
        .catch((e) => {
          var message = apiError("API MFA Sign In: ", e);
          if (!this.state.authErrorList.includes(message)) {
            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, message],
            }));
          }
        })
    );
  }

  CallSetupMFA(UserOBJ) {
    SetupMFA(UserOBJ)
      .then(() => {
        this.FinishLogin(UserOBJ);
      })
      .catch((e) => {
        var message = apiError("API MFA Sign In: ", e);
        if (!this.state.authErrorList.includes(message)) {
          this.setState((previousState) => ({
            authErrorList: [...previousState.authErrorList, message],
          }));
        }
      });
  }

  async handleLogin() {
    await trackPromise(
      Auth.signIn(this.state.username, this.state.password)
        .then(async (UserOBJ) => {
          let User = {
            UserOBJ: UserOBJ,
            attributes: {
              email: this.state.username,
              Environment: this.context.Environment,
            },
          };

          if (
            UserOBJ.preferredMFA === "SMS_MFA" ||
            UserOBJ.preferredMFA === "SOFTWARE_TOKEN_MFA"
          ) {
            if (UserOBJ.signInUserSession.accessToken.jwtToken) {
              this.FinishLogin(UserOBJ);
            }
          }

          if (UserOBJ.preferredMFA === "NOMFA") {
            //this.CallSetupMFA(User);
            if (UserOBJ.signInUserSession.accessToken.jwtToken) {
              this.FinishLogin(UserOBJ);
            }
          } else {
            switch (UserOBJ.challengeName) {
              case "NEW_PASSWORD_REQUIRED":
                this.setState({
                  UserOBJ: User,
                  validated: false,
                  errorList: [],
                  newPassword: "",
                  password: "",
                });
                break;

              case "MFA_SETUP":
                this.CallSetupMFA(User);

                break;

              case "SMS_MFA":
              case "SOFTWARE_TOKEN_MFA":
                // this.setState({ CheckMFA: true, UserOBJ: UserOBJ });
                this.setState({ CheckMFA: false, UserOBJ: UserOBJ });
                break;
              default:
                break;
            }
          }
        })
        .catch((e) => {
          var message = apiError("API Sign In: ", e);
          if (!this.state.authErrorList.includes(message)) {
            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, message],
            }));
          }
        })
    );
  }

  async GetUserSettings() {
    await trackPromise(
      Promise.all([
        axios.get(
          "/api/UserSites/GetUserSettings/" +
            this.state.siteID +
            "/" +
            this.state.username
        ),
      ])
        .then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            var placeholderArr =
              responses[0].data[responses[0].data.length - 1];
            if (placeholderArr.isActive) {
              let SettingsList = SetSettingsList(placeholderArr);
              var ProductName = sessionStorage.getItem("productName");
              if (
                ProductName === "Sage 200c Standard" &&
                !this.context.SageAccessToken
              ) {
                GetSageLogin(this.context.SAGE_CLIENT_ID);
              } else {
                this.setState({ DataLoaded: true, CheckMFA: false }, () => {
                  this.context.setIsAuthenticated(true);
                  this.context.setSettingsList(SettingsList);
                  this.props.navigate("/");
                });
              }
            } else {
              throw new Error("Issue with Login - User might be Inactive.");
            }
          }
        })
        .catch((e) => {
          var message = apiError("API User Settings Get: ", e);
          if (!this.state.authErrorList.includes(message)) {
            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, message],
            }));
          }
        })
    );
  }

  async handleReset(e) {
    e.preventDefault();
    this.setState({ errorList: [], authErrorList: [] }, () => {
      let validateString = {
        newPassword: this.state.newPassword,
      };
      this.validateForm(schemaPasswordReset, validateString).then(async () => {
        if (this.state.validated) {
          await trackPromise(
            Auth.completeNewPassword(
              this.state.UserOBJ.UserOBJ,
              this.state.newPassword
            )
              .then((setResetUser) => {
                this.setState(
                  {
                    UserOBJ: null,
                    password: this.state.newPassword,
                    username: setResetUser.challengeParam.userAttributes.email,
                  },
                  () => {
                    this.handleLogin();
                  }
                );
              })
              .catch((e) => {
                var message = apiError("API Complete new password: ", e);
                if (!this.state.authErrorList.includes(message)) {
                  this.setState((previousState) => ({
                    authErrorList: [...previousState.authErrorList, message],
                  }));
                }
              })
          );
        }
      });
    });
  }

  async validateForm(schema, validateString) {
    var validateResult = await trackPromise(
      schema
        .validate(validateString, { abortEarly: false })
        .then(() => {
          return true;
        })
        .catch((e) => {
          var message = {};

          e.inner.forEach((e) => {
            message[e.path] = e.message;
          });
          return message;
        })
    );

    if (validateResult === true) {
      this.setState({ validated: true, errorList: "" }, () => {
        return true;
      });
    } else {
      this.setState({ validated: false, errorList: validateResult }, () => {});
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ errorList: [], authErrorList: [] }, () => {
      let validateString = {
        username: this.state.username,
        password: this.state.password,
        siteName: this.state.siteName,
      };
      this.validateForm(schemaLogin, validateString).then(() => {
        if (this.state.validated) {
          this.getSiteNameSubmit();
        }
      });
    });
  }

  async getSiteNameSubmit() {
    await trackPromise(
      Promise.all([
        axios.get("/api/sitesettings/" + this.state.siteName).catch((e) => {
          var message = apiError("API SiteSettings: ", e);
          if (!this.state.authErrorList.includes(message)) {
            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, message],
            }));
          }
        }),
        axios
          .get(
            "/api/UserSites/GetUserLastLoggedIn/" +
              this.state.siteName +
              "/" +
              this.state.username
          )
          .catch((e) => {
            var message = apiError("API SiteSettings: ", e);
            if (!this.state.authErrorList.includes(message)) {
              this.setState((previousState) => ({
                authErrorList: [...previousState.authErrorList, message],
              }));
            }
          }),
      ])
        .then(async (responses) => {
          if (!responses[0]) {
            throw new Error("Issue getting Site Settings.");
          }
          if (!responses[1]) {
            throw new Error("Issue getting Time User Last Logged on.");
          }
          let SageInfo = responses[0].data;
          let siteID = SageInfo[0].siteID;
          let SiteSettingsID = SageInfo[0].siteSettingsID;
          let productName = SageInfo[0].productName;
          let IPWhitelistSetting = SageInfo[0].useIPWhitelist;
          let UserLastLoggedIn = responses[1].data.lastLoggedIn;
          let DeviceExpireLength = responses[1].data.deviceExpireLength;

          var date = moment(UserLastLoggedIn, "DD-MM-YYYY HH:mm:ss"),
            LoggedInTimePlusExpireLength = date
              .add(DeviceExpireLength, "days")
              .format("DD-MM-YYYY HH:mm:ss"),
            dateToCompare = moment(
              LoggedInTimePlusExpireLength,
              "DD-MM-YYYY HH:mm:ss"
            );
          let now = moment(moment(), "DD-MM-YYYY HH:mm:ss");

          if (now.isSameOrAfter(dateToCompare)) {
            localStorage.clear();
          }

          if (siteID) {
            if (IPWhitelistSetting) {
              await trackPromise(
                Promise.all([
                  axios
                    .get("/api/Ipwhitelists/" + this.state.siteName)
                    .catch((e) => {
                      var message = apiError("API Whitelist: ", e);
                      if (!this.state.authErrorList.includes(message)) {
                        this.setState((previousState) => ({
                          authErrorList: [
                            ...previousState.authErrorList,
                            message,
                          ],
                        }));
                      }
                    }),
                ]).then((response) => {
                  if (!response[0]) {
                    throw new Error(
                      "No IP whitelist data found for this Site."
                    );
                  }
                  let IPWhiteList = response[0].data;
                  var matchFound = false;

                  IPWhiteList.forEach((element) => {
                    if (!matchFound) {
                      //check for a valid single IP address
                      if (
                        element.ipAddressFrom !== null &&
                        (ipRangeCheck(this.state.IP4, element.ipAddressFrom) ||
                          ipRangeCheck(this.state.IP6, element.ipAddressFrom))
                      ) {
                        matchFound = true;
                      }

                      //check for a match in the CIDR Range
                      if (
                        element.cidrrange !== null &&
                        (ipRangeCheck(this.state.IP4, element.cidrrange) ||
                          ipRangeCheck(this.state.IP6, element.cidrrange))
                      ) {
                        matchFound = true;
                      }

                      //check for a match in the given IP range
                      if (
                        element.ipAddressTo !== null &&
                        element.ipAddressFrom !== null
                      ) {
                        var range = getIPRange(
                          element.ipAddressFrom,
                          element.ipAddressTo
                        );
                        if (range.includes(this.state.IP4)) {
                          matchFound = true;
                        }
                      }
                    }
                  });

                  if (!matchFound) {
                    throw new Error("Your IP address is not in the whitelist.");
                  }
                })
              );
            }

            sessionStorage.setItem("siteID", siteID);
            sessionStorage.setItem("SiteSettingsID", SiteSettingsID);
            sessionStorage.setItem("productName", productName);
            this.context.IPWhitelistSetting = IPWhitelistSetting;
            sessionStorage.setItem("siteName", this.state.siteName);
            this.handleLogin();
            this.setState({ siteID });
          } else {
            throw new Error("No Active Site Found.");
          }
        })
        .catch((e) => {
          sessionStorage.setItem("siteID", "");
          sessionStorage.setItem("SiteSettingsID", "");
          sessionStorage.setItem("productName", "Sage Intacct");
          sessionStorage.setItem("siteName", "");
          var message = apiError("API Get: ", e);
          if (!this.state.authErrorList.includes(message)) {
            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, message],
            }));
          }
        })
    );
  }

  renderMFACheck() {
    let RememberDeviceLabel = (
      <>
        Remember Device
        {this.state.RememberDevice ? (
          <div
            style={{
              position: "absolute",
              left: "15em",
              top: "0.5em",
              width: "100%",
              textAlign: "left",
            }}
          >
            Please note - Device will only be remembered for a certain amount of
            time.
          </div>
        ) : null}
      </>
    );

    return (
      <Form onSubmit={this.handleCompleteLogin}>
        <Row>
          <Col sm={LabelColumnAmount}></Col>
          <Col sm={InputColumnAmount}>
            <p>Please enter your MFA code to complete sign-in.</p>
          </Col>
        </Row>
        <FormInputBox
          type="text"
          Label="MFA Code"
          name="MFACode"
          placeholder=""
          value={this.handleCallback}
          LabelColumnAmount={3}
          InputColumnAmount={6}
          errorList={this.state.errorList}
        />

        <FormCheck
          type="switch"
          Label={RememberDeviceLabel}
          name="RememberDevice"
          id="RememberDevice"
          initialState={this.state.RememberDevice}
          value={this.handleCallback}
          LabelColumnAmount={3}
          InputColumnAmount={1}
          errorList={this.state.errorList}
        />

        <Row>
          <Col sm={LabelColumnAmount}></Col>
          <Col sm={5}>
            <Button variant="primary" type="submit">
              <FaSignInAlt /> Go
            </Button>
            {this.state.authErrorList.length !== 0 ? (
              <div className="invalid-tooltip">{this.state.authErrorList}</div>
            ) : null}
          </Col>
        </Row>
      </Form>
    );
  }
  renderPasswordReset() {
    return (
      <Form onSubmit={this.handleReset}>
        <Row>
          <Col sm={LabelColumnAmount}></Col>
          <Col sm={InputColumnAmount}>
            <p>You are required to change your password.</p>
            {PasswordRequirements()}
          </Col>
        </Row>

        <FormInputBox
          type="password"
          Label="New Password"
          name="newPassword"
          placeholder=""
          value={this.handleCallback}
          LabelColumnAmount={3}
          InputColumnAmount={6}
          errorList={this.state.errorList}
          schema={schemaPassword}
          autoComplete="new-password"
        />

        <Row>
          <Col sm={LabelColumnAmount}></Col>
          <Col sm={5}>
            <Button variant="primary" type="submit">
              <FaSignInAlt /> Go
            </Button>
            {this.state.authErrorList.length !== 0 ? (
              <div className="invalid-tooltip">{this.state.authErrorList}</div>
            ) : null}
          </Col>
        </Row>
      </Form>
    );
  }

  renderLogin() {
    return (
      <>
        {this.state.PasswordResetSuccess ? (
          <>
            <h4>Password successfully reset</h4>
            <p>Please login as normal using your new password</p>
          </>
        ) : null}
        <Form onSubmit={this.handleSubmit}>
          <FormInputBox
            type="text"
            Label="Site Name"
            name="siteName"
            placeholder={this.state.siteName}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            errorList={this.state.errorList}
            schema={schemaSiteName}
          />

          <FormInputBox
            type="text"
            Label="Username"
            name="username"
            placeholder={this.state.username}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            errorList={this.state.errorList}
            schema={schemaUsername}
          />

          <FormInputBox
            type="password"
            Label="Password"
            name="password"
            placeholder={this.state.password}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            errorList={this.state.errorList}
            schema={schemaPassword}
          />

          <Row>
            <Col sm={LabelColumnAmount}></Col>
            <Col sm={5} className="IconBtn">
              <Button variant="primary" type="submit">
                <FaSignInAlt /> Go
              </Button>
              {this.state.authErrorList.length !== 0 ? (
                <div className="invalid-tooltip">
                  {this.state.authErrorList}
                </div>
              ) : null}
            </Col>
            <Col sm={5}>
              <Link to="/ResetPassword">
                <Button variant="primary">Forgot Password?</Button>
              </Link>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  render() {
    let params = this.props.location.state;
    return (
      <AppConsumer>
        {() => (
          <>
            <h1>Login</h1>
            <div className="Login">
              {params ? (
                <OutputMessage
                  errorList={this.state.errorList}
                  updateSuccess={this.state.updateSuccess}
                  AddedSuccess={this.state.AddedSuccess}
                  PostFeedback={params.PostFeedback}
                />
              ) : null}

              {this.state.CheckMFA ? (
                this.renderMFACheck()
              ) : (
                <>
                  {this.state.UserOBJ === null
                    ? this.renderLogin()
                    : this.renderPasswordReset()}
                </>
              )}
            </div>
          </>
        )}
      </AppConsumer>
    );
  }
}

Login.contextType = AppContext;

export default withRouter(Login);
