import React, { Component } from "react";
import Popup from "reactjs-popup";
import { FaTimesCircle, FaPlus, FaSave } from "react-icons/fa";
import { Form, Row, Col, Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import * as yup from "yup";
import FormInputBox from "../../Form/FormInputbox";
import FormValuesInput from "../../Form/FormValuesInput";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
  inputRegex,
  inputRegexMessage,
  ValueInputRegex,
  ValueInputRegexMessage,
  RuntimeValuesMessage,
} from "../../../libs/Variables";
import apiError from "../../../libs/apiError";
import GetDatetime from "../../../libs/GetDatetime";
import { handleValidation } from "../../handleValidation";
import DuplicateRuntimeFilterChecker from "./DuplicateRuntimeFilterChecker";

const FilterFieldNameSchema = yup
    .string()
    .required()
    .matches(inputRegex, "Filter Field Name" + inputRegexMessage)
    .max(60, "Filter Field Name must be less than 60 characters"),
  FilterSetNameSchema = yup
    .string()
    .required()
    .matches(inputRegex, "Filter Set Name" + inputRegexMessage)
    .max(60, "Filter Set Name must be less than 60 characters"),
  FilterValueSchema = yup
    .string()
    .nullable()
    .matches(ValueInputRegex, ValueInputRegexMessage)
    .max(60, "This Value must be less than 60 characters");

let blankState = {
  SiteID: sessionStorage.getItem("siteID"),
  CompanyID: sessionStorage.getItem("CompanyID"),
  errorList: [],
  filterFieldName: undefined,
  filterSetName: undefined,
  filterValue: [
    {
      sysddFilterValuesID: 0,
      filterValue: "",
    },
  ],
  validationErrorList: "",
};

class NewRuntimeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = blankState;
  }
  componentDidMount() {
    this.setState({ blankState });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleCallback = (name, theData) => {
    // console.log(name, theData);
    this.setState((state) => {
      var pattFilterFieldName = new RegExp("filterFieldName");
      var pattFilterValue = new RegExp("filterValue");
      if (pattFilterFieldName.test(name)) {
        state["filterFieldName"] = theData.toUpperCase();
      } else if (pattFilterValue.test(name)) {
        let str = String(name);
        let currentKey = str.slice(-1);
        if (this.state.filterValue[currentKey] !== undefined) {
          state.filterValue[currentKey].filterValue = theData;
        } else {
          state.filterValue[currentKey] = {
            sysddFilterValuesID: 0,
            filterValue: theData,
          };
        }
      } else {
        state[name] = theData;
      }
      return state;
    });
  };

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "filterFieldName",
          data: this.state.filterFieldName,
          schema: FilterFieldNameSchema,
        },
        {
          name: "filterSetName",
          data: this.state.filterSetName,
          schema: FilterSetNameSchema,
        },
      ];

      var ValueList = [];
      for (let element of this.state.filterValue) {
        if (element.filterValue !== undefined) {
          ValueList.push(element.filterValue);
        }
      }
      if (ValueList.length === 1 && ValueList[0] === "") {
        reject(["At least one Filter Value is required."]);
      }

      this.state.filterValue.forEach((element, i) => {
        items.push({
          name: "filterValue" + i,
          data: element.filterValue,
          schema: FilterValueSchema,
        });
      });

      var Status = [];
      items.forEach(async (element, i, k) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            // console.log(e);
            reject(e);
          });
        if (i === k.length - 1) {
          DuplicateRuntimeFilterChecker(
            this.props.CurrentFilters,
            this.state,
            true
          )
            .then(() => {
              if (!Status.includes(false)) {
                resolve(true);
              }
            })
            .catch((e) => {
              // console.log(e);
              reject(e);
            });
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    return new Promise((resolve) => {
      this.validateForm()
        .then(() => {
          this.setState({ validationErrorList: "" }, () => {
            this.DoSubmit();
            resolve(true);
          });
        })
        .catch((e) => {
          this.setState({
            validationErrorList: JSON.stringify(e),
          });
        });
    });
  }

  handleReset(e) {
    e.preventDefault();
    this.resetForm();
  }
  resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce(
      (acc, v) => ({ ...acc, [v]: undefined }),
      {}
    );
    this.setState({
      ...stateReset,
      ...blankState,
      filterFieldName: undefined,
      filterSetName: undefined,
      filterValue: [
        {
          sysddFilterValuesID: 0,
          filterValue: "",
        },
      ],
    });
  }

  async DoSubmit() {
    let output = this.state;

    let DateTimeUpdated = GetDatetime();
    let DateTimeCreated = GetDatetime();

    let outputJSON = {
      SiteID: sessionStorage.getItem("siteID"),
      CompanyID: sessionStorage.getItem("CompanyID"),
      filterSetName: output.filterSetName,
      filterFieldName: output.filterFieldName,
      filterValueList: output.filterValue,
      DateTimeUpdated: DateTimeUpdated,
      DateTimeCreated: DateTimeCreated,
    };

    // console.log(outputJSON);
    await trackPromise(
      axios
        .post("api/SysddfilterHeadings/", outputJSON)
        .then((results) => {
          this.props.AddedSuccess("AddedSuccess", true);
          this.resetForm();
        })
        .catch((e) => {
          var message = apiError("PostAPI: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
          this.props.errorList("errorList", message);
        })
    );
  }

  render() {
    return (
      <>
        <Popup
          className="RuntimeFilter"
          trigger={
            <Button variant="primary">
              <FaPlus /> Add New
            </Button>
          }
          modal
          nested
          closeOnDocumentClick={false}
        >
          {(Close) => (
            <div className="Modal">
              <button
                className="close"
                onClick={(e) => {
                  this.handleReset(e);
                  Close();
                }}
              >
                <FaTimesCircle />
              </button>
              <div className="Title">
                <h2>Add New Runtime Filter</h2>
              </div>

              <div className="content">
                <Form id="NewRuntimeFilterForm">
                  <FormInputBox
                    type="text"
                    Label="Filter Set Name"
                    name="filterSetName"
                    placeholder={this.state.filterSetName}
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    required={false}
                    errorList={this.state.errorList}
                    schema={FilterSetNameSchema}
                  />

                  <FormInputBox
                    type="text"
                    Label="Filter Field Name"
                    name="filterFieldName"
                    placeholder={this.state.filterFieldName}
                    value={this.handleCallback}
                    ConvertToUppercase="true"
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    required={false}
                    errorList={this.state.errorList}
                    schema={FilterFieldNameSchema}
                  />

                  {RuntimeValuesMessage}

                  <div className="Values">
                    <Row>
                      <Form.Label column sm={LabelColumnAmount}>
                        Filter Values
                      </Form.Label>
                      <Col sm={8}>
                        <FormValuesInput
                          type="text"
                          Label=""
                          name="filterValue"
                          placeholder={[""]}
                          value={this.handleCallback}
                          LabelColumnAmount={LabelColumnAmount}
                          InputColumnAmount={InputColumnAmount}
                          required={false}
                          errorList={this.state.errorList}
                          schema={FilterValueSchema}
                          UnlimitedValues={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
                <Row>
                  <Col sm={LabelColumnAmount}></Col>
                  <Col sm={4} className="IconBtn">
                    <Button
                      variant="primary"
                      onClick={async (e) => {
                        await this.handleSubmit(e).then(() => {
                          Close();
                        });
                      }}
                      type="submit"
                    >
                      <FaSave /> Save
                    </Button>

                    {this.state.validationErrorList
                      ? ValidationError(this.state.validationErrorList)
                      : null}

                    <Button
                      variant="primary"
                      onClick={(e) => {
                        this.handleReset(e);
                        Close();
                      }}
                    >
                      <FaTimesCircle /> Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Popup>
      </>
    );
  }
}

export default NewRuntimeFilter;
