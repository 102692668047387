import React, { Component } from "react";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { FaInfoCircle, FaTimesCircle } from "react-icons/fa";
import Dropzone from "react-dropzone";
import IsError from "../components/IsError";
import apiError from "../libs/apiError";
import withRouter from "../libs/withRouter";
import { AppContext } from "../libs/contextLib";
import { Row, Col, Form } from "react-bootstrap";
import GetDatetime from "../libs/GetDatetime";
import FormDropdown from "../components/Form/FormDropdown";
import FormDatePicker from "../components/Form/FormDatePicker";
import FormLabelbox from "../components/Form/FormLabelbox";
import OutputMessage from "../components/OutputMessage";
import { LabelColumnAmount, InputColumnAmount } from "../libs/Variables";
import ARUDDFileProcessor from "../components/ARUDD/ARUDDFileProcessor";
import ARUDDConsoleSelection from "../components/ARUDD/ARUDDConsoleSelection";
import TooltipActionButtonPlacedAbove from "../components/TooltipActionButtonPlacedAbove";

const parseString = require("xml2js").parseString;

let BlankState = {
  NoData: false,
  errorList: [],
  OutputList: [],
  files: [],
  AddedSuccess: false,
};

class ARUDD extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = BlankState;
    this.updateFiles = this.updateFiles.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.FormReset) {
      this.resetForm();
    }
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    let SiteID = sessionStorage.getItem("siteID");
    let productName = sessionStorage.getItem("productName");

    if (productName === "Sage 200c Standard") {
      this.setState({
        Unsupported: true,
      });
    } else {
      await trackPromise(
        Promise.all([
          axios
            .get("/api/DdrejectionHeaders/GetDdlistForARUDD/" + SiteID)
            .catch((e) => {
              var message = apiError("API GetDdlistForARUDD Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
                NoData: true,
              }));
            }),
          axios
            .get("/api/Syscompanies/GetSyscompanies/" + SiteID)
            .catch((e) => {
              var message = apiError("API Syscompanies Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
        ])
          .then((responses) => {
            if (responses[0] && responses[1]) {
              let TransactionReversalDate = GetDatetime(true),
                FullDDList = responses[0].data,
                CompanyList = responses[1].data;
              FullDDList.unshift({ id: 0, content: "" });
              CompanyList.unshift({ id: 0, content: "" });
              this.setState({
                FullDDList,
                CompanyList,
                PlaceholderListFilled: true,
                TransactionReversalDate: TransactionReversalDate,
              });
            } else {
              throw new Error("No Direct Debits Found.");
            }
          })
          .catch((e) => {
            var message = apiError("API Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
              NoData: true,
            }));
          })
      );
    }
  }

  handleCallback = (name, theData) => {
    // console.log(name);
    // console.log(theData);
    const theState = this.state;
    this.setState((state) => {
      state.errorList = [];
      var pattDDListID = new RegExp("DDListHeaderID");
      var pattFormReset = new RegExp("FormReset"),
        pattCompanyID = new RegExp("CompanyID");

      if (pattCompanyID.test(name)) {
        let a = [];
        if (!theData) {
          theData = 0;
        }

        theState.FullDDList.forEach((element) => {
          var CompanyID = parseInt(element.companyID);
          if (CompanyID === parseInt(theData)) {
            a[element.id] = {
              ...element,
            };
          }
        });

        if (a.length > 0) {
          var index = a.findIndex((item) => item);

          state.SelectedCustomerList = a[index].customerList;
          state.DDListHeaderID = a[index].id;
          state.CurrencySymbol = a[index].currencyCode;
          state.RejectionOption = a[index].rejectionOption;
          state.OriginalProcessingDate = a[index].collectionDate;
          state.RunSelected = a[index].content;
        }
        state.DDList = a;
      } else if (pattDDListID.test(name)) {
        let a = {};

        theState.DDList.forEach((element) => {
          a[element.id] = {
            SelectedCustomerList: element.customerList,
            DDListHeaderID: element.id,
            CurrencySymbol: element.currencyCode,
            CompanyID: element.companyID,
            RejectionOption: element.rejectionOption,
            OriginalProcessingDate: element.collectionDate,
            RunSelected: element.content,
          };
        });

        if (!theData) {
          theData = 0;
        }
        state.SelectedCustomerList = a[theData].SelectedCustomerList;
        state.DDListHeaderID = a[theData].DDListHeaderID;
        state.CurrencySymbol = a[theData].CurrencySymbol;
        state.RejectionOption = a[theData].RejectionOption;
        state.OriginalProcessingDate = a[theData].OriginalProcessingDate;
        state.RunSelected = a[theData].RunSelected;
      } else if (pattFormReset.test(name)) {
        state["resetNeeded"] = true;
      }
      state[name] = theData;
      return state;
    });
  };

  handleError = (message) => {
    if (message) {
      this.setState((previousState) => ({
        errorList: [...previousState.errorList, message],
      }));
    } else {
      this.setState({ errorList: [] });
    }
  };

  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState(
      {
        ...stateReset,
        ...BlankState,
        file: null,
        filename: null,
        resetNeeded: false,
      },
      () => {
        this.loadData();
      }
    );
  }

  removeFile(e) {
    e.preventDefault();
    this.resetForm();
  }

  updateFiles = (files) => {
    const [file] = files,
      reader = new FileReader();
    reader.addEventListener("load", () => {
      this.setState({ file: reader.result, filename: file.name }, () => {
        this.parseFile();
      });
    });
    if (file) {
      reader.readAsText(file);
    }
  };

  parseFile() {
    parseString(this.state.file, (e, result) => {
      this.setState({ ParsedData: result });
    });
  }

  renderContent() {
    let DDRejectionHeaderInfo = {
      OriginalProcessingDate: this.state.OriginalProcessingDate,
      RejectionDate: this.state.TransactionReversalDate,
      DDListHeaderID: this.state.DDListHeaderID,
      FileName: this.state.filename,
      CompanyID: this.state.CompanyID,
      CurrencySymbol: this.state.CurrencySymbol,
      RunSelected: this.state.RunSelected,
    };

    return (
      <>
        <TooltipActionButtonPlacedAbove
          LinkTo={{ pathname: `/ARUDDResults`, state: {} }}
          ToolTipLabel="View Past ARUDD Submissions"
          Label="View Past ARUDD Submissions"
          Icon={<FaInfoCircle />}
        />
        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
          PostFeedback={this.state.PostFeedback}
        />
        <div className="detailsContent">
          <Form
            id="GenerateReverseDDForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <div id="sectionOne">
              <FormDropdown
                id="CompanyID"
                Label="Company"
                name="CompanyID"
                placeholder={this.state.CompanyID}
                itemContent={this.state.CompanyList}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={4}
                errorList={this.state.errorList}
              />
            </div>

            {this.state.DDList && this.state.DDList.length > 0 ? (
              <>
                <FormDropdown
                  id="DDListHeaderID"
                  Label="Direct Debit to Reverse Against"
                  name="DDListHeaderID"
                  placeholder={this.state.DDListHeaderID}
                  itemContent={this.state.DDList}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={4}
                  errorList={this.state.errorList}
                />
                <FormDatePicker
                  Label="Transaction Reversal Date"
                  name="TransactionReversalDate"
                  placeholder={this.state.TransactionReversalDate}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={4}
                  errorList={this.state.errorList}
                />
                {this.state.TransactionReversalDate === undefined ||
                this.state.TransactionReversalDate === null ? null : (
                  <div id="sectionTwo">
                    {this.state.RejectionOption === 1 ? (
                      <>
                        <Row className="FormGroup ">
                          <Col
                            className="form-label col-form-label"
                            sm={LabelColumnAmount}
                          >
                            Next, please add your Rejection file -
                          </Col>
                          <Col sm={InputColumnAmount} className="Reader">
                            <Dropzone onDrop={this.updateFiles}>
                              {({ getRootProps, getInputProps }) => (
                                <>
                                  <label
                                    className="form-control"
                                    htmlFor={"upload"}
                                  >
                                    {this.state.filename ? (
                                      <>
                                        <Row>
                                          <Col sm={10}>
                                            {this.state.filename}{" "}
                                          </Col>
                                          <Col
                                            sm={2}
                                            className="close"
                                            onClick={(e) => {
                                              this.removeFile(e);
                                            }}
                                          >
                                            <FaTimesCircle />
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        Drop file here or click to upload.
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    {...getInputProps()}
                                    id="upload"
                                    accept="application/xml,"
                                  />
                                </>
                              )}
                            </Dropzone>
                          </Col>
                        </Row>

                        {this.state.ParsedData ? (
                          <>
                            <ARUDDFileProcessor
                              XMLData={this.state.ParsedData}
                              CustomerList={this.state.SelectedCustomerList}
                              DDRejectionHeaderInfo={DDRejectionHeaderInfo}
                              errorList={this.handleError}
                              FormReset={this.handleCallback}
                            />
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {this.state.RejectionOption === 2 ? (
                      <ARUDDConsoleSelection
                        CustomerList={this.state.SelectedCustomerList}
                        DDRejectionHeaderInfo={DDRejectionHeaderInfo}
                        errorList={this.handleError}
                        FormReset={this.handleCallback}
                      />
                    ) : null}
                  </div>
                )}
              </>
            ) : (
              <>
                {this.state.CompanyID ? (
                  <FormLabelbox
                    id="NoDataForCompany"
                    Label=""
                    name="NoDataForCompany"
                    placeholder={
                      "No complete collections found for this company."
                    }
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                  />
                ) : null}
              </>
            )}
          </Form>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="ARUDD">
        <h1>Rejection File (ARUDD) Import</h1>

        {this.state.Unsupported ? (
          <>Rejections with Sage 200c Standard is not currently supported.</>
        ) : this.state.NoData ? (
          <IsError errorList={this.state.errorList} />
        ) : this.state.PlaceholderListFilled ? (
          this.renderContent()
        ) : null}
      </div>
    );
  }
}

ARUDD.contextType = AppContext;
export default withRouter(ARUDD);
