import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { FaFileUpload } from "react-icons/fa";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { getDimensions } from "../../libs/Variables";

const FormImageUpload = (props) => {
  const [TheImage, setTheImage] = useState(props.placeholder);
  const [ErrorList, setErrorList] = useState();
  let name = props.name;

  const HandleRemove = (e) => {
    setErrorList();
    setTheImage("");
    props.value(name, "");
  };

  const HandleChange = async (e) => {
    try {
      setErrorList();
      let file = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        const base64data = reader.result;
        let imgDimensions = await getDimensions(base64data);
        if (
          imgDimensions.width <= props.MaxWidth &&
          imgDimensions.height <= props.MaxHeight
        ) {
          setTheImage(base64data);
          props.value(name, base64data);
        } else {
          var message = `Image Dimensions not valid - needs to be less than Width:${props.MaxWidth}px and Height:${props.MaxHeight}px.`;
          setErrorList(message);
          props.errorList("errorList", message);
        }
      });
      if (file) {
        reader.readAsDataURL(file);
      }
    } catch (e) {
      props.errorList("errorList", e.message);
      setErrorList(e.message);
    }
  };

  const renderForm = () => {
    return (
      <Form.Group
        className={"FormGroup FormImageUpload"}
        as={Row}
        controlId={name}
      >
        <Form.Label column sm={props.LabelColumnAmount}>
          {props.Label}
        </Form.Label>
        <Col>
          <Row>
            {TheImage ? (
              <Col sm={3}>
                <img alt="New Upload" src={TheImage} />
              </Col>
            ) : null}

            <Col sm={3} className="IconBtn">
              <Col>
                <Button
                  variant="primary"
                  className="form-control inputWrapper"
                  required={props.required}
                  hidden={props.disabled}
                >
                  <FaFileUpload /> {TheImage ? "Replace" : "Upload"} Image
                  <Form.Control
                    type="file"
                    className="fileInput hidden"
                    aria-describedby="inputGroupPrepend"
                    name={name}
                    onChange={HandleChange}
                    required={props.required}
                    disabled={props.disabled}
                  />
                </Button>
              </Col>
              {TheImage ? (
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="form-control inputWrapper"
                    required={props.required}
                    hidden={props.disabled}
                    onClick={HandleRemove}
                  >
                    <IoMdRemoveCircleOutline /> Remove Image
                  </Button>
                </Col>
              ) : null}
            </Col>
            {ErrorList ? (
              <Col sm={6}>
                <div className="invalid-tooltip typeError">{ErrorList}</div>
              </Col>
            ) : null}
            {!ErrorList && props.errorList[name] ? (
              <Col sm={6}>
                <div className="invalid-tooltip typeError">
                  {props.errorList[name]}
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Form.Group>
    );
  };

  return !props.hidden ? (
    props.tooltip ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={name + "Tooltip"} className="">
            {props.tooltip}
          </Tooltip>
        }
      >
        {renderForm()}
      </OverlayTrigger>
    ) : (
      renderForm()
    )
  ) : null;
};

export default FormImageUpload;
