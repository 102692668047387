import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { Button } from "react-bootstrap";
import apiError from "../../../libs/apiError";
import GetDatetime from "../../../libs/GetDatetime";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function RemoveRuntimeFilter(row) {
  return new Promise((resolve, reject) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className="ConfirmAlert">
            <div className="AlertBody">
              <h1>Confirm Remove Runtime Filter</h1>
              Are you sure you wish to do this?
              <div className="ButtonGroup">
                <Button
                  onClick={async () => {
                    let DateTimeUpdated = GetDatetime();
                    let outputJSON = {
                      SYSDDFilterHeadingsID: row.sysddFilterHeadingsID,
                      DateTimeUpdated: DateTimeUpdated,
                    };

                    await trackPromise(
                      axios
                        .put(
                          "api/SysddfilterHeadings/RemoveDDFilter/",
                          outputJSON
                        )
                        .then((response) => {
                          resolve(response.data);
                        })
                        .catch((e) => {
                          var message = apiError("PutAPI: ", e);
                          reject(message);
                        })
                    );
                    onClose();
                  }}
                >
                  Yes
                </Button>
                <Button onClick={onClose}>No</Button>
              </div>
            </div>
          </div>
        );
      },
    });
  });
}
