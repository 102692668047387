import React, { Component } from "react";
import { AppContext } from "../../libs/contextLib";
import UkModulusChecking from "uk-modulus-checking";
import { Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import moment from "moment";
import GetDatetime from "../../libs/GetDatetime";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import StringList from "../../libs/StringList";
import {
  AccountNameSchema,
  AccountNumberSchema,
  SortCodeSchema,
  BankPaymentReferenceSchema,
  IBANSchema,
  BicSwiftNumberSchema,
  MandateReferenceSchema,
  MandateSignatureDateSchema,
  MandateTypeSchema,
} from "../../libs/ValidationConsts";
import { handleValidation } from "../handleValidation";
import { SetObserver } from "../../libs/Observer";
import BankDetailsImportTable from "./BankDetailsImportTable";
import { Encrypt } from "../../libs/Crypto";

let blankState = {
  errorList: [],
  ParsedErrorList: [],
  HasAccountListLoaded: false,
};
class BankDetailsImportDBHandle extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.loadData();
    SetObserver();
  }

  async loadData() {
    try {
      let SiteID = sessionStorage.getItem("siteID"),
        CompanyDetails = JSON.parse(sessionStorage.getItem("CompanyDetails")),
        CompanyID = CompanyDetails.CompanyID,
        rowData = this.props.rowData,
        MustPassModulusCheck = this.props.MustPassModulusCheck,
        ParsedErrorList = [],
        ParsedWarningList = [],
        CustomerList = [],
        HasBankDetailsList = [],
        ParsedRowCount = 0,
        i = 0;

      Promise.all([
        axios
          .get("/api/customerLists/" + SiteID + "/" + CompanyID)
          .catch((e) => {
            var message = apiError("API Get: ", e);
            this.props.errorList(message);
          }),
        axios.get("/api/SysmandateStatusLists/").catch((e) => {
          var message = apiError("API Placeholder Get: ", e);
          this.props.errorList(message);
        }),
        axios
          .get(
            "/api/SyscompanySettings/BankDetailsNeeded/" +
              SiteID +
              "/" +
              CompanyID
          )
          .catch((e) => {
            var message = apiError("API Get: ", e);
            this.props.errorList(message);
          }),
      ])
        .then((responses) => {
          let List, MandateStatusLists, BankDetailsNeeded;
          if (responses[0] && responses[0] !== undefined) {
            List = responses[0].data;
          } else {
            throw new Error(
              "Problem retreving up to date Customer HasAccount List."
            );
          }
          if (responses[1] && responses[1] !== undefined) {
            MandateStatusLists = responses[1].data;
          } else {
            throw new Error("Problem Fetching Mandate Status List.");
          }
          if (responses[2] && responses[2] !== undefined) {
            BankDetailsNeeded = responses[2].data;
          } else {
            throw new Error("Problem checking if Bank Checking needed.");
          }

          var MandateRecord = [];
          MandateStatusLists.forEach((element) => {
            var c = element.content.split("-");
            if (c[0] === "Finished") {
              MandateRecord[element.id] = "FN";
            } else {
              MandateRecord[element.id] = c[0].trim();
            }
          });

          List.forEach((element) => {
            CustomerList[element.accountReference] = element.customerListID;
            if (element.hasBankDetails) {
              HasBankDetailsList.push(element.accountReference);
            }
          });
          this.setState(
            {
              SiteID,
              MandateRecord,
              CustomerList,
              CompanyID,
              rowData,
              MustPassModulusCheck,
              BankDetailsNeeded,
            },
            () => {
              rowData.forEach(async (element, index) => {
                ParsedRowCount++;
                var rowNumber = index + 2;
                var DataRow = element.data;

                if (DataRow.Sort_Code) {
                  DataRow.Sort_Code = DataRow.Sort_Code.replace(/-/g, "");
                }

                if (element.errors.length !== 0) {
                  element.errors.forEach((e) => {
                    if (e.code === "MissingQuotes") {
                      throw new Error(
                        `Missing closing quotes on row ${rowNumber}, parsing aborted.`
                      );
                    }
                    ParsedErrorList.push({
                      id: i,
                      AccountReference: DataRow.Account_Reference,
                      Row: rowNumber,
                      message: e.message,
                    });
                    i++;
                    this.handleOutputGeneration(ParsedErrorList);
                  });
                }
                var testCustomerList =
                  DataRow.Account_Reference in CustomerList;

                if (!testCustomerList) {
                  ParsedErrorList.push({
                    id: i,
                    AccountReference: DataRow.Account_Reference,
                    Row: rowNumber,
                    message: "Customer does not exist.",
                  });
                  i++;
                  this.handleOutputGeneration(ParsedErrorList);
                }
                if (HasBankDetailsList.includes(DataRow.Account_Reference)) {
                  ParsedErrorList.push({
                    id: i,
                    AccountReference: DataRow.Account_Reference,
                    Row: rowNumber,
                    message: "Bank account data exists.",
                  });
                  i++;
                  this.handleOutputGeneration(ParsedErrorList);
                }

                if (
                  DataRow.Sort_Code &&
                  DataRow.Account_Number &&
                  !DataRow.IBAN &&
                  !DataRow["BIC_/_Swift_Number"]
                ) {
                  var IsUK = true;
                } else if (
                  DataRow.IBAN &&
                  DataRow["BIC_/_Swift_Number"] &&
                  !DataRow.Sort_Code &&
                  !DataRow.Account_Number
                ) {
                  var IsSEPA = true;
                }

                if (
                  DataRow.IBAN &&
                  DataRow["BIC_/_Swift_Number"] &&
                  DataRow.Sort_Code &&
                  DataRow.Account_Number
                ) {
                  ParsedErrorList.push({
                    id: i,
                    AccountReference: DataRow.Account_Reference,
                    Row: rowNumber,
                    message:
                      "Bank Type not correctly set - only a Sortcode & Account Number or IBAN & BIC/Swift Number should be supplied.",
                  });
                  i++;
                  this.handleOutputGeneration(ParsedErrorList);
                }

                if (!IsUK && !IsSEPA && this.state.BankDetailsNeeded) {
                  ParsedErrorList.push({
                    id: i,
                    AccountReference: DataRow.Account_Reference,
                    Row: rowNumber,
                    message:
                      "Bank Type is blank - either a Sortcode & Account Number or IBAN & BIC/Swift Number needs to be supplied.",
                  });
                  i++;
                  this.handleOutputGeneration(ParsedErrorList);
                }

                if (IsUK) {
                  element.data["BankType"] = 1;

                  let items = [
                    {
                      name: "Account_Name",
                      data: DataRow.Account_Name,
                      schema: AccountNameSchema,
                    },
                    {
                      name: "Additional_Reference",
                      data: DataRow.Additional_Reference,
                      schema: BankPaymentReferenceSchema,
                    },
                    {
                      name: "Mandate_Reference",
                      data: DataRow.Mandate_Reference,
                      schema: MandateReferenceSchema,
                    },
                    {
                      name: "Sort_Code",
                      data: DataRow.Sort_Code,
                      schema: SortCodeSchema,
                    },
                    {
                      name: "Account_Number",
                      data: DataRow.Account_Number,
                      schema: AccountNumberSchema,
                    },
                    {
                      name: "Mandate_Signature_Date",
                      data: DataRow.Mandate_Signature_Date,
                      schema: MandateSignatureDateSchema,
                    },
                    {
                      name: "Mandate_Type",
                      data: DataRow.Mandate_Type,
                      schema: MandateTypeSchema,
                    },
                  ];

                  items.forEach((item) => {
                    handleValidation(item).catch((e) => {
                      ParsedErrorList.push({
                        id: i,
                        AccountReference: DataRow.Account_Reference,
                        Row: rowNumber,
                        message: e[item.name],
                      });
                      i++;
                      this.handleOutputGeneration(ParsedErrorList);
                    });
                  });

                  let ModulusCheck = new UkModulusChecking({
                    accountNumber: DataRow.Account_Number,
                    sortCode: DataRow.Sort_Code,
                  });

                  if (!ModulusCheck.isValid()) {
                    if (this.state.MustPassModulusCheck) {
                      ParsedErrorList.push({
                        id: i,
                        AccountReference: DataRow.Account_Reference,
                        Row: rowNumber,
                        message: "Modulus Check Failed.",
                      });
                    } else {
                      ParsedWarningList.push({
                        id: i,
                        AccountReference: DataRow.Account_Reference,
                        Row: rowNumber,
                        message: "Modulus Check Failed.",
                      });
                    }

                    i++;
                    this.handleOutputGeneration(ParsedErrorList);
                  }

                  //Sortcode validation
                  let checks = [];
                  let sortCode = parseInt(ModulusCheck.sortCode, 10);
                  for (let check of ModulusCheck.weightTable) {
                    if (sortCode >= check.start && sortCode <= check.end) {
                      checks.push(check);
                    }
                  }
                  if (!checks.length > 0) {
                    if (this.state.MustPassModulusCheck) {
                      ParsedErrorList.push({
                        id: i,
                        AccountReference: DataRow.Account_Reference,
                        Row: rowNumber,
                        message: "Sortcode flagged as invalid.",
                      });
                    } else {
                      ParsedWarningList.push({
                        id: i,
                        AccountReference: DataRow.Account_Reference,
                        Row: rowNumber,
                        message: "Sortcode flagged as invalid.",
                      });
                    }
                    i++;
                    this.handleOutputGeneration(ParsedErrorList);
                  }
                }
                if (IsSEPA) {
                  element.data["BankType"] = 2;
                  let items = [
                    {
                      name: "Account_Name",
                      data: DataRow.Account_Name,
                      schema: AccountNameSchema,
                    },
                    {
                      name: "Additional_Reference",
                      data: DataRow.Additional_Reference,
                      schema: BankPaymentReferenceSchema,
                    },
                    {
                      name: "Mandate_Reference",
                      data: DataRow.Mandate_Reference,
                      schema: MandateReferenceSchema,
                    },
                    {
                      name: "IBAN",
                      data: DataRow.IBAN,
                      schema: IBANSchema,
                    },
                    {
                      name: "BIC_/_Swift_Number",
                      data: DataRow["BIC_/_Swift_Number"],
                      schema: BicSwiftNumberSchema,
                    },
                    {
                      name: "Mandate_Signature_Date",
                      data: DataRow.Mandate_Signature_Date,
                      schema: MandateSignatureDateSchema,
                    },
                    {
                      name: "Mandate_Type",
                      data: DataRow.Mandate_Type,
                      schema: MandateTypeSchema,
                    },
                  ];

                  items.forEach((item) => {
                    handleValidation(item).catch((e) => {
                      ParsedErrorList.push({
                        id: i,
                        AccountReference: DataRow.Account_Reference,
                        Row: rowNumber,
                        message: e[item.name],
                      });
                      i++;
                      this.handleOutputGeneration(ParsedErrorList);
                    });
                  });
                }
                if (!this.state.BankDetailsNeeded) {
                  element.data["BankType"] = 3;

                  let items = [
                    {
                      name: "Account_Name",
                      data: DataRow.Account_Name,
                      schema: AccountNameSchema,
                    },
                    {
                      name: "Additional_Reference",
                      data: DataRow.Additional_Reference,
                      schema: BankPaymentReferenceSchema,
                    },
                    {
                      name: "Mandate_Reference",
                      data: DataRow.Mandate_Reference,
                      schema: MandateReferenceSchema,
                    },
                    {
                      name: "Mandate_Signature_Date",
                      data: DataRow.Mandate_Signature_Date,
                      schema: MandateSignatureDateSchema,
                    },
                    {
                      name: "Mandate_Type",
                      data: DataRow.Mandate_Type,
                      schema: MandateTypeSchema,
                    },
                  ];

                  items.forEach((item) => {
                    handleValidation(item).catch((e) => {
                      ParsedErrorList.push({
                        id: i,
                        AccountReference: DataRow.Account_Reference,
                        Row: rowNumber,
                        message: e[item.name],
                      });
                      i++;
                      this.handleOutputGeneration(ParsedErrorList);
                    });
                  });
                }

                if (DataRow.Mandate_Type) {
                  if (
                    !this.state.MandateRecord.includes(DataRow.Mandate_Type)
                  ) {
                    ParsedErrorList.push({
                      id: i,
                      AccountReference: DataRow.Account_Reference,
                      Row: rowNumber,
                      message: `Mandate Type invalid format. Should be either: \n ${StringList(
                        this.state.MandateRecord
                      )}`,
                    });
                    i++;
                    this.handleOutputGeneration(ParsedErrorList);
                  }
                }
              });
              this.handleOutputGeneration(ParsedErrorList);
              this.setState({
                ParsedRowCount: ParsedRowCount,
                ParsedErrorList: ParsedErrorList,
                ParsedWarningList: ParsedWarningList,
                HasAccountListLoaded: true,
              });
            }
          );
        })
        .catch((e) => {
          var message = apiError("API Get: ", e);
          this.props.errorList(message);
        });
    } catch (e) {
      var message = apiError("API Get: ", e);
      this.props.errorList(message);
    }
  }
  handleOutputGeneration(theData) {
    let OutputList = [];
    var recordsToDisregard = [];
    theData.forEach((ErrorElement) => {
      recordsToDisregard.push(ErrorElement.AccountReference);
    });

    this.state.rowData.forEach((ParsedDataElement) => {
      var AccountReference = ParsedDataElement.data.Account_Reference;
      if (!recordsToDisregard.includes(AccountReference)) {
        ParsedDataElement.data = {
          ...ParsedDataElement.data,
          customerListID: this.state.CustomerList[AccountReference],
        };
        OutputList.push(ParsedDataElement.data);
      }
    });

    this.setState({
      OutputList: OutputList,
      ParsedErrorList: theData,
    });
  }

  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      this.loadData();
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    let output = this.state;
    let DateTime = GetDatetime();
    let outputJSON = [];

    this.state.OutputList.forEach((element) => {
      let MandateSignatureDate = moment(
        element.Mandate_Signature_Date,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      outputJSON.push({
        CompanyID: output.CompanyID,
        CustomerListID: element.customerListID,
        AccountReference: element.Account_Reference,
        CustomerAccountName: element.Account_Name,
        AccountName: element.Account_Name,
        AccountNumber: Encrypt(element.Account_Number),
        SortCode: Encrypt(element.Sort_Code),
        IBAN: Encrypt(element.IBAN),
        BicSwiftNumber: Encrypt(element["BIC_/_Swift_Number"]),
        BankPaymentReference: element.Additional_Reference,
        MandateStatusListId: this.state.MandateRecord.indexOf(
          element.Mandate_Type
        ),
        BankType: element.BankType,
        MandateReference: element.Mandate_Reference,
        MandateSignatureDate: MandateSignatureDate,
        IsDefault: true,
        DateTimeUpdated: DateTime,
        DateTimeCreated: DateTime,
        CreatedBy: this.context.usr,
        SiteID: output.SiteID,
      });
    });
    // console.log(outputJSON);
    await trackPromise(
      axios
        .post("api/CustomerBankDetails/PostCustomerBankDetailBulk", outputJSON)
        .then(() => {
          this.props.navigate("/CustomerList", {
            state: { AddedSuccess: true },
          });
          this.resetForm();
        })
        .catch((e) => {
          var message = apiError("PutAPI: ", e);
          this.props.errorList(message);
        })
    );
  }

  render() {
    if (this.state.HasAccountListLoaded) {
      return (
        <div className="BankDetailsImportDBHandle">
          <p>
            Total rows parsed: &nbsp;
            {this.state.ParsedRowCount}
          </p>

          {this.state.ParsedErrorList ? (
            this.state.ParsedErrorList.length >= 1 ? (
              <>
                <BankDetailsImportTable
                  Label="Error"
                  TableContent={this.state.ParsedErrorList}
                />
              </>
            ) : null
          ) : null}
          {this.state.ParsedWarningList ? (
            this.state.ParsedWarningList.length >= 1 ? (
              <>
                <BankDetailsImportTable
                  Label="Warning"
                  TableContent={this.state.ParsedWarningList}
                />
                {this.state.ParsedWarningList &&
                this.state.ParsedWarningList.length >= 1 ? (
                  <p>
                    Please note that any rows that appear in the Warning table
                    can be committed - This is just a warning that we have
                    flagged something wrong with the data submitted.
                  </p>
                ) : null}
              </>
            ) : null
          ) : null}

          {this.state.OutputList ? (
            this.state.OutputList.length >= 1 ? (
              <>
                {this.state.ParsedErrorList &&
                this.state.ParsedErrorList.length >= 1 ? (
                  <p>
                    Errors have been found with the data submitted - please
                    resolve these errors and re-upload the file.
                  </p>
                ) : (
                  <>
                    {/* <p>
                      Total rows that have passed validation: &nbsp;
                      {this.state.OutputList.length}
                    </p> */}
                    <p>Commit rows to the system?</p>
                    <p>
                      <Button variant="primary" onClick={this.handleSubmit}>
                        Commit
                      </Button>
                    </p>
                  </>
                )}
              </>
            ) : (
              <p>No rows passed validation. Unable to commit to Database.</p>
            )
          ) : null}
        </div>
      );
    } else return null;
  }
}

BankDetailsImportDBHandle.contextType = AppContext;
export default withRouter(BankDetailsImportDBHandle);
