import React, { Component } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { FaRegEdit, FaTimesCircle, FaSave } from "react-icons/fa";
import { AppContext } from "../libs/contextLib";
import apiError from "../libs/apiError";
import { trackPromise } from "react-promise-tracker";
import { Form, Col, Row, Button } from "react-bootstrap";
import FormLabelbox from "../components/Form/FormLabelbox";
import FormInputBox from "../components/Form/FormInputbox";
import FormCheck from "../components/Form/FormCheck";
import FormPassword from "../components/Form/FormPassword";
import CheckConfirmOK from "../components/Form/CheckConfirmOK";
import GetDatetime from "../libs/GetDatetime";
import withRouter from "../libs/withRouter";
import * as yup from "yup";
import OutputMessage from "../components/OutputMessage";
import {
  LabelColumnAmount,
  InputColumnAmount,
  DataNotChanged,
  ValidationError,
  passRegex,
  inputRegex,
  inputRegexMessage,
} from "../libs/Variables";
import { handleValidation } from "../components/handleValidation";
import { Encrypt } from "../libs/Crypto";

const CompanyIDSchema = yup
    .string()
    .required("Company ID is required")
    .matches(inputRegex, "Company ID" + inputRegexMessage)
    .max(100, "Company ID must be less than 100 characters"),
  UserIDSchema = yup
    .string()
    .required("User ID is required")
    .matches(inputRegex, "User ID" + inputRegexMessage)
    .max(250, "User ID must be less than 250 characters"),
  UserPasswordSchema = yup
    .string()
    .required("User Password is required")
    .matches(passRegex, "User Password invalid")
    .max(50, "User Password must be less than 50 characters");
// SenderIDSchema = yup
//   .string()
//   .required("Sender ID is required")
//   .matches(inputRegex, "Sender ID " + inputRegexMessage)
//   .max(50, "Sender ID must be less than 50 characters"),
// SenderPasswordSchema = yup
//   .string()
//   .required("Sender Password is required")
//   .matches(passRegex, "Sender Password invalid.")
//   .max(50, "Sender Password must be less than 50 characters");

let blankState = {
  notEditable: true,
  editVisible: true,
  PlaceholderListFilled: false,
  updateSuccess: false,
  ReLoginRequired: false,
  errorList: [],
  PostFeedback: "",
  DateTimeUpdated: "",
  DateTimeCreated: "",
};

class SiteSettings extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = blankState;
    this.loadData();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handleSetEdit = this.handleSetEdit.bind(this);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  async loadData() {
    let params = this.props.location.state,
      updateSuccess,
      PostFeedback;
    if (params !== undefined && params) {
      updateSuccess = params.updateSuccess;
      PostFeedback = params.PostFeedback;
    }
    let SiteName = sessionStorage.getItem("siteName");
    let SiteID = sessionStorage.getItem("siteID");

    await trackPromise(
      Promise.all([
        axios.get("/api/sitesettings/" + SiteName).catch((e) => {
          var message = apiError("API sitesettings: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        }),
        axios
          .get("/api/Ipwhitelists/GetIpwhitelistCount/" + SiteName)
          .catch((e) => {
            var message = apiError("API IPList Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          }),
      ])
        .then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            let PlaceholderList = responses[0].data[0];
            let IPList = 0;
            if (responses[1] && responses[1] !== undefined) {
              IPList = responses[1].data;
            }

            this.setState({
              SiteID,
              SiteName,
              IPCount: IPList,
              SiteSettingsID: PlaceholderList.siteSettingsID,
              ProductName: PlaceholderList.productName,
              CompanyID: PlaceholderList.intacctCompanyID,
              UserID: PlaceholderList.userId,
              UserPassword: PlaceholderList.userPassword,
              IsActive: PlaceholderList.isActive,
              DateTimeUpdated: PlaceholderList.dateTimeUpdated,
              UseIPWhitelist: PlaceholderList.useIpwhiteList,
              PlaceholderListFilled: true,
              updateSuccess,
              PostFeedback,
            });
          } else {
            throw new Error(
              "An Error has occured in the Site Settings API Call."
            );
          }
        })
        .catch((e) => {
          var message = apiError("API sitesettings: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  handleFormReset(e) {
    e.preventDefault();
    this.resetForm();
  }
  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      this.loadData();
      this.props.navigate("/SiteSettings");
    });
  }

  handleSetEdit() {
    this.setState({
      notEditable: false,
      editVisible: false,
      updateSuccess: false,
      DataUpdated: false,
      PostFeedback: "",
    });
  }

  handleCallback = (name, theData) => {
    // console.log(name, theData);
    this.setState(
      (state) => {
        var pattIPWhitelistSetting = new RegExp("UseIPWhitelist"),
          pattUserPassword = new RegExp("UserPassword");
        if (pattIPWhitelistSetting.test(name)) {
          state["ReLoginRequired"] = true;
          state[name] = theData;
        } else if (pattUserPassword.test(name)) {
          state[name] = Encrypt(theData);
        } else {
          state[name] = theData;
        }
        state["DataUpdated"] = true;
        state["NoUpdateRequired"] = false;
        return state;
      },
      () => {
        // console.log(this.state);
      }
    );
  };

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      if (this.state.ProductName === "Sage Intacct") {
        let items = [
          {
            name: "CompanyID",
            data: this.state.CompanyID,
            schema: CompanyIDSchema,
          },
          {
            name: "UserID",
            data: this.state.UserID,
            schema: UserIDSchema,
          },
          {
            name: "UserPassword",
            data: this.state.UserPassword,
            schema: UserPasswordSchema,
          },
        ];

        var Status = [];

        items.forEach(async (element, i) => {
          Status[i] = false;
          await handleValidation(element)
            .then(() => {
              Status[i] = true;
            })
            .catch((e) => {
              reject(e);
            });
          if (!Status.includes(false)) {
            resolve(true);
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.validateForm()
      .then((response) => {
        if (this.state.IPCount === 0 && this.state.UseIPWhitelist) {
          var OutputMessage = (
            <>
              <p>
                IP address white listing will not take effect until at least one
                record is added to the White List.
              </p>
              <p>
                The Use IP Whitelist setting has therefore been set to false and
                a re-login is not required.
              </p>
            </>
          );
          CheckConfirmOK(OutputMessage, "550px").then(() => {
            this.setState(
              {
                // PostFeedback: [
                //   "Please note - Use IP Whitelist setting has been set to false as the White List is empty. Please add an entry and try again.",
                // ],
                ReLoginRequired: false,
                UseIPWhitelist: false,
              },
              () => {
                this.doSubmit();
              }
            );
          });
        } else {
          this.setState(
            {
              errorList: [],
            },
            () => {
              this.doSubmit();
            }
          );
        }
      })
      .catch((e) => {
        this.setState({
          validated: false,
          validationErrorList: JSON.stringify(e),
        });
      });
  }

  async doSubmit() {
    let output = this.state;
    let DateTimeUpdated;
    if (this.state.DataUpdated) {
      DateTimeUpdated = GetDatetime();

      let outputJSON = {
        DateTimeUpdated: DateTimeUpdated,
        IntacctCompanyID: output.CompanyID,
        UserID: output.UserID,
        UserPassword: output.UserPassword,
        UseIPWhitelist: output.UseIPWhitelist,
      };

      this.context.IPWhitelistSetting = output.UseIPWhitelist;
      // console.log("put");
      // console.log(outputJSON);
      await trackPromise(
        axios
          .put("api/sitesettings/" + this.state.SiteSettingsID, outputJSON)
          .then((response) => {
            // console.log(response);
            if (this.state.ReLoginRequired) {
              this.resetForm();
              this.SignOut();
            } else {
              var PostFeedback = this.state.PostFeedback;
              this.props.navigate("/SiteSettings", {
                state: { updateSuccess: true, PostFeedback: PostFeedback },
              });
              this.resetForm();
            }
          })
          .catch((e) => {
            var message = apiError("Put SiteSettings API : ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          })
      );
    } else {
      this.setState({ NoUpdateRequired: true });
    }
  }

  SignOut = () => {
    this.context.setIsAuthenticated(false);
    this.context.setSettingsList([]);
    sessionStorage.clear();
    Auth.signOut({ global: true })
      .then(() => {
        this.props.navigate("/login");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  productSwitch() {
    switch (this.state.ProductName) {
      case "Sage 50":
        return this.renderSage50();
      case "Sage 200":
        return this.renderSage200();
      case "Sage 200c Standard":
        return this.renderSageStandard();
      default:
        return this.renderIntacct();
    }
  }

  renderSage50() {
    return (
      <>
        {/* <h2>Sage 50 Credentials</h2> */}
        <div className="detailsContent"> {this.siteWideSettings()}</div>
      </>
    );
  }

  renderSage200() {
    return (
      <>
        {/* <h2>Sage 200 Credentials</h2> */}
        <div className="detailsContent"> {this.siteWideSettings()}</div>
      </>
    );
  }

  renderSageStandard() {
    return (
      <>
        {/* <h2>Sage 200c Standard Credentials</h2> */}
        <div className="detailsContent"> {this.siteWideSettings()}</div>
      </>
    );
  }

  renderIntacct() {
    const notEditable = this.state.notEditable;
    return (
      <>
        <h2>Intacct Credentials</h2>

        <div className="detailsContent">
          {this.state.CompanyID ? (
            <FormInputBox
              type="text"
              Label="Company ID"
              name="CompanyID"
              placeholder={this.state.CompanyID}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
              schema={CompanyIDSchema}
            />
          ) : null}

          <FormInputBox
            type="text"
            Label="User ID"
            name="UserID"
            placeholder={this.state.UserID}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            disabled={notEditable}
            errorList={this.state.errorList}
            schema={UserIDSchema}
          />

          {!notEditable ? (
            <FormPassword
              Label="User Password"
              name="UserPassword"
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
              schema={UserPasswordSchema}
            />
          ) : null}

          {this.siteWideSettings()}
        </div>
      </>
    );
  }

  siteWideSettings() {
    const notEditable = this.state.notEditable;
    return (
      <>
        <h2>Sitewide Settings</h2>
        <FormCheck
          type="switch"
          Label="Use IP Whitelist"
          name="UseIPWhitelist"
          id="UseIPWhitelist"
          initialState={this.state.UseIPWhitelist}
          value={this.handleCallback}
          LabelColumnAmount={LabelColumnAmount}
          InputColumnAmount={InputColumnAmount}
          errorList={this.state.errorList}
          disabled={this.state.notEditable}
          useWarningMessage={true}
          alertMessage={
            <>
              <p>
                Changing this setting will require you to re-login upon saving
                your changes (providing that you have at least one active IP
                Address in the White List).
              </p>

              <p>Are you sure you wish to change the setting?</p>
            </>
          }
        />
        {!notEditable ? (
          <Row>
            <Col sm={LabelColumnAmount}></Col>
            <Col sm={8} className="IconBtn">
              <Button variant="primary" type="submit">
                <FaSave /> Save
              </Button>
              {this.state.NoUpdateRequired ? DataNotChanged : null}
              {this.state.validationErrorList
                ? ValidationError(this.state.validationErrorList)
                : null}

              <Button variant="primary" onClick={this.handleFormReset}>
                <FaTimesCircle /> Cancel
              </Button>
            </Col>
          </Row>
        ) : null}
      </>
    );
  }

  renderContent() {
    const editVisible = this.state.editVisible;
    return (
      <div className="SiteSettings">
        <h1>Site Settings</h1>
        <Row>
          {editVisible ? (
            <Col className="right TooltipActionButton">
              <Button
                variant="primary"
                type="button"
                onClick={this.handleSetEdit}
              >
                <FaRegEdit /> Edit
              </Button>
            </Col>
          ) : null}
        </Row>

        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          PostFeedback={this.state.PostFeedback}
        />

        <div className="detailsContent">
          <Form
            id="SiteSettingsDetailsForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <FormLabelbox
              type="text"
              Label="Site Name"
              name="siteName"
              placeholder={this.state.SiteName}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />

            <FormLabelbox
              type="text"
              Label="Sage Application"
              name="ProductName"
              placeholder={this.state.ProductName}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />

            {this.productSwitch()}
          </Form>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.PlaceholderListFilled) {
      return null;
    } else {
      return this.renderContent();
    }
  }
}
SiteSettings.contextType = AppContext;
export default withRouter(SiteSettings);
