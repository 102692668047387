import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../libs/contextLib";
import { Row, Col, Form, Button, Badge } from "react-bootstrap";
import {
  FaArrowCircleLeft,
  FaRegEdit,
  FaAngleDoubleRight,
  FaTimesCircle,
  FaSave,
  FaInfoCircle,
} from "react-icons/fa";
import { handleValidation } from "../handleValidation";
import * as yup from "yup";
import axios from "axios";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import FormLabelbox from "../Form/FormLabelbox";
import FormInputBox from "../Form/FormInputbox";
import FormDropdown from "../Form/FormDropdown";
import FormDatePicker from "../Form/FormDatePicker";
import CheckConfirm from "../Form/CheckConfirm";
import GetDatetime from "../../libs/GetDatetime";
import SetEntity from "../../libs/SetEntity";
import { SetObserver } from "../../libs/Observer";
import IsError from "../../components/IsError";
import OutputMessage from "../../components/OutputMessage";
import { trackPromise } from "react-promise-tracker";
import CollectionDateConfirm from "./CollectionDateConfirm";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";
import RuntimeFilter from "../CompanyList/RuntimeFiltering/RuntimeFilter";

const CollectionListNameSchema = yup
    .string()
    .required("Collection List Name is required")
    .matches(inputRegex, "Collection List Name" + inputRegexMessage)
    .max(60, "Collection List Name must be less than 60 characters"),
  NonIntacctCollectionListNameSchema = yup
    .string()
    .required("Collection List Name is required")
    .matches(inputRegex, "Collection List Name" + inputRegexMessage)
    .max(20, "Collection List Name must be less than 20 characters"),
  BaseTotalSchema = yup
    .string()
    .matches(
      /^(\+|-)?([0-9]+(\.[0-9]{1,2})?)$/,
      "Total Collection must be a decimal correct to 2 places."
    ),
  TransferLocationSchema = yup
    .string()
    .nullable()
    .required("Transfer Location can't be blank.");

let blankState = {
    notEditable: true,
    NoData: false,
    sectionElementIsHidden: true,
    continueClicked: false,
    TotalCollectionEditable: 0.0,
    BaseTotal: 0.0,
    BankTotal: 0.0,
    errorList: [],
    NewDDRun: "",
    Status: "",
    StatusID: 1,
    DateTimeUpdated: "",
    DateTimeCreated: "",
    Message: "",
  },
  IsDev = process.env.NODE_ENV === "development" ? true : false;

class DDRunNew extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.HandleRefresh = this.HandleRefresh.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate() {
    SetObserver();
    if (this.state.StatusID <= 5) {
      clearInterval(this.interval);
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  RefreshInterval() {
    if (this.state.StatusID === 6 || this.state.StatusID === 7) {
      return (this.interval = setInterval(() => {
        // console.log("RefreshInterval invoked");
        this.HandleRefresh();
      }, 5000));
    }
  }

  async loadData() {
    let params = this.props.location.state,
      SiteID = sessionStorage.getItem("siteID"),
      ProductName = sessionStorage.getItem("productName"),
      theDDRun = JSON.parse(sessionStorage.getItem("DDRun")),
      NewDDRun,
      updateSuccess,
      AddedSuccess,
      PostFeedback;
    if (params !== undefined && params) {
      NewDDRun = params.NewDDRun;
      updateSuccess = params.updateSuccess;
      AddedSuccess = params.AddedSuccess;
      PostFeedback = params.PostFeedback;
      if (params.NewDDRun) {
        sessionStorage.setItem("NewListItem", true);
      } else {
        sessionStorage.setItem("NewListItem", false);
      }
    } else {
      NewDDRun = JSON.parse(sessionStorage.getItem("NewListItem"));
    }

    if (NewDDRun || theDDRun === null) {
      await trackPromise(
        Promise.all([
          axios
            .get("/api/Syscompanies/GetSyscompanies/" + SiteID)
            .catch((e) => {
              var message = apiError("API Syscompanies Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
        ]).then((responses) => {
          if (responses[0]) {
            let CompanyID = responses[0].data[0].id;
            sessionStorage.setItem("CompanyID", CompanyID);
            this.setState(
              {
                SiteID,
                ProductName,
                theDDRun,
                SYSCompanyList: responses[0].data,
                SYSCompany: responses[0].data[0].content,
                CompanyID: CompanyID,
                IsTopLevel: responses[0].data[0].isTopLevel,
                EntityCurrency: responses[0].data[0].entityCurrency,
                UseRuntimeFilters: responses[0].data[0].useRuntimeFilters,
                DisableDDCompanySync: responses[0].data[0].disableDDCompanySync,
                PlaceholderListFilled: true,

                TransactionsTo: GetDatetime(true),
                CollectionDate: GetDatetime(true),
                OldCollectionDate: GetDatetime(true),

                sectionElementIsHidden: true,
                continueClicked: false,
                NewDDRun: true,
                notEditable: true,
                IsBankTransferComplete: false,
                updateSuccess,
                AddedSuccess,
                PostFeedback,
              },
              () => {
                // console.log(this.state);
              }
            );
          } else {
            throw new Error("No active companies found");
          }
        })
      ).catch((e) => {
        var message = apiError("API New DDRun Get: ", e);
        this.setState((previousState) => ({
          NoData: true,
          errorList: [...previousState.errorList, message],
        }));
      });
    }
  }

  async HandleRefresh() {
    await trackPromise(
      Promise.all([
        axios.get(
          "/api/DdlistHeaders/CheckStatusID/" +
            this.state.SiteID +
            "/" +
            this.state.HeaderID
        ),
      ]).then((responses) => {
        var StatusID = responses[0].data[0].statusID;

        if (StatusID >= 6) {
          this.setState({ StatusID: StatusID });
        } else {
          this.setState(
            {
              updateSuccess: false,
              AddedSuccess: false,
              DataUpdated: false,
              PostFeedback: "",
              errorList: [],
            },
            () => {
              this.loadData();
            }
          );
        }
      })
    ).catch((e) => {
      var message = apiError("API CheckStatusID: ", e);
      this.setState((previousState) => ({
        errorList: [...previousState.errorList, message],
      }));
    });
  }

  handleFormReset(e) {
    e.preventDefault();
    this.props.navigate("/DDList");
  }
  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      this.loadData();
    });
  }

  handleCallback = (name, theData) => {
    // console.log(name);
    // console.log(theData);
    const theState = this.state;
    this.setState(async (state) => {
      var pattCompanyID = new RegExp("CompanyID");
      var pattCollectionBank = new RegExp("CollectionBank");
      var pattTransferBankAccount = new RegExp("TransferBankAccountListID");
      var pattErrorList = new RegExp("errorList");
      if (pattCompanyID.test(name)) {
        let a = {};
        theState.SYSCompanyList.forEach((element) => {
          a[element.id] = [
            element.content,
            element.disableDDCompanySync,
            element.isTopLevel,
            element.entityCurrency,
            element.useRuntimeFilters,
          ];
        });
        let SetCompany = a[theData][0];
        let SetDisableDDCompanySync = a[theData][1];
        let SetIsTopLevel = a[theData][2];
        let SetEntityCurrency = a[theData][3];
        let SetUseRuntimeFilters = a[theData][4];
        sessionStorage.setItem("CompanyID", theData);
        state.SYSCompany = SetCompany;
        state.DisableDDCompanySync = SetDisableDDCompanySync;
        state.IsTopLevel = SetIsTopLevel;
        state.EntityCurrency = SetEntityCurrency;
        state.UseRuntimeFilters = SetUseRuntimeFilters;
        state["NoUpdateRequired"] = false;
        state["DataUpdated"] = true;
        state[name] = theData;
      } else if (pattErrorList.test(name)) {
        if (theData === "clear") {
          state.errorList = [];
        } else {
          state.errorList = [...state.errorList, theData];
        }
      } else if (pattCollectionBank.test(name)) {
        state = this.UpdateBankAccountData(
          state,
          state["bankAccountList"],
          theData,
          state.TransferBankAccountListID
        );
      } else if (pattTransferBankAccount.test(name)) {
        state = this.UpdateBankAccountData(
          state,
          state["bankAccountList"],
          state.CollectionBank,
          theData
        );
      } else {
        state[name] = theData;
      }
      // console.log(state);
      return state;
    });
  };

  UpdateBankAccountData = (
    state,
    bankAccountList,
    CollectionBank,
    currentTransferBankAccountID
  ) => {
    var index = bankAccountList.findIndex(
      (i) => i.id === parseInt(CollectionBank)
    );
    var BankCurrency = bankAccountList[index].bankCurrency;
    let TransferBankAccountList = [];
    bankAccountList.forEach((element) => {
      if (
        element.bankCurrency === BankCurrency &&
        element.id !== parseInt(CollectionBank)
      ) {
        TransferBankAccountList.push({
          bankCurrency: element.bankCurrency,
          bankCurrencySymbol: element.bankCurrencySymbol,
          content: element.content,
          id: element.id,
        });
      }
    });

    if (TransferBankAccountList.length >= 1) {
      if (currentTransferBankAccountID !== 0) {
        var i = TransferBankAccountList.findIndex(
          (i) => i.id === parseInt(currentTransferBankAccountID)
        );
        if (i >= 0) {
          var TransferBankAccountListID = TransferBankAccountList[i].id;
        } else {
          TransferBankAccountListID = TransferBankAccountList[0].id;
        }
      } else {
        TransferBankAccountListID = TransferBankAccountList[0].id;
      }
    }

    if (state.ProductName === "Sage Intacct") {
      let TransferLocationList = [
        {
          id: 0,
          entityCompanyName: "",
          entityLocationId: "",
          isTopLevel: false,
          content: "",
          EntityCurrency: "",
        },
      ];
      state.CompanyLists.forEach((element) => {
        if (!element.isTopLevel && element.entityCurrency === BankCurrency) {
          TransferLocationList.push(element);
        }
      });
      state["TransferLocationList"] = TransferLocationList;
    }

    state["TransferBankAccountList"] = TransferBankAccountList;
    state["TransferBankAccountListID"] = TransferBankAccountListID;
    state["CollectionBank"] = CollectionBank;
    state["BankCurrency"] = BankCurrency;
    if (this.state.ProductName !== "Sage Intacct") {
      let BaseCurrencyList = this.state.BaseCurrencyList;
      i = BaseCurrencyList.findIndex((i) => i.content === BankCurrency);
      state["BaseCurrency"] = BaseCurrencyList[i].id;
      state["InvoiceCurrency"] = BaseCurrencyList[i].id;
    }
    return state;
  };

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "CollectionListName",
          data: this.state.CollectionListName,
          schema: CollectionListNameSchema,
        },
        {
          name: "TotalCollectionEditable",
          data: this.state.TotalCollectionEditable,
          schema: BaseTotalSchema,
        },
      ];
      if (this.state.TransferCollection && this.state.TopLevelEntity) {
        items.push({
          name: "TransferLocationID",
          data: this.state.TransferLocationID,
          schema: TransferLocationSchema,
        });
      }
      if (this.state.ProductName !== "Sage Intacct") {
        items = [
          {
            name: "CollectionListName",
            data: this.state.CollectionListName,
            schema: NonIntacctCollectionListNameSchema,
          },
          {
            name: "TotalCollectionEditable",
            data: this.state.TotalCollectionEditable,
            schema: BaseTotalSchema,
          },
        ];
      }

      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.validateForm()
      .then((response) => {
        // console.log(response);
        this.setState({ errorList: "" }, () => {
          if (this.state.StatusID === 1) {
            CollectionDateConfirm(this.state.CollectionDate)
              .then(async () => {
                this.doSubmit(true);
              })
              .catch(() => {
                this.setState({ CollectionDate: this.state.OldCollectionDate });
              });
          } else {
            if (
              this.state.UseRuntimeFilters &&
              !this.state.RuntimeFilterSelected
            ) {
              let OutputNoFilterText = (
                <>
                  <h3>
                    Are you sure you wish to generate a direct debit run with no
                    filtering?
                  </h3>
                </>
              );
              CheckConfirm(OutputNoFilterText, "500px")
                .then(() => {
                  this.doSubmit(true);
                })
                .catch(() => {});
            } else {
              this.doSubmit(true);
            }
          }
        });
      })
      .catch((e) => {
        this.setState({
          validated: false,
          validationErrorList: JSON.stringify(e),
        });
      });
  }

  async doSubmit() {
    let output = this.state,
      DateTimeUpdated = GetDatetime(),
      DateTimeCreated = GetDatetime(),
      outputJSON = {
        CollectionListName: output.CollectionListName,
        CollectionDate: output.CollectionDate,
        TransactionsFrom: output.TransactionsFrom,
        TransactionsTo: output.TransactionsTo,
        StatusID: output.StatusID,
        DateTimeUpdated: DateTimeUpdated,
        IsFileOutput: output.IsFileOutput,
        CollectionTotal: 0,
        TransferBankAccountListID: output.TransferBankAccountListID,
        TransferLocationID: output.TransferLocationID,
        JournalSymbol: output.JournalSymbol,

        DateTimeCreated: DateTimeCreated,
        IsPosted: output.IsPosted,
        Company: output.SYSCompany,
        CompanyID: output.CompanyID,
        CollectionBank: output.CollectionBank,
        BaseCurrency: output.BaseCurrency,
        IsDeleted: false,
        InvoiceCurrency: output.InvoiceCurrency,
        TransactionsListTempPopulated: output.TransactionsListTempPopulated,
        SiteID: output.SiteID,
        CreatedBy: this.context.usr,
        RuntimeFilterSelected: output.RuntimeFilterSelected
          ? [output.RuntimeFilterSelected]
          : null,
      };

    if (output.InvoiceCurrencyLabel === output.BaseCurrencyLabel) {
      outputJSON["BaseTotal"] = outputJSON["CollectionTotal"];
    } else {
      outputJSON["BaseTotal"] = output.TotalCollectionEditable;
    }

    if (output.InvoiceCurrencyLabel === output.BankCurrency) {
      outputJSON["BankTotal"] = outputJSON["CollectionTotal"];
    } else {
      outputJSON["BankTotal"] = output.TotalCollectionEditable;
    }

    // console.log(outputJSON);

    await axios
      .post("api/DdlistHeaders/", outputJSON)
      .then(async (results) => {
        var postResponse = results.data;
        let DDRunState = {
          HeaderID: postResponse.ddlistHeaderId,
          CompanyID: postResponse.syscompanyId,
        };
        sessionStorage.setItem("DDRun", JSON.stringify(DDRunState));
        // console.log(postResponse);
        let postMessageJSON = {
          JobName: this.state.CollectionListName,
          JobURL:
            this.context.INTACCT_API_BASE_URL +
            "/api/ARTransactions/?siteID=" +
            this.state.SiteID +
            "&SYSCompanyID=" +
            postResponse.syscompanyId +
            "&DDListHeaderID=" +
            postResponse.ddlistHeaderId +
            "&RunType=New Run" +
            "&accessToken=" +
            this.context.SageAccessToken,
        };

        if (!this.state.DisableDDCompanySync) {
          this.RefreshInterval();
          await trackPromise(
            Promise.all([
              axios.get(
                this.context.INTACCT_API_BASE_URL +
                  "/api/SyncCompany/?siteID=" +
                  this.state.SiteID +
                  "&SYSCompanyID=" +
                  DDRunState.CompanyID +
                  "&accessToken=" +
                  this.context.SageAccessToken,
                {
                  crossdomain: true,
                }
              ),

              IsDev
                ? axios.get(postMessageJSON.JobURL)
                : axios.post("api/AWSSQS/postMessage/", postMessageJSON),
            ]).then((responses) => {
              if (
                responses[0] &&
                responses[0] !== undefined &&
                responses[1] &&
                responses[1] !== undefined
              ) {
                this.props.navigate("/DDRun", {
                  state: {
                    AddedSuccess: true,
                    updateSuccess: false,
                  },
                });
                this.resetForm();
              }
            })
          ).catch((e) => {
            var message = apiError("API SyncDDList: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          });
        } else {
          this.RefreshInterval();
          await Promise.all([
            IsDev
              ? axios.get(postMessageJSON.JobURL)
              : axios.post("api/AWSSQS/postMessage/", postMessageJSON),
          ])
            .then((responses) => {
              if (responses[0] && responses[0] !== undefined) {
                this.props.navigate("/DDRun", {
                  state: {
                    AddedSuccess: true,
                    updateSuccess: false,
                  },
                });
                this.resetForm();
              }
            })
            .catch((e) => {
              var message = apiError("API SyncDDList: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            });
        }
      })
      .catch((e) => {
        var message = apiError("Post Error: ", e);
        this.setState((previousState) => ({
          errorList: [...previousState.errorList, message],
        }));
      });
  }

  async handleContinue(e) {
    e.preventDefault();

    await trackPromise(
      Promise.all([
        axios
          .get(
            "/api/BankAccountLists/" +
              this.state.SiteID +
              "/" +
              this.state.CompanyID
          )
          .catch((e) => {
            var message = apiError("API BankAccountLists Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          }),
        axios
          .get(
            "/api/SyscompanySettings/GetDefaultCompanySettings/" +
              this.state.SiteID +
              "/" +
              this.state.CompanyID
          )
          .catch((e) => {
            var message = apiError("API GetDefaultCompanySettings Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          }),
        axios
          .get(
            "/api/CurrencyLists/GetBaseCurrencyList/" +
              this.state.SiteID +
              "/" +
              this.state.CompanyID
          )
          .catch((e) => {
            var message = apiError("API CurrencyLists Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          }),
        axios.get("/api/CompanyLists/" + this.state.SiteID).catch((e) => {
          var message = apiError("apiCompanyLists Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
      ]).then((responses) => {
        let CompanyLists;

        if (responses[0] && responses[0] !== undefined) {
          var bankAccountList = responses[0].data;
        } else {
          throw new Error("No Active Bank Accounts found");
        }

        if (responses[1] && responses[1] !== undefined) {
          var DefaultReceiptsBankID =
            responses[1].data[0].defaultReceiptsBankID;
          var TransferCollection = responses[1].data[0].transferCollection,
            TransferBankAccountListID =
              responses[1].data[0].transferBankAccountListID,
            JournalSymbol = responses[1].data[0].journalSymbol,
            TransferLocationID = responses[1].data[0].transferLocationID;
        } else {
          throw new Error("No Default Bank found");
        }

        var index = bankAccountList.findIndex(
          (i) => i.id === parseInt(DefaultReceiptsBankID)
        );
        var BankCurrency = bankAccountList[index].bankCurrency;
        let TransferBankAccountList = [];
        bankAccountList.forEach((element) => {
          if (
            element.bankCurrency === BankCurrency &&
            element.id !== DefaultReceiptsBankID
          ) {
            TransferBankAccountList.push({
              bankCurrency: element.bankCurrency,
              bankCurrencySymbol: element.bankCurrencySymbol,
              content: element.content,
              id: element.id,
            });
          }
        });

        if (TransferBankAccountList.length >= 1) {
          if (TransferBankAccountListID !== 0) {
            var i = TransferBankAccountList.findIndex(
              (i) => i.id === parseInt(TransferBankAccountListID)
            );
            if (i >= 0) {
              TransferBankAccountListID = TransferBankAccountList[i].id;
            } else {
              TransferBankAccountListID = TransferBankAccountList[0].id;
            }
          } else {
            TransferBankAccountListID = TransferBankAccountList[0].id;
          }
        }

        if (responses[2] && responses[2] !== undefined) {
          var BaseCurrencyList = responses[2].data;
          let ToCompare = BankCurrency;
          if (
            !this.state.IsTopLevel &&
            this.state.ProductName === "Sage Intacct"
          ) {
            ToCompare = this.state.EntityCurrency;
          }
          i = BaseCurrencyList.findIndex((i) => i.content === ToCompare);
          var BaseCurrency = BaseCurrencyList[i].id;
          var BaseCurrencyLabel = BaseCurrencyList[i].content;
        } else {
          BaseCurrencyList = [];
          BaseCurrency = 0;
          BaseCurrencyLabel = null;
        }

        if (responses[2] && responses[2] !== undefined) {
          var InvoiceCurrencyList = responses[2].data;
          index = InvoiceCurrencyList.findIndex(
            (i) => i.content === BankCurrency
          );
          var InvoiceCurrency = InvoiceCurrencyList[index].id;
          var InvoiceCurrencyLabel = InvoiceCurrencyList[index].content;
          var InvoiceCurrencySymbol = InvoiceCurrencyList[index].currencySymbol;
        } else {
          InvoiceCurrencyList = [];
          InvoiceCurrency = null;
          InvoiceCurrencyLabel = null;
        }

        let TransferLocationList = [
            {
              id: 0,
              entityCompanyName: "",
              entityLocationId: "",
              isTopLevel: false,
              content: "",
              EntityCurrency: "",
            },
          ],
          TopLevelEntity = false;
        if (responses[3] && responses[3] !== undefined) {
          CompanyLists = SetEntity(responses[3].data, "content");
          i = this.state.SYSCompanyList.findIndex(
            (i) => i.id === parseInt(this.state.CompanyID)
          );

          if (this.state.SYSCompanyList[i].isTopLevel) {
            TopLevelEntity = true;
          }

          if (this.state.ProductName === "Sage Intacct") {
            CompanyLists.forEach((element) => {
              if (
                !element.isTopLevel &&
                element.entityCurrency === BankCurrency
              ) {
                TransferLocationList.push(element);
              }
            });
          }
        } else {
          throw new Error(`Problem Fetching Location List.`);
        }

        this.setState(
          {
            bankAccountList: bankAccountList,
            CompanyLists,

            TransferCollection,
            TransferBankAccountListID,
            TransferBankAccountList,
            JournalSymbol,
            TransferLocationID,
            TopLevelEntity,
            TransferLocationList,

            BaseCurrencyList: BaseCurrencyList,
            CollectionBank: DefaultReceiptsBankID,
            BankCurrency: BankCurrency,
            BaseCurrency: BaseCurrency,
            BaseCurrencyLabel: BaseCurrencyLabel,

            InvoiceCurrencyList: InvoiceCurrencyList,
            InvoiceCurrency: InvoiceCurrency,
            InvoiceCurrencyLabel: InvoiceCurrencyLabel,
            InvoiceCurrencySymbol: InvoiceCurrencySymbol,

            TransactionsFrom: null,
            IsFileOutput: false,
            IsPosted: false,
            TransactionsListTempPopulated: false,
            CollectionListName: "",

            continueClicked: true,
            sectionElementIsHidden: false,
            notEditable: false,
          },
          () => {
            // console.log(this.state);
          }
        );
      })
    ).catch((e) => {
      var message = apiError("API New Run Get: ", e);
      this.setState((previousState) => ({
        NoData: true,
        errorList: [...previousState.errorList, message],
      }));
    });
  }

  RuntimeFilterSelected = () => {
    return (
      <div className="form-control-plaintext">
        {this.state.RuntimeFilterSelected.filterSetName} with Values:{" "}
        {this.state.RuntimeFilterSelected.filterValueList.map(
          (Element, i, { length }) => {
            return (
              <span key={i}>
                {Element.filterValue}
                {length - 1 === i ? "" : <>, </>}
              </span>
            );
          }
        )}
      </div>
    );
  };

  renderContent() {
    const notEditable = this.state.notEditable;
    const sectionElementIsHidden = this.state.sectionElementIsHidden;

    return (
      <>
        <Row>
          <Col>
            <Link to="/DDList">
              <FaArrowCircleLeft /> Back to Direct Debit List
            </Link>
          </Col>
        </Row>

        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
          PostFeedback={this.state.PostFeedback}
          OmittedCustomersFeedback={this.state.OmittedCustomersFeedback}
          DDStatus={this.state.Status}
        />

        <div className="detailsContent">
          <Form
            id="GenerateDDForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <div id="sectionOne">
              {!this.state.continueClicked ? (
                <FormDropdown
                  id="CompanyID"
                  Label="Company"
                  name="CompanyID"
                  placeholder={this.state.CompanyID}
                  itemContent={this.state.SYSCompanyList}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={4}
                  errorList={this.state.errorList}
                />
              ) : (
                <FormLabelbox
                  type="text"
                  Label="Company"
                  name="SYSCompany"
                  placeholder={this.state.SYSCompany}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                />
              )}

              {!this.state.continueClicked ? (
                <Form.Group className={"FormGroup"} as={Row}>
                  <Col sm={LabelColumnAmount}></Col>
                  <Col sm={2}>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={this.handleContinue}
                    >
                      <FaAngleDoubleRight /> Continue
                    </Button>
                  </Col>
                </Form.Group>
              ) : null}
            </div>
            <div id="sectionTwo">
              <>
                <FormInputBox
                  type="text"
                  Label="Collection List Name"
                  name="CollectionListName"
                  placeholder={this.state.CollectionListName}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={4}
                  disabled={notEditable}
                  hidden={sectionElementIsHidden}
                  errorList={this.state.errorList}
                  schema={
                    this.state.ProductName === "Sage Intacct"
                      ? CollectionListNameSchema
                      : NonIntacctCollectionListNameSchema
                  }
                />

                {this.state.CollectionBank ? (
                  <>
                    <FormDropdown
                      id="CollectionBank"
                      Label="Collection Bank"
                      name="CollectionBank"
                      placeholder={this.state.CollectionBank}
                      itemContent={this.state.bankAccountList}
                      value={this.handleCallback}
                      LabelColumnAmount={LabelColumnAmount}
                      InputColumnAmount={4}
                      disabled={notEditable}
                      hidden={sectionElementIsHidden}
                      errorList={this.state.errorList}
                    />

                    {this.state.TransferCollection ? (
                      <>
                        {this.state.TransferBankAccountList.length >= 1 ? (
                          <>
                            <FormDropdown
                              id="TransferBankAccountListID"
                              Label="Transfer Bank Account"
                              name="TransferBankAccountListID"
                              placeholder={this.state.TransferBankAccountListID}
                              itemContent={this.state.TransferBankAccountList}
                              value={this.handleCallback}
                              LabelColumnAmount={LabelColumnAmount}
                              InputColumnAmount={InputColumnAmount}
                              required={false}
                              disabled={notEditable}
                              hidden={sectionElementIsHidden}
                              errorList={this.state.errorList}
                            />
                            {/* {this.state.ProductName === "Sage Intacct" ? (
                                <FormDropdown
                                  id="JournalType"
                                  Label="Journal Type"
                                  name="JournalType"
                                  placeholder={this.state.JournalTypeID}
                                  itemContent={this.state.JournalTypes}
                                  value={this.handleCallback}
                                  LabelColumnAmount={LabelColumnAmount}
                                  InputColumnAmount={InputColumnAmount}
                                  required={false}
                                  disabled={notEditable}
                                  hidden={sectionElementIsHidden}
                                  errorList={this.state.errorList}
                                />
                              ) : null} */}
                            {this.state.ProductName === "Sage Intacct" &&
                            this.state.TopLevelEntity ? (
                              <FormDropdown
                                id="TransferLocationID"
                                Label={"Transfer Location"}
                                name="TransferLocationID"
                                placeholder={this.state.TransferLocationID}
                                itemContent={this.state.TransferLocationList}
                                value={this.handleCallback}
                                LabelColumnAmount={LabelColumnAmount}
                                InputColumnAmount={InputColumnAmount}
                                required={false}
                                disabled={notEditable}
                                hidden={sectionElementIsHidden}
                                errorList={this.state.errorList}
                                schema={TransferLocationSchema}
                              />
                            ) : null}
                          </>
                        ) : (
                          <FormLabelbox
                            id="TransferBankAccountListID"
                            Label="Bank Account"
                            name="TransferBankAccountListID"
                            placeholder={
                              <Badge disabled={notEditable} bg="warning">
                                No Additional Accounts Found
                              </Badge>
                            }
                            LabelColumnAmount={LabelColumnAmount}
                            InputColumnAmount={InputColumnAmount}
                          />
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}

                <FormLabelbox
                  type="text"
                  Label="Bank Currency"
                  name="BankCurrency"
                  placeholder={this.state.BankCurrency}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  hidden={sectionElementIsHidden}
                />

                {this.state.ProductName === "Sage Intacct" &&
                this.state.InvoiceCurrencyList ? (
                  <FormDropdown
                    id="InvoiceCurrency"
                    Label="Invoice Currency"
                    name="InvoiceCurrency"
                    placeholder={this.state.InvoiceCurrency}
                    itemContent={this.state.InvoiceCurrencyList}
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={2}
                    disabled={notEditable}
                    hidden={sectionElementIsHidden}
                    errorList={this.state.errorList}
                  />
                ) : null}

                {this.state.ProductName === "Sage Intacct" &&
                this.state.BaseCurrencyList ? (
                  <>
                    {this.state.IsTopLevel ? (
                      <FormDropdown
                        id="BaseCurrency"
                        Label="Base Currency"
                        name="BaseCurrency"
                        placeholder={this.state.BaseCurrency}
                        itemContent={this.state.BaseCurrencyList}
                        value={this.handleCallback}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={2}
                        disabled={notEditable}
                        hidden={sectionElementIsHidden}
                        errorList={this.state.errorList}
                      />
                    ) : (
                      <FormLabelbox
                        type="text"
                        Label="Base Currency"
                        name="BaseCurrency"
                        placeholder={this.state.BaseCurrencyLabel}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                      />
                    )}
                  </>
                ) : null}

                <FormDatePicker
                  Label="Transactions From"
                  name="TransactionsFrom"
                  placeholder={this.state.TransactionsFrom}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={2}
                  disabled={notEditable}
                  hidden={sectionElementIsHidden}
                  errorList={this.state.errorList}
                  ShowBlank={true}
                />

                <FormDatePicker
                  Label="Transactions To"
                  name="TransactionsTo"
                  placeholder={this.state.TransactionsTo}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={2}
                  disabled={notEditable}
                  hidden={sectionElementIsHidden}
                  errorList={this.state.errorList}
                  ShowBlank={false}
                />

                <FormDatePicker
                  Label="Collection Date"
                  name="CollectionDate"
                  placeholder={this.state.CollectionDate}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={2}
                  disabled={notEditable}
                  hidden={sectionElementIsHidden}
                  errorList={this.state.errorList}
                  ShowBlank={false}
                />

                {!notEditable && this.state.UseRuntimeFilters ? (
                  <>
                    <Row className={"FormGroup"}>
                      <Form.Label column sm={LabelColumnAmount}>
                        Runtime Filters
                      </Form.Label>
                      <Col>
                        {this.state.RuntimeFilterSelected
                          ? this.RuntimeFilterSelected()
                          : null}
                        <RuntimeFilter
                          SiteID={this.state.SiteID}
                          CompanyID={this.state.CompanyID}
                          ShowSelect={true}
                          ButtonLabel={
                            this.state.RuntimeFilterSelected ? (
                              <>
                                <FaRegEdit />
                                Change Selected Filter
                              </>
                            ) : (
                              <>
                                <FaInfoCircle /> View Runtime Filter List
                              </>
                            )
                          }
                          RuntimeFilterSelected={this.handleCallback}
                          DataUpdated={this.handleCallback}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null}
              </>

              {!notEditable && this.state.continueClicked ? (
                <Row>
                  <Col sm={LabelColumnAmount}></Col>
                  <Col sm={4} className="IconBtn">
                    <Button variant="primary" type="submit">
                      <FaSave />
                      Generate
                    </Button>
                    {this.state.validationErrorList
                      ? ValidationError(this.state.validationErrorList)
                      : null}

                    <Button variant="primary" onClick={this.handleFormReset}>
                      <FaTimesCircle /> Cancel
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Form>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="DDRun">
        <h1>Generate List</h1>
        {this.state.NoData ? (
          <IsError errorList={this.state.errorList} />
        ) : !this.state.PlaceholderListFilled ? null : (
          this.renderContent()
        )}
      </div>
    );
  }
}

DDRunNew.contextType = AppContext;
export default withRouter(DDRunNew);
