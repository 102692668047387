import * as yup from "yup";
import { inputRegex, inputRegexMessage } from "./Variables";
const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}$/;
export const AccountNameSchema = yup
    .string()
    .nullable()
    .matches(
      /^[a-zA-Z0-9' _<>&%.()-]*$/,
      "Account Name - Only upper or lowercase characters A-Z, 0-9, spaces, brackets, full stops, hyphans, ampersands, apostrophes, underscores, chevrons and percentage signs are allowed."
    )
    .max(100, "Account Name must be less than 100 characters"),
  AccountNumberSchema = yup
    .string()
    .nullable()
    .min(8, "Account number must be 8 characters")
    .max(8, "Account number must be 8 characters")
    .matches("^[0-9]+$", "Account number must be only numbers"),
  SortCodeSchema = yup
    .string()
    .required("sortCode is required")
    .min(6, "Sortcode must be 6 characters (not including hyphens)")
    .max(6, "Sortcode must be 6 characters (not including hyphens)")
    .matches("^[0-9]+$", "Sortcode must be only numbers"),
  BankPaymentReferenceSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, "Bank Payment Reference" + inputRegexMessage)
    .max(60, "Bank Payment Reference must be less than 60 characters"),
  MandateSignatureDateSchema = yup
    .string()
    .nullable()
    .matches(
      dateRegex,
      "Mandate Signature Date invalid format, should be in the format dd/mm/yyyy."
    ),
  IBANSchema = yup
    .string()
    .required("IBAN is required")
    .matches(/^[a-zA-Z0-9]*$/, "IBAN Invaild.")
    .max(40, "IBAN must be less than 40 characters"),
  BicSwiftNumberSchema = yup
    .string()
    .required("BIC / Swift Number is required")
    .matches(/^[a-zA-Z0-9]*$/, "BIC / Swift Number Invaild.")
    .max(11, "BIC / Swift Number must be less than 11 characters"),
  MandateTypeSchema = yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9]*$/, "Mandate Type Invaild.")
    .min(2, "MandateType must be 2 characters")
    .max(2, "MandateType must be 2 characters"),
  MandateReferenceSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, "Mandate Reference" + inputRegexMessage)
    .max(60, "Mandate Reference must be less than 60 characters");
