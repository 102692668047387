import React, { Component } from "react";
import axios from "axios";
import { AppContext } from "../../libs/contextLib";
import apiError from "../../libs/apiError";
import BootstrapTable from "../react-bootstrap-table2";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import GetDatetime from "../../libs/GetDatetime";
import OverlayFormatter from "../../libs/OverlayFormatter";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaTrashAlt, FaChevronDown, FaChevronRight } from "react-icons/fa";
import { BiSelectMultiple } from "react-icons/bi";
import { MdFilterNone } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { SetObserver } from "../../libs/Observer";
import NoTextFormatter from "../NoTextFormatter";
import CheckConfirm from "../Form/CheckConfirm";
import CheckConfirmOK from "../Form/CheckConfirmOK";
import ShowSchedule from "./ShowSchedule";

class DDRunDetails extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {};

    this.HandleRemoveAccount = this.HandleRemoveAccount.bind(this);
    this.handleCollectAll = this.handleCollectAll.bind(this);
    this.handleCollectNone = this.handleCollectNone.bind(this);
  }
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    SetObserver();
    if (
      this.props.DDlistCustomerSummary !== prevProps.DDlistCustomerSummary ||
      this.props.DDlistCustomerDetailed !== prevProps.DDlistCustomerDetailed
    ) {
      this.loadData();
    }
  }

  async loadData() {
    let DDListHeaderID = await this.props.DDListHeaderID;
    let ProductName = sessionStorage.getItem("productName");
    this.setState({
      DDListHeaderID: DDListHeaderID,
      ProductName: ProductName,
    });

    var DDlistCustomerSummary = await this.props.DDlistCustomerSummary;
    var DDlistCustomerDetailed = await this.props.DDlistCustomerDetailed;
    var CustomerSummary = [];

    try {
      DDlistCustomerSummary.forEach((element, i) => {
        CustomerSummary[element.customerListID] = {
          customerListID: element.customerListID,
          accountName: element.accountName,
          accountReference: element.accountReference,
          sageURN: element.sageURN,
          collectionTotal: 0.0,
        };
      });
    } catch (e) {
      var message = apiError("DDlistCustomerSummary: ", e);
      this.props.errorList("errorList", message);
    }
    var FilteredCustomerDetails = [];
    try {
      DDlistCustomerSummary.forEach((ref) => {
        DDlistCustomerDetailed.forEach((element, i) => {
          if (element.customerListID === ref.customerListID) {
            if (
              typeof FilteredCustomerDetails[element.customerListID] ==
              "undefined"
            ) {
              FilteredCustomerDetails[element.customerListID] = [];
            }

            FilteredCustomerDetails[element.customerListID][
              element.ddListLinesID
            ] = element;

            CustomerSummary[element.customerListID].collectionTotal =
              CustomerSummary[element.customerListID].collectionTotal +
              element.collectionAmount;
          }
        });
      });
    } catch (e) {
      message = apiError("DDlistCustomerSummary: ", e);
      this.props.errorList("errorList", message);
    }
    var SetGrandCollectionTotal = 0;
    CustomerSummary.forEach((e, i) => {
      SetGrandCollectionTotal = SetGrandCollectionTotal + e.collectionTotal;
    });
    // console.log(CustomerSummary);
    // console.log(FilteredCustomerDetails);

    this.setState(
      {
        CustomerSummary,
        FilteredCustomerDetails,
        GrandCollectionTotal: SetGrandCollectionTotal,
      },
      () => {
        this.props.GrandCollectionTotal(
          "GrandCollectionTotal",
          SetGrandCollectionTotal
        );
      }
    );
  }

  async doSubmit(row, CollectionAmount) {
    let output = this.state;
    let DateTimeUpdated = GetDatetime();
    let outputJSON = {
      DDListLinesID: row.ddListLinesID,
      DDListHeaderID: output.DDListHeaderID,
      CollectionAmount: CollectionAmount,
      GrandCollectionTotal: output.GrandCollectionTotal,
      DateTimeUpdated: DateTimeUpdated,
      InvoiceNumber: row.invoiceNumber,
      TransactionID: row.transactionID,
    };

    await axios
      .put(
        "api/DdlistCustomers/PutDdlistLines/" + row.ddListLinesID,
        outputJSON
      )
      .then((response) => {
        // console.log(response);
        this.props.RefreshCollections("RefreshCollections", true);
      })
      .catch((e) => {
        var message = apiError("PutDdlistLines: ", e);
        this.props.errorList("errorList", message);
        this.props.RefreshCollections("RefreshCollections", true);
      });
  }

  async DoRemoval(row) {
    var newValue = parseFloat(row.collectionTotal);

    let newCustomerSummary = this.state.CustomerSummary.filter(
      (i) => i.accountReference !== row.accountReference
    );
    let CustomerSummary = [];
    newCustomerSummary.forEach((element, i) => {
      CustomerSummary[element.customerListID] = {
        customerListID: element.customerListID,
        accountName: element.accountName,
        accountReference: element.accountReference,
        sageURN: element.sageURN,
        collectionTotal: element.collectionTotal,
      };
    });
    var SetGrandCollectionTotal =
      parseFloat(this.state.GrandCollectionTotal) - newValue;

    this.props.GrandCollectionTotal(
      "GrandCollectionTotal",
      SetGrandCollectionTotal
    );

    this.props.errorList("errorList", "clear");

    let DateTimeUpdated = GetDatetime();

    let outputJSON = {
      DDListHeaderID: this.state.DDListHeaderID,
      CustomerListID: row.customerListID,
      AccountReference: row.accountReference,
      GrandCollectionTotal: SetGrandCollectionTotal,
      DateTimeUpdated: DateTimeUpdated,
    };

    await axios
      .put("api/DdlistCustomers/RemoveDDCustomerPut/", outputJSON)
      .then((response) => {
        this.setState({
          CustomerSummary: CustomerSummary,
          GrandCollectionTotal: SetGrandCollectionTotal,
        });
      })
      .catch((e) => {
        apiError("RemoveDDCustomerPutAPI: ", e);
      });
  }
  HandleRemoveAccount(e, row) {
    e.preventDefault();

    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className="ConfirmAlert">
            <div className="AlertBody">
              <h1>Confirm Remove Account</h1>
              Are you sure you wish to do this?
              <div className="ButtonGroup">
                <Button
                  onClick={() => {
                    this.DoRemoval(row);
                    onClose();
                  }}
                >
                  Yes
                </Button>
                <Button onClick={onClose}>No</Button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  async handleCollectAll(e, row) {
    // console.log(row);
    e.preventDefault();
    let OutputText = (
      <>
        <h3>
          This will set the Collection Amount for all transactions to each of
          their individual Outstanding Amounts for {row.accountName}.
        </h3>
        <p>Do you want to proceed?</p>
      </>
    );
    await CheckConfirm(OutputText, "500px")
      .then(async () => {
        this.props.errorList("errorList", "clear");
        let CustomerDetailsData =
          this.state.FilteredCustomerDetails[row.customerListID];
        let parent = row.customerListID;
        let CustomerSummary = this.state.CustomerSummary;
        let TransactionScheduleAmountErrorList = [];
        CustomerDetailsData.forEach((element) => {
          let oldValue = parseFloat(element.collectionAmount);
          let newValue = parseFloat(element.outstandingAmount);
          if (element.transactionType === "Invoice") {
            let currentHistoryCollectionAmount =
              element.currentHistoryCollectionAmount - element.collectionAmount;
            if (
              currentHistoryCollectionAmount + newValue >
              element.outstandingAmount
            ) {
              TransactionScheduleAmountErrorList.push({
                TransactionID: element.invoiceNumber,
              });
              newValue =
                element.outstandingAmount - currentHistoryCollectionAmount;
            }
          }

          element.collectionAmount = newValue;
          CustomerSummary[parent].collectionTotal =
            parseFloat(CustomerSummary[parent].collectionTotal) -
            oldValue +
            newValue;

          var SetGrandCollectionTotal =
            parseFloat(this.state.GrandCollectionTotal) - oldValue + newValue;

          this.setState(
            {
              CustomerSummary,
              GrandCollectionTotal: SetGrandCollectionTotal,
            },
            () => {
              this.props.GrandCollectionTotal(
                "GrandCollectionTotal",
                SetGrandCollectionTotal
              );
              this.doSubmit(element, newValue);
            }
          );
        });

        if (TransactionScheduleAmountErrorList.length > 0) {
          var OutputMessage = (
            <>
              <h3>Unable to Fully Update</h3>
              <p>
                The total schedule amount for the follow transactions are
                greater than their individual Outstanding Amounts -
              </p>
              <BootstrapTable
                bootstrap4
                responsive
                hover
                keyField="TransactionID"
                data={TransactionScheduleAmountErrorList}
                columns={[
                  {
                    dataField: "TransactionID",
                    text: "Transaction",
                  },
                ]}
                pagination={paginationFactory()}
              />
              <p>
                The "To Collect" values have therefore been updated to the
                maximum value that can be collected.
              </p>
            </>
          );
          await CheckConfirmOK(OutputMessage, "500px").then(() => {});
        }
      })
      .catch((e) => {});
  }

  async handleCollectNone(e, row) {
    // console.log(row);
    e.preventDefault();
    let OutputText = (
      <>
        <h3>
          This will set the Collection Amount for all transactions to zero for{" "}
          {row.accountName}.
        </h3>
        <p>Do you want to proceed?</p>
      </>
    );
    await CheckConfirm(OutputText, "500px")
      .then(() => {
        this.props.errorList("errorList", "clear");
        let CustomerDetailsData =
          this.state.FilteredCustomerDetails[row.customerListID];

        // console.log(CustomerDetailsData);
        let parent = row.customerListID;
        let CustomerSummary = this.state.CustomerSummary;
        CustomerDetailsData.forEach((element) => {
          let oldValue = parseFloat(element.collectionAmount);
          let newValue = 0;

          element.collectionAmount = newValue;
          CustomerSummary[parent].collectionTotal = 0;
          // parseFloat(CustomerSummary[parent].collectionTotal) - oldValue;

          var SetGrandCollectionTotal =
            parseFloat(this.state.GrandCollectionTotal) - oldValue;

          this.setState(
            {
              CustomerSummary,
              GrandCollectionTotal: SetGrandCollectionTotal,
            },
            () => {
              this.props.GrandCollectionTotal(
                "GrandCollectionTotal",
                SetGrandCollectionTotal
              );
              this.doSubmit(element, newValue);
            }
          );
        });
      })
      .catch(() => {});
  }

  GetFilteredCustomerDetailsData(customerListID) {
    if (this.state.FilteredCustomerDetails[customerListID]) {
      var data = this.state.FilteredCustomerDetails[customerListID].sort(
        (a, b) => {
          function ConvertDate(value) {
            var date = value.split("-");
            var d = parseInt(date[0], 10),
              m = parseInt(date[1], 10),
              y = parseInt(date[2], 10);
            return Date.parse(m + "-" + d + "-" + y);
          }
          var aDate = ConvertDate(a.transactionDate);
          var bDate = ConvertDate(b.transactionDate);

          if (aDate > bDate) return 1;
          if (aDate < bDate) return -1;
          if (a.invoiceNumber > b.invoiceNumber) return 1;
          if (a.invoiceNumber < b.invoiceNumber) return -1;
          if (a.transactionType > b.transactionType) return 1;
          if (a.transactionType < b.transactionType) return -1;
          else return 0;
        }
      );
      return data;
    } else {
      return [];
    }
  }

  render() {
    if (!this.state.CustomerSummary) {
      return null;
    } else {
      let RemoveAccountColumn = [
        {
          dataField: "RemoveAccount",
          text: "",
          editable: false,
          headerAlign: "center",
          align: "center",
          isDummyField: true,
          headerClasses: "smlButtonCol",
          formatter: (cellContent, row) => {
            return (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="RemoveAccountInfoTooltip" className="">
                    Remove Account
                  </Tooltip>
                }
              >
                <Button
                  className="ButtonRemove"
                  onClick={(e) => this.HandleRemoveAccount(e, row)}
                >
                  <FaTrashAlt />
                </Button>
              </OverlayTrigger>
            );
          },
        },
      ];
      let CollectColumn = [
        {
          dataField: "CollectNone",
          text: "",
          editable: false,
          headerAlign: "center",
          align: "center",
          isDummyField: true,
          headerClasses: "smlButtonCol",
          formatter: (cellContent, row) => {
            return (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="CommitInfoTooltip" className="">
                    Collect None
                  </Tooltip>
                }
              >
                <Button
                  className="ButtonRemove"
                  onClick={(e) => this.handleCollectNone(e, row)}
                >
                  <MdFilterNone />
                </Button>
              </OverlayTrigger>
            );
          },
        },
        {
          dataField: "CollectAll",
          text: "",
          editable: false,
          headerAlign: "center",
          align: "center",
          isDummyField: true,
          headerClasses: "smlButtonCol",
          formatter: (cellContent, row) => {
            return (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="CommitInfoTooltip" className="">
                    Collect All
                  </Tooltip>
                }
              >
                <Button
                  className="ButtonRemove"
                  onClick={(e) => this.handleCollectAll(e, row)}
                >
                  <BiSelectMultiple />
                </Button>
              </OverlayTrigger>
            );
          },
        },
      ];

      let ShowURN =
        this.props.StatusID >= 3 &&
        this.props.StatusID <= 5 &&
        this.state.ProductName !== "Sage Intacct"
          ? true
          : false;
      let URNColumn = {
        dataField: "sageURN",
        text: "Posted URN",
        headerClasses: "mdCol",
        headerFormatter: NoTextFormatter,
        sort: true,
        filter: textFilter({ placeholder: " " }),
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      };

      let collectionTotalColumn = {
        dataField: "collectionTotal",
        text: "Collection Total",
        headerAlign: "right",
        align: "right",
        headerClasses: ShowURN ? "mdCol" : null,
        headerFormatter: NoTextFormatter,
        sort: true,
        formatter: (cellContent, row) => {
          return parseFloat(cellContent).toFixed(2);
        },
      };

      let accountReferenceColumn = {
        dataField: "accountReference",
        text: "Account Reference",
        sort: true,
        filter: textFilter({ placeholder: " " }),
      };

      let accountNameColumn = {
        dataField: "accountName",
        text: "Account Name",
        sort: true,
        filter: textFilter({ placeholder: " " }),
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      };

      let CustomerSummaryVolumnsRemaining = [
        accountReferenceColumn,
        accountNameColumn,
        collectionTotalColumn,
      ];
      if (ShowURN) {
        CustomerSummaryVolumnsRemaining = [
          accountReferenceColumn,
          accountNameColumn,
          URNColumn,
          collectionTotalColumn,
        ];
      }

      if (
        (this.context.SettingsList.CanDelete.DDAccounts ||
          this.context.SettingsList.IsAdmin) &&
        this.props.StatusID === 1
      ) {
        var CustomerSummaryColumnList = RemoveAccountColumn.concat(
          CollectColumn,
          CustomerSummaryVolumnsRemaining
        );
      } else {
        if (this.props.StatusID === 1) {
          CustomerSummaryColumnList = CollectColumn.concat(
            CustomerSummaryVolumnsRemaining
          );
        } else {
          CustomerSummaryColumnList = CustomerSummaryVolumnsRemaining;
        }
      }

      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {size} Results
        </span>
      );

      let DDListDetailsPage = 1;
      if (
        this.state.CustomerSummary.filter((e) => {
          return e != null;
        }).length > 9 &&
        sessionStorage.getItem("DDListDetailsPage")
      ) {
        DDListDetailsPage = sessionStorage.getItem("DDListDetailsPage");
      }

      const paginationOptions = {
        page: parseInt(DDListDetailsPage),
        onPageChange: (page) => {
          sessionStorage.setItem("DDListDetailsPage", page);
        },
        hidePageListOnlyOnePage: true,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
          {
            text: "10",
            value: 10,
          },
          {
            text: "50",
            value: 50,
          },
          {
            text: "100",
            value: 100,
          },
          {
            text: "All",
            value: this.state.CustomerSummary.length,
          },
        ],
      };
      let CustomerSummaryVolumnsColumns = [
        {
          dataField: "transactionDate",
          text: "Tran Date",
          editable: false,
        },
        {
          dataField: "invoiceNumber",
          text:
            this.state.ProductName === "Sage Intacct" ? "Inv No." : "Reference",
          editable: false,
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
        {
          dataField: "transactionType",
          text: "Tran Type",
          editable: false,
        },
        {
          dataField: "dueDate",
          text: "Due Date",
          editable: false,
        },
        {
          dataField: "reference",
          text:
            this.state.ProductName === "Sage Intacct"
              ? "Reference"
              : "2nd Reference",
          editable: false,
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
        {
          dataField: "baseAmount",
          text: "Tran Amt.",
          editable: false,
          headerAlign: "right",
          align: "right",
          formatter: (cellContent, row) => {
            cellContent = parseFloat(cellContent).toFixed(2);
            return cellContent;
          },
        },
        {
          dataField: "outstandingAmount",
          text: "OS Amt.",
          editable: false,
          headerAlign: "right",
          align: "right",
          formatter: (cellContent, row) => {
            cellContent = parseFloat(cellContent).toFixed(2);
            return cellContent;
          },
        },
        {
          dataField: "collectionAmount",
          text: "To Collect",
          headerAlign: "right",
          align: "right",
          formatter: (cellContent, row) => {
            cellContent = parseFloat(cellContent).toFixed(2);
            return cellContent;
          },
          validator: (newValue, row, column) => {
            var decimalFormat = new RegExp(/^-?(?!0\d|$)\d*(\.\d{0,2})?$/);
            if (!decimalFormat.test(newValue)) {
              return {
                valid: false,
                message:
                  "Invalid format, must be a number and in decimal format, e.g. 0.00",
              };
            }
            if (newValue === undefined || newValue === null) {
              return {
                valid: false,
                message: "Value required",
              };
            }
            newValue = parseFloat(newValue);

            if (row.transactionType === "Invoice") {
              if (row.theScheduleList.length >= 1) {
                var RemainingAmount =
                  row.periodTotalAmount - row.amountAlreadyCollected;
                if (newValue > RemainingAmount && row.outstandingAmount > 0) {
                  return {
                    valid: false,
                    message: `${newValue} exceeds the remaining amount of ${RemainingAmount}`,
                  };
                }
              }
              let currentHistoryCollectionAmount =
                row.currentHistoryCollectionAmount - row.collectionAmount;
              if (
                currentHistoryCollectionAmount + newValue >
                row.outstandingAmount
              ) {
                let maxValue =
                  row.outstandingAmount - currentHistoryCollectionAmount;
                let ScheduleMessage = `The total schedule amount for this transaction is greater than the Outstanding Amount. The maximum that can be set is ${maxValue}`;
                if (maxValue <= 0) {
                  ScheduleMessage = `Unable to update the Collection Amount as all the available funds for this transaction have been allocated in other Direct Debit Runs and Schedules.`;
                }
                return {
                  valid: false,
                  message: ScheduleMessage,
                };
              }
            }

            if (Math.sign(row.outstandingAmount) > 0) {
              // Positive
              if (newValue > parseFloat(row.outstandingAmount)) {
                return {
                  valid: false,
                  message:
                    "Value is more than the Outstanding Amount - unable to proceed.",
                };
              }
              if (newValue < 0.0) {
                return {
                  valid: false,
                  message: "Value is less than 0 - unable to proceed.",
                };
              }
            } else {
              // Negative
              if (newValue < parseFloat(row.outstandingAmount)) {
                return {
                  valid: false,
                  message:
                    "Value is more than the Outstanding Amount - unable to proceed.",
                };
              }
              if (newValue > 0.0) {
                return {
                  valid: false,
                  message: "Value is more than 0 - unable to proceed.",
                };
              }
            }
            return true;
          },
        },
        {
          dataField: "Schedule",
          text: "",
          editable: false,
          headerAlign: "right",
          align: "right",
          isDummyField: true,
          headerClasses: "smlButtonCol",
          formatter: (cellContent, row) => {
            if (row.currentHistoryCollectionAmount !== 0) {
              return (
                <ShowSchedule
                  CurrentDDHeaderID={this.state.DDListHeaderID}
                  ScheduleRowData={row.theScheduleList}
                />
              );
            } else return null;
          },
        },
      ];
      const expandRow = {
        renderer: (row) => (
          <>
            {this.props.StatusID === 1 ? (
              <BootstrapTable
                bootstrap4
                responsive
                hover
                keyField="ddListLinesID"
                data={this.GetFilteredCustomerDetailsData(row.customerListID)}
                columns={CustomerSummaryVolumnsColumns}
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  autoSelectText: true,
                  timeToCloseMessage: 8000,
                  afterSaveCell: (oldValue, newValue, row, column) => {
                    newValue = parseFloat(newValue);
                    oldValue = parseFloat(oldValue);
                    if (newValue !== oldValue) {
                      this.props.errorList("errorList", "clear");
                      var SetGrandCollectionTotal =
                        parseFloat(this.state.GrandCollectionTotal) -
                        oldValue +
                        newValue;

                      this.setState(
                        {
                          GrandCollectionTotal: SetGrandCollectionTotal,
                        },
                        () => {
                          this.props.GrandCollectionTotal(
                            "GrandCollectionTotal",
                            SetGrandCollectionTotal
                          );
                          this.doSubmit(row, newValue);
                        }
                      );
                    }
                  },
                })}
              />
            ) : (
              <BootstrapTable
                bootstrap4
                responsive
                hover
                keyField="ddListLinesID"
                data={this.GetFilteredCustomerDetailsData(row.customerListID)}
                columns={CustomerSummaryVolumnsColumns}
              />
            )}
          </>
        ),
        onlyOneExpanding: true,
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: () => {
          return null;
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="CommitInfoTooltip" className="">
                    Toggle Expand Row
                  </Tooltip>
                }
              >
                <Button className="ButtonRemove">
                  <FaChevronDown />
                </Button>
              </OverlayTrigger>
            );
          }
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="CommitInfoTooltip" className="">
                  Toggle Expand Row
                </Tooltip>
              }
            >
              <Button className="ButtonRemove">
                <FaChevronRight />
              </Button>
            </OverlayTrigger>
          );
        },
        expandColumnPosition: "left",
        parentClassName: "parentExpand",
      };
      const defaultSorted = [
        {
          dataField: "accountReference",
          order: "asc",
        },
      ];

      return (
        <div className=" DDRunDetails">
          <BootstrapTable
            bootstrap4
            responsive
            hover
            keyField="accountReference"
            data={this.state.CustomerSummary.filter((e) => {
              return e != null;
            })}
            columns={CustomerSummaryColumnList}
            filter={filterFactory()}
            pagination={paginationFactory(paginationOptions)}
            expandRow={expandRow}
            defaultSorted={defaultSorted}
          />
        </div>
      );
    }
  }
}

DDRunDetails.contextType = AppContext;
export default DDRunDetails;
