import React, { useState, createContext } from "react";

export const AppContext = createContext({
  isAuthenticated: false,
  IPWhitelistSetting: false,
  usr: "",
  SettingsList: "",
  INTACCT_API_BASE_URL: "",
  SAGE_CLIENT_ID: "",
  SAGE_CLIENT_SECRET: "",
  SageAccessToken: "",
  Environment: "",

  setIsAuthenticated: () => {},
  setUsr: () => {},
  setIPWhitelistSetting: () => {},
  setSettingsList: () => {},
  setSageAccessToken: () => {},
  setEnvironment: () => {},
});

export function AppProvider(props) {
  let [isAuthenticated, setIsAuthenticated] = useState(false),
    [IPWhitelistSetting, setIPWhitelistSetting] = useState(false),
    [usr, setUsr] = useState(""),
    [SettingsList, setSettingsList] = useState(""),
    [INTACCT_API_BASE_URL, setINTACCT_API_BASE_URL] = useState(""),
    [SAGE_CLIENT_ID, setSAGE_CLIENT_ID] = useState(""),
    [SAGE_CLIENT_SECRET, setSAGE_CLIENT_SECRET] = useState(""),
    [SageAccessToken, setSageAccessToken] = useState(""),
    [Environment, setEnvironment] = useState("");

  let state = {
    isAuthenticated: isAuthenticated,
    IPWhitelistSetting: IPWhitelistSetting,
    usr: usr,
    SettingsList: SettingsList,
    INTACCT_API_BASE_URL: INTACCT_API_BASE_URL,
    SAGE_CLIENT_ID: SAGE_CLIENT_ID,
    SAGE_CLIENT_SECRET: SAGE_CLIENT_SECRET,
    SageAccessToken: SageAccessToken,
    Environment: Environment,

    setIsAuthenticated: setIsAuthenticated,
    setIPWhitelistSetting: setIPWhitelistSetting,
    setUsr: setUsr,
    setSettingsList: setSettingsList,
    setSageAccessToken: setSageAccessToken,
    setEnvironment: setEnvironment,

    setINTACCT_API_BASE_URL: setINTACCT_API_BASE_URL,
    setSAGE_CLIENT_ID: setSAGE_CLIENT_ID,
    setSAGE_CLIENT_SECRET: setSAGE_CLIENT_SECRET,
  };

  return (
    <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
  );
}

export const AppConsumer = AppContext.Consumer;
export default AppContext;
