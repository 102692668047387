export default function SetEntity(CompanyList, entity) {
  let ProductName = sessionStorage.getItem("productName");
  var a = CompanyList;
  Object.keys(CompanyList).forEach((item) => {
    if (a[item].entityLocationId && ProductName !== "Sage 200c Standard") {
      CompanyList[item][entity] =
        a[item].entityLocationId + " - " + a[item].entityCompanyName;
    } else {
      CompanyList[item][entity] = a[item].entityCompanyName;
    }
  });

  return CompanyList;
}
