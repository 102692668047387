import React, { Component } from "react";
import {
  FaArrowCircleLeft,
  FaAngleDoubleRight,
  FaTimesCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import FormInputBox from "../Form/FormInputbox";
import FormDropdown from "../Form/FormDropdown";
import FormCheck from "../Form/FormCheck";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import GetDatetime from "../../libs/GetDatetime";
import SetEntity from "../../libs/SetEntity";
import { handleValidation } from "../handleValidation";
import { AppContext } from "../../libs/contextLib";
import IsError from "../IsError";
import * as yup from "yup";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";
import OutputMessage from "../../components/OutputMessage";
const newCompanyNameSchema = yup
  .string()
  .required("Company Name is required")
  .matches(inputRegex, "Company Name" + inputRegexMessage)
  .max(100, "Company Name must be less than 100 characters");

let blankState = {
  notEditableSectionOne: false,
  entityEditable: true,
  contiueElementVisible: true,
  validated: false,
  companyName: "",
  newCompanyName: "",
  companySettingID: 1,
  IsActive: true,
  FilterFieldName: "",
  FilterValue: [""],
  InCredit: false,
  DDOutputFormat: 1,
  TransactionFilter: 1,
  DisableDDCompanySync: false,
  MustPassModulusCheck: false,
  IncludeOnHoldAccounts: false,
  UseRuntimeFilters: false,
  UseScheduling: false,
  TransferCollection: false,
  IncludeInvoices: false,
  IncludeCredits: false,
  IncludeReceipts: false,
  IncludePayments: false,
  IncludeDebitMemos: false,
  IncludeCreditMemos: false,
  FileFormat: 1,
  RejectionOption: 1,
  AddNewAccountsOnUpdate: false,
  CollectNewTransactionsOnUpdate: false,
  ShowNotificationLettersOnCreated: true,
  ShowTransactionsOutsideDateRange: false,
  SummariseOutput: true,
  CustomFormat: "",
  UseLetterTextDefault: false,
  UseScheduleText: false,
  LetterOpening: "Dear {FirstName},",
  ServiceUserNumber: "",
  ReceivingPartyIdentifyingNumber: "",
  UserPostOverride: false,
  errorList: [],
};

let CompanyListLabel = "Entity";

class CompanyListDetails extends Component {
  static contextState = AppContext;

  constructor(props) {
    super(props);
    this.state = blankState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  async loadData() {
    let params = this.props.location.state,
      SiteID = sessionStorage.getItem("siteID"),
      SiteSettingsID = parseInt(sessionStorage.getItem("SiteSettingsID")),
      ProductName = sessionStorage.getItem("productName"),
      NewCompany;
    if (ProductName !== "Sage Intacct") {
      CompanyListLabel = "Sage Company";
    }
    if (params !== undefined && params) {
      NewCompany = params.NewCompany;
      if (params.NewCompany) {
        sessionStorage.setItem("NewListItem", true);
        sessionStorage.setItem("CompanyID", null);
      } else {
        sessionStorage.setItem("NewListItem", false);
      }
    } else {
      NewCompany = JSON.parse(sessionStorage.getItem("NewListItem"));
    }

    await trackPromise(
      Promise.all([
        axios.get("/api/SysbankFormats/").catch((e) => {
          var message = apiError("API Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
        axios.get("/api/SystransactionFilters/").catch((e) => {
          var message = apiError("API transactionFilters Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
        axios.get("/api/CompanyLists/" + SiteID).catch((e) => {
          var message = apiError("API Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
      ])
        .then((responses) => {
          let DDOutputFormatList,
            TransactionFilterList,
            setAmendableFormat,
            setDefaultFormat,
            CompanyLists;

          if (responses[0] && responses[0] !== undefined) {
            DDOutputFormatList = responses[0].data;
            DDOutputFormatList.forEach((element) => {
              if (element.id === this.state.DDOutputFormat) {
                setAmendableFormat = element.isAmendableFormat;
                setDefaultFormat = element.defaultFormat;
              }
            });
          } else {
            throw new Error("Problem Fetching DDOutput Format List.");
          }
          if (responses[1] && responses[1] !== undefined) {
            TransactionFilterList = responses[1].data;
          } else {
            throw new Error("Problem Fetching Transaction Filter List.");
          }
          if (responses[2] && responses[2] !== undefined) {
            CompanyLists = responses[2].data;
            CompanyLists = SetEntity(CompanyLists, "content");
          } else {
            throw new Error(`Problem Fetching ${CompanyListLabel} List.`);
          }

          this.setState(
            {
              SiteID,
              SiteSettingsID,
              ProductName,
              CompanyListLabel,
              NewCompany,
              DDOutputFormatList,
              AmendableFormat: setAmendableFormat,
              DefaultFormat: setDefaultFormat,
              TransactionFilterList,
              CompanyLists,
              CompanyListId: CompanyLists[0].id,
              notEditableSectionOne: false,
              contiueElementVisible: true,
            },
            () => {
              // console.log(this.state);
            }
          );
        })
        .catch((e) => {
          var message = apiError("apiDDOutputFormatList: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  handleCallback = (name, theData) => {
    // console.log(name);
    // console.log(theData);
    this.setState((state) => {
      state[name] = theData;

      return state;
    });
  };

  handleFormReset(e) {
    e.preventDefault();

    this.props.navigate("/CompanyList");
  }

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "newCompanyName",
          data: this.state.newCompanyName,
          schema: newCompanyNameSchema,
        },
      ];
      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.validateForm()
      .then((response) => {
        this.setState({ validated: true, errorList: "" }, () => {
          this.doSubmit("Submit");
        });
      })
      .catch((e) => {
        this.setState({
          validated: false,
          validationErrorList: JSON.stringify(e),
        });
      });
  }

  async doSubmit() {
    let output = this.state;

    let DateTime = GetDatetime();

    var FilterValueList = [];
    for (var i = 0; i <= 7; i++) {
      if (!output.FilterValue[0]) {
        output.FilterValue[0] = null;
      }
      FilterValueList[i] = output.FilterValue[i];
    }
    if (output.CustomFormat) {
      var CustomFormat = output.CustomFormat.toString();
    }

    let outputJSON = {
      SiteSettingsID: output.SiteSettingsID,
      CompanyListId: output.CompanyListId,
      CompanyName: output.newCompanyName,
      IsActive: output.IsActive,
      DefaultReceiptsBankID: output.DefaultReceiptsBank,
      TransferCollection: output.TransferCollection,
      FilterFieldName: output.FilterFieldName,
      FilterValueList: FilterValueList,

      IncludeAccountsInCredit: output.InCredit,
      DDOutputFormatID: output.DDOutputFormat,
      TransactionFilterID: output.TransactionFilter,
      IncludeOnHoldAccounts: output.IncludeOnHoldAccounts,
      UseRuntimeFilters: output.UseRuntimeFilters,
      DisableDDCompanySync: output.DisableDDCompanySync,
      MustPassModulusCheck: output.MustPassModulusCheck,
      UserPostOverride: output.UserPostOverride,

      UseScheduling: output.UseScheduling,

      IncludeInvoices: output.IncludeInvoices,
      IncludeCredits: output.IncludeCredits,
      IncludeReceipts: output.IncludeReceipts,
      IncludePayments: output.IncludePayments,

      ShowTransactionsOutsideDateRange: output.ShowTransactionsOutsideDateRange,
      ShowNotificationLettersOnCreated: output.ShowNotificationLettersOnCreated,
      SummariseOutput: output.SummariseOutput,
      LetterOpening: output.LetterOpening,
      UseLetterTextDefault: output.UseLetterTextDefault,
      UseScheduleText: output.UseScheduleText,
      ServiceUserNumber: output.ServiceUserNumber,
      ReceivingPartyIdentifyingNumber: output.ReceivingPartyIdentifyingNumber,
      CustomFormat: CustomFormat,

      IncludeDebitMemos: output.IncludeDebitMemos,
      AddNewAccountsOnUpdate: output.AddNewAccountsOnUpdate,
      CollectNewTransactionsOnUpdate: output.CollectNewTransactionsOnUpdate,
      IncludeCreditMemos: output.IncludeCreditMemos,
      FileFormat: output.FileFormat,
      RejectionOption: output.RejectionOption,

      DateTimeUpdated: DateTime,
      DateTimeCreated: DateTime,
      SiteID: output.SiteID,
    };

    // console.log("post");
    // console.log(outputJSON);
    await trackPromise(
      axios
        .post("api/SyscompanySettings/", outputJSON)
        .then((results) => {
          console.log(results);

          var postResponse = JSON.parse(results.request.response);
          axios
            .post("api/SyscompanySyncs/", {
              SYSCompanyID: postResponse.syscompanyId,
              SiteID: output.SiteID,
              DateTimeCreated: DateTime,
              DateTimeUpdated: DateTime,
              LastCustomerListSync: DateTime,
              LastBankListSync: DateTime,
            })
            .then((SyscompanySyncsResults) => {
              this.setState(
                {
                  CompanyID: postResponse.syscompanyId,
                },
                () => {
                  this.getSyncData().then(() => {
                    sessionStorage.setItem(
                      "CompanyID",
                      postResponse.syscompanyId
                    );

                    this.props.navigate("/CompanyListDetails", {
                      state: {
                        NewCompany: true,
                        NewCompanyFirstLoad: true,
                      },
                    });
                  });
                }
              );
            })
            .catch((e) => {
              var message = apiError("CompanySyncPostAPI: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            });
        })
        .catch((e) => {
          var message = apiError("postAPI: ", e);
          console.log(message);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  async handleContinue(e) {
    e.preventDefault();

    this.validateForm()
      .then(async () => {
        this.setState({
          validated: true,
          errorList: [],
        });
        await trackPromise(this.doSubmit("Continue"));
      })
      .catch((e) => {
        this.setState({ validated: false, errorList: e }, () => {});
      });
  }
  async getSyncData() {
    await trackPromise(
      Promise.all([
        axios
          .get(
            this.context.INTACCT_API_BASE_URL +
              "/api/SyncCompany/?siteID=" +
              this.state.SiteID +
              "&SYSCompanyID=" +
              this.state.CompanyID +
              "&accessToken=" +
              this.context.SageAccessToken,
            {
              crossdomain: true,
            }
          )
          .catch((e) => {
            var message = apiError("SyncCompany Error: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          }),
      ]).then((responses) => {
        if (responses[0]) {
          this.loadData()
            .then(() => {
              this.setState({
                notEditableSectionOne: true,
                contiueElementVisible: false,
              });
            })
            .catch((e) => {
              var message = apiError("API Sync Error: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            });
        }
      })
    ).catch((e) => {
      var message = apiError("API Sync Error:", e);
      this.setState((previousState) => ({
        NoData: true,
        errorList: [...previousState.errorList, message],
      }));
    });
  }

  renderContent() {
    const notEditableSectionOne = this.state.notEditableSectionOne;

    const contiueElementVisible = this.state.contiueElementVisible;
    return (
      <div className="CompanyListDetails">
        <h1>Company Settings</h1>
        <Row>
          <Col>
            <Link to="/CompanyList">
              <FaArrowCircleLeft /> Back to Company List
            </Link>
          </Col>
          <div id="sectionOne"></div>
        </Row>

        <OutputMessage errorList={this.state.errorList} />

        <div className="detailsContent">
          <Form
            id="CompanyListDetailsForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <div id="sectionOne">
              <FormInputBox
                placeholder={
                  this.state.companyName
                    ? this.state.companyName
                    : this.state.newCompanyName
                }
                type="text"
                Label="Company Name"
                name="newCompanyName"
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionOne}
                errorList={this.state.errorList}
                schema={newCompanyNameSchema}
              />

              <FormDropdown
                id="CompanyListId"
                Label={CompanyListLabel}
                name="CompanyListId"
                placeholder={this.state.CompanyListId}
                itemContent={this.state.CompanyLists}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                required={true}
                errorList={this.state.errorList}
              />

              <FormCheck
                type="switch"
                Label="Is Active"
                name="IsActive"
                id="IsActive"
                initialState={this.state.IsActive}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionOne}
                errorList={this.state.errorList}
              />

              {contiueElementVisible ? (
                <Form.Group className={"FormGroup"} as={Row}>
                  <Col sm={LabelColumnAmount}></Col>
                  <Col sm={4} className="IconBtn">
                    <Button
                      variant="primary"
                      type="button"
                      name="Continue"
                      onClick={this.handleContinue}
                    >
                      <FaAngleDoubleRight /> Continue
                    </Button>
                    {this.state.validationErrorList
                      ? ValidationError(this.state.validationErrorList)
                      : null}

                    <Button
                      variant="primary"
                      disabled={false}
                      onClick={this.handleFormReset}
                    >
                      <FaTimesCircle /> Cancel
                    </Button>
                  </Col>
                </Form.Group>
              ) : null}
            </div>
          </Form>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.NoData) {
      return <IsError errorList={this.state.errorList} />;
    } else {
      if (!this.state.CompanyLists) {
        return null;
      } else {
        return this.renderContent();
      }
    }
  }
}
CompanyListDetails.contextType = AppContext;
export default withRouter(CompanyListDetails);
