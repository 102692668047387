import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
import { Auth } from "aws-amplify";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

let cognitoIdentityClient = new CognitoIdentityClient({
  region: process.env.REACT_APP_REGION,
});

export function Client() {
  return new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_REGION,
    credentials: fromCognitoIdentityPool({
      client: cognitoIdentityClient,
      identityPoolId: Auth._config.identityPoolId,
    }),
  });
}
