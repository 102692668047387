import { Row, Col, Button } from "react-bootstrap";
import BootstrapTable from "../components/react-bootstrap-table2";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

export const handlePrevPage =
  ({ page, onPageChange }) =>
  () => {
    if (page <= 1) {
      onPageChange(page);
    } else {
      onPageChange(page - 1);
    }
  };

export const handleNextPage =
  ({ page, onPageChange }, totalPages) =>
  () => {
    if (page >= totalPages) {
      onPageChange(page);
    } else {
      onPageChange(page + 1);
    }
  };

export function PaginationDisplay(
  defaultSorted,
  sizePerPage,
  keyField,
  Columns,
  RowData,
  Title = "",
  expandRow = {}
) {
  let totalPages = Math.ceil(RowData.length / sizePerPage);
  const paginationOptions = {
    custom: true,
    totalSize: RowData.length,
    hidePageListOnlyOnePage: true,
    sizePerPage: sizePerPage,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <>
            {Title}
            <BootstrapTable
              bootstrap4
              responsive
              hover
              keyField={keyField}
              data={RowData}
              columns={Columns}
              filter={filterFactory()}
              {...paginationTableProps}
              defaultSorted={defaultSorted}
              expandRow={expandRow}
            />
            <Row>
              <Col>
                <PaginationTotalStandalone {...paginationProps} />
              </Col>
              {RowData.length > sizePerPage ? (
                <>
                  {paginationProps.page === 1 ? null : (
                    <Col sm="1">
                      <Button
                        className="btn btn-primary"
                        onClick={handlePrevPage(paginationProps)}
                      >
                        Back
                      </Button>
                    </Col>
                  )}
                  {paginationProps.page === totalPages ? null : (
                    <Col sm="1">
                      <Button
                        className="btn btn-primary"
                        onClick={handleNextPage(paginationProps, totalPages)}
                      >
                        Next
                      </Button>
                    </Col>
                  )}
                </>
              ) : null}
            </Row>
          </>
        )}
      </PaginationProvider>
    </>
  );
}
