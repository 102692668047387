import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import Colours from "./Variables/Colours";

let Border = `2px solid ${Colours.green}`,
  Radius = "0",
  width = "100%",
  padding = "6px 12px",
  styles = StyleSheet.create({
    tableContainer: {
      width: width,
      flexDirection: "row",
      flexWrap: "wrap",
      // background: Colours.white,
      borderCollapse: "separate",
      borderSpacing: 0,
      marginTop: "10px",
      paddingBottom: "20px",
    },
    TrContainer: {
      // borderLeft: Border,
      // borderRight: Border,
      borderBottom: Border,
      // backgroundColor: Colours.borderGrey,
    },
    tr: {
      flexDirection: "row",
      width: width,
      // backgroundColor: Colours.white,
    },
    thTr: {
      flexDirection: "row",
      width: width,
      // backgroundColor: Colours.greenBackground,
      borderTopLeftRadius: Radius,
      borderTopRightRadius: Radius,
      borderTop: Border,
      // borderLeft: Border,
      // borderRight: Border,
      borderBottom: Border,
    },
    ChildSection: {
      fontSize: 8,
      width: width,
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      // padding: "0 10px 9px 10px",
    },
    ChildTrTh: {
      flexDirection: "row",
      width: width,
      // backgroundColor: Colours.greenBackground,
    },
    ChildTr: {
      flexDirection: "row",
      width: width,
      // backgroundColor: Colours.white,
    },
    ChildFooter: {
      flexDirection: "row",
      width: width,
      // backgroundColor: Colours.white,
      borderBottomLeftRadius: Radius,
      borderBottomRightRadius: Radius,
    },
    footer: {
      borderBottomLeftRadius: Radius,
      borderBottomRightRadius: Radius,
      borderBottom: Border,
      // borderLeft: Border,
      // borderRight: Border,
      // backgroundColor: Colours.borderGrey,
    },
    th: {
      padding: padding,
      fontWeight: 700,
      fontStyle: "normal",
    },
    td: {
      padding: padding,
    },
    RightAlign: { textAlign: "right" },
  });

function SetRowStyle(i, r) {
  let IsEven = i % 2 === 0 ? true : false;
  let IsLastElement = i + 1 === r.length ? true : false;
  let RowStyle;
  switch (IsEven) {
    case true:
      //  styles.ChildFooter["backgroundColor"] = Colours.white;
      //  styles.ChildTr["backgroundColor"] = Colours.white;
      break;
    case false:
      // styles.ChildFooter["backgroundColor"] = Colours.grey;
      // styles.ChildTr["backgroundColor"] = Colours.grey;
      break;
    default:
      break;
  }

  if (IsLastElement) {
    RowStyle = styles.ChildFooter;
  } else {
    RowStyle = styles.ChildTr;
  }
  return RowStyle;
}

const TableSection = ({ TableData }) => {
  styles.th["width"] =
    TableData.ProductName !== "Sage Intacct" ? "25%" : "33.3%";
  styles.td["width"] =
    TableData.ProductName !== "Sage Intacct" ? "25%" : "33.3%";
  return (
    <View style={{ ...styles.tableContainer }}>
      <View style={{ ...styles.thTr }}>
        <Text style={{ ...styles.th }}>Account Reference</Text>
        <Text style={{ ...styles.th }}>Account Name</Text>
        {TableData.ProductName !== "Sage Intacct" ? (
          <Text style={{ ...styles.th, ...styles.RightAlign }}>Posted URN</Text>
        ) : null}
        <Text style={{ ...styles.th, ...styles.RightAlign }}>
          Collection Total
        </Text>
      </View>
      {TableData.ReportListData.map((Element, i, row) => (
        <View
          key={i}
          style={i + 1 === row.length ? styles.footer : styles.TrContainer}
        >
          <View style={{ ...styles.tr }}>
            <Text style={{ ...styles.td }}>{Element.accountReference}</Text>
            <Text style={{ ...styles.td }}>{Element.accountName}</Text>
            {TableData.ProductName !== "Sage Intacct" ? (
              <Text style={{ ...styles.td, ...styles.RightAlign }}>
                {Element.sageURN}
              </Text>
            ) : null}
            <Text style={{ ...styles.td, ...styles.RightAlign }}>
              {Element.collectionTotal.toFixed(2)}
            </Text>
          </View>
          <View style={styles.ChildSection}>
            <View style={styles.ChildTrTh}>
              <Text style={{ ...styles.th }}>Tran Date</Text>
              <Text style={{ ...styles.th }}>
                {TableData.ProductName !== "Sage Intacct"
                  ? "Reference"
                  : "Inv No."}
              </Text>
              <Text style={{ ...styles.th }}>Tran Type</Text>
              <Text style={{ ...styles.th }}>Due Date</Text>
              <Text style={{ ...styles.th }}>
                {TableData.ProductName !== "Sage Intacct"
                  ? "2nd Reference"
                  : "Reference"}
              </Text>
              <Text style={{ ...styles.th, ...styles.RightAlign }}>
                Tran Amt.
              </Text>
              <Text style={{ ...styles.th, ...styles.RightAlign }}>
                OS Amt.
              </Text>
              <Text style={{ ...styles.th, ...styles.RightAlign }}>
                To Collect
              </Text>
            </View>
            {Element.ddListItems.map((ChildElement, k, r) => {
              let RowStyle = SetRowStyle(k, r);

              return (
                <div key={k}>
                  <View wrap={true} style={{ ...RowStyle }}>
                    <Text style={{ ...styles.td }}>
                      {ChildElement.transactionDate}
                    </Text>
                    <Text style={{ ...styles.td }}>
                      {ChildElement.invoiceNumber}
                    </Text>
                    <Text style={{ ...styles.td }}>
                      {ChildElement.transactionType}
                    </Text>
                    <Text style={{ ...styles.td }}>{ChildElement.dueDate}</Text>
                    <Text style={{ ...styles.td }}>
                      {ChildElement.reference}
                    </Text>
                    <Text style={{ ...styles.td, ...styles.RightAlign }}>
                      {ChildElement.baseAmount.toFixed(2)}
                    </Text>
                    <Text style={{ ...styles.td, ...styles.RightAlign }}>
                      {ChildElement.outstandingAmount.toFixed(2)}
                    </Text>
                    <Text style={{ ...styles.td, ...styles.RightAlign }}>
                      {ChildElement.collectionAmount.toFixed(2)}
                    </Text>
                  </View>
                  {/* Add ScheduleList Rows */}
                  {ChildElement.currentHistoryCollectionAmount !== 0 ? (
                    <View style={styles.ChildSection}>
                      <View style={styles.ChildTrTh}>
                        <Text style={{ ...styles.th }}>Schedule Date</Text>
                        <Text style={{ ...styles.th }}>DD Run</Text>
                        <Text style={{ ...styles.th, ...styles.RightAlign }}>
                          Schedule Amount
                        </Text>
                        <Text style={{ ...styles.th, ...styles.RightAlign }}>
                          Collection Amount
                        </Text>
                      </View>
                      {ChildElement.theScheduleList.map(
                        (ScheduleElement, k, r) => {
                          RowStyle = SetRowStyle(k, r);

                          return (
                            <View key={k} wrap={true} style={{ ...RowStyle }}>
                              <Text style={{ ...styles.td }}>
                                {moment(ScheduleElement.scheduleDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </Text>
                              <Text style={{ ...styles.td }}>
                                {ScheduleElement.ddRun}
                              </Text>

                              <Text
                                style={{ ...styles.td, ...styles.RightAlign }}
                              >
                                {ScheduleElement.scheduleAmount.toFixed(2)}
                              </Text>
                              <Text
                                style={{ ...styles.td, ...styles.RightAlign }}
                              >
                                {ScheduleElement.collectionAmount
                                  ? ScheduleElement.collectionAmount.toFixed(2)
                                  : "0.00"}
                              </Text>
                            </View>
                          );
                        }
                      )}
                    </View>
                  ) : null}
                </div>
              );
            })}
          </View>
        </View>
      ))}
    </View>
  );
};

export default TableSection;
