import React, { Component } from "react";
import { Container, Nav, Navbar, Row, Col, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FaList, FaPlusSquare, FaUserShield } from "react-icons/fa";
import { FiHelpCircle } from "react-icons/fi";
import { GiReceiveMoney, GiSecretBook } from "react-icons/gi";
import { AppContext } from "../libs/contextLib";
import withRouter from "../libs/withRouter";
import Pdf from "../downloads/UserGuide.pdf";

class Sidebar extends Component {
  static contextType = AppContext;

  render() {
    let productName = sessionStorage.getItem("productName");
    return (
      <div className="sideBar">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Navbar.Brand href="/">
                <img
                  src={require("../img/Logo-W.webp")}
                  alt="Solutions Web App"
                />
              </Navbar.Brand>
            </Col>
          </Row>
          <Row style={{ paddingTop: "24px" }}>
            <Col sm={12}>
              <h1>Direct Debit</h1>
            </Col>
          </Row>
          {this.context.isAuthenticated ? (
            <Navbar id="main-nav">
              <Nav className="flex-column">
                {this.context.SettingsList.length !== 0 &&
                (this.context.SettingsList.IsAdmin ||
                  this.context.SettingsList.CanView.CompanyList) ? (
                  <Row>
                    <Col sm={1}>
                      <FaList />
                    </Col>
                    <Col sm={10}>
                      <LinkContainer to="/CompanyList">
                        <Nav.Link>Company List</Nav.Link>
                      </LinkContainer>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col sm={1}>
                    <GiReceiveMoney />
                  </Col>
                  <Col sm={10}>
                    <NavDropdown
                      title="Direct Debit Processing"
                      id="navbarScrollingDropdown"
                    >
                      <LinkContainer to="/DDSetupFile">
                        <Nav.Link>Create DD Setup (AUDDIS) File</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/DDList">
                        <Nav.Link>Generate Run</Nav.Link>
                      </LinkContainer>

                      {productName === "Sage Intacct" ? (
                        this.context.SettingsList.CanImportARUDDFile ||
                        this.context.SettingsList.IsAdmin ? (
                          <LinkContainer to="/ARUDD">
                            <Nav.Link>Rejection File (ARUDD) Import</Nav.Link>
                          </LinkContainer>
                        ) : null
                      ) : null}
                    </NavDropdown>
                  </Col>
                </Row>
                {this.context.SettingsList.length !== 0 &&
                this.context.SettingsList.IsAdmin ? (
                  <>
                    <Row>
                      <Col sm={1}>
                        <FaUserShield />
                      </Col>
                      <Col sm={10}>
                        <NavDropdown
                          title="Site Admin"
                          id="navbarScrollingDropdown"
                        >
                          <LinkContainer to="/UserList">
                            <Nav.Link>User List</Nav.Link>
                          </LinkContainer>
                          <LinkContainer to="/IPWhiteList">
                            <Nav.Link>IP White List</Nav.Link>
                          </LinkContainer>
                          <LinkContainer to="/SiteSettings">
                            <Nav.Link>Site Settings</Nav.Link>
                          </LinkContainer>
                        </NavDropdown>
                      </Col>
                    </Row>
                  </>
                ) : null}
                <Row>
                  <Col sm={1}>
                    <FiHelpCircle />
                  </Col>
                  <Col sm={10}>
                    <Nav.Link
                      href={Pdf}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      App Help Guide
                    </Nav.Link>
                  </Col>
                </Row>
                <Row>
                  <Col sm={1}>
                    <GiSecretBook />
                  </Col>
                  <Col sm={10}>
                    <Nav.Link
                      href={
                        "https://www.solutionsforaccounting.co.uk/end-user-licence-agreements/"
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      View EULA
                    </Nav.Link>
                  </Col>
                </Row>
                {this.context.SettingsList.IsSuperAdmin ? (
                  <>
                    <h3>Admin Area</h3>
                    <Row>
                      <Col sm={1}>
                        <FaPlusSquare />
                      </Col>
                      <Col sm={10}>
                        <LinkContainer to="/NewSite">
                          <Nav.Link>New Site</Nav.Link>
                        </LinkContainer>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Nav>
              <Navbar.Toggle aria-controls="main-nav" />
            </Navbar>
          ) : null}
        </Container>
      </div>
    );
  }
}

Sidebar.contextType = AppContext;

export default withRouter(Sidebar);
