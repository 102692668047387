import React, { Component } from "react";
import { AppContext } from "../../libs/contextLib";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FaSync } from "react-icons/fa";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import apiError from "../../libs/apiError";

class SyncEntities extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.HandleSync = this.HandleSync.bind(this);
  }

  componentDidMount() {
    this.setState({
      SiteID: sessionStorage.getItem("siteID"),
      ProductName: sessionStorage.getItem("productName"),
    });
  }

  async HandleSync(e) {
    e.preventDefault();
    await trackPromise(
      Promise.all([
        axios
          .get(
            this.context.INTACCT_API_BASE_URL +
              "/api/CompanyList/?siteID=" +
              this.state.SiteID +
              "&accessToken=" +
              this.context.SageAccessToken,
            {
              crossdomain: true,
            }
          )
          .catch((e) => {
            var message = apiError("SyncCompanyList Error: ", e);
            this.props.errorList("errorList", message);
          }),
      ])
        .then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            this.props.PostFeedback("PostFeedback", [responses[0].data]);
          }
        })
        .catch((e) => {
          var message = apiError("SyncCompanyList Error:", e);
          this.props.errorList("errorList", message);
        })
    );
  }

  render() {
    if (this.context.SettingsList.IsAdmin) {
      let SyncLabel = "Entities";
      if (this.state.ProductName !== "Sage Intacct") {
        SyncLabel = "Sage Companies";
      }
      return (
        <div className="TooltipActionButton">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="ReloadInfoTooltip">Sync {SyncLabel}</Tooltip>}
          >
            <Button variant="primary" onClick={this.HandleSync}>
              <span>
                <FaSync />
              </span>
              <span>Sync {SyncLabel}</span>
            </Button>
          </OverlayTrigger>
        </div>
      );
    } else return null;
  }
}

SyncEntities.contextType = AppContext;
export default SyncEntities;
