import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaArrowCircleLeft, FaTimesCircle, FaSave } from "react-icons/fa";
import { AppContext } from "../../libs/contextLib";
import { Client } from "../../libs/SetClient";
import {
  AdminCreateUserCommand,
  AdminSetUserMFAPreferenceCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import * as yup from "yup";
import { Row, Col, Form, Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import FormInputBox from "../Form/FormInputbox";
import OutputMessage from "../OutputMessage";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";
import { handleValidation } from "../handleValidation";
import GetDatetime from "../../libs/GetDatetime";
import axios from "axios";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import { Auth } from "aws-amplify";

const schemaUsername = yup
    .string()
    .required("Username is required")
    .email("Must be a valid email address")
    .max(100, "Username must be less than 100 characters"),
  schemaFirstName = yup
    .string()
    .required("FirstName is required")
    .matches(inputRegex, "FirstName" + inputRegexMessage)
    .max(50, "FirstName must be less than 50 characters"),
  schemaLastName = yup
    .string()
    .required("LastName is required")
    .matches(inputRegex, "LastName" + inputRegexMessage)
    .max(50, "LastName must be less than 50 characters"),
  schemaPhoneNumber = yup
    .string()
    .required("PhoneNumber is required")
    .matches(
      /^(\+)[0-9]*$/,
      "PhoneNumber must be all numbers and begin with a Country Code e.g. +44"
    )
    .max(50, "PhoneNumber must be less than 50 characters");

let blankState = {
  CanAccessCompanyList: false,
  CanAddBankDetails: false,
  CanAddNewCompanies: false,
  CanCreateDDRuns: false,
  CanImportBankDetails: false,
  CanImportARUDDFile: false,
  CanDeleteDDRuns: false,
  CanRemoveDDAccounts: false,
  CanEditBankDetails: false,
  CanEditCompanySettings: false,
  CanGenerateOutputFiles: false,
  CanPostDDRuns: false,
  CanViewBankDetails: false,
  CanViewCustomerList: false,
  Username: "",
  FirstName: "",
  LastName: "",
  PhoneNumber: "",
  SagePassword: "",
  SageUserName: "",
  Password: "@Thepassword1",

  SiteID: sessionStorage.getItem("siteID"),
  SiteSettingsID: sessionStorage.getItem("SiteSettingsID"),

  errorList: [],
  authErrorList: [],

  DateTimeUpdated: "",
  DateTimeCreated: "",
};
class NewUser extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
    this.state.Username = "";
    let params = this.props.location.state;
    if (params !== undefined && params) {
      this.state.PostFeedback = params.PostFeedback;
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
  }

  componentDidMount() {
    this.setState({
      SiteID: sessionStorage.getItem("siteID"),
      SiteSettingsID: sessionStorage.getItem("SiteSettingsID"),
    });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleFormReset(e) {
    e.preventDefault();
    this.resetForm();
  }
  resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce(
      (acc, v) => ({ ...acc, [v]: undefined }),
      {}
    );
    this.setState({ ...stateReset, ...blankState }, () => {
      this.props.navigate("/UserList");
    });
  }

  handleCallback = (name, theData) => {
    this.setState((state) => {
      state[name] = theData;
      return state;
    });
  };

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "Username",
          data: this.state.Username,
          schema: schemaUsername,
        },
        {
          name: "FirstName",
          data: this.state.FirstName,
          schema: schemaFirstName,
        },
        {
          name: "LastName",
          data: this.state.LastName,
          schema: schemaLastName,
        },
        {
          name: "PhoneNumber",
          data: this.state.PhoneNumber,
          schema: schemaPhoneNumber,
        },
      ];
      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.validateForm()
      .then((response) => {
        // console.log(response);
        this.setState({ errorList: "" }, () => {
          this.CheckIfUserExits()
            .then((ID) => {
              this.PostExistingUsr(ID);
            })
            .catch(() => {
              this.signUp();
            });
        });
      })
      .catch((e) => {
        this.setState({
          validated: false,
          validationErrorList: JSON.stringify(e),
        });
      });
  }

  async CheckIfUserExits() {
    return new Promise(async (resolve, reject) => {
      await trackPromise(
        Promise.all([
          axios
            .get("/api/UserSites/CheckIfUserExits/" + this.state.Username)
            .catch((e) => {
              var message = apiError("API User Details Get: ", e);
              reject(message);
            }),
        ]).then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            resolve(responses[0].data[0].userListID);
          }
        })
      ).catch((e) => {
        var message = apiError("API User Details Get: ", e);
        reject(message);
      });
    });
  }

  async PostExistingUsr(UserListID) {
    let output = this.state;
    let DateTimeUpdated = GetDatetime();
    let DateTimeCreated = GetDatetime();

    let outputJSON = {
      UserListId: UserListID,
      SiteID: this.state.SiteID,
      SiteSettingsID: output.SiteSettingsID,
      UserName: output.Username,
      DateTimeUpdated: DateTimeUpdated,
      DateTimeCreated: DateTimeCreated,
    };

    // console.log(outputJSON);
    const postAPI = await "api/UserSites/PostExistingUsrToUserSite";

    trackPromise(
      axios
        .post(postAPI, outputJSON)
        .then((results) => {
          sessionStorage.setItem(
            "UserNameState",
            JSON.stringify(this.state.Username)
          );
          this.props.navigate("/UserDetails", {
            state: {
              NewUser: true,
            },
          });
          this.resetForm();
        })
        .catch((e) => {
          var message = apiError("PostAPI: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  async signUp() {
    let SiteName = sessionStorage.getItem("siteName");
    let client = Client();

    let NewUserParams = {
        Username: this.state.Username,
        UserPoolId: Auth._config.userPoolId,
        TemporaryPassword: this.state.Password,
        DesiredDeliveryMediums: ["EMAIL"],
        // MessageAction: "RESEND",
        UserAttributes: [
          {
            Name: "custom:sitename",
            Value: SiteName,
          },
          {
            Name: "email",
            Value: this.state.Username,
          },
          {
            Name: "email_verified",
            Value: "true",
          },
          {
            Name: "phone_number",
            Value: this.state.PhoneNumber,
          },
          {
            Name: "phone_number_verified",
            Value: "true",
          },
        ],
      },
      createUser = await trackPromise(
        client.send(new AdminCreateUserCommand(NewUserParams)).catch((e) => {
          throw new Error(e);
        })
      );

    let MFAParams = {
        Username: this.state.Username,
        UserPoolId: Auth._config.userPoolId,
        SMSMfaSettings: {
          Enabled: false,
          PreferredMfa: false,
        },
        SoftwareTokenMfaSettings: {
          Enabled: false,
          PreferredMfa: false,
        },
      },
      SetUserMFA = await trackPromise(
        client
          .send(new AdminSetUserMFAPreferenceCommand(MFAParams))
          .catch((e) => {
            throw new Error(e);
          })
      );

    await trackPromise(Promise.all([createUser, SetUserMFA]))
      .then(() => {
        this.PostNewUser();
      })
      .catch((e) => {
        this.setState((previousState) => ({
          authErrorList: [...previousState.authErrorList, e.message],
        }));
      });
  }

  async PostNewUser() {
    let output = this.state;

    let DateTimeUpdated = GetDatetime();
    let DateTimeCreated = GetDatetime();

    let outputJSON = {
      DateTimeUpdated: DateTimeUpdated,
      SiteID: this.state.SiteID,

      CanAccessCompanyList: output.CanAccessCompanyList,
      CanAddBankDetails: output.CanAddBankDetails,
      CanAddNewCompanies: output.CanAddNewCompanies,
      CanCreateDDRuns: output.CanCreateDDRuns,
      CanImportBankDetails: output.CanImportBankDetails,
      CanImportARUDDFile: output.CanImportARUDDFile,
      CanDeleteDDRuns: output.CanDeleteDDRuns,
      CanRemoveDDAccounts: output.CanRemoveDDAccounts,
      CanEditBankDetails: output.CanEditBankDetails,
      CanEditCompanySettings: output.CanEditCompanySettings,
      CanGenerateOutputFiles: output.CanGenerateOutputFiles,
      CanPostDDRuns: output.CanPostDDRuns,
      CanViewBankDetails: output.CanViewBankDetails,
      CanViewCustomerList: output.CanViewCustomerList,
      FirstName: output.FirstName,
      LastName: output.LastName,
      PhoneNumber: output.PhoneNumber,
      SagePassword: output.SagePassword,
      SageUserName: output.SageUserName,
      SiteSettingsID: output.SiteSettingsID,
      UserName: output.Username,
      DateTimeCreated: DateTimeCreated,
    };

    // console.log(outputJSON);
    const postAPI = await "api/UserSites/";

    trackPromise(
      axios
        .post(postAPI, outputJSON)
        .then((results) => {
          sessionStorage.setItem(
            "UserNameState",
            JSON.stringify(this.state.Username)
          );

          this.props.navigate("/UserDetails", {
            state: {
              NewUser: true,
            },
          });
          this.resetForm();
        })
        .catch((e) => {
          var message = apiError("PostAPI: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  renderContent() {
    return (
      <div className="UserDetails">
        <h1>Add New User</h1>
        <Row>
          <Col>
            <Link to="/UserList">
              <FaArrowCircleLeft /> Back to User List
            </Link>
          </Col>
        </Row>

        <OutputMessage
          errorList={this.state.errorList}
          PostFeedback={this.state.PostFeedback}
        />

        <div className="detailsContent">
          <Form
            id="UserDetailsForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <FormInputBox
              type="text"
              Label="Username (email address)"
              name="Username"
              placeholder={this.state.Username}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
              schema={schemaUsername}
            />

            <FormInputBox
              type="text"
              Label="First Name"
              name="FirstName"
              placeholder={this.state.FirstName}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
              schema={schemaFirstName}
            />
            <FormInputBox
              type="text"
              Label="Last Name"
              name="LastName"
              placeholder={this.state.LastName}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
              schema={schemaLastName}
            />
            <FormInputBox
              type="text"
              Label="Phone Number"
              name="PhoneNumber"
              placeholder={this.state.PhoneNumber}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
              schema={schemaPhoneNumber}
            />

            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={InputColumnAmount}>
                <p>
                  Please note that a temporary password is automatically
                  generated when you click save.
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={4} className="IconBtn">
                <Button variant="primary" type="submit">
                  <FaSave /> Save
                </Button>
                {this.state.authErrorList.length !== 0 ? (
                  <div className="invalid-tooltip">
                    {this.state.authErrorList}
                  </div>
                ) : null}
                {this.state.validationErrorList
                  ? ValidationError(this.state.validationErrorList)
                  : null}

                <Button variant="primary" onClick={this.handleFormReset}>
                  <FaTimesCircle /> Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

NewUser.contextType = AppContext;
export default withRouter(NewUser);
