import axios from "axios";
import { trackPromise } from "react-promise-tracker";

let urlElements = window.location.href.split("/");
let redirect_uri = `https://${urlElements[2]}/login`;

export function GetSageLogin(REACT_APP_SAGE_CLIENT_ID) {
  var state = window.location.pathname;
  var scopes = "openid profile email offline_access";
  let redirect = `https://id.sage.com/authorize?audience=s200ukipd/sage200&client_id=${REACT_APP_SAGE_CLIENT_ID}&response_type=code&redirect_uri=${redirect_uri}&scope=${scopes}&state=${state}`;
  window.location.href = redirect;
}

export function GetSageAccessToken(
  code,
  REACT_APP_SAGE_CLIENT_ID,
  REACT_APP_SAGE_CLIENT_SECRET
) {
  return new Promise(async (resolve, reject) => {
    let outputJSON = {
      client_id: REACT_APP_SAGE_CLIENT_ID,
      client_secret: REACT_APP_SAGE_CLIENT_SECRET,
      redirect_uri: redirect_uri,
      code: code,
      grant_type: "authorization_code",
    };

    await trackPromise(
      axios
        .post("https://id.sage.com/oauth/token", outputJSON)
        .then((results) => {
          var postResponse = results.data;
          //   console.log(postResponse);
          //   console.log(postResponse.access_token);

          return resolve(postResponse.access_token);
        })
        .catch((e) => {
          // console.log(e.response.data);
          return reject(e.response.data);
        })
    );
  });
}
