import React, { Component } from "react";
import { AppContext } from "../../libs/contextLib";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CheckIfValueReturned from "../../libs/CheckIfValueReturned";
import OverlayFormatter from "../../libs/OverlayFormatter";
import BootstrapTable from "../react-bootstrap-table2";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SetObserver } from "../../libs/Observer";
import withRouter from "../../libs/withRouter";

class CustomerListTable extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "accountReference",
          text: "Account Reference",
          sort: true,
          filter: textFilter({ placeholder: " " }),
        },
        {
          dataField: "accountName",
          text: "Account Name",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },

        {
          dataField: "isActive",
          text: "Is Active?",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          headerClasses: "center",
          classes: "center",
          formatter: (cellContent, row) => CheckIfValueReturned(row.isActive),
        },

        {
          dataField: "isOnHold",
          text: "Is On Hold?",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          headerClasses: "center",
          classes: "center",
          formatter: (cellContent, row) => CheckIfValueReturned(row.isOnHold),
        },
        {
          dataField: "hasBankDetails",
          text: "Has Bank Details?",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          headerClasses: "center",
          classes: "center",
          formatter: (cellContent, row) =>
            CheckIfValueReturned(row.hasBankDetails),
        },
      ],
    };
  }

  componentDidMount() {
    SetObserver();
  }

  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
    let CustomerListPage = 1;
    if (sessionStorage.getItem("CustomerListPage")) {
      CustomerListPage = sessionStorage.getItem("CustomerListPage");
    }
    const paginationOptions = {
      page: parseInt(CustomerListPage),
      onPageChange: (page) => {
        sessionStorage.setItem("CustomerListPage", page);
      },
      hidePageListOnlyOnePage: true,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "All",
          value: this.props.rows.length,
        },
      ],
    };

    const defaultSorted = [
      {
        dataField: "accountReference",
        order: "asc",
      },
    ];

    const rowEvents = {
      onMouseEnter: (e, row, rowIndex) => {
        return (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="AddNewTooltip" className="">
                Add New
              </Tooltip>
            }
          >
            test
          </OverlayTrigger>
        );
      },
      onClick: (e, row, rowIndex, context = this.context) => {
        let BankDetailState = {
          AccountName: row.accountName,
          AccountReference: row.accountReference,
          CustomerListID: row.customerListID,
          MustPassModulusCheck: row.mustPassModulusCheck,
        };

        if (row.hasBankDetails) {
          BankDetailState.NewBankAccount = false;
          sessionStorage.setItem(
            "BankDetails",
            JSON.stringify(BankDetailState)
          );

          this.props.navigate("/BankDetails");
        } else {
          BankDetailState.NewBankAccount = true;
          sessionStorage.setItem(
            "BankDetails",
            JSON.stringify(BankDetailState)
          );

          context.SettingsList.CanAdd.BankDetails ||
          context.SettingsList.IsAdmin
            ? this.props.navigate("/BankDetails")
            : this.props.navigate("/noaccess");
        }
      },
    };

    if (!this.props.rows) {
      return null;
    } else {
      return (
        <div className="CustomerListTable">
          <BootstrapTable
            bootstrap4
            responsive
            hover
            keyField="accountReference"
            data={this.props.rows}
            rowEvents={rowEvents}
            columns={this.state.columns}
            filter={filterFactory()}
            pagination={paginationFactory(paginationOptions)}
            defaultSorted={defaultSorted}
          />
        </div>
      );
    }
  }
}
CustomerListTable.contextType = AppContext;
export default withRouter(CustomerListTable);
