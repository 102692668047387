import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../libs/contextLib";
import { Client } from "../../libs/SetClient";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  FaArrowCircleLeft,
  FaRegEdit,
  FaTimesCircle,
  FaSave,
} from "react-icons/fa";
import { trackPromise } from "react-promise-tracker";
import * as yup from "yup";
import axios from "axios";
import apiError from "../../libs/apiError";
import FormLabelbox from "../Form/FormLabelbox";
import FormInputBox from "../Form/FormInputbox";
import FormCheck from "../Form/FormCheck";
import FormContainer from "../Form/FormContainer";
import FormPassword from "../Form/FormPassword";
import CheckConfirm from "../Form/CheckConfirm";
import withRouter from "../../libs/withRouter";
import GetDatetime from "../../libs/GetDatetime";
import IsError from "../../components/IsError";
import NewIP from "../../components/IP/NewIP";
import OutputMessage from "../../components/OutputMessage";
import { handleValidation } from "../handleValidation";
import {
  LabelColumnAmount,
  InputColumnAmount,
  DataNotChanged,
  ValidationError,
  passRegex,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";
import { Encrypt } from "../../libs/Crypto";
import { AdminUpdateUserAttributesCommand } from "@aws-sdk/client-cognito-identity-provider";
import { Auth } from "aws-amplify";

const FirstNameSchema = yup
    .string()
    .required("FirstName is required")
    .matches(inputRegex, "FirstName" + inputRegexMessage)
    .max(50, "FirstName must be less than 50 characters"),
  LastNameSchema = yup
    .string()
    .required("LastName is required")
    .matches(inputRegex, "LastName" + inputRegexMessage)
    .max(50, "LastName must be less than 50 characters"),
  schemaPhoneNumber = yup
    .string()
    .required("PhoneNumber is required")
    .nullable()
    .matches(
      /^(\+)[0-9]*$/,
      "PhoneNumber must be all numbers and begin with a Country Code e.g. +44"
    )
    .max(50, "PhoneNumber must be less than 50 characters"),
  SageUserNameSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, "Sage Username" + inputRegexMessage)
    .max(50, "Sage Username must be less than 50 characters"),
  SagePasswordSchema = yup
    .string()
    .nullable()
    .matches(passRegex, "Sage Password invalid.")
    .max(50, "Sage Password must be less than 50 characters");

let blankState = {
  notEditable: true,
  editVisible: true,
  NoData: false,
  reloadPage: false,

  CanAccessCompanyList: false,
  CanAddBankDetails: false,
  CanAddNewCompanies: false,
  CanCreateDDRuns: false,
  CanImportBankDetails: false,
  CanImportARUDDFile: false,
  CanDeleteDDRuns: false,
  CanRemoveDDAccounts: false,
  CanEditBankDetails: false,
  CanEditCompanySettings: false,
  CanGenerateOutputFiles: false,
  CanPostDDRuns: false,
  CanViewBankDetails: false,
  CanViewCustomerList: false,
  FirstName: "",
  IsActive: true,
  IsAdmin: false,
  LastName: "",
  PhoneNumber: "",
  SagePassword: "",
  SageUserName: "",

  errorList: [],

  DateTimeUpdated: "",
};

class UserDetails extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handleSetEdit = this.handleSetEdit.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.reloadPage) {
      this.props.navigate("/UserDetails", {
        state: { AddedSuccess: true },
      });
      this.resetForm();
    }
  }

  async loadData() {
    let params = this.props.location.state,
      Username = JSON.parse(sessionStorage.getItem("UserNameState")),
      SiteID = sessionStorage.getItem("siteID"),
      reloadPage = false,
      notEditable = true,
      editVisible = true,
      updateSuccess,
      AddedSuccess,
      NewUser;
    if (params !== undefined && params) {
      updateSuccess = params.updateSuccess;
      AddedSuccess = params.AddedSuccess;
      NewUser = params.NewUser;
    }

    if (NewUser) {
      notEditable = false;
      editVisible = false;
    }

    await trackPromise(
      Promise.all([
        axios
          .get("/api/UserSites/GetUserSettings/" + SiteID + "/" + Username)
          .catch((e) => {
            var message = apiError("API User Details Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          }),
      ]).then((responses) => {
        let placeholderArr;
        if (responses[0] && responses[0] !== undefined) {
          placeholderArr = responses[0].data[responses[0].data.length - 1];
        } else {
          throw new Error("Problem Fetching User Details.");
        }
        this.setState({
          PlaceholderListFilled: true,
          SiteID,
          Username,
          CanAccessCompanyList: placeholderArr.canAccessCompanyList,
          CanAddBankDetails: placeholderArr.canAddBankDetails,
          CanAddNewCompanies: placeholderArr.canAddNewCompanies,
          CanCreateDDRuns: placeholderArr.canCreateDDRuns,
          CanImportBankDetails: placeholderArr.canImportBankDetails,
          CanImportARUDDFile: placeholderArr.canImportARUDDFile,
          CanDeleteDDRuns: placeholderArr.canDeleteDDRuns,
          CanRemoveDDAccounts: placeholderArr.canRemoveDDAccounts,
          CanEditBankDetails: placeholderArr.canEditBankDetails,
          CanEditCompanySettings: placeholderArr.canEditCompanySettings,
          CanGenerateOutputFiles: placeholderArr.canGenerateOutputFiles,
          CanPostDDRuns: placeholderArr.canPostDDRuns,
          CanViewBankDetails: placeholderArr.canViewBankDetails,
          CanViewCustomerList: placeholderArr.canViewCustomerList,
          FirstName: placeholderArr.firstName,
          IsActive: placeholderArr.isActive,
          IsAdmin: placeholderArr.isAdmin,
          LastName: placeholderArr.lastName,
          PhoneNumber: placeholderArr.phoneNumber,
          SagePassword: placeholderArr.sagePassword,
          SageUserName: placeholderArr.sageUserName,
          SiteSettingsID: placeholderArr.siteSettingsID,
          UserListID: placeholderArr.userListID,
          UserSiteID: placeholderArr.userSiteID,
          UseIPWhitelist: placeholderArr.useIPWhitelist,
          reloadPage,
          notEditable,
          editVisible,
          updateSuccess,
          AddedSuccess,

          DateTimeUpdated: placeholderArr.dateTimeUpdated,
        });
      })
    ).catch((e) => {
      var message = apiError("API Get: ", e);
      this.setState((previousState) => ({
        errorList: [...previousState.errorList, message],
        NoData: true,
      }));
    });
  }

  handleFormReset(e) {
    e.preventDefault();
    this.resetForm();
  }
  async resetForm() {
    const keys = Object.keys(this.state),
      stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      this.loadData();
    });
  }

  handleSetEdit() {
    this.setState({
      notEditable: false,
      editVisible: false,
      updateSuccess: false,
      AddedSuccess: false,
      DataUpdated: false,
    });
  }

  handleCallback = (name, theData) => {
    // console.log(name, theData);
    var pattSagePassword = new RegExp("SagePassword");
    var pattAddedSuccess = new RegExp("AddedSuccess");
    this.setState((state) => {
      if (pattSagePassword.test(name)) {
        state[name] = Encrypt(theData);
      } else if (pattAddedSuccess.test(name)) {
        state["reloadPage"] = theData;
      } else {
        state[name] = theData;
      }
      state["NoUpdateRequired"] = false;
      state["DataUpdated"] = true;
      // console.log(state);
      return state;
    });
  };

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "FirstName",
          data: this.state.FirstName,
          schema: FirstNameSchema,
        },
        {
          name: "LastName",
          data: this.state.LastName,
          schema: LastNameSchema,
        },
        {
          name: "PhoneNumber",
          data: this.state.PhoneNumber,
          schema: schemaPhoneNumber,
        },
        {
          name: "SageUserName",
          data: this.state.SageUserName,
          schema: SageUserNameSchema,
        },
        {
          name: "SagePassword",
          data: this.state.SagePassword,
          schema: SagePasswordSchema,
        },
      ];
      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    if (this.state.DataUpdated || this.state.NewUser) {
      this.validateForm()
        .then((response) => {
          this.setState({ errorList: "" }, () => {
            if (this.state.NewUser) {
              let OutputNoFilterText = (
                <h3>Have you set all required Settings for your new user?</h3>
              );
              CheckConfirm(OutputNoFilterText, "500px")
                .then(() => {
                  this.doSubmit(true);
                })
                .catch(() => {});
            } else {
              this.doSubmit(true);
            }
          });
        })
        .catch((e) => {
          this.setState({
            validated: false,
            validationErrorList: JSON.stringify(e),
          });
        });
    } else {
      this.setState({ NoUpdateRequired: true });
    }
  }

  async doSubmit() {
    try {
      let UpdateParams = {
          Username: this.state.Username,
          UserPoolId: Auth._config.userPoolId,
          UserAttributes: [
            {
              Name: "phone_number",
              Value: this.state.PhoneNumber,
            },
            {
              Name: "phone_number_verified",
              Value: "true",
            },
          ],
        },
        client = Client(),
        UpdateUser = await trackPromise(
          client
            .send(new AdminUpdateUserAttributesCommand(UpdateParams))
            .catch((e) => {
              throw new Error(e);
            })
        );

      await trackPromise(Promise.all([UpdateUser])).then(async () => {
        let output = this.state,
          DateTimeUpdated = GetDatetime(),
          outputJSON = {
            DateTimeUpdated: DateTimeUpdated,
            SiteID: this.state.SiteID,
            CanAccessCompanyList: output.CanAccessCompanyList,
            CanAddBankDetails: output.CanAddBankDetails,
            CanAddNewCompanies: output.CanAddNewCompanies,
            CanCreateDDRuns: output.CanCreateDDRuns,
            CanImportBankDetails: output.CanImportBankDetails,
            CanImportARUDDFile: output.CanImportARUDDFile,
            CanDeleteDDRuns: output.CanDeleteDDRuns,
            CanRemoveDDAccounts: output.CanRemoveDDAccounts,
            CanEditBankDetails: output.CanEditBankDetails,
            CanEditCompanySettings: output.CanEditCompanySettings,
            CanGenerateOutputFiles: output.CanGenerateOutputFiles,
            CanPostDDRuns: output.CanPostDDRuns,
            CanViewBankDetails: output.CanViewBankDetails,
            CanViewCustomerList: output.CanViewCustomerList,
            FirstName: output.FirstName,
            IsActive: output.IsActive,
            IsAdmin: output.IsAdmin,
            LastName: output.LastName,
            PhoneNumber: output.PhoneNumber,
            SagePassword: output.SagePassword,
            SageUserName: output.SageUserName,
            SiteSettingsID: output.SiteSettingsID,
          };

        //   console.log("put");
        outputJSON["UserListID"] = output.UserListID;
        outputJSON["UserSiteID"] = output.UserSiteID;

        // console.log(outputJSON);
        const putAPI = "api/UserSites/";

        await trackPromise(
          axios
            .put(putAPI, outputJSON)
            .then((response) => {
              // console.log(response);

              sessionStorage.setItem(
                "UserNameState",
                JSON.stringify(this.state.Username)
              );

              if (this.state.UseIPWhitelist && this.state.NewUser) {
                this.setState({ open: true });
              } else {
                this.props.navigate("/UserDetails", {
                  state: { updateSuccess: true },
                });
                this.resetForm();
              }
            })
            .catch((e) => {
              var message = apiError("PutAPI: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            })
        );
      });
    } catch (e) {
      this.setState({ validationErrorList: e.message });
    }
  }

  renderContent() {
    const notEditable = this.state.notEditable,
      editVisible = this.state.editVisible,
      ProductName = sessionStorage.getItem("productName");

    return (
      <div className="UserDetails">
        <h1>User Details</h1>
        <Row>
          <Col>
            <Link to="/UserList">
              <FaArrowCircleLeft /> Back to User List
            </Link>
          </Col>
          {editVisible ? (
            <Col className="right TooltipActionButton">
              <Button
                variant="primary"
                type="button"
                onClick={this.handleSetEdit}
              >
                <FaRegEdit /> Edit
              </Button>
            </Col>
          ) : null}
        </Row>

        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
        />
        <div className="detailsContent">
          <Form
            id="UserDetailsForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <FormLabelbox
              type="text"
              Label="Username"
              name="Username"
              placeholder={this.state.Username}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />

            {!notEditable && this.state.Username === this.context.usr ? (
              <FormContainer
                Label="User Password"
                name="UserPassword"
                content={
                  <Link to="/ResetPassword">
                    <Button variant="primary">Reset Password</Button>
                  </Link>
                }
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
              />
            ) : null}

            <FormInputBox
              type="text"
              Label="First Name"
              name="FirstName"
              placeholder={this.state.FirstName}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
              schema={FirstNameSchema}
            />
            <FormInputBox
              type="text"
              Label="Last Name"
              name="LastName"
              placeholder={this.state.LastName}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
              schema={LastNameSchema}
            />
            <FormInputBox
              type="text"
              Label="Phone Number"
              name="PhoneNumber"
              placeholder={this.state.PhoneNumber}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
              schema={schemaPhoneNumber}
            />
            {ProductName !== "Sage Intacct" ? (
              <>
                <FormInputBox
                  type="text"
                  Label="Sage Username"
                  name="SageUserName"
                  placeholder={this.state.SageUserName}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                  schema={SageUserNameSchema}
                />
                {!notEditable ? (
                  <FormPassword
                    Label="Sage Password"
                    name="SagePassword"
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    disabled={notEditable}
                    errorList={this.state.errorList}
                    schema={SagePasswordSchema}
                  />
                ) : null}
              </>
            ) : null}

            <FormCheck
              type="switch"
              Label="Is Active"
              name="IsActive"
              id="IsActive"
              initialState={this.state.IsActive}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
            />
            <FormCheck
              type="switch"
              Label="Is Admin"
              name="IsAdmin"
              id="IsAdmin"
              initialState={this.state.IsAdmin}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
              useWarningMessage={false}
              messageMaxWidth="600px"
              alertMessage={
                <>
                  <h1>Setting this will grant the user Admin privilege.</h1>
                  <h4>
                    This will give them the ability to Add, Edit, View and in
                    some areas, Delete.
                  </h4>
                  <p>Are you sure you wish enable this setting?</p>
                </>
              }
            />

            {this.state.IsAdmin ? null : (
              <>
                <FormCheck
                  type="switch"
                  Label="Can Access Company List"
                  name="CanAccessCompanyList"
                  id="CanAccessCompanyList"
                  initialState={this.state.CanAccessCompanyList}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />
                <FormCheck
                  type="switch"
                  Label="Can Add New Companies"
                  name="CanAddNewCompanies"
                  id="CanAddNewCompanies"
                  initialState={this.state.CanAddNewCompanies}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />
                <FormCheck
                  type="switch"
                  Label="Can Edit Company Settings"
                  name="CanEditCompanySettings"
                  id="CanEditCompanySettings"
                  initialState={this.state.CanEditCompanySettings}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />
                <FormCheck
                  type="switch"
                  Label="Can View Customer List"
                  name="CanViewCustomerList"
                  id="CanViewCustomerList"
                  initialState={this.state.CanViewCustomerList}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />
                <FormCheck
                  type="switch"
                  Label="Can Add Bank Details"
                  name="CanAddBankDetails"
                  id="CanAddBankDetails"
                  initialState={this.state.CanAddBankDetails}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />
                <FormCheck
                  type="switch"
                  Label="Can View Bank Details"
                  name="CanViewBankDetails"
                  id="CanViewBankDetails"
                  initialState={this.state.CanViewBankDetails}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />
                <FormCheck
                  type="switch"
                  Label="Can Edit Bank Details"
                  name="CanEditBankDetails"
                  id="CanEditBankDetails"
                  initialState={this.state.CanEditBankDetails}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />

                <FormCheck
                  type="switch"
                  Label="Can Import Bank Details"
                  name="CanImportBankDetails"
                  id="CanImportBankDetails"
                  initialState={this.state.CanImportBankDetails}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />

                {ProductName === "Sage Intacct" ? (
                  <>
                    <FormCheck
                      type="switch"
                      Label="Can Import ARUDD Files"
                      name="CanImportARUDDFile"
                      id="CanImportARUDDFile"
                      initialState={this.state.CanImportARUDDFile}
                      value={this.handleCallback}
                      LabelColumnAmount={LabelColumnAmount}
                      InputColumnAmount={InputColumnAmount}
                      disabled={notEditable}
                      errorList={this.state.errorList}
                    />
                  </>
                ) : null}

                <FormCheck
                  type="switch"
                  Label="Can Create Direct Debit Runs"
                  name="CanCreateDDRuns"
                  id="CanCreateDDRuns"
                  initialState={this.state.CanCreateDDRuns}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />

                <FormCheck
                  type="switch"
                  Label="Can Post Direct Debit Runs"
                  name="CanPostDDRuns"
                  id="CanPostDDRuns"
                  initialState={this.state.CanPostDDRuns}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />

                <FormCheck
                  type="switch"
                  Label="Can Delete Direct Debit Runs"
                  name="CanDeleteDDRuns"
                  id="CanDeleteDDRuns"
                  initialState={this.state.CanDeleteDDRuns}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />
                <FormCheck
                  type="switch"
                  Label="Can Remove Direct Debit Accounts"
                  name="CanRemoveDDAccounts"
                  id="CanRemoveDDAccounts"
                  initialState={this.state.CanRemoveDDAccounts}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />

                <FormCheck
                  type="switch"
                  Label="Can Generate Output Files"
                  name="CanGenerateOutputFiles"
                  id="CanGenerateOutputFiles"
                  initialState={this.state.CanGenerateOutputFiles}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                />
                {this.state.open ? (
                  <NewIP
                    errorList={this.handleCallback}
                    AddedSuccess={this.handleCallback}
                    FromNewUser={true}
                    open={this.state.open}
                    setOpen={this.handleCallback}
                  />
                ) : null}
              </>
            )}

            {!notEditable ? (
              <>
                <div className="row BlankMargin"></div>
                <div className="FormSave">
                  <Row>
                    <Col sm={LabelColumnAmount}></Col>
                    <Col sm={8} className="IconBtn">
                      <Button variant="primary" type="submit">
                        <FaSave /> Save
                      </Button>
                      {this.state.NoUpdateRequired ? DataNotChanged : null}
                      {this.state.validationErrorList
                        ? ValidationError(this.state.validationErrorList)
                        : null}

                      <Button variant="primary" onClick={this.handleFormReset}>
                        <FaTimesCircle /> Cancel
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            ) : null}
          </Form>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.NoData) {
      return <IsError errorList={this.state.errorList} />;
    } else {
      if (!this.state.PlaceholderListFilled) {
        return null;
      } else {
        return this.renderContent();
      }
    }
  }
}

UserDetails.contextType = AppContext;
export default withRouter(UserDetails);
