import React, { Component } from "react";
import axios from "axios";
import { AppContext } from "../libs/contextLib";
import apiError from "../libs/apiError";
import DDListTable from "../components/DDList/DDListTable";
import { trackPromise } from "react-promise-tracker";
import OutputMessage from "../components/OutputMessage";
import { SetObserver } from "../libs/Observer";

class DDList extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = { errorList: [] };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    SetObserver();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  async loadData() {
    let theSiteID = sessionStorage.getItem("siteID");
    this.setState({
      siteID: theSiteID,
    });
    if (theSiteID) {
      await trackPromise(
        Promise.all([
          axios.get("api/DdlistHeaders/GetDdlistHeader/" + theSiteID),
          axios.get("api/SysddlistStatus/"),
        ])
          .then((responses) => {
            if (!responses[0] && responses[0] === undefined) {
              throw new Error("No Active DD Runs Found");
            }
            if (!responses[1] && responses[1] === undefined) {
              throw new Error("No Active DDListStatuses Found");
            }
            let List = responses[0].data;
            let DDListStatuses = responses[1].data;
            let a = {};
            //in order to allow the filtering and sorting of the DD headers, this array has to be set out as this (i.e. [content] = content) -
            DDListStatuses.forEach((element) => {
              a[element.content] = element.content;
            });
            DDListStatuses = a;
            this.setState({
              List,
              DDListStatuses,
            });
          })
          .catch((e) => {
            var message = apiError("DDList: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          })
      );
    }
  }

  render() {
    return (
      <div className="DDList">
        <h1>Direct Debit List</h1>
        {
          <OutputMessage
            PostFeedback={this.state.PostFeedback}
            errorList={this.state.errorList}
          />
        }
        {
          <DDListTable
            rows={this.state.List}
            apiReturn={this.state.errorList}
            DDListStatuses={this.state.DDListStatuses}
          />
        }
      </div>
    );
  }
}

DDList.contextType = AppContext;
export default DDList;
