import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "react-bootstrap";
import CheckConfirm from "../Form/CheckConfirm";

export default function TranslatedBaseAmountError(
  TranslatedBaseAmount,
  CollectionTotal,
  BaseCurrencyName,
  CurrencySymbol,
  IsCheckTranslatedBaseAmountNeeded
) {
  let Name = `'Total Collection (${CurrencySymbol})'`;
  let CurrencyName = BaseCurrencyName;
  if (BaseCurrencyName.slice(-1) === "s") {
    CurrencyName = BaseCurrencyName.substring(0, BaseCurrencyName.length - 1);
  }
  return new Promise((resolve, reject) => {
    if (IsCheckTranslatedBaseAmountNeeded) {
      resolve(true);
    } else {
      if (TranslatedBaseAmount > 0.0) {
        var OutputMessage = (
          <>
            <h3>Have you confirmed the value entered in {Name}?</h3>
            <p>
              The Exchange Rate may have changed since the this Direct Debit Run
              was committed.
            </p>
          </>
        );
        CheckConfirm(OutputMessage, "600px")
          .then(async () => {
            resolve(true);
          })
          .catch(() => {});
      } else {
        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          customUI: ({ onClose }) => {
            return (
              <div className="ConfirmAlert">
                <div className="AlertBody" style={{ maxWidth: "600px" }}>
                  <h1>{Name} Cannot be Zero</h1>
                  <p>
                    Please update {Name} to be the {CurrencyName} conversion of{" "}
                    {CollectionTotal}.
                  </p>
                  <div
                    className="ButtonGroup"
                    style={{ display: "block", textAlign: "center" }}
                  >
                    <Button
                      onClick={() => {
                        reject(true);
                        onClose();
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </div>
            );
          },
        });
      }
    }
  });
}
