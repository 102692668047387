import React, { Component } from "react";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { AppContext } from "../../libs/contextLib";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import BootstrapTable from "../react-bootstrap-table2";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SetObserver } from "../../libs/Observer";
import OverlayFormatter from "../../libs/OverlayFormatter";
import withRouter from "../../libs/withRouter";
import apiError from "../../libs/apiError";
import CheckIfValueReturned from "../../libs/CheckIfValueReturned";
import OutputMessage from "../OutputMessage";
import TooltipActionButtonPlacedAbove from "../TooltipActionButtonPlacedAbove";
import NoTextFormatter from "../NoTextFormatter";

class ARUDDResults extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = { errorList: [] };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidMount() {
    SetObserver();
    this.loadData();
  }

  async loadData() {
    let SiteID = sessionStorage.getItem("siteID"),
      updateSuccess,
      AddedSuccess,
      PostFeedback;

    if (this.props.location !== undefined) {
      let params = this.props.location.state;
      updateSuccess = params.updateSuccess;
      AddedSuccess = params.AddedSuccess;
      PostFeedback = params.PostFeedback;
    }

    await trackPromise(
      Promise.all([
        axios
          .get("/api/DdrejectionHeaders/GetDdrejectionResults/" + SiteID)
          .catch((e) => {
            var message = apiError("API GetDdrejectionResults: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          }),
      ])
        .then((responses) => {
          if (responses[0]) {
            this.setState({
              RowData: responses[0].data,
              updateSuccess,
              AddedSuccess,
              PostFeedback,
            });
          }
        })
        .catch((e) => {
          var message = apiError("API Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  renderContent() {
    let columns = [
      {
        dataField: "fileName",
        text: "Rejection Run Name",
        sort: true,
        filter: textFilter({ placeholder: " " }),
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },

      {
        dataField: "rejectionDate",
        text: "Rejection Date",
        sort: true,
        filter: textFilter({ placeholder: " " }),
      },

      {
        dataField: "rejectionTotal",
        text: "Value",
        sort: true,
        headerClasses: "ButtonCol",
        headerFormatter: NoTextFormatter,
        formatter: (cellContent, row) => {
          return (
            <div className="CollectionTotal">
              <div>
                {row.currency}
                {parseFloat(cellContent).toFixed(2)}
              </div>
            </div>
          );
        },
      },
      {
        dataField: "isPosted",
        text: "Is Posted",
        sort: true,
        headerClasses: "ButtonCol center",
        headerFormatter: NoTextFormatter,
        classes: "center",
        formatter: (cellContent, row) => CheckIfValueReturned(row.isPosted),
      },
    ];
    let DetailColumns = [
      {
        dataField: "reference",
        text: "Reference",
        sort: true,
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
      {
        dataField: "acRef",
        text: "A/C Ref",
        sort: true,
      },
      {
        dataField: "acName",
        text: "A/C Name",
        sort: true,
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
      {
        dataField: "rejectionReasonName",
        text: "Return Reason",
        sort: true,
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
      {
        dataField: "originalProcessingDate",
        text: "Date",
        sort: true,
      },
      {
        dataField: "originalValue",
        text: "Value",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <div className="CollectionTotal">
              <div>
                {row.currency}
                {parseFloat(cellContent).toFixed(2)}
              </div>
            </div>
          );
        },
      },
      {
        dataField: "isPosted",
        text: "Is Posted",
        sort: true,
        headerClasses: "ButtonCol center",
        classes: "center",
        formatter: (cellContent, row) => CheckIfValueReturned(row.isPosted),
      },
      {
        dataField: "message",
        text: "Message",
        sort: true,
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
    ];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
    let ARUDDResultsListPage = 1;
    if (sessionStorage.getItem("ARUDDResultsListPage")) {
      ARUDDResultsListPage = sessionStorage.getItem("ARUDDResultsListPage");
    }
    const paginationOptions = {
      page: parseInt(ARUDDResultsListPage),
      onPageChange: (page) => {
        sessionStorage.setItem("ARUDDResultsListPage", page);
      },
      hidePageListOnlyOnePage: true,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "All",
          value: this.state.RowData.length,
        },
      ],
    };

    const expandRow = {
      renderer: (row) => (
        <BootstrapTable
          bootstrap4
          responsive
          hover
          keyField="ddRejectionLinesID"
          data={row.listItems}
          columns={DetailColumns}
          filter={filterFactory()}
          // pagination={paginationFactory(paginationOptions)}
        />
      ),
      onlyOneExpanding: true,
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: () => {
        return null;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="CommitInfoTooltip" className="">
                  Toggle Expand Row
                </Tooltip>
              }
            >
              <Button className="ButtonRemove">
                <FaChevronDown />
              </Button>
            </OverlayTrigger>
          );
        }
        return (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="CommitInfoTooltip" className="">
                Toggle Expand Row
              </Tooltip>
            }
          >
            <Button className="ButtonRemove">
              <FaChevronRight />
            </Button>
          </OverlayTrigger>
        );
      },
      expandColumnPosition: "left",
      parentClassName: "parentExpand",
    };

    const defaultSorted = [
      {
        dataField: "Status",
        order: "desc",
      },
    ];

    return (
      <>
        <div className="ARUDDTable">
          <BootstrapTable
            bootstrap4
            responsive
            hover
            keyField="ddRejectionHeaderID"
            data={this.state.RowData}
            // rowEvents={rowEvents}
            expandRow={expandRow}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory(paginationOptions)}
            defaultSorted={defaultSorted}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        <div className="ARUDD">
          <h1>Rejection File (ARUDD) Import</h1>
          <OutputMessage
            errorList={this.state.errorList}
            updateSuccess={this.state.updateSuccess}
            AddedSuccess={this.state.AddedSuccess}
            PostFeedback={this.state.PostFeedback}
          />
          <TooltipActionButtonPlacedAbove
            LinkTo={{
              pathname: `/ARUDD`,
            }}
            ToolTipLabel="Add New ARUDD Submission"
            Label="Add New ARUDD Submission"
          />
          {this.state.RowData ? this.renderContent() : null}
        </div>
      </>
    );
  }
}

ARUDDResults.contextType = AppContext;
export default withRouter(ARUDDResults);
