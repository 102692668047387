import React, { Component } from "react";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Container } from "react-bootstrap";
import RouteList from "./RouteList";
import { AppContext } from "./libs/contextLib";
import { Client } from "./libs/SetClient";
import { AdminGetUserCommand } from "@aws-sdk/client-cognito-identity-provider";
import { Auth } from "aws-amplify";
import Header from "./components/Header";
import SideBar from "./components/Sidebar";
import Footer from "./components/Footer";
import apiError from "./libs/apiError";
import { SetObserver } from "./libs/Observer";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import SetSettingsList from "./components/SetSettingsList";
import OutputMessage from "./components/OutputMessage";

export default class App extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = { errorList: [], SiteID: sessionStorage.getItem("siteID") };
    this.loadData();
  }

  componentDidUpdate() {
    SetObserver();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  async loadData() {
    await trackPromise(
      Promise.all([
        axios.get("/api/Environment/").catch((e) => {
          var message = apiError("API Get Environment: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        }),
      ]).then(async (responses) => {
        let env = responses[0].data,
          Environment =
            env.Environment !== "unknown" ? env.Environment : "development",
          INTACCT_API_BASE_URL =
            env.REACT_APP_INTACCT_API_BASE_URL !== "unknown"
              ? env.REACT_APP_INTACCT_API_BASE_URL
              : process.env.REACT_APP_INTACCT_API_BASE_URL,
          USER_POOL_ID =
            env.REACT_APP_USER_POOL_ID !== "unknown"
              ? env.REACT_APP_USER_POOL_ID
              : process.env.REACT_APP_USER_POOL_ID,
          IDENTITY_POOL_ID =
            env.REACT_APP_IDENTITY_POOL_ID !== "unknown"
              ? env.REACT_APP_IDENTITY_POOL_ID
              : process.env.REACT_APP_IDENTITY_POOL_ID,
          CLIENT_ID =
            env.REACT_APP_CLIENT_ID !== "unknown"
              ? env.REACT_APP_CLIENT_ID
              : process.env.REACT_APP_CLIENT_ID,
          SAGE_CLIENT_ID =
            env.REACT_APP_SAGE_CLIENT_ID !== "unknown"
              ? env.REACT_APP_SAGE_CLIENT_ID
              : process.env.REACT_APP_SAGE_CLIENT_ID,
          SAGE_CLIENT_SECRET =
            env.REACT_APP_SAGE_CLIENT_SECRET !== "unknown"
              ? env.REACT_APP_SAGE_CLIENT_SECRET
              : process.env.REACT_APP_SAGE_CLIENT_SECRET;

        if (env) {
          Auth.configure({
            mandatorySignIn: true,
            region: process.env.REACT_APP_REGION,
            userPoolId: USER_POOL_ID,
            identityPoolId: IDENTITY_POOL_ID,
            userPoolWebClientId: CLIENT_ID,
          });
          this.context.setEnvironment(Environment);
          this.context.setINTACCT_API_BASE_URL(INTACCT_API_BASE_URL);
          this.context.setSAGE_CLIENT_ID(SAGE_CLIENT_ID);
          this.context.setSAGE_CLIENT_SECRET(SAGE_CLIENT_SECRET);

          await Auth.currentSession()
            .then(async (u) => {
              if (this.state.SiteID) {
                if (u.accessToken.jwtToken) {
                  const command = new AdminGetUserCommand({
                    UserPoolId: Auth._config.userPoolId,
                    Username: u.idToken.payload.email,
                  });
                  let client = Client();
                  await client
                    .send(command)
                    .then((a) => {
                      if (a.UserMFASettingList) {
                        this.GetUserSettings(u);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              }
            })
            .catch((e) => {
              if (e !== "No current user") {
                console.log(e);
              }
            });
        }
      })
    );
  }

  async GetUserSettings(u) {
    let usr = u.idToken.payload.email;

    await trackPromise(
      Promise.all([
        axios
          .get(
            "/api/UserSites/GetUserSettings/" + this.state.SiteID + "/" + usr
          )
          .catch((e) => {
            apiError("API Get: ", e);
          }),
      ])
        .then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            var placeholderArr =
              responses[0].data[responses[0].data.length - 1];
            let SettingsList = SetSettingsList(placeholderArr);
            this.context.setIsAuthenticated(true);
            this.context.setUsr(usr);
            this.context.IPWhitelistSetting = placeholderArr.useIPWhitelist;
            this.context.setSettingsList(SettingsList);
          }
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  render() {
    return (
      <div className="backgroundContainer">
        <Header />
        <SideBar />
        <main className="contentContainer">
          <Container className="content">
            {this.state.errorList ? (
              <OutputMessage errorList={this.state.errorList} />
            ) : null}
            <RouteList />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
App.contextType = AppContext;
