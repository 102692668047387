import React, { Component } from "react";
import { AppContext } from "../../libs/contextLib";
import * as yup from "yup";
import { Row, Col, Form, Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { FaTimesCircle, FaSave } from "react-icons/fa";
import FormInputBox from "../Form/FormInputbox";
import FormDropdown from "../Form/FormDropdown";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
} from "../../libs/Variables";
import GetDatetime from "../../libs/GetDatetime";
import axios from "axios";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import OutputMessage from "../OutputMessage";
import { handleValidation } from "../handleValidation";
import { Encrypt } from "../../libs/Crypto";
import { v4 as uuidv4 } from "uuid";

const SiteNameSchema = yup
    .string()
    .required("SiteName is required")
    .max(100, "SiteName must be less than 100 characters"),
  CompanyIDSchema = yup
    .string()
    .required("Company ID is required")
    .max(100, "Company ID must be less than 100 characters"),
  UserIDSchema = yup
    .string()
    .required("User ID is required")
    .max(250, "User ID must be less than 250 characters"),
  UserPasswordSchema = yup
    .string()
    .required("User Password is required")
    .max(70, "User Password must be less than 70 characters");

let blankState = {
  PlaceholderListFilled: false,
  CompanyID: "",
  NewSiteID: uuidv4(),
  IsActive: true,
  errorList: [],

  DateTimeUpdated: "",
  DateTimeCreated: "",
};

class NewSite extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
    this.state.Username = "";
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    let params = this.props.location.state,
      PostFeedback;
    if (params !== undefined && params) {
      PostFeedback = params.PostFeedback;
    }

    await trackPromise(
      Promise.all([
        axios.get("/api/SysproductLists/").catch((e) => {
          var message = apiError("API Syscompanies Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        }),
      ])
        .then((responses) => {
          this.setState(
            {
              ProductList: responses[0].data,
              ProductName: responses[0].data[3].id,
              ProductID: responses[0].data[3].id,
              PlaceholderListFilled: true,
              PostFeedback,
            },
            () => {
              // console.log(this.state);
            }
          );
        })
        .catch((e) => {
          var message = apiError("API New DDRun Get: ", e);
          this.setState((previousState) => ({
            NoData: true,
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  handleFormReset(e) {
    e.preventDefault();
    this.resetForm();
  }
  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      this.loadData();
    });
  }

  handleCallback = (name, theData) => {
    this.setState((state) => {
      var pattUserPassword = new RegExp("UserPassword");
      if (pattUserPassword.test(name)) {
        state[name] = Encrypt(theData, state.NewSiteID);
      } else {
        state[name] = theData;
      }
      return state;
    });
  };

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items;
      if (this.state.ProductName === 3) {
        items = [
          {
            name: "SiteName",
            data: this.state.SiteName,
            schema: SiteNameSchema,
          },
          {
            name: "CompanyID",
            data: this.state.CompanyID,
            schema: CompanyIDSchema,
          },
          {
            name: "UserID",
            data: this.state.UserID,
            schema: UserIDSchema,
          },
          {
            name: "UserPassword",
            data: this.state.UserPassword,
            schema: UserPasswordSchema,
          },
        ];
      } else {
        items = [
          {
            name: "SiteName",
            data: this.state.SiteName,
            schema: SiteNameSchema,
          },
        ];
      }

      var Status = [];
      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.validateForm()
      .then((response) => {
        // console.log(response);
        this.setState({ errorList: "" }, () => {
          this.doSubmit();
        });
      })
      .catch((e) => {
        this.setState({
          validated: false,
          validationErrorList: JSON.stringify(e),
        });
      });
  }

  async doSubmit() {
    let output = this.state;

    let Username = this.context.usr;

    let DateTimeUpdated = GetDatetime();
    let DateTimeCreated = GetDatetime();
    let outputJSON = {
      SysproductListId: output.ProductName,
      SiteID: output.NewSiteID,
      IsActive: output.IsActive,
      SiteName: output.SiteName,
      IntacctCompanyID: output.CompanyID,
      UserID: output.UserID,
      UserPassword: output.UserPassword,
      DateTimeUpdated: DateTimeUpdated,
      DateTimeCreated: DateTimeCreated,
    };

    // console.log(outputJSON);
    await trackPromise(
      axios
        .post("api/SiteSettings/" + Username, outputJSON)
        .then(async (SiteSettingsResults) => {
          await trackPromise(
            Promise.all([
              axios
                .get(
                  this.context.INTACCT_API_BASE_URL +
                    "/api/CompanyList/?siteID=" +
                    SiteSettingsResults.data.siteId +
                    "&accessToken=" +
                    this.context.SageAccessToken,
                  {
                    crossdomain: true,
                  }
                )
                .catch((e) => {
                  var message = apiError("SyncCompanyList Error: ", e);
                  this.setState((previousState) => ({
                    errorList: [...previousState.errorList, message],
                  }));
                }),
            ]).then((responses) => {
              if (responses[0]) {
                this.props.navigate("/NewSite", {
                  state: {
                    PostFeedback: ["Site has been successfully setup."],
                  },
                });
                this.resetForm();
              }
            })
          ).catch((e) => {
            var message = apiError("API Sync Error:", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          });
        })
        .catch((e) => {
          // console.log(e);
          var message = apiError("PostAPI: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  productSwitch() {
    switch (this.state.ProductName) {
      case "1":
        return this.renderSage50();
      case "2":
        return this.renderSage200();
      case "3":
        return this.renderIntacct();
      case "4":
        return this.renderSageStandard();
      default:
        return this.renderIntacct();
    }
  }

  renderSage50() {
    return (
      <>
        <h2>Sage 50 Credentials</h2>
        {this.renderNewSiteRequest()}
      </>
    );
  }

  renderSage200() {
    return (
      <>
        <h2>Sage 200 Credentials</h2>
        {this.renderNewSiteRequest()}
      </>
    );
  }

  renderSageStandard() {
    return (
      <>
        <h2>Sage Standard Credentials</h2>
        {this.renderNewSiteRequest()}
      </>
    );
  }

  renderIntacct() {
    const notEditable = this.state.notEditable;
    return (
      <>
        <h2>Intacct Credentials</h2>

        <div className="detailsContent">
          <FormInputBox
            type="text"
            Label="Company ID"
            name="CompanyID"
            placeholder={this.state.CompanyID}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            disabled={notEditable}
            errorList={this.state.errorList}
            schema={CompanyIDSchema}
          />

          <FormInputBox
            type="text"
            Label="User ID"
            name="UserID"
            placeholder={this.state.UserID}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            disabled={notEditable}
            errorList={this.state.errorList}
            schema={UserIDSchema}
            autoComplete="new-user"
          />

          <FormInputBox
            type="password"
            Label="User Password"
            name="UserPassword"
            placeholder={this.state.UserPassword}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            disabled={notEditable}
            errorList={this.state.errorList}
            schema={UserPasswordSchema}
            autoComplete="new-password"
          />
        </div>
      </>
    );
  }
  renderNewSiteRequest() {
    return (
      <>
        <p>
          Reminder: you need to request that the client must visit{" "}
          <a
            href={`https://${window.location.host}/NewSiteRequest`}
            target="_blank"
            rel="noreferrer"
          >
            NewSiteRequest
          </a>
          , in order to generate an Access Token with which to obtain the Sage
          SiteID.
        </p>
        <FormInputBox
          type="text"
          Label="Sage SiteID"
          name="CompanyID"
          placeholder={this.state.CompanyID}
          value={this.handleCallback}
          LabelColumnAmount={LabelColumnAmount}
          InputColumnAmount={InputColumnAmount}
          errorList={this.state.errorList}
          schema={CompanyIDSchema}
        />
      </>
    );
  }

  renderContent() {
    return (
      <div className="NewSite">
        <h1>New Site</h1>

        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
          PostFeedback={this.state.PostFeedback}
        />

        <div className="detailsContent">
          <Form
            id="NewSiteForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <FormInputBox
              type="text"
              Label="Site Name"
              name="SiteName"
              placeholder={this.state.SiteName}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
              schema={SiteNameSchema}
            />
            <FormDropdown
              id="ProductName"
              Label="Sage Application"
              name="ProductName"
              placeholder={this.state.ProductName}
              itemContent={this.state.ProductList}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
            />
            {this.productSwitch()}

            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={4} className="IconBtn">
                <Button variant="primary" type="submit">
                  <FaSave /> Save
                </Button>
                {this.state.authErrorList ? (
                  <div className="invalid-tooltip">
                    {this.state.authErrorList}
                  </div>
                ) : null}
                {this.state.validationErrorList
                  ? ValidationError(this.state.validationErrorList)
                  : null}

                <Button variant="primary" onClick={this.handleFormReset}>
                  <FaTimesCircle /> Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.PlaceholderListFilled) {
      return null;
    } else {
      return this.renderContent();
    }
  }
}

NewSite.contextType = AppContext;
export default withRouter(NewSite);
