import React, { Component } from "react";
import { AppContext } from "../libs/contextLib";
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
import apiError from "../libs/apiError";
import FormCheck from "../components/Form/FormCheck";
import FormDropdown from "../components/Form/FormDropdown";
import { saveAs } from "file-saver";
import { FaFileDownload, FaSave } from "react-icons/fa";
import { SetObserver } from "../libs/Observer";
import withRouter from "../libs/withRouter";
import { trackPromise } from "react-promise-tracker";
import DDSetupTable from "../components/DDSetup/DDSetupTable";
import IsError from "../components/IsError";
import OutputMessage from "../components/OutputMessage";
import { LabelColumnAmount, InputColumnAmount } from "../libs/Variables";

let blankState = {
  NoData: false,

  "0N": true,
  errorList: [],
};

class DDSetupFile extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
    this.handleContinue = this.handleContinue.bind(this);
    this.handleBankFile = this.handleBankFile.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate() {
    SetObserver();
  }

  async loadData() {
    let SiteID, updateSuccess, AddedSuccess, PostFeedback;

    SiteID = sessionStorage.getItem("siteID");
    let params = this.props.location.state;
    if (params !== undefined && params) {
      updateSuccess = params.updateSuccess;
      AddedSuccess = params.AddedSuccess;
      PostFeedback = params.PostFeedback;
    }
    await trackPromise(
      Promise.all([
        axios.get("/api/Syscompanies/GetSyscompanies/" + SiteID).catch((e) => {
          var message = apiError("API GetSyscompanies Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
        axios.get("/api/SysmandateStatusLists/").catch((e) => {
          var message = apiError("API SysmandateStatusLists Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
      ])
        .then((responses) => {
          if (responses[0] && responses[1]) {
            this.setState({
              SiteID,
              updateSuccess,
              AddedSuccess,
              PostFeedback,
              PlaceholderListFilled: true,
              CompanyList: responses[0].data,
              Company: responses[0].data[0].content,
              CompanyID: responses[0].data[0].id,
              MandateStatusLists: responses[1].data,
              BankFormat: responses[0].data[0].sysbankFormatId,
              FileFormatExtension: responses[0].data[0].fileFormatExtension,
            });
          } else {
            throw new Error("No companies or Mandate Status List found");
          }
        })
        .catch((e) => {
          var message = apiError("API Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        })
    );
  }

  handleCallback = (name, theData) => {
    // console.log(name);
    // console.log(theData);
    const theState = this.state;
    this.setState(
      (state) => {
        var pattCompanyID = new RegExp("CompanyID");

        if (pattCompanyID.test(name)) {
          let a = {};
          theState.CompanyList.forEach((element) => {
            a[element.id] = [
              element.content,
              element.sysbankFormatId,
              element.fileFormatExtension,
            ];
          });
          let SetCompany = a[theData][0],
            SetBankFormat = a[theData][1],
            SetFileFormatExtension = a[theData][2];
          state.Company = SetCompany;
          state.BankFormat = SetBankFormat;
          state.FileFormatExtension = SetFileFormatExtension;
        }
        state["NoUpdateRequired"] = false;
        state["DataUpdated"] = true;

        state[name] = theData;
        return state;
      },
      () => {
        // console.log(this.state);
      }
    );
  };

  async handleContinue(e) {
    e.preventDefault();
    this.setState({
      continueClicked: false,
      updateSuccess: "",
      errorList: [],
    });
    let Setup = this.state["0N"];
    let ConvertDDI = this.state["0S"];
    let Cancellation = this.state["0C"];
    var validated = false;
    var validationErrorList = "";
    let MandatesToGet = {
      Setup: 0,
      ConvertDDI: 0,
      Cancellation: 0,
    };
    if (Setup) {
      MandatesToGet["Setup"] = 1;
    }
    if (ConvertDDI) {
      MandatesToGet["ConvertDDI"] = 4;
    }
    if (Cancellation) {
      MandatesToGet["Cancellation"] = 8;
    }
    if (!Setup && !ConvertDDI && !Cancellation) {
      validationErrorList = "Please select at least one Mandate Status.";
    } else {
      validated = true;
    }
    this.setState({
      validationErrorList: validationErrorList,
    });
    if (validated) {
      await trackPromise(
        Promise.all([
          axios.get(
            "/api/CustomerBankDetails/GetDDFileSetupData/" +
              "?SiteID=" +
              this.state.SiteID +
              "&CompanyID=" +
              this.state.CompanyID +
              "&Setup=" +
              MandatesToGet.Setup +
              "&ConvertDDI=" +
              MandatesToGet.ConvertDDI +
              "&Cancellation=" +
              MandatesToGet.Cancellation
          ),
          axios
            .get(
              "/api/SyscompanySettings/BankDetailsNeeded/" +
                this.state.SiteID +
                "/" +
                this.state.CompanyID
            )
            .catch((e) => {
              var message = apiError("API BankDetailsNeeded Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
                NoData: true,
              }));
            }),
        ])
          .then((responses) => {
            if (!responses[1] && responses[1] === undefined) {
              throw new Error("Problem checking if Bank Checking needed.");
            }
            if (responses[0]) {
              let MandateResults = responses[0].data;

              this.setState({
                BankDetailsNeeded: responses[1].data,
                MandatesToGet,
                MandateResults: MandateResults,
                continueClicked: true,
                errorList: [],
              });
            } else {
              throw new Error(
                "No DDFile Setup Data found for this configuration."
              );
            }
          })
          .catch((e) => {
            var message = apiError("API Get DDFile Setup Data Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          })
      );
    }
  }

  async handleBankFile(e) {
    e.preventDefault();

    if (this.state.MandateResults) {
      let bankFileSetup = {
        SiteID: this.state.SiteID,
        CompanyID: this.state.CompanyID,
        HeaderID: 0,
        IsAUDDIS: true,
        SageAccessToken: this.context.SageAccessToken,
        Setup: this.state.MandatesToGet.Setup,
        ConvertDDI: this.state.MandatesToGet.ConvertDDI,
        Cancellation: this.state.MandatesToGet.Cancellation,
      };

      await trackPromise(
        Promise.all([
          axios
            .post("api/DdlistHeaders/GenerateBankFileContent/", bankFileSetup)
            .catch((e) => {
              var message = apiError("getBankListContent Error: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
        ]).then((responses) => {
          if (responses[0]) {
            var filename = "DDSetup(AUDDIS)" + this.state.FileFormatExtension;
            let output = responses[0].data;
            if (output !== "") {
              var blob = new Blob([output], {
                type: "text/plain;charset=utf-8",
              });
              saveAs(blob, filename);

              this.props.navigate("/DDSetupFile", {
                state: {
                  PostFeedback: ["AUDDIS File Generated and Mandates Updated."],
                },
              });
              this.resetForm();
            }
          }
        })
      ).catch((e) => {
        var message = apiError("API getBankListContent: ", e);
        this.setState((previousState) => ({
          errorList: [...previousState.errorList, message],
        }));
      });
    }
  }

  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      this.loadData();
    });
  }

  renderContent() {
    return (
      <>
        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
          PostFeedback={this.state.PostFeedback}
        />

        <div className="detailsContent">
          <Form
            id="GenerateDDForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <div id="sectionOne">
              <>
                <FormDropdown
                  id="CompanyID"
                  Label="Company"
                  name="CompanyID"
                  placeholder={this.state.CompanyID}
                  itemContent={this.state.CompanyList}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={4}
                  errorList={this.state.errorList}
                />
              </>

              <div className="CheckSection">
                <Row>
                  <Col sm={LabelColumnAmount} className="right">
                    Mandate Statuses to Retrieve
                  </Col>
                  <Col className="CheckSectionContent" sm={InputColumnAmount}>
                    {this.state.MandateStatusLists.map((element, i) =>
                      element.requiresSetupFile ? (
                        <FormCheck
                          key={i}
                          type="switch"
                          initialState={
                            element.content.substring(0, 2) === "0N"
                              ? true
                              : false
                          }
                          Label={element.content}
                          name={element.id}
                          id={element.content.substring(0, 2)}
                          value={this.handleCallback}
                          LabelColumnAmount={6}
                          InputColumnAmount={6}
                          errorList={this.state.errorList}
                        />
                      ) : null
                    )}
                  </Col>
                </Row>
              </div>
              <Form.Group className={"FormGroup"} as={Row}>
                <Col sm={LabelColumnAmount}></Col>
                <Col sm={2} className="IconBtn">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={this.handleContinue}
                  >
                    <FaSave /> {this.state.continueClicked ? "Reload" : "Load"}
                    {this.state.validationErrorList ? (
                      <div className="invalid-tooltip">
                        {this.state.validationErrorList}
                      </div>
                    ) : null}
                  </Button>
                </Col>
              </Form.Group>

              {!this.state.continueClicked ? null : (
                <>
                  <Row>
                    <Col></Col>
                    <Col sm="2" className="right TooltipActionButton">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="CommitInfoTooltip" className="">
                            Generate DD Setup (AUDDIS) File
                          </Tooltip>
                        }
                      >
                        <Button variant="primary" onClick={this.handleBankFile}>
                          <FaFileDownload /> Generate File
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <DDSetupTable
                    rows={this.state.MandateResults}
                    MandateStatusLists={this.state.MandateStatusLists}
                    blankState={blankState}
                    BankDetailsNeeded={this.state.BankDetailsNeeded}
                  />
                </>
              )}
            </div>
          </Form>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="DDSetupFile">
        <h1>Create DD Setup (AUDDIS) File</h1>
        {this.state.NoData ? (
          <IsError errorList={this.state.errorList} />
        ) : this.state.PlaceholderListFilled ? (
          this.renderContent()
        ) : null}
      </div>
    );
  }
}
DDSetupFile.contextType = AppContext;
export default withRouter(DDSetupFile);
