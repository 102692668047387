import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";
import Countdown from "./Countdown";
export const LabelColumnAmount = 4;
export const InputColumnAmount = 6;

class OutputMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OmittedCustomersFeedback: true,
      AddedSuccess: true,
      UpdateSuccess: true,
      PostFeedback: true,
      FetchingSage: true,
      PostingProgress: true,
    };
  }

  componentDidMount() {
    if (
      this.props.errorList !== undefined &&
      this.props.errorList.length !== 0
    ) {
      let iterator = this.props.errorList.keys();

      this.setState((state) => {
        for (let key of iterator) {
          state = { ...state, [key]: true };
        }
        return state;
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props !== prevProps &&
      this.props.errorList !== undefined &&
      this.props.errorList.length !== 0
    ) {
      let iterator = this.props.errorList.keys();

      this.setState((state) => {
        for (let key of iterator) {
          state = { ...state, [key]: true };
        }
        return state;
      });
    }
  }

  RenderItem(Name, Label, Item) {
    return (
      <>
        {this.state[Name] ? (
          <div
            className={
              isNaN(Name) ? "detailsContent" : "detailsContent ErrorList"
            }
          >
            <Row>
              <Col>
                <div style={{ padding: "0.375rem 2rem", textAlign: "center" }}>
                  {Item}
                </div>
              </Col>
              <div
                className="CloseButton close cross"
                onClick={() => {
                  this.setState({ [Name]: false });
                }}
              >
                <FaTimesCircle />
              </div>
            </Row>
          </div>
        ) : null}
      </>
    );
  }

  render() {
    let errorRemovedDuplicates = [...new Set(this.props.errorList)];
    let NotFetchStatus = true;
    if (
      this.props.DDStatus === "Fetching from Sage" ||
      this.props.DDStatus === "Posting in Progress"
    ) {
      NotFetchStatus = false;
    }
    return (
      <>
        {this.props.DDStatus === "Fetching from Sage" ? (
          <>
            {this.RenderItem(
              "FetchingSage",
              "",
              <>
                <> Fetching from Sage</>
                <>
                  <Countdown />
                </>
              </>
            )}
          </>
        ) : null}

        {this.props.DDStatus === "Posting in Progress" ? (
          <>
            {this.RenderItem(
              "PostingProgress",
              "",
              <>
                Posting in Progress
                <>
                  <Countdown />
                </>
              </>
            )}
          </>
        ) : null}

        {errorRemovedDuplicates && NotFetchStatus
          ? errorRemovedDuplicates.constructor === Array
            ? errorRemovedDuplicates.map((Element, i) => {
                if (Element !== null) {
                  return (
                    <div key={i}>
                      {this.RenderItem(i, "Error:", <>{Element}</>)}
                    </div>
                  );
                } else return null;
              })
            : this.RenderItem(
                "ErrorList",
                "Error:",
                <>{errorRemovedDuplicates}</>
              )
          : null}

        {this.props.PostFeedback && NotFetchStatus
          ? this.props.PostFeedback.map((Element, i) => {
              return (
                <div key={i}>
                  {Element !== "" ? (
                    <>
                      {this.RenderItem(
                        "PostFeedback",
                        "APIFeedback:",
                        <>{Element}</>
                      )}
                    </>
                  ) : null}
                </div>
              );
            })
          : null}

        {this.props.errorList.length <= 0 ? (
          <>
            {this.props.AddedSuccess && NotFetchStatus
              ? this.RenderItem(
                  "AddedSuccess",
                  "",
                  "Record(s) Successfully Added"
                )
              : null}

            {this.props.updateSuccess && NotFetchStatus
              ? this.RenderItem(
                  "UpdateSuccess",
                  "",
                  "Record(s) Successfully Updated"
                )
              : null}
          </>
        ) : null}
      </>
    );
  }
}

export default OutputMessage;
