import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaTimesCircle } from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { SetObserver } from "../../libs/Observer";
import { PaginationDisplay } from "../PaginationComponents";
import OverlayFormatter from "../../libs/OverlayFormatter";

class ShowSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OpenSchedule: false,
      Columns: [
        {
          dataField: "scheduleDate",
          text: "Schedule Date",
          sort: true,
          formatter: (cellContent, row) => {
            return moment(cellContent).format("DD-MM-YYYY");
          },
        },
        {
          dataField: "ddRun",
          text: "DD Run",
          sort: true,
          formatter: (cellContent, row) => {
            if (this.props.CurrentDDHeaderID === row.ddHeaderID) {
              return <i>This Run</i>;
            } else {
              return OverlayFormatter(cellContent, row);
            }
          },
        },
        {
          dataField: "scheduleAmount",
          text: "Schedule Amount",
          sort: true,
          headerAlign: "right",
          align: "right",
          formatter: (cellContent, row) => {
            cellContent = parseFloat(cellContent).toFixed(2);
            return cellContent;
          },
        },

        {
          dataField: "collectionAmount",
          text: "Collection Amount",
          sort: true,
          headerAlign: "right",
          align: "right",
          formatter: (cellContent, row) => {
            return cellContent ? parseFloat(cellContent).toFixed(2) : "0.00";
          },
        },
      ],
    };
  }

  componentDidMount() {
    SetObserver();
  }

  RenderPopup() {
    const sizePerPage = 12,
      defaultSorted = [
        {
          dataField: "scheduleDate",
          order: "asc",
        },
      ];

    return (
      <>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="CommitInfoTooltip" className="">
              Show Schedule
            </Tooltip>
          }
        >
          <Button
            className="ButtonRemove"
            onClick={(e) => {
              this.setState({
                OpenSchedule: !this.state.OpenSchedule,
              });
            }}
          >
            <AiOutlineSchedule />
          </Button>
        </OverlayTrigger>
        <Popup
          className="ShowSchedule"
          open={this.state.OpenSchedule}
          modal
          nested
          closeOnDocumentClick={true}
        >
          {(Close) => (
            <div className="Modal">
              <button
                className="close"
                onClick={(e) => {
                  this.setState({
                    OpenSchedule: !this.state.OpenSchedule,
                  });
                }}
              >
                <FaTimesCircle />
              </button>
              <div className="Title">
                <h2>Schedule List</h2>
              </div>
              <div className="content">
                {PaginationDisplay(
                  defaultSorted,
                  sizePerPage,
                  "scheduleID",
                  this.state.Columns,
                  this.props.ScheduleRowData
                )}
              </div>
            </div>
          )}
        </Popup>
      </>
    );
  }

  render() {
    return <>{this.RenderPopup()}</>;
  }
}

export default ShowSchedule;
