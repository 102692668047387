import React, { Component } from "react";
import { Link } from "react-router-dom";
import CustomerListTable from "../components/CustomerList/CustomerListTable";
import { FaFileImport } from "react-icons/fa";
import axios from "axios";
import { AppContext } from "../libs/contextLib";
import apiError from "../libs/apiError";
import withRouter from "../libs/withRouter";
import { trackPromise } from "react-promise-tracker";
import IsError from "../components/IsError";
import { Row, Col } from "react-bootstrap";
import { FaArrowCircleLeft } from "react-icons/fa";
import TooltipActionButton from "../components/TooltipActionButton";
import OutputMessage from "../components/OutputMessage";

class CustomerList extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = { errorList: [] };
  }
  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    let params = this.props.location.state,
      AddedSuccess;
    if (params !== undefined && params) {
      AddedSuccess = params.AddedSuccess;
    }
    let SiteID = sessionStorage.getItem("siteID"),
      CompanyDetails = JSON.parse(sessionStorage.getItem("CompanyDetails")),
      CompanyID = CompanyDetails.CompanyID,
      CompanyName = CompanyDetails.CompanyName;

    if (SiteID) {
      await trackPromise(
        Promise.all([
          axios
            .get("/api/customerLists/" + SiteID + "/" + CompanyID)
            .catch((e) => {
              var message = apiError("apiUrl Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
        ]).then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            let List = responses[0].data;
            this.setState({
              List,
              SiteID,
              CompanyName,
              AddedSuccess,
            });
          } else {
            throw new Error("No Active Customers found");
          }
        })
      ).catch((e) => {
        var message = apiError("CompanyList: ", e);
        this.setState((previousState) => ({
          errorList: [...previousState.errorList, message],
        }));
      });
    }
  }

  renderAddNew() {
    if (this.context.SettingsList.length !== 0) {
      if (
        this.context.SettingsList.IsAdmin ||
        this.context.SettingsList.CanImportBankDetails
      ) {
        return (
          <TooltipActionButton
            LinkTo={{
              pathname: `/BankDetailsImport`,
              state: { content: this.state.List },
            }}
            ToolTipLabel="Import Bank Details"
            Label="Import Bank Details"
            Icon={<FaFileImport />}
          />
        );
      } else return null;
    } else return null;
  }

  render() {
    return (
      <div className="CustomerList">
        <h1>{this.state.CompanyName} - Customer List</h1>

        {this.state.errorList.length >= 1 ? (
          <IsError errorList={this.state.errorList} />
        ) : this.state.List ? (
          <>
            <Row>
              <Col>
                <Link to="/CompanyList">
                  <FaArrowCircleLeft /> Back to Company List
                </Link>
              </Col>
            </Row>
            <OutputMessage
              errorList={this.state.errorList}
              updateSuccess={this.state.updateSuccess}
              AddedSuccess={this.state.AddedSuccess}
              PostFeedback={this.state.PostFeedback}
            />
            <CustomerListTable rows={this.state.List} />
            {this.renderAddNew()}
          </>
        ) : null}
      </div>
    );
  }
}
CustomerList.contextType = AppContext;
export default withRouter(CustomerList);
