import React, { Component } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import * as yup from "yup";

class FormSortCodeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLeft: this.props.placeholder.substring(0, 2),
      dataMiddle: this.props.placeholder.substring(2, 4),
      dataRight: this.props.placeholder.substring(4, 6),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(event) {
    var newValue = event.target.value;

    switch (event.target.name) {
      default:
      case "dataLeft":
        this.setState(
          (prevState, props) => ({
            ...prevState,
            dataLeft: newValue.substring(0, 2),
          }),
          () => {
            let value =
              this.state.dataLeft +
              this.state.dataMiddle +
              this.state.dataRight;

            this.props.value(this.props.name, value);
          }
        );
        break;
      case "dataMiddle":
        this.setState(
          (prevState, props) => ({
            ...prevState,
            dataMiddle: newValue.substring(0, 2),
          }),
          () => {
            let value =
              this.state.dataLeft +
              this.state.dataMiddle +
              this.state.dataRight;
            this.props.value(this.props.name, value);
          }
        );
        break;
      case "dataRight":
        this.setState(
          (prevState, props) => ({
            ...prevState,
            dataRight: newValue.substring(0, 2),
          }),
          () => {
            let value =
              this.state.dataLeft +
              this.state.dataMiddle +
              this.state.dataRight;
            this.props.value(this.props.name, value);
          }
        );
        break;
    }
  }

  handleBlur(e) {
    if (this.props.schema) {
      this.validateForm()
        .then((response) => {
          this.setState({ errorList: "" }, () => {
            this.props.value(
              this.props.name,
              this.state.dataLeft + this.state.dataMiddle + this.state.dataRight
            );
          });
        })
        .catch((e) => {
          // console.log(e);
          this.setState({ errorList: e }, () => {});
        });
    }
  }

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      if (this.props.schema) {
        let schema = this.props.schema;
        await yup
          .object()
          .shape({ [this.props.name]: schema })
          .validate(
            {
              [this.props.name]:
                this.state.dataLeft +
                this.state.dataMiddle +
                this.state.dataRight,
            },
            { abortEarly: false }
          )
          .then(() => {
            resolve(true);
          })
          .catch((e) => {
            var message = {};

            e.inner.forEach((e) => {
              message[e.path] = e.message;
            });

            reject(message);
          });
      }
    });
  }

  render() {
    const name = this.props.name;
    return !this.props.hidden ? (
      <Form.Group className={"FormGroup"} as={Row} controlId={this.props.name}>
        <Form.Label column sm={this.props.LabelColumnAmount}>
          {this.props.Label}
        </Form.Label>
        <Col sm={this.props.InputColumnAmount}>
          <div
            className="FormSortCodeInput"
            disabled={this.props.disabled}
            onBlur={this.handleBlur}
          >
            <InputGroup disabled={this.props.disabled}>
              <Form.Control
                type={this.props.type}
                aria-describedby="inputGroupPrepend"
                name="dataLeft"
                value={this.state.dataLeft || ""}
                onChange={this.handleChange}
                required={this.props.required}
                disabled={this.props.disabled}
              />
              <span className="dash">-</span>
              <Form.Control
                type={this.props.type}
                aria-describedby="inputGroupPrepend"
                name="dataMiddle"
                value={this.state.dataMiddle || ""}
                onChange={this.handleChange}
                required={this.props.required}
                disabled={this.props.disabled}
              />
              <span className="dash">-</span>
              <Form.Control
                type={this.props.type}
                aria-describedby="inputGroupPrepend"
                name="dataRight"
                value={this.state.dataRight || ""}
                onChange={this.handleChange}
                required={this.props.required}
                disabled={this.props.disabled}
              />
            </InputGroup>
          </div>
        </Col>
        <Col>
          {this.state.errorList && this.state.errorList[name] ? (
            <div className="invalid-tooltip typeError">
              {this.state.errorList[name]}
            </div>
          ) : null}
          {!this.state.errorList && this.props.errorList[name] ? (
            <div className="invalid-tooltip typeError">
              {this.props.errorList[name]}
            </div>
          ) : null}
        </Col>
      </Form.Group>
    ) : null;
  }
}

export default FormSortCodeInput;
