import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function OverlayFormatter(cellContent, row, label) {
  let trigger = (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="ReferenceTooltip" className="">
          {label ? label : cellContent}
        </Tooltip>
      }
    >
      <span>{cellContent}</span>
    </OverlayTrigger>
  );

  if (cellContent === null) {
    return <span>{cellContent}</span>;
  }

  if (row === "HeaderElement") {
    return trigger;
  }
  if (cellContent.length >= 1) {
    return trigger;
  } else return <span>{cellContent}</span>;
}
