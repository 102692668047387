import { Auth } from "aws-amplify";
import { Row, Col, Form } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import QRCode from "qrcode";
// import { FcAndroidOs } from "react-icons/fc";
// import { FaApple } from "react-icons/fa";
import { Button } from "react-bootstrap";
import FormLabelbox from "../Form/FormLabelbox";
import FormInputBox from "../Form/FormInputbox";

const LabelColumnAmount = 12,
  InputColumnAmount = 12;

let QR,
  QRBase,
  verifyCode = "",
  errorList = [];

const SetupMFA = async (User) => {
  await Auth.setupTOTP(User.UserOBJ)
    .then((code) => {
      let AppName =
        User.attributes.Environment === "Production"
          ? "SolutionsDirectDebit"
          : `${User.attributes.Environment}_SolutionsDirectDebit`;
      const str = `otpauth://totp/${AppName}:${User.attributes.email}?secret=${code}&issuer=SolutionsForAccounting`;

      QRCode.toDataURL(str, { errorCorrectionLevel: "H" })
        .then((qr) => {
          QR = qr;
          QRBase = code;
        })
        .catch((e) => {
          throw new Error(e);
        });
    })
    .catch((e) => {
      errorList = [...errorList, e];
    });

  const submitHandler = (e) => {
    e.preventDefault();
  };

  return new Promise((resolve, reject) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      overlayClassName: "SetupMFA",
      customUI: ({ onClose }) => {
        return (
          <div className="ConfirmAlert">
            <div className="AlertBody">
              <h1>Multi-Factor Authentication Setup</h1>
              <p>You need to setup MFA for your account.</p>
              <Form onSubmit={submitHandler}>
                <ol>
                  <li>
                    <FormLabelbox
                      type="text"
                      Label="First, Install an Authenticator App on your phone (such as Google Authenticator)."
                      name="First"
                      placeholder={""}
                      LabelColumnAmount={12}
                      InputColumnAmount={0}
                    />

                    {/* <Row>
                      <Col sm={12} className="IconBtn">
                        <Button
                          onClick={() =>
                            (window.location.href =
                              "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2")
                          }
                        >
                          <FcAndroidOs /> Get it on Google Play
                        </Button>
                        <Button
                          onClick={() =>
                            (window.location.href =
                              "https://apps.apple.com/us/app/google-authenticator/id388497605")
                          }
                        >
                          <FaApple /> Download on the App Store
                        </Button>
                      </Col>
                    </Row> */}
                  </li>

                  <li>
                    <FormLabelbox
                      type="text"
                      Label="Next, please scan this QR Code using the Authenticator App."
                      name="QRCode"
                      placeholder={<img src={QR} alt="QRCode" />}
                      LabelColumnAmount={LabelColumnAmount}
                      InputColumnAmount={InputColumnAmount}
                    />
                    <FormLabelbox
                      type="text"
                      Label="Or alternatively, you can manually enter this code into your Authenticator App."
                      name="QRBase"
                      placeholder={QRBase}
                      LabelColumnAmount={LabelColumnAmount}
                      InputColumnAmount={InputColumnAmount}
                    />
                  </li>

                  <li>
                    <FormInputBox
                      type="text"
                      Label="Finally please enter a code from the Authenticator App to complete setup."
                      name="verifyCode"
                      placeholder={verifyCode}
                      value={(name, theData) => {
                        verifyCode = theData;
                      }}
                      LabelColumnAmount={LabelColumnAmount}
                      InputColumnAmount={2}
                      errorList={(e) => {
                        errorList = [...errorList, e];
                      }}
                    />
                  </li>
                </ol>
                <Row>
                  <div className="row BlankMargin"></div>
                  <Col sm={2}></Col>
                  <Col className="IconBtn">
                    <Button
                      variant="primary"
                      onClick={async () => {
                        Auth.verifyTotpToken(User.UserOBJ, verifyCode)
                          .then(() => {
                            Auth.setPreferredMFA(User.UserOBJ, "TOTP")
                              .then((response) => {
                                resolve(response);
                              })
                              .catch((e) => {
                                reject(e);
                              });
                          })
                          .catch((e) => {
                            reject(e);
                          });
                        onClose();
                      }}
                    >
                      Verify MFA Setup
                    </Button>
                    <Button variant="primary" onClick={onClose}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        );
      },
    });
  });
};
export default SetupMFA;
