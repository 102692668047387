import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import * as yup from "yup";

const ValueInputColumnAmount = 6;
const btnAddColumnAmount = 6;

class FormValuesInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ValueElementList: this.props.placeholder,
      ValueElementCount: 0,
    };

    this.handleAddElement = this.handleAddElement.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    let count = document.querySelectorAll(".ValueInputElement").length;
    this.setState({ ValueElementCount: count });
  }

  handleAddElement() {
    let count = document.querySelectorAll(".ValueInputElement").length;
    this.setState({ ValueElementCount: count });

    if (this.props.UnlimitedValues) {
      this.setState((prevState) => ({
        ValueElementCount: prevState.ValueElementCount + 1,
        ValueElementList: [...prevState.ValueElementList, count],
      }));
    } else {
      if (this.state.ValueElementCount <= 7 && count <= 7) {
        this.setState((prevState) => ({
          ValueElementCount: prevState.ValueElementCount + 1,
          ValueElementList: [...prevState.ValueElementList, count],
        }));
      }
    }
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({ [name]: value });
    this.props.value([name], value);
  }

  handleBlur(e) {
    let value = e.target.value;
    let name = e.target.name;

    if (this.props.schema) {
      this.validateForm(name, value)
        .then((response) => {
          this.setState({ errorList: "" }, () => {
            this.props.value(name, value);
          });
        })
        .catch((e) => {
          this.setState({ errorList: e }, () => {});
        });
    }
  }

  async validateForm(name, value) {
    return new Promise(async (resolve, reject) => {
      if (this.props.schema) {
        let schema = this.props.schema;

        await yup
          .object()
          .shape({ [name]: schema })
          .validate(
            {
              [name]: value,
            },
            { abortEarly: false }
          )
          .then(() => {
            resolve(true);
          })
          .catch((e) => {
            var message = {};

            e.inner.forEach((e) => {
              message[e.path] = e.message;
            });

            reject(message);
          });
      }
    });
  }

  btnAdd() {
    if (!this.props.disabled) {
      return (
        <div className="btnAdd">
          <Button
            block="true"
            variant="primary"
            onClick={this.handleAddElement}
            disabled={this.props.disabled}
          >
            <FaPlus />
          </Button>
        </div>
      );
    } else return null;
  }

  inputElement(placeholder, i) {
    let valueIndex = i;
    let ValueElementCount = this.state.ValueElementCount - 1;
    let name = this.props.name + valueIndex;
    if (placeholder === i) {
      placeholder = "";
    }
    return (
      <Col sm={12} key={valueIndex}>
        <Row>
          <Col
            sm={ValueInputColumnAmount}
            className={"ValueInputElement " + name}
          >
            <Form.Control
              type={this.props.type}
              aria-describedby={this.props.name}
              name={name}
              value={
                this.props.UnlimitedValues
                  ? this.state[name] || placeholder.filterValue
                  : this.state[name] || placeholder
              }
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              isInvalid={this.props.isInvalid}
              required={this.props.required}
              disabled={this.props.disabled}
            />
          </Col>
          <Col sm={btnAddColumnAmount}>
            {this.props.UnlimitedValues
              ? valueIndex === ValueElementCount
                ? this.btnAdd()
                : null
              : valueIndex === ValueElementCount && ValueElementCount !== 7
              ? this.btnAdd()
              : null}
            {this.state.errorList && this.state.errorList[name] ? (
              <div className="invalid-tooltip typeError">
                {this.state.errorList[name]}
              </div>
            ) : null}
          </Col>
        </Row>
      </Col>
    );
  }

  render() {
    return !this.props.hidden ? (
      <div id="ValueInput">
        <Form.Group className={"FormGroup"} controlId={this.props.name}>
          <Row id="cloneSource">
            {this.state.ValueElementList.map((placeholder, i) =>
              this.inputElement(placeholder, i)
            )}
            {this.state.ValueElementCount <= 0 ? this.btnAdd() : null}
          </Row>
        </Form.Group>
      </div>
    ) : null;
  }
}

export default FormValuesInput;
