import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";

export default function CheckIfTicked(value, ShowTickBox) {
  if (ShowTickBox) {
    return (
      <div className="tick" style={{ cursor: "pointer" }}>
        {value ? <FaRegCheckSquare /> : <FaRegSquare />}
      </div>
    );
  } else return null;
}

// import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
// import { Row, Col } from "react-bootstrap";
// import OverlayFormatter from "./OverlayFormatter";

// export default function CheckIfTicked(value, ShowTickBox, cellContent) {
//   if (ShowTickBox) {
//     return (
//       <>
//         <Row>
//           <Col sm={2}>
//             <div
//               className="tick"
//               // style={
//               //   {
//               //      textAlign: "right",
//               //   }
//               // }
//             >
//               {value ? <FaRegCheckSquare /> : <FaRegSquare />}
//             </div>
//           </Col>
//           <Col sm={10}>{OverlayFormatter(cellContent)}</Col>
//         </Row>
//       </>
//     );
//   } else
//     return (
//       <>
//         <Row>
//           <Col sm={2}>
//             <div> </div>
//           </Col>
//           <Col sm={10}>{OverlayFormatter(cellContent)}</Col>
//         </Row>
//       </>
//     );
// }
