import React from "react";
import CookieConsent from "react-cookie-consent";

export default function Footer() {
  return (
    <footer>
      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonClasses="btn btn-primary"
        containerClasses="cookieConsent alert alert-warning col-lg-12"
        contentClasses="text-capitalize"
        buttonText="Accept"
        cookieName="DirectDebit"
        expires={150}
      >
        The Direct Debit App uses cookies to enhance the user experience.
      </CookieConsent>
    </footer>
  );
}
