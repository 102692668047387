import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaTimesCircle } from "react-icons/fa";
import { Button } from "react-bootstrap";
import OverlayFormatter from "../../libs/OverlayFormatter";
import { SetObserver } from "../../libs/Observer";
import { PaginationDisplay } from "../PaginationComponents";

class OmittedCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "accountReference",
          text: "Account Reference",
          sort: true,
        },
        {
          dataField: "accountName",
          text: "Account Name",
          sort: true,
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
        {
          dataField: "reason",
          text: "Omission Reason",
          sort: true,
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
        {
          dataField: "transactionTotal",
          text: "Transaction Total",
          sort: true,
          formatter: (cellContent, row) => {
            cellContent = parseFloat(cellContent).toFixed(2);
            return cellContent;
          },
        },
      ],
    };
  }

  componentDidMount() {
    SetObserver();
  }

  RenderPopup() {
    const sizePerPage = 10,
      defaultSorted = [
        {
          dataField: "accountReference",
          order: "asc",
        },
      ];
    return (
      <Popup
        className="OmittedCustomers"
        trigger={
          <Button variant="primary">
            Omitted Customers ({this.props.CustomerList.length})
          </Button>
        }
        modal
        nested
        closeOnDocumentClick={false}
      >
        {(Close) => (
          <div className="Modal">
            <button className="close" onClick={Close}>
              <FaTimesCircle />
            </button>
            <div className="Title">{/* <h2>Omitted Customers List</h2> */}</div>
            <div className="content">
              {PaginationDisplay(
                defaultSorted,
                sizePerPage,
                "accountReference",
                this.state.columns,
                this.props.CustomerList
              )}
            </div>
          </div>
        )}
      </Popup>
    );
  }

  render() {
    return <>{this.RenderPopup()}</>;
  }
}

export default OmittedCustomers;
