import React, { useState } from "react";
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { handleValidation } from "../handleValidation";
const DOMPurify = require("dompurify");

const FormInputBox = (props) => {
  const [Data, setData] = useState(props.placeholder || "");
  const [ErrorList, setErrorList] = useState();

  const handleChange = (e) => {
    let cleanedValue = DOMPurify.sanitize(e.target.value.trimStart());
    if (props.ConvertToUppercase) {
      cleanedValue = cleanedValue.toUpperCase();
    }

    setData(cleanedValue);
    props.value(props.name, cleanedValue);
  };

  const handleBlur = (e) => {
    if (props.schema) {
      let items = {
        name: props.name,
        data: Data.trimStart(),
        schema: props.schema,
      };
      handleValidation(items)
        .then((response) => {
          setErrorList("");
          props.value(props.name, e.target.value.trimStart());
        })
        .catch((e) => {
          setErrorList(e);
        });
    }
  };

  const renderSwitch = (props) => {
    switch (props.type) {
      case "password":
        return (
          <>
            <input
              type="text"
              value={""}
              style={{ display: "none" }}
              readOnly
            />

            <Form.Control
              type="password"
              aria-describedby="inputGroupPrepend"
              name={props.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required={props.required}
              disabled={props.disabled}
              autoComplete={props.autoComplete ? props.autoComplete : null}
            />
          </>
        );

      case "textarea":
        return (
          <Form.Control
            as="textarea"
            rows={props.rows}
            aria-describedby="inputGroupPrepend"
            name={props.name}
            value={Data}
            onChange={handleChange}
            onBlur={handleBlur}
            required={props.required}
            disabled={props.disabled}
            autoComplete={props.autoComplete ? props.autoComplete : null}
          />
        );
      default:
        return (
          <Form.Control
            type={props.type}
            aria-describedby="inputGroupPrepend"
            name={props.name}
            value={Data}
            onChange={handleChange}
            onBlur={handleBlur}
            required={props.required}
            disabled={props.disabled}
            autoComplete={props.autoComplete ? props.autoComplete : null}
          />
        );
    }
  };

  const renderForm = () => {
    let name = props.name;

    return (
      <Form.Group className={"FormGroup"} as={Row} controlId={name}>
        <Form.Label column sm={props.LabelColumnAmount}>
          {props.Label}
        </Form.Label>
        <Col sm={props.InputColumnAmount}>{renderSwitch(props)}</Col>

        {ErrorList && ErrorList[name] ? (
          <Col>
            <div className="invalid-tooltip typeError">{ErrorList[name]}</div>
          </Col>
        ) : null}
        {!ErrorList && props.errorList[name] ? (
          <Col>
            <div className="invalid-tooltip typeError">
              {props.errorList[name]}
            </div>
          </Col>
        ) : null}
      </Form.Group>
    );
  };

  return !props.hidden ? (
    props.tooltip ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={props.name + "Tooltip"} className="">
            {props.tooltip}
          </Tooltip>
        }
      >
        {renderForm()}
      </OverlayTrigger>
    ) : (
      renderForm()
    )
  ) : null;
};

export default FormInputBox;
