import React, { Component } from "react";
import {
  FaArrowCircleLeft,
  FaRegEdit,
  FaSave,
  FaTimesCircle,
  FaInfoCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button, Badge, ToggleButton } from "react-bootstrap";
import FormInputBox from "../Form/FormInputbox";
import FormDropdown from "../Form/FormDropdown";
import FormCheck from "../Form/FormCheck";
import FormValuesInput from "../Form/FormValuesInput";
import FormLabelbox from "../Form/FormLabelbox";
import FormImageUpload from "../Form/FormImageUpload";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import GetDatetime from "../../libs/GetDatetime";
import SetEntity from "../../libs/SetEntity";
import { AppContext } from "../../libs/contextLib";
import IsError from "../../components/IsError";
import CustomFormat from "./GenerateCustomFormat";
import * as yup from "yup";
import OutputMessage from "../../components/OutputMessage";
import { handleValidation } from "../handleValidation";
import {
  LabelColumnAmount,
  InputColumnAmount,
  DataNotChanged,
  ValidationError,
  inputRegex,
  inputRegexMessage,
  ValueInputRegex,
  ValueInputRegexMessage,
  textareaRegex,
  textareaRegexMessage,
} from "../../libs/Variables";
import RuntimeFilter from "./RuntimeFiltering/RuntimeFilter";

const CompanyNameSchema = yup
    .string()
    .required("Company Name is required")
    .matches(inputRegex, "Company Name" + inputRegexMessage)
    .max(100, "Company Name must be less than 100 characters"),
  FilterFieldNameSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, "Filter Field Name" + inputRegexMessage)
    .max(60, "Filter Field Name must be less than 60 characters"),
  FilterValueSchema = yup
    .string()
    .nullable()
    .matches(ValueInputRegex, ValueInputRegexMessage)
    .max(60, "This Value must be less than 60 characters"),
  ServiceUserNumberSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, "Service User Number" + inputRegexMessage)
    .min(6, "Service User Number must be 6 characters")
    .max(6, "Service User Number must be 6 characters"),
  ReceivingPartyIdentifyingNumberSchema = yup
    .string()
    .nullable()
    .matches(
      inputRegex,
      "Receiving Party Identifying Number" + inputRegexMessage
    )
    .min(10, "Receiving Party Identifying Number must be 10 characters")
    .max(10, "Receiving Party Identifying Number must be 10 characters"),
  JournalTypeSchema = yup
    .string()
    .nullable()
    .required("Journal Type can't be blank."),
  LetterSchema = yup
    .string()
    .nullable()
    .matches(textareaRegex, "Letter Text Field " + textareaRegexMessage)
    .max(250, "Letter Text Field must be less than 250 characters");

let blankState = {
  notEditableSectionTwo: false,
  notEditableSectionOne: false,
  entityEditable: true,
  editVisible: false,
  sectionElementIsHidden: true,
  syncResponse: false,
  submitSuccess: false,
  updateSuccess: false,
  AddedSuccess: false,
  validated: false,

  CompanyName: "",
  companySettingID: 1,
  IsActive: true,
  FilterFieldName: "",
  FilterValue: [""],
  InCredit: false,
  DDOutputFormat: 1,
  TransactionFilter: 1,
  FileFormat: 1,
  RejectionOption: 1,
  IncludeOnHoldAccounts: false,
  UseRuntimeFilters: false,
  DisableDDCompanySync: false,
  MustPassModulusCheck: false,
  TransferCollection: false,
  TransferBank: 0,
  JournalSymbol: null,

  UseScheduling: false,
  ScheduleCollectionTypeID: 1,

  IncludeInvoices: false,
  IncludeCredits: false,
  IncludeReceipts: false,
  IncludePayments: false,
  IncludeDebitMemos: false,
  IncludeCreditMemos: false,
  AddNewAccountsOnUpdate: false,
  CollectNewTransactionsOnUpdate: false,

  ShowTransactionsOutsideDateRange: false,
  ShowNotificationLettersOnCreated: true,
  SummariseOutput: true,
  UseLetterTextDefault: false,
  UseScheduleText: false,
  LetterOpening: "",
  LetterText: "",
  ScheduleText: "",
  CustomFormat: "",
  ServiceUserNumber: "",
  ReceivingPartyIdentifyingNumber: "",

  UserPostOverride: false,
  errorList: [],
};

let CompanyListLabel = "Entity";

class CompanyListDetails extends Component {
  static contextState = AppContext;

  constructor(props) {
    super(props);
    this.state = blankState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handleSetEdit = this.handleSetEdit.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  async loadData() {
    let params = this.props.location.state,
      NewCompany = false,
      NewCompanyFirstLoad = false,
      notEditableSectionTwo = false,
      notEditableSectionOne = false,
      editVisible = false,
      sectionElementIsHidden = false,
      updateSuccess,
      AddedSuccess,
      PostFeedback;

    if (params !== undefined && params) {
      NewCompany = params.NewCompany;
      NewCompanyFirstLoad = params.NewCompanyFirstLoad;
      updateSuccess = params.updateSuccess;
      AddedSuccess = params.AddedSuccess;
      PostFeedback = params.PostFeedback;
    }

    if (!NewCompany) {
      notEditableSectionTwo = true;
      notEditableSectionOne = true;
      editVisible = true;
      sectionElementIsHidden = false;
    }

    let CompanyID = sessionStorage.getItem("CompanyID"),
      SiteID = sessionStorage.getItem("siteID"),
      SiteSettingsID = parseInt(sessionStorage.getItem("SiteSettingsID")),
      ProductName = sessionStorage.getItem("productName");
    if (ProductName !== "Sage Intacct") {
      CompanyListLabel = "Sage Company";
    }

    await trackPromise(
      Promise.all([
        axios
          .get("/api/SyscompanySettings/" + SiteID + "/" + CompanyID)
          .catch((e) => {
            var message = apiError("apiSyscompanySettings Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
              NoData: true,
            }));
          }),
        axios
          .get("/api/BankAccountLists/" + SiteID + "/" + CompanyID)
          .catch((e) => {
            var message = apiError("apiBankFormatList Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
              NoData: true,
            }));
          }),
        axios.get("/api/SysbankFormats/").catch((e) => {
          var message = apiError("apiDDOutputFormatList Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
        axios.get("/api/SystransactionFilters/").catch((e) => {
          var message = apiError("apiSystransactionFilters Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
        axios.get("/api/CompanyLists/" + SiteID).catch((e) => {
          var message = apiError("apiCompanyLists Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
        axios.get("/api/SysscheduleCollectionTypes/").catch((e) => {
          var message = apiError("apiSysscheduleCollectionTypes Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
        axios.get("/api/FileFormatLists/").catch((e) => {
          var message = apiError("apiFileFormatLists Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
        axios.get("/api/RejectionOptionLists/").catch((e) => {
          var message = apiError("apiRejectionOptionsListsGet: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        }),
        ProductName === "Sage Intacct"
          ? axios
              .get(
                this.context.INTACCT_API_BASE_URL +
                  "/api/Journal/ListJournalTypes/?siteID=" +
                  SiteID,
                {
                  crossdomain: true,
                }
              )
              .catch((e) => {
                var message = apiError("apiListJournalTypes Get: ", e);
                this.setState((previousState) => ({
                  errorList: [...previousState.errorList, message],
                  NoData: true,
                }));
              })
          : null,
      ])
        .then((responses) => {
          let placeholderArr,
            CustomFormat,
            bankFormatList,
            setAmendableFormat,
            setIsFixedLength,
            setDefaultFormat,
            setDefaultHeader,
            DDOutputFormatList,
            TransactionFilterList,
            FileFormatList,
            RejectionOptionList,
            CompanyLists,
            ScheduleCollectionTypes,
            ListJournalTypes;

          if (responses[0] && responses[0] !== undefined) {
            placeholderArr = responses[0].data[0];
          } else {
            throw new Error("No Active Companies found");
          }

          if (responses[1] && responses[1] !== undefined) {
            bankFormatList = responses[1].data;
          } else {
            throw new Error("No Active Bank Account Lists found");
          }
          if (responses[2] && responses[2] !== undefined) {
            DDOutputFormatList = responses[2].data;
            DDOutputFormatList.forEach((element) => {
              if (element.id === placeholderArr.ddOutputFormatID) {
                setAmendableFormat = element.isAmendableFormat;
                setIsFixedLength = element.isFixedLength;
                setDefaultFormat = element.defaultFormat;
                setDefaultHeader = element.defaultHeader;
              }
            });
          } else {
            throw new Error("Problem Fetching DDOutput Format List.");
          }

          if (responses[3] && responses[3] !== undefined) {
            TransactionFilterList = responses[3].data;
          } else {
            throw new Error("Problem Fetching Transaction Filter List.");
          }

          let TopLevelEntity = false,
            EntityName;
          if (responses[4] && responses[4] !== undefined) {
            CompanyLists = responses[4].data;
            CompanyLists = SetEntity(CompanyLists, "content");
            var index = CompanyLists.findIndex(
              (i) => i.id === parseInt(placeholderArr.companyListId)
            );
            EntityName = CompanyLists[index].content;
            if (CompanyLists[index].isTopLevel) {
              TopLevelEntity = true;
            }
          } else {
            throw new Error(`Problem Fetching ${CompanyListLabel} List.`);
          }

          if (responses[5] && responses[5] !== undefined) {
            ScheduleCollectionTypes = responses[5].data;
          } else {
            throw new Error("Problem Fetching Schedule Collection Type List.");
          }

          if (responses[6] && responses[6] !== undefined) {
            FileFormatList = responses[6].data;
          } else {
            throw new Error("Problem Fetching FileFormat List.");
          }

          if (responses[7] && responses[7] !== undefined) {
            RejectionOptionList = responses[7].data;
          } else {
            throw new Error("Problem Fetching RejectionOption List.");
          }

          let JournalTypes = [{ id: 0, JournalSymbol: "", content: "" }],
            JournalTypeID,
            JournalSymbol;
          if (ProductName === "Sage Intacct") {
            if (responses[8] && responses[8] !== undefined) {
              ListJournalTypes = responses[8].data;
              ListJournalTypes.forEach((element) => {
                JournalTypes[element.id] = {
                  id: element.id,
                  JournalSymbol: element.symbol,
                  content: `${element.symbol} - ${element.title}`,
                };
              });
              JournalTypes.sort((a, b) => {
                var x = a["content"];
                var y = b["content"];
                return x < y ? -1 : x > y ? 1 : 0;
              });

              JournalTypeID =
                placeholderArr.journalTypeID === 0 ||
                placeholderArr.journalTypeID === null
                  ? 0
                  : placeholderArr.journalTypeID;

              var JournalTypesIndex = 0;
              try {
                JournalTypesIndex = JournalTypes.findIndex(
                  (i) => i.id === parseInt(JournalTypeID)
                );
              } catch (e) {
                PostFeedback = [
                  "Unable to find Journal Type - Journal might be inactive. You will therefore need to select a new Journal Type.",
                ];
                JournalTypeID = "";
              }
              JournalSymbol = JournalTypes[JournalTypesIndex].JournalSymbol;
            } else {
              throw new Error("Problem Fetching Journal Types List.");
            }
          }

          let setFilterValue = "";
          setFilterValue = placeholderArr.filterValueList.filter(Boolean);

          CustomFormat = placeholderArr.customFormat;

          let BankAccountObject = this.SetBankAccountObject(
            bankFormatList,
            CompanyLists,
            placeholderArr.defaultReceiptsBankID,
            placeholderArr.transferBank,
            ProductName
          );

          let TransactionTypeGroups = [];
          switch (ProductName) {
            case "Sage 50":
              TransactionTypeGroups = ["Invoices", "Credits"];

              break;
            case "Sage 200":
            case "Sage 200c Standard":
              TransactionTypeGroups = [
                "Invoices",
                "Credits",
                "Receipts",
                "Payments",
              ];

              break;
            default:
              //Sage Intacct
              TransactionTypeGroups = [
                "Invoices",
                "Credits",
                "Receipts",
                "Payments",
                "DebitMemos",
                "CreditMemos",
              ];
              break;
          }

          this.setState(
            {
              SiteID,
              CompanyID,
              ProductName,
              SiteSettingsID,
              CompanySettingID: placeholderArr.companySettingID,
              CompanyListId: placeholderArr.companyListId,
              EntityName,
              TopLevelEntity,
              CompanyName: placeholderArr.companyName,
              IsActive: placeholderArr.isActive,
              DefaultReceiptsBank: BankAccountObject.DefaultReceiptsBank,
              FilterFieldName: placeholderArr.filterFieldName,
              FilterValue: setFilterValue,
              InCredit: placeholderArr.includeAccountsInCredit,
              DDOutputFormat: placeholderArr.ddOutputFormatID,
              TransactionFilter: placeholderArr.transactionFilterID,
              FileFormat: placeholderArr.fileFormat,
              RejectionOption: placeholderArr.rejectionOption,
              // FetchNewCustomers: placeholderArr.fetchNewCustomers,
              IncludeOnHoldAccounts: placeholderArr.includeOnHoldAccounts,
              UseRuntimeFilters: placeholderArr.useRuntimeFilters,
              DisableDDCompanySync: placeholderArr.disableDDCompanySync,
              MustPassModulusCheck: placeholderArr.mustPassModulusCheck,
              UserPostOverride: placeholderArr.userPostOverride,
              AddNewAccountsOnUpdate: placeholderArr.addNewAccountsOnUpdate,
              CollectNewTransactionsOnUpdate:
                placeholderArr.collectNewTransactionsOnUpdate,

              TransferCollection: placeholderArr.transferCollection,
              TransferBank: BankAccountObject.TransferBank,
              JournalTypeID,
              JournalSymbol: JournalSymbol,
              JournalTypes,
              TransferLocation: placeholderArr.transferLocation,
              TransferLocationList: BankAccountObject.TransferLocationList,

              UseScheduling: placeholderArr.useScheduling,
              ScheduleCollectionTypeID: placeholderArr.scheduleCollectionTypeID,
              ScheduleCollectionTypes,

              IncludeInvoices: placeholderArr.includeInvoices,
              IncludeCredits: placeholderArr.includeCredits,
              IncludeReceipts: placeholderArr.includeReceipts,
              IncludePayments: placeholderArr.includePayments,
              IncludeDebitMemos: placeholderArr.includeDebitMemos,
              IncludeCreditMemos: placeholderArr.includeCreditMemos,

              ShowNotificationLettersOnCreated:
                placeholderArr.showNotificationLettersOnCreated,
              ShowTransactionsOutsideDateRange:
                placeholderArr.showTransactionsOutsideDateRange,
              SummariseOutput: placeholderArr.summariseOutput,
              UseLetterTextDefault: placeholderArr.useLetterTextDefault,
              LetterImage: placeholderArr.letterImage,
              LetterOpening: placeholderArr.letterOpening,
              LetterText: placeholderArr.letterText,
              UseScheduleText: placeholderArr.useScheduleText,
              ScheduleText: placeholderArr.scheduleText,
              ServiceUserNumber: placeholderArr.serviceUserNumber,
              ReceivingPartyIdentifyingNumber:
                placeholderArr.receivingPartyIdentifyingNumber,
              CustomFormat: CustomFormat,

              bankFormatList,
              TransferBankAccountList:
                BankAccountObject.TransferBankAccountList,
              TransactionTypeGroups,
              DDOutputFormatList,
              AmendableFormat: setAmendableFormat,
              IsFixedLength: setIsFixedLength,
              DefaultFormat: setDefaultFormat,
              DefaultHeader: setDefaultHeader,
              TransactionFilterList,
              FileFormatList,
              RejectionOptionList,
              CompanyLists,
              DateTimeUpdated: placeholderArr.dateTimeUpdated,
              DateTimeCreated: placeholderArr.dateTimeCreated,
              PlaceholderListFilled: true,
              NewCompany,
              NewCompanyFirstLoad,
              updateSuccess,
              AddedSuccess,
              PostFeedback,
              notEditableSectionTwo,
              notEditableSectionOne,
              editVisible,
              sectionElementIsHidden,
            },
            () => {
              // console.log(this.state);
              if (NewCompanyFirstLoad) {
                this.doSubmit();
              }
            }
          );
        })
        .catch((e) => {
          var message = apiError("api Placeholder Get:", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        })
    );
  }

  SetBankAccountObject(
    bankFormatList,
    CompanyLists,
    defaultReceiptsBankID,
    currentTransferBankAccountID,
    ProductName
  ) {
    if (defaultReceiptsBankID !== 0) {
      var index = bankFormatList.findIndex(
        (i) => i.id === parseInt(defaultReceiptsBankID)
      );
      if (index >= 0) {
        var DefaultReceiptsBank = bankFormatList[index].id;
        var ReceiptsBankCurrency = bankFormatList[index].bankCurrency;
      } else {
        DefaultReceiptsBank = bankFormatList[0].id;
        ReceiptsBankCurrency = bankFormatList[0].bankCurrency;
      }
    } else {
      DefaultReceiptsBank = bankFormatList[0].id;
      ReceiptsBankCurrency = bankFormatList[0].bankCurrency;
    }
    let TransferBankAccountList = [];
    bankFormatList.forEach((element) => {
      if (
        element.bankCurrency === ReceiptsBankCurrency &&
        element.id !== DefaultReceiptsBank
      ) {
        TransferBankAccountList.push({
          bankCurrency: element.bankCurrency,
          bankCurrencySymbol: element.bankCurrencySymbol,
          content: element.content,
          id: element.id,
        });
      }
    });

    if (TransferBankAccountList.length >= 1) {
      if (currentTransferBankAccountID !== 0) {
        var i = TransferBankAccountList.findIndex(
          (i) => i.id === parseInt(currentTransferBankAccountID)
        );
        if (i >= 0) {
          var TransferBank = TransferBankAccountList[i].id;
        } else {
          TransferBank = TransferBankAccountList[0].id;
        }
      } else {
        TransferBank = TransferBankAccountList[0].id;
      }
    }

    let TransferLocationList = [
      {
        id: 0,
        entityCompanyName: "",
        entityLocationId: "",
        isTopLevel: false,
        content: "",
        EntityCurrency: "",
      },
    ];

    if (ProductName === "Sage Intacct") {
      CompanyLists.forEach((element) => {
        if (
          !element.isTopLevel &&
          element.entityCurrency === ReceiptsBankCurrency
        ) {
          TransferLocationList.push(element);
        }
      });
    }

    return {
      DefaultReceiptsBank,
      TransferBankAccountList,
      TransferBank,
      TransferLocationList,
    };
  }

  handleCallback = (name, theData) => {
    // console.log(name);
    // console.log(theData);
    const theState = this.state,
      NoUpdateRequired = false,
      DataUpdated = true;
    this.setState((state) => {
      var pattFilterValue = new RegExp("FilterValue");
      var pattDDOutputFormat = new RegExp("DDOutputFormat");
      var pattDataUpdated = new RegExp("DataUpdated");
      var pattDefaultReceiptsBank = new RegExp("DefaultReceiptsBank");
      var pattTransferBankAccount = new RegExp("TransferBankAccount");
      var pattJournalType = new RegExp("JournalType");
      var pattErrorList = new RegExp("errorList");
      if (pattErrorList.test(name)) {
        state.errorList = [...state.errorList, theData];
      } else if (pattFilterValue.test(name)) {
        let str = String(name);
        let currentKey = str.slice(-1);
        state.FilterValue[currentKey] = theData;
      } else if (pattDDOutputFormat.test(name)) {
        theState.DDOutputFormatList.forEach((element) => {
          if (element.id === parseInt(theData)) {
            state.AmendableFormat = element.isAmendableFormat;
            state.IsFixedLength = element.isFixedLength;
            state.DefaultFormat = element.defaultFormat;
            state.DefaultHeader = element.defaultHeader;
            state.CustomFormat = element.defaultFormat;
            state.DDOutputFormat = element.id;
          }
        });
      } else if (pattDefaultReceiptsBank.test(name)) {
        let BankAccountObject = this.SetBankAccountObject(
          state.bankFormatList,
          state.CompanyLists,
          theData,
          state.TransferBank,
          state.ProductName
        );
        state["DefaultReceiptsBank"] = BankAccountObject.DefaultReceiptsBank;
        state["TransferBank"] = BankAccountObject.TransferBank;
        state["TransferBankAccountList"] =
          BankAccountObject.TransferBankAccountList;
        state["TransferLocationList"] = BankAccountObject.TransferLocationList;
      } else if (pattTransferBankAccount.test(name)) {
        let BankAccountObject = this.SetBankAccountObject(
          state.bankFormatList,
          state.CompanyLists,
          state.DefaultReceiptsBank,
          theData,
          state.ProductName
        );
        state["DefaultReceiptsBank"] = BankAccountObject.DefaultReceiptsBank;
        state["TransferBank"] = BankAccountObject.TransferBank;
        state["TransferBankAccountList"] =
          BankAccountObject.TransferBankAccountList;
        state["TransferLocationList"] = BankAccountObject.TransferLocationList;
      } else if (pattJournalType.test(name)) {
        var index = state.JournalTypes.findIndex(
          (i) => i.id === parseInt(theData)
        );
        state["JournalTypeID"] = state.JournalTypes[index].id;
        state["JournalSymbol"] = state.JournalTypes[index].JournalSymbol;
      } else if (pattDataUpdated.test(name)) {
        state["NoUpdateRequired"] = NoUpdateRequired;
        state["DataUpdated"] = DataUpdated;
      } else {
        state[name] = theData;
      }
      state["NoUpdateRequired"] = NoUpdateRequired;
      state["DataUpdated"] = DataUpdated;
      // console.log(state);
      return state;
    });
  };

  handleFormReset(e) {
    e.preventDefault();
    this.resetForm();
  }
  resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce(
      (acc, v) => ({ ...acc, [v]: undefined }),
      {}
    );
    this.setState(
      {
        ...stateReset,
        ...blankState,
        FilterValue: [""],
      },
      () => {
        // console.log(this.state);
        this.loadData();
      }
    );
  }

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "CompanyName",
          data: this.state.CompanyName,
          schema: CompanyNameSchema,
        },
        {
          name: "FilterFieldName",
          data: this.state.FilterFieldName,
          schema: FilterFieldNameSchema,
        },
        {
          name: "FilterFieldName0",
          data: this.state.FilterValue[0],
          schema: FilterValueSchema,
        },
        {
          name: "FilterFieldName1",
          data: this.state.FilterValue[1],
          schema: FilterValueSchema,
        },
        {
          name: "FilterFieldName2",
          data: this.state.FilterValue[2],
          schema: FilterValueSchema,
        },
        {
          name: "FilterFieldName3",
          data: this.state.FilterValue[3],
          schema: FilterValueSchema,
        },
        {
          name: "FilterFieldName4",
          data: this.state.FilterValue[4],
          schema: FilterValueSchema,
        },
        {
          name: "FilterFieldName5",
          data: this.state.FilterValue[5],
          schema: FilterValueSchema,
        },
        {
          name: "FilterFieldName6",
          data: this.state.FilterValue[6],
          schema: FilterValueSchema,
        },
        {
          name: "FilterFieldName7",
          data: this.state.FilterValue[7],
          schema: FilterValueSchema,
        },
      ];
      if (this.state.TransferCollection && this.state.TopLevelEntity) {
        items.push({
          name: "JournalType",
          data: this.state.JournalTypeID,
          schema: JournalTypeSchema,
        });
      }
      var Status = [];

      if (this.state.UseScheduling) {
        if (!this.state.ScheduleCollectionTypeID) {
          reject("A Schedule Collection Type has not been set.");
        }
      }
      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.validateForm()
      .then((response) => {
        this.setState({ validated: true, errorList: "" }, () => {
          this.doSubmit();
        });
      })
      .catch((e) => {
        this.setState({
          validated: false,
          validationErrorList: JSON.stringify(e),
        });
      });
  }

  async doSubmit() {
    let output = this.state;

    let DateTimeUpdated = GetDatetime();

    var FilterValueList = [];
    for (var i = 0; i <= 7; i++) {
      if (!output.FilterValue[0]) {
        output.FilterValue[0] = null;
      }
      FilterValueList[i] = output.FilterValue[i];
    }
    if (output.CustomFormat) {
      var CustomFormat = output.CustomFormat.toString();
    }

    let outputJSON = {
      SiteSettingsID: this.state.SiteSettingsID,
      CompanyListId: output.CompanyListId,
      CompanyName: output.CompanyName,
      IsActive: output.IsActive,
      DefaultReceiptsBankID: output.DefaultReceiptsBank,
      TransferCollection: output.TransferCollection,
      TransferBank: output.TransferBank,
      JournalTypeID: output.JournalTypeID,
      JournalSymbol: output.JournalSymbol,
      TransferLocation: output.TransferLocation,
      FilterFieldName: output.FilterFieldName,
      FilterValueList: FilterValueList,

      IncludeAccountsInCredit: output.InCredit,
      DDOutputFormatID: output.DDOutputFormat,
      TransactionFilterID: output.TransactionFilter,
      FileFormat: output.FileFormat,
      RejectionOption: output.RejectionOption,
      IncludeOnHoldAccounts: output.IncludeOnHoldAccounts,
      UseRuntimeFilters: output.UseRuntimeFilters,
      DisableDDCompanySync: output.DisableDDCompanySync,
      MustPassModulusCheck: output.MustPassModulusCheck,
      // FetchNewCustomers: output.FetchNewCustomers,
      UserPostOverride: output.UserPostOverride,

      UseScheduling: output.UseScheduling,
      ScheduleCollectionTypeID: output.ScheduleCollectionTypeID,

      IncludeInvoices: output.IncludeInvoices,
      IncludeCredits: output.IncludeCredits,
      IncludeReceipts: output.IncludeReceipts,
      IncludePayments: output.IncludePayments,

      ShowNotificationLettersOnCreated: output.ShowNotificationLettersOnCreated,
      ShowTransactionsOutsideDateRange: output.ShowTransactionsOutsideDateRange,
      SummariseOutput: output.SummariseOutput,
      UseLetterTextDefault: output.UseLetterTextDefault,
      LetterOpening: output.LetterOpening,
      LetterText: output.LetterText,
      UseScheduleText: output.UseScheduleText,
      ScheduleText: output.ScheduleText,
      LetterImage: output.LetterImage,
      ServiceUserNumber: output.ServiceUserNumber,
      ReceivingPartyIdentifyingNumber: output.ReceivingPartyIdentifyingNumber,
      CustomFormat: CustomFormat,
      CustomHeader: output.DefaultHeader,

      IncludeDebitMemos: output.IncludeDebitMemos,
      AddNewAccountsOnUpdate: output.AddNewAccountsOnUpdate,
      CollectNewTransactionsOnUpdate: output.CollectNewTransactionsOnUpdate,
      IncludeCreditMemos: output.IncludeCreditMemos,

      DateTimeUpdated: DateTimeUpdated,
      SiteID: output.SiteID,
    };

    // console.log("put");
    outputJSON["DateTimeCreated"] = output.DateTimeCreated;
    outputJSON["CompanySettingID"] = output.CompanySettingID;
    outputJSON["CompanyID"] = output.CompanyID;
    if (!output.IsActive) {
      let InactiveDate = GetDatetime();
      outputJSON["InactiveDate"] = InactiveDate;
    } else {
      outputJSON["InactiveDate"] = null;
    }
    // console.log(outputJSON);

    if (output.NewCompanyFirstLoad) {
      await trackPromise(
        axios
          .put(
            "api/SyscompanySettings/" +
              output.CompanySettingID +
              "/" +
              output.CompanyID,
            outputJSON
          )
          .then((response) => {
            this.setState({
              NewCompany: true,
              NewCompanyFirstLoad: false,
            });

            this.props.navigate("/CompanyListDetails", {
              state: {
                NewCompany: true,
                NewCompanyFirstLoad: false,
              },
            });
          })
          .catch((e) => {
            apiError("PutAPI: ", e);
            var message = apiError("PutAPI: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          })
      );
    } else {
      if (output.DataUpdated || output.NewCompany) {
        await trackPromise(
          axios
            .put(
              "api/SyscompanySettings/" +
                output.CompanySettingID +
                "/" +
                output.CompanyID,
              outputJSON
            )
            .then((response) => {
              if (!output.NewCompany) {
                var updateSuccessResult = true;
                var AddedSuccessResult = false;
              } else {
                updateSuccessResult = false;
                AddedSuccessResult = true;
              }
              this.setState({
                updateSuccess: updateSuccessResult,
                AddedSuccess: AddedSuccessResult,
                notEditableSectionTwo: true,
                notEditableSectionOne: true,
                editVisible: true,
                NewCompany: false,
                NewCompanyFirstLoad: false,
              });

              this.props.navigate("/CompanyListDetails", {
                state: {
                  NewCompany: false,
                  NewCompanyFirstLoad: false,
                  updateSuccess: updateSuccessResult,
                  AddedSuccess: AddedSuccessResult,
                },
              });
              this.resetForm();
            })
            .catch((e) => {
              apiError("PutAPI: ", e);
              var message = apiError("PutAPI: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            })
        );
      } else {
        this.setState({ NoUpdateRequired: true });
      }
    }
  }

  handleSetEdit() {
    this.setState({
      notEditableSectionOne: false,
      notEditableSectionTwo: false,
      notEditable: false,
      editVisible: false,
      updateSuccess: false,
      AddedSuccess: false,
      DataUpdated: false,
    });
  }

  renderCustomDDFormat() {
    const notEditableSectionTwo = this.state.notEditableSectionTwo;
    const sectionElementIsHidden = this.state.sectionElementIsHidden;
    return (
      <>
        {this.state.AmendableFormat && !this.state.IsFixedLength ? (
          <CustomFormat
            name="CustomFormat"
            placeholder={this.state.CustomFormat}
            DefaultFormat={this.state.DefaultFormat}
            DefaultHeader={this.state.DefaultHeader}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={8}
            required={false}
            disabled={notEditableSectionTwo}
            hidden={sectionElementIsHidden}
            errorList={this.state.errorList}
          />
        ) : !sectionElementIsHidden ? (
          <Form.Group className={"FormGroup"} as={Row} controlId="CustomFormat">
            <Form.Label column sm={LabelColumnAmount}>
              Custom Format
            </Form.Label>
            <Form.Label
              style={{ textAlign: "left" }}
              column
              sm={InputColumnAmount}
            >
              <Badge bg="danger">
                A custom DD Format cannot be set for this Bank Format
              </Badge>
            </Form.Label>
          </Form.Group>
        ) : null}
      </>
    );
  }

  renderContent() {
    const notEditableSectionOne = this.state.notEditableSectionOne;
    const notEditableSectionTwo = this.state.notEditableSectionTwo;
    const sectionElementIsHidden = this.state.sectionElementIsHidden;
    const editVisible = this.state.editVisible;
    return (
      <div className="CompanyListDetails">
        <h1>Company Settings</h1>
        <Row>
          <Col>
            <Link to="/CompanyList">
              <FaArrowCircleLeft /> Back to Company List
            </Link>
          </Col>
          <div id="sectionOne"></div>
          {editVisible &&
          (this.context.SettingsList.CanEdit.CompanySettings ||
            this.context.SettingsList.IsAdmin) ? (
            <Col className="right TooltipActionButton">
              <Button
                variant="primary"
                type="button"
                onClick={this.handleSetEdit}
              >
                <FaRegEdit /> Edit
              </Button>
            </Col>
          ) : null}
        </Row>

        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
          PostFeedback={this.state.PostFeedback}
        />

        <div className="detailsContent">
          <Form
            id="CompanyListDetailsForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <div id="sectionOne">
              <FormInputBox
                placeholder={this.state.CompanyName}
                type="text"
                Label="Company Name"
                name="CompanyName"
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionOne}
                errorList={this.state.errorList}
                schema={CompanyNameSchema}
              />

              <FormLabelbox
                id="CompanyListId"
                Label={
                  !notEditableSectionOne ? (
                    <Badge bg="danger">
                      {CompanyListLabel} can't be changed
                    </Badge>
                  ) : (
                    CompanyListLabel
                  )
                }
                name="CompanyListId"
                placeholder={this.state.EntityName}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
              />

              <FormCheck
                type="switch"
                Label="Is Active"
                name="IsActive"
                id="IsActive"
                initialState={this.state.IsActive}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionOne}
                errorList={this.state.errorList}
              />
            </div>

            <div id="sectionTwo">
              <FormDropdown
                id="DefaultReceiptsBank"
                Label="Default Receipt Bank Account"
                name="DefaultReceiptsBank"
                placeholder={this.state.DefaultReceiptsBank}
                itemContent={this.state.bankFormatList}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                required={false}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />
              {this.state.ProductName === "Sage Intacct" ? (
                <FormCheck
                  type="switch"
                  Label="Transfer Collection Total to Separate Bank"
                  name="TransferCollection"
                  id="TransferCollection"
                  initialState={this.state.TransferCollection}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditableSectionTwo}
                  hidden={sectionElementIsHidden}
                  errorList={this.state.errorList}
                />
              ) : null}
              {this.state.TransferCollection ? (
                <>
                  {this.state.TransferBankAccountList.length >= 1 ? (
                    <>
                      <FormDropdown
                        id="TransferBankAccount"
                        Label="Transfer Bank Account"
                        name="TransferBankAccount"
                        placeholder={this.state.TransferBank}
                        itemContent={this.state.TransferBankAccountList}
                        value={this.handleCallback}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                        required={false}
                        disabled={notEditableSectionTwo}
                        hidden={sectionElementIsHidden}
                        errorList={this.state.errorList}
                      />
                      {this.state.ProductName === "Sage Intacct" ? (
                        <FormDropdown
                          id="JournalType"
                          Label="Journal Type"
                          name="JournalType"
                          placeholder={this.state.JournalTypeID}
                          itemContent={this.state.JournalTypes}
                          value={this.handleCallback}
                          LabelColumnAmount={LabelColumnAmount}
                          InputColumnAmount={InputColumnAmount}
                          required={false}
                          disabled={notEditableSectionTwo}
                          hidden={sectionElementIsHidden}
                          errorList={this.state.errorList}
                          schema={JournalTypeSchema}
                        />
                      ) : null}
                      {this.state.ProductName === "Sage Intacct" &&
                      this.state.TopLevelEntity ? (
                        <FormDropdown
                          id="TransferLocation"
                          Label={"Transfer Location"}
                          name="TransferLocation"
                          placeholder={this.state.TransferLocation}
                          itemContent={this.state.TransferLocationList}
                          value={this.handleCallback}
                          LabelColumnAmount={LabelColumnAmount}
                          InputColumnAmount={InputColumnAmount}
                          required={false}
                          disabled={notEditableSectionTwo}
                          hidden={sectionElementIsHidden}
                          errorList={this.state.errorList}
                        />
                      ) : null}
                    </>
                  ) : (
                    <FormLabelbox
                      id="TransferBankAccount"
                      Label="Bank Account"
                      name="TransferBankAccount"
                      placeholder={
                        <Badge disabled={notEditableSectionTwo} bg="warning">
                          No Additional Accounts Found
                        </Badge>
                      }
                      LabelColumnAmount={LabelColumnAmount}
                      InputColumnAmount={InputColumnAmount}
                    />
                  )}
                </>
              ) : null}

              <FormCheck
                type="switch"
                Label="Use Runtime Filters"
                name="UseRuntimeFilters"
                id="UseRuntimeFilters"
                initialState={this.state.UseRuntimeFilters}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              {!this.state.UseRuntimeFilters ? (
                <>
                  <FormInputBox
                    type="text"
                    Label="Filter Field Name"
                    name="FilterFieldName"
                    placeholder={this.state.FilterFieldName}
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    required={false}
                    disabled={notEditableSectionTwo}
                    hidden={sectionElementIsHidden}
                    errorList={this.state.errorList}
                    schema={FilterFieldNameSchema}
                  />
                  {!sectionElementIsHidden ? (
                    <div className="Values">
                      <Row>
                        <Form.Label column sm={LabelColumnAmount}>
                          Filter Values
                        </Form.Label>
                        <Col sm={8}>
                          <FormValuesInput
                            type="text"
                            Label=""
                            name="FilterValue"
                            placeholder={
                              this.state.filterValueList
                                ? this.state.filterValueList.filter(Boolean)
                                : this.state.FilterValue
                            }
                            value={this.handleCallback}
                            LabelColumnAmount={LabelColumnAmount}
                            InputColumnAmount={InputColumnAmount}
                            required={false}
                            disabled={notEditableSectionTwo}
                            hidden={sectionElementIsHidden}
                            errorList={this.state.errorList}
                            schema={FilterValueSchema}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {!notEditableSectionTwo ? (
                    <Row className={"FormGroup"}>
                      <Form.Label column sm={LabelColumnAmount}>
                        Runtime Filters
                      </Form.Label>
                      <Col sm={InputColumnAmount}>
                        <RuntimeFilter
                          SiteID={this.state.SiteID}
                          CompanyID={this.state.CompanyID}
                          DataUpdated={this.handleCallback}
                          ButtonLabel={
                            <>
                              <FaInfoCircle /> View Runtime Filter List
                            </>
                          }
                        />
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}

              <FormDropdown
                id="DDOutputFormat"
                Label="DD Output Format"
                name="DDOutputFormat"
                placeholder={this.state.DDOutputFormat}
                itemContent={this.state.DDOutputFormatList}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                required={true}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              {this.renderCustomDDFormat()}

              <FormDropdown
                id="FileFormat"
                Label="Output File Format"
                name="FileFormat"
                placeholder={this.state.FileFormat}
                itemContent={this.state.FileFormatList}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                required={false}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              {this.state.DDOutputFormat === 3 ? (
                <>
                  <FormInputBox
                    type="text"
                    Label="Service User Number"
                    name="ServiceUserNumber"
                    placeholder={this.state.ServiceUserNumber}
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    required={false}
                    disabled={notEditableSectionTwo}
                    hidden={sectionElementIsHidden}
                    errorList={this.state.errorList}
                    schema={ServiceUserNumberSchema}
                  />
                  <FormInputBox
                    type="text"
                    Label="Receiving Party Identifying Number"
                    name="ReceivingPartyIdentifyingNumber"
                    placeholder={this.state.ReceivingPartyIdentifyingNumber}
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    required={false}
                    disabled={notEditableSectionTwo}
                    hidden={sectionElementIsHidden}
                    errorList={this.state.errorList}
                    schema={ReceivingPartyIdentifyingNumberSchema}
                  />
                </>
              ) : null}

              <FormCheck
                type="switch"
                Label="Summarise Output"
                name="SummariseOutput"
                id="SummariseOutput"
                initialState={this.state.SummariseOutput}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              <FormDropdown
                id="TransactionFilter"
                Label="Transaction Filter"
                name="TransactionFilter"
                placeholder={this.state.TransactionFilter}
                itemContent={this.state.TransactionFilterList}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                required={false}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              {this.state.ProductName === "Sage Intacct" ? (
                <>
                  <FormDropdown
                    id="RejectionOption"
                    Label="Select Rejection Option"
                    name="RejectionOption"
                    placeholder={this.state.RejectionOption}
                    itemContent={this.state.RejectionOptionList}
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    required={false}
                    disabled={notEditableSectionTwo}
                    hidden={sectionElementIsHidden}
                    errorList={this.state.errorList}
                  />
                </>
              ) : null}

              <FormCheck
                type="switch"
                Label="Show Transactions Outside of Date Range"
                name="ShowTransactionsOutsideDateRange"
                id="ShowTransactionsOutsideDateRange"
                initialState={this.state.ShowTransactionsOutsideDateRange}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              <FormCheck
                type="switch"
                Label="Include On Hold Accounts"
                name="IncludeOnHoldAccounts"
                id="IncludeOnHoldAccounts"
                initialState={this.state.IncludeOnHoldAccounts}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              <FormCheck
                type="switch"
                Label="Include Accounts in Credit"
                name="InCredit"
                id="InCredit"
                initialState={this.state.InCredit}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              <FormCheck
                type="switch"
                Label="Disable CompanySync"
                name="DisableDDCompanySync"
                id="DisableDDCompanySync"
                initialState={this.state.DisableDDCompanySync}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
                alertMessage={
                  <>
                    <p>
                      Disabling this setting may lead to inaccurate direct debit
                      runs. This is because the data stored for customers will
                      not be up to date.
                    </p>

                    <p>Are you sure you wish disable the setting?</p>
                  </>
                }
              />

              <FormCheck
                type="switch"
                Label="Add New Accounts on Update"
                name="AddNewAccountsOnUpdate"
                id="AddNewAccountsOnUpdate"
                initialState={this.state.AddNewAccountsOnUpdate}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />
              <FormCheck
                type="switch"
                Label="Collect New Transactions on Update"
                name="CollectNewTransactionsOnUpdate"
                id="CollectNewTransactionsOnUpdate"
                initialState={this.state.CollectNewTransactionsOnUpdate}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />
              <FormCheck
                type="switch"
                Label="Must Pass Modulus Check?"
                name="MustPassModulusCheck"
                id="MustPassModulusCheck"
                initialState={this.state.MustPassModulusCheck}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              <FormCheck
                type="switch"
                Label="Permit Notification Letters Before Commit Stage"
                name="ShowNotificationLettersOnCreated"
                id="ShowNotificationLettersOnCreated"
                initialState={this.state.ShowNotificationLettersOnCreated}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              <FormImageUpload
                MaxWidth={370}
                MaxHeight={130}
                placeholder={this.state.LetterImage}
                Label="Letter Image"
                name="LetterImage"
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                errorList={this.handleCallback}
              />

              <FormInputBox
                placeholder={this.state.LetterOpening}
                type="text"
                Label="Notification Letter Opening"
                name="LetterOpening"
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                errorList={this.state.errorList}
                schema={LetterSchema}
              />

              <FormCheck
                type="switch"
                Label="Notification Letter Text Default"
                name="UseLetterTextDefault"
                id="UseLetterTextDefault"
                initialState={this.state.UseLetterTextDefault}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              {!this.state.UseLetterTextDefault ? (
                <FormInputBox
                  placeholder={this.state.LetterText}
                  type="textarea"
                  rows={3}
                  Label="Notification Letter Text"
                  name="LetterText"
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditableSectionTwo}
                  errorList={this.state.errorList}
                  schema={LetterSchema}
                />
              ) : null}

              {this.state.ProductName === "Sage Intacct" ? (
                <>
                  <FormCheck
                    type="switch"
                    Label="Use Scheduling"
                    name="UseScheduling"
                    id="UseScheduling"
                    initialState={this.state.UseScheduling}
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    disabled={notEditableSectionTwo}
                    hidden={sectionElementIsHidden}
                    errorList={this.state.errorList}
                  />

                  {this.state.UseScheduling ? (
                    <>
                      <FormCheck
                        type="switch"
                        Label="Use Default Schedule Text"
                        name="UseScheduleText"
                        id="UseScheduleText"
                        initialState={this.state.UseScheduleText}
                        value={this.handleCallback}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                        disabled={notEditableSectionTwo}
                        hidden={sectionElementIsHidden}
                        errorList={this.state.errorList}
                      />

                      {!this.state.UseScheduleText ? (
                        <FormInputBox
                          placeholder={this.state.ScheduleText}
                          type="textarea"
                          rows={3}
                          Label="Schedule Letter Text"
                          name="ScheduleText"
                          value={this.handleCallback}
                          LabelColumnAmount={LabelColumnAmount}
                          InputColumnAmount={InputColumnAmount}
                          disabled={notEditableSectionTwo}
                          errorList={this.state.errorList}
                          schema={LetterSchema}
                        />
                      ) : null}
                    </>
                  ) : null}

                  {this.state.UseScheduling ? (
                    !this.state.ScheduleCollectionTypeID ? (
                      <Row className="FormGroup">
                        <Form.Label column sm={LabelColumnAmount}>
                          Schedule Collection Type
                        </Form.Label>
                        <Col sm={InputColumnAmount}>
                          <ToggleButton
                            id="ScheduleCollectionTypeID"
                            variant="primary"
                            type="checkbox"
                            className="form-control EmptyButton"
                            checked={0}
                            disabled={notEditableSectionTwo}
                            value=""
                            onChange={(e) =>
                              this.setState({
                                ScheduleCollectionTypeID:
                                  e.currentTarget.checked,
                                NoUpdateRequired: false,
                                DataUpdated: true,
                              })
                            }
                          />
                        </Col>
                      </Row>
                    ) : (
                      <FormDropdown
                        id="ScheduleCollectionTypeID"
                        Label="Schedule Collection Type"
                        name="ScheduleCollectionTypeID"
                        placeholder={this.state.ScheduleCollectionTypeID}
                        itemContent={this.state.ScheduleCollectionTypes}
                        value={this.handleCallback}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                        required={false}
                        disabled={notEditableSectionTwo}
                        hidden={sectionElementIsHidden}
                        errorList={this.state.errorList}
                      />
                    )
                  ) : null}
                </>
              ) : null}

              {!sectionElementIsHidden ? (
                <div className="CheckSection">
                  <Row>
                    <Col sm={LabelColumnAmount} className="right">
                      Transaction Types to Retrieve
                    </Col>
                    <Col className="CheckSectionContent" sm={3}>
                      {this.state.TransactionTypeGroups.map(
                        (TransactionTypeGroups, i) => (
                          <FormCheck
                            key={i}
                            type="switch"
                            initialState={
                              this.state[`Include${TransactionTypeGroups}`]
                            }
                            Label={TransactionTypeGroups}
                            name={"Include" + TransactionTypeGroups}
                            id={"Include" + TransactionTypeGroups}
                            value={this.handleCallback}
                            disabled={notEditableSectionTwo}
                            hidden={sectionElementIsHidden}
                            errorList={this.state.errorList}
                          />
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              ) : null}

              <FormCheck
                type="switch"
                Label="User Post Override"
                name="UserPostOverride"
                id="UserPostOverride"
                initialState={this.state.UserPostOverride}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditableSectionTwo}
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
              />

              {!editVisible ? (
                <>
                  <div className="row BlankMargin"></div>
                  <div className="FormSave">
                    <Row>
                      <Col sm={LabelColumnAmount}></Col>
                      <Col sm={8} className="IconBtn">
                        <Button variant="primary" type="submit" name="Submit">
                          <FaSave /> Save
                        </Button>
                        {this.state.NoUpdateRequired ? DataNotChanged : null}
                        {this.state.validationErrorList
                          ? ValidationError(this.state.validationErrorList)
                          : null}
                        {!this.state.NewCompany ? (
                          <Button
                            variant="primary"
                            onClick={this.handleFormReset}
                          >
                            <FaTimesCircle /> Cancel
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </>
              ) : null}
            </div>
          </Form>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.NoData) {
      return <IsError errorList={this.state.errorList} />;
    } else {
      if (!this.state.CompanyLists) {
        return null;
      } else {
        return this.renderContent();
      }
    }
  }
}
CompanyListDetails.contextType = AppContext;
export default withRouter(CompanyListDetails);
