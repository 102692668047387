import React, { useState, useEffect } from "react";
import * as yup from "yup";
import BootstrapTable from "../react-bootstrap-table2";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import { SetObserver } from "../../libs/Observer";
import OverlayFormatter from "../../libs/OverlayFormatter";
import CheckIfTicked from "../../libs/CheckIfTicked";
import { handleValidation } from "../handleValidation";
import { inputRegex, inputRegexMessage } from "../../libs/Variables";
const RefSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, inputRegexMessage)
    .max(60, "Ref must be less than 60 characters"),
  ReturnCodeSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, inputRegexMessage)
    .max(20, "Return Code must be less than 20 characters"),
  ReturnDescriptionSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, inputRegexMessage)
    .max(50, "Return Description must be less than 50 characters");

const ARUDDImportTable = (props) => {
  const [RowData, setRowData] = useState();
  const [SelectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setRowData(props.rows);
    SetObserver();
  }, [props.rows]);

  if (!RowData) {
    return null;
  } else {
    let tickColumn = [
      {
        dataField: "Tick",
        text: "",
        headerFormatter: () => {
          var output = `Select ${SelectAll ? "None" : "All"}`;

          return OverlayFormatter(
            CheckIfTicked(SelectAll, true),
            "HeaderElement",
            output
          );
        },
        headerEvents: {
          onClick: () => {
            setSelectAll(!SelectAll);
            let newRowData = [...RowData];

            RowData.forEach((element) => {
              if (element.Status === "Reverse?") {
                var index = RowData.findIndex(
                  (i) => i.TableKey === element.TableKey
                );
                let item = { ...element, Tick: !SelectAll };
                newRowData[index] = item;
              }
            });
            setRowData(newRowData);
            props.ForSubmit("ForSubmit", newRowData);
          },
        },
        isDummyField: true,
        headerClasses: "TickedCol center",
        classes: "center",
        editable: false,
        formatter: (cellContent, row) => {
          let ShowTickBox = false;
          if (row.Status === "Reverse?") {
            ShowTickBox = true;
          }
          return CheckIfTicked(row.Tick, ShowTickBox);
        },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            if (row.Status === "Reverse?") {
              var index = RowData.findIndex((i) => i.TableKey === row.TableKey);
              let Ticked = !RowData[index].Tick;
              // 1. Make a shallow copy of the items
              let newRowData = [...RowData];
              // 2. Make a shallow copy of the item you want to mutate and Replace the property you're intested in
              let item = { ...newRowData[index], Tick: Ticked };
              // 3. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
              newRowData[index] = item;
              // 4. Set the state to our new copy
              setRowData(newRowData);
              props.ForSubmit("ForSubmit", newRowData);
            }
          },
        },
      },
    ];

    let columns = [
      {
        dataField: "Status",
        text: "Status",
        sort: true,
        editable: false,
        filter: textFilter({ placeholder: " " }),
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
      {
        dataField: "ACRef",
        text: "A/C Ref",
        sort: true,
        editable: false,
        filter: textFilter({ placeholder: " " }),
      },
      {
        dataField: "ACName",
        text: "A/C Name",
        sort: true,
        editable: false,
        filter: textFilter({ placeholder: " " }),
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
      {
        dataField: "Ref",
        text: "Ref",
        sort: true,
        editable: props.IsConsoleImport ? true : false,
        filter: textFilter({ placeholder: " " }),
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
      {
        dataField: "ReturnCode",
        text: "Return Code",
        sort: true,
        editable: props.IsConsoleImport ? true : false,
        filter: textFilter({ placeholder: " " }),
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
      {
        dataField: "ReturnDescription",
        text: "Return Description",
        sort: true,
        editable: props.IsConsoleImport ? true : false,
        filter: textFilter({ placeholder: " " }),
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
      {
        dataField: "Date",
        text: "Date",
        sort: true,
        editable: false,
        filter: textFilter({ placeholder: " " }),
      },
      {
        dataField: "Value",
        text: "Value",
        sort: true,
        editable: false,
        filter: textFilter({ placeholder: " " }),
        formatter: (cellContent, row) => {
          return (
            <div className="CollectionTotal">
              <div>
                {/* {row.CurrencySymbol} */}
                {parseFloat(cellContent).toFixed(2)}
              </div>
            </div>
          );
        },
      },
    ];
    let showTicked;
    RowData.forEach((element) => {
      if (element.Status === "Reverse?") {
        showTicked = true;
        return;
      }
    });

    if (showTicked) {
      columns = tickColumn.concat(columns);
    }

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
    let ARUDDListPage = 1;
    if (sessionStorage.getItem("ARUDDListPage")) {
      ARUDDListPage = sessionStorage.getItem("ARUDDListPage");
    }
    const paginationOptions = {
      page: parseInt(ARUDDListPage),
      onPageChange: (page) => {
        sessionStorage.setItem("ARUDDListPage", page);
      },
      hidePageListOnlyOnePage: true,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "All",
          value: RowData.length,
        },
      ],
    };

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (row.Status === "Reverse?" && !props.IsConsoleImport) {
          var index = RowData.findIndex((i) => i.TableKey === row.TableKey);
          let Ticked = !RowData[index].Tick;
          // 1. Make a shallow copy of the items
          let newRowData = [...RowData];
          // 2. Make a shallow copy of the item you want to mutate and Replace the property you're intested in
          let item = { ...newRowData[index], Tick: Ticked };
          // 3. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
          newRowData[index] = item;
          // 4. Set the state to our new copy
          setRowData(newRowData);

          props.ForSubmit("ForSubmit", newRowData);
        }
      },
    };

    const defaultSorted = [
      {
        dataField: "Status",
        order: "desc",
      },
    ];

    const validateForm = (name, data) => {
      return new Promise(async (resolve, reject) => {
        let items = [];
        switch (name.concat("Schema")) {
          case "RefSchema":
            items.push({
              name: name,
              data: data,
              schema: RefSchema,
            });
            break;
          case "ReturnCodeSchema":
            items.push({
              name: name,
              data: data,
              schema: ReturnCodeSchema,
            });
            break;
          case "ReturnDescriptionSchema":
            items.push({
              name: name,
              data: data,
              schema: ReturnDescriptionSchema,
            });
            break;

          default:
            break;
        }

        var Status = [];

        items.forEach(async (element, i) => {
          Status[i] = false;
          await handleValidation(element)
            .then(() => {
              Status[i] = true;
            })
            .catch((e) => {
              reject(e);
            });
          if (!Status.includes(false)) {
            resolve(true);
          }
        });
      });
    };

    return (
      <div className="ARUDDTable">
        <BootstrapTable
          bootstrap4
          responsive
          hover
          classes="ARUDDImportTable"
          rowClasses={(row) => {
            if (row.Status === "Reverse?") {
              return "ShowHover";
            }
          }}
          keyField="TableKey"
          data={RowData}
          rowEvents={rowEvents}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(paginationOptions)}
          defaultSorted={defaultSorted}
          cellEdit={cellEditFactory({
            mode: "click",
            blurToSave: true,
            autoSelectText: true,
            timeToCloseMessage: 8000,
            beforeSaveCell(oldValue, newValue, row, column, done) {
              setTimeout(() => {
                switch (column.dataField) {
                  case "Ref":
                  case "ReturnCode":
                  case "ReturnDescription":
                    validateForm(column.dataField, newValue)
                      .then(() => {
                        done();
                      })
                      .catch((e) => {
                        // console.log(e);
                        props.ValidationErrorList("ValidationErrorList", e);
                        done(false);
                      });
                    break;

                  default:
                    break;
                }
              }, 0);
              return { async: true };
            },
            afterSaveCell: (oldValue, newValue, row, column) => {
              if (newValue !== oldValue) {
                if (row.Status === "Reverse?") {
                  var index = RowData.findIndex(
                    (i) => i.TableKey === row.TableKey
                  );
                  // 1. Make a shallow copy of the items
                  let newRowData = [...RowData];
                  // 2. Make a shallow copy of the item you want to mutate and Replace the property you're intested in
                  let item = {
                    ...newRowData[index],
                    [column.dataField]: newValue,
                  };
                  // 3. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                  newRowData[index] = item;
                  // 4. Set the state to our new copy
                  setRowData(newRowData);
                  props.ForSubmit("ForSubmit", newRowData);
                }
              }
            },
          })}
        />
      </div>
    );
  }
};

export default ARUDDImportTable;
