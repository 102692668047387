import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import apiError from "../../libs/apiError";
import { Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import GetDatetime from "../../libs/GetDatetime";

export default function RemoveListItem(navigate, DDListHeaderID) {
  let DateTimeUpdated = GetDatetime();

  let outputJSON = {
    DDListHeaderID: DDListHeaderID,
    DateTimeUpdated: DateTimeUpdated,
  };

  confirmAlert({
    closeOnEscape: true,
    closeOnClickOutside: true,
    customUI: ({ onClose }) => {
      return (
        <div className="ConfirmAlert">
          <div className="AlertBody">
            <h1>Confirm Removal</h1>
            Are you sure you wish to do this?
            <div className="ButtonGroup">
              <Button
                onClick={async () => {
                  await trackPromise(
                    axios
                      .put("api/DdlistHeaders/RemoveDDHeaderPut/", outputJSON)
                      .then(() => {
                        sessionStorage.setItem("DDRun", null);
                        sessionStorage.setItem("NewListItem", true);
                        navigate("/DDList");
                      })
                      .catch((e) => {
                        var message = apiError("setDDlistCustomerSummary: ", e);
                        apiError("RemoveDDHeaderPutAPI: ", message);
                        console.log(message);
                      })
                  );
                  onClose();
                }}
              >
                Yes
              </Button>
              <Button onClick={onClose}>No</Button>
            </div>
          </div>
        </div>
      );
    },
  });
}
