import * as yup from "yup";
import validator from "validator";
import { ipRegexMessage } from "../libs/Variables";
const ibantools = require("ibantools");

export function handleValidation(items) {
  return new Promise((resolve, reject) => {
    let name = items.name,
      data = items.data,
      schema = items.schema;

    yup
      .object()
      .shape({ [name]: schema })
      .validate(
        {
          [name]: data,
        },
        { abortEarly: true }
      )
      .then(() => {
        var pattIPAddressFrom = new RegExp("IPAddressFrom"),
          pattIPAddressTo = new RegExp("IPAddressTo"),
          pattIPIBAN = new RegExp("IBAN"),
          pattBicSwiftNumber = new RegExp("BicSwiftNumber"),
          pattCIDRRange = new RegExp("CIDRRange");

        if (pattIPAddressFrom.test(name) || pattIPAddressTo.test(name)) {
          if (data === null || data === "" || data === undefined) {
            resolve(true);
          } else {
            if (validator.isIP(data, 4) || validator.isIP(data, 6)) {
              resolve(true);
            } else {
              throw new Error(name + ipRegexMessage);
            }
          }
        } else if (pattCIDRRange.test(name)) {
          if (data === null || data === "" || data === undefined) {
            resolve(true);
          } else {
            if (validator.isIPRange(data, 4) || validator.isIPRange(data, 6)) {
              resolve(true);
            } else {
              throw new Error(name + ipRegexMessage);
            }
          }
        } else if (pattIPIBAN.test(name)) {
          if (ibantools.isValidIBAN(data)) {
            resolve(true);
          } else {
            throw new Error("IBAN Check Failed.");
          }
        } else if (pattBicSwiftNumber.test(name)) {
          if (ibantools.isValidBIC(data)) {
            resolve(true);
          } else {
            throw new Error("BIC Check Failed.");
          }
        } else {
          resolve(true);
        }
      })
      .catch((e) => {
        // console.log(e);
        var message = {};
        message[name] = e.message;
        reject(message);
      });
  });
}
