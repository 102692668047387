import React, { Component } from "react";
import OverlayFormatter from "../../libs/OverlayFormatter";
import { Row, Col } from "react-bootstrap";
import { PaginationDisplay } from "../PaginationComponents";

class BankDetailsImportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let columns = [
      {
        dataField: "AccountReference",
        text: "Account Reference",
      },
      {
        dataField: "Row",
        text: "Row",
      },
      {
        dataField: "message",
        text: this.props.Label,
        formatter: (cellContent, row) => {
          return OverlayFormatter(cellContent, row);
        },
      },
    ];
    let SortedData = this.props.TableContent.sort((a, b) =>
      a.AccountReference > b.AccountReference
        ? 1
        : b.AccountReference > a.AccountReference
        ? -1
        : 0
    );
    const sizePerPage = 10,
      defaultSorted = [
        {
          dataField: "AccountReference",
          order: "asc",
        },
      ];
    return (
      <Row>
        <Col sm="1">
          <p>{this.props.Label} list:</p>
        </Col>
        <Col>
          {PaginationDisplay(
            defaultSorted,
            sizePerPage,
            "id",
            columns,
            SortedData
          )}
        </Col>
      </Row>
    );
  }
}

export default BankDetailsImportTable;
