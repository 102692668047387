import React, { Component } from "react";
import { AppContext } from "../../libs/contextLib";
import * as yup from "yup";
import { trackPromise } from "react-promise-tracker";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import FormInputBox from "../Form/FormInputbox";
import FormLabelbox from "../Form/FormLabelbox";
// import FormDropdown from "../Form/FormDropdown";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
} from "../../libs/Variables";
import axios from "axios";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import OutputMessage from "../OutputMessage";
import { GetSageLogin } from "../Login/SageLogin";
import { handleValidation } from "../handleValidation";

const SiteNameSchema = yup
    .string()
    .required("SiteName is required")
    .max(100, "SiteName must be less than 100 characters"),
  CompanyIDSchema = yup
    .string()
    .required("Company ID is required")
    .max(100, "Company ID must be less than 100 characters"),
  schemaEmail = yup
    .string()
    .required("Email is required")
    .email("Must be a valid email address")
    .max(100, "Email must be less than 100 characters"),
  UserIDSchema = yup
    .string()
    .required("User ID is required")
    .max(250, "User ID must be less than 250 characters"),
  UserPasswordSchema = yup
    .string()
    .required("User Password is required")
    .max(70, "User Password must be less than 70 characters");

let blankState = {
  PlaceholderListFilled: false,
  CompanyID: "",

  IsActive: true,
  errorList: [],

  DateTimeUpdated: "",
  DateTimeCreated: "",
};

class NewSiteRequest extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.GoSageLogin = this.GoSageLogin.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.context.SageAccessToken &&
      this.state.SageSiteID === undefined &&
      this.context.INTACCT_API_BASE_URL
    ) {
      await trackPromise(
        Promise.all([
          axios
            .get(
              this.context.INTACCT_API_BASE_URL +
                "/api/SiteSettings/GetSageSiteID/?&accessToken=" +
                this.context.SageAccessToken,
              {
                crossdomain: true,
              }
            )
            .catch((e) => {
              var message = apiError("API GetSageSiteID Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
        ])
          .then((responses) => {
            this.setState({
              SageSiteID: responses[0].data.siteID,
              SageSiteName: responses[0].data.siteName,
            });
          })
          .catch((e) => {
            var message = apiError("API GetSageSiteID Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          })
      );
    }
  }

  async loadData() {
    let params = this.props.location.state,
      PostFeedback;
    if (params !== undefined && params) {
      PostFeedback = params.PostFeedback;
    }

    await trackPromise(
      Promise.all([
        axios.get("/api/SysproductLists/").catch((e) => {
          var message = apiError("API Syscompanies Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        }),
      ])
        .then((responses) => {
          this.setState({
            ProductList: responses[0].data,
            ProductName: responses[0].data[1].content,
            ProductID: responses[0].data[1].id,
            PlaceholderListFilled: true,
            PostFeedback,
          });
        })
        .catch((e) => {
          var message = apiError("API New DDRun Get: ", e);
          this.setState((previousState) => ({
            NoData: true,
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  handleCallback = (name, theData) => {
    this.setState((state) => {
      var pattUserPassword = new RegExp("UserPassword");
      if (pattUserPassword.test(name)) {
        state[name] = theData;
      } else {
        state[name] = theData;
      }
      return state;
    });
  };

  productSwitch() {
    switch (this.state.ProductName) {
      case "1":
        return this.renderSage50();
      case "2":
        return this.renderSage200();
      case "3":
        return this.renderIntacct();
      default:
        return this.renderSageStandard();
    }
  }

  renderSage50() {
    return (
      <>
        {/* <h2>Sage 50 Credentials</h2> */}
        <div className="detailsContent"></div>
      </>
    );
  }

  renderSage200() {
    return (
      <>
        {/* <h2>Sage 200 Credentials</h2> */}
        <div className="detailsContent"></div>
      </>
    );
  }

  GoSageLogin(e) {
    e.preventDefault();
    GetSageLogin(this.context.SAGE_CLIENT_ID);
  }

  renderSageStandard() {
    return (
      <>
        {!this.context.SageAccessToken ? (
          <>
            <FormLabelbox
              type="text"
              Label="In order to create your new site, we need a Site ID from Sage."
              name=""
              placeholder=""
              LabelColumnAmount={6}
              InputColumnAmount={6}
            />
            <div className="FormGroup row">
              <Col className="form-label col-form-label" sm={LabelColumnAmount}>
                Please therefore log into Sage:
              </Col>
              <Col sm={InputColumnAmount}>
                <Button variant="primary" onClick={this.GoSageLogin}>
                  Login into Sage
                </Button>
              </Col>
            </div>
          </>
        ) : (
          <>
            <FormInputBox
              type="text"
              Label="Site Name"
              name="SiteName"
              placeholder={this.state.SiteName}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
              schema={SiteNameSchema}
            />
            <FormInputBox
              type="text"
              Label="Your Email Address"
              name="Email"
              placeholder={this.state.Email}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
              schema={SiteNameSchema}
            />
            <FormLabelbox
              type="text"
              Label="Sage Site Name"
              name="SageSiteName"
              placeholder={this.state.SageSiteName}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />
            <FormLabelbox
              type="text"
              Label="Sage SiteID"
              name="SageSiteID"
              placeholder={this.state.SageSiteID}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />
          </>
        )}
      </>
    );
  }

  renderIntacct() {
    const notEditable = this.state.notEditable;
    return (
      <>
        <FormInputBox
          type="text"
          Label="Site Name"
          name="SiteName"
          placeholder={this.state.SiteName}
          value={this.handleCallback}
          LabelColumnAmount={LabelColumnAmount}
          InputColumnAmount={InputColumnAmount}
          errorList={this.state.errorList}
          schema={SiteNameSchema}
        />
        <h2>Intacct Credentials</h2>

        <div className="detailsContent">
          <FormInputBox
            type="text"
            Label="Company ID"
            name="CompanyID"
            placeholder={this.state.CompanyID}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            disabled={notEditable}
            errorList={this.state.errorList}
            schema={CompanyIDSchema}
          />

          <FormInputBox
            type="text"
            Label="User ID"
            name="UserID"
            placeholder={this.state.UserID}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            disabled={notEditable}
            errorList={this.state.errorList}
            schema={UserIDSchema}
            autoComplete="new-user"
          />

          <FormInputBox
            type="password"
            Label="User Password"
            name="UserPassword"
            placeholder={this.state.UserPassword}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            disabled={notEditable}
            errorList={this.state.errorList}
            schema={UserPasswordSchema}
            autoComplete="new-password"
          />
        </div>
      </>
    );
  }

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items;
      if (this.state.ProductName === 3) {
        items = [
          {
            name: "SiteName",
            data: this.state.SiteName,
            schema: SiteNameSchema,
          },
          {
            name: "CompanyID",
            data: this.state.CompanyID,
            schema: CompanyIDSchema,
          },
          {
            name: "UserID",
            data: this.state.UserID,
            schema: UserIDSchema,
          },
          {
            name: "UserPassword",
            data: this.state.UserPassword,
            schema: UserPasswordSchema,
          },
        ];
      } else {
        items = [
          {
            name: "SiteName",
            data: this.state.SiteName,
            schema: SiteNameSchema,
          },
          {
            name: "Email",
            data: this.state.Email,
            schema: schemaEmail,
          },
        ];
      }

      var Status = [];
      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.validateForm()
      .then((response) => {
        // console.log(response);
        this.setState({ errorList: "" }, () => {
          this.doSubmit();
        });
      })
      .catch((e) => {
        this.setState({
          validated: false,
          validationErrorList: JSON.stringify(e),
        });
      });
  }

  async doSubmit() {
    let output = this.state;

    let outputJSON = {
      Email: output.Email,
      ProductName: output.ProductName,
      SiteName: output.SiteName,
      SageSiteID: output.SageSiteID,
      SageSiteName: output.SageSiteName,
    };

    // console.log(outputJSON);
    await trackPromise(
      axios
        .put("api/SiteSettings/SendNewSiteRequest", outputJSON)
        .then(async (responses) => {
          if (responses) {
            this.props.navigate("/NewSiteRequest", {
              state: {
                PostFeedback: [responses.data],
              },
            });
            this.resetForm();
          }
        })
        .catch((e) => {
          console.log(e.message);
          var message = apiError("PostAPI: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        })
    );
  }

  handleFormReset(e) {
    e.preventDefault();
    this.resetForm();
  }
  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState(
      { ...stateReset, ...blankState, SageSiteID: null, SageSiteName: null },
      () => {
        this.context.setSageAccessToken("");
        this.loadData();
      }
    );
  }

  renderContent() {
    return (
      <div className="NewSiteRequest">
        <h1>New Site Request</h1>

        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
          PostFeedback={this.state.PostFeedback}
        />

        <div className="detailsContent">
          <Form
            id="NewSiteForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            {/* <FormLabelbox
              type="text"
              Label="Product Name"
              name="ProductName"
              placeholder={this.state.ProductList[1].content}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            /> */}

            {/* <FormDropdown
              id="ProductName"
              Label="Sage Application"
              name="ProductName"
              placeholder={this.state.ProductName}
              itemContent={this.state.ProductList}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
            /> */}

            {this.productSwitch()}

            {this.state.ProductName !== 3 &&
            this.state.SageSiteID !== null &&
            this.state.SageSiteID !== undefined ? (
              <>
                <Row>
                  <Col sm={LabelColumnAmount}></Col>
                  <Col sm={4} className="IconBtn">
                    <Button variant="primary" type="submit">
                      <FiSend /> Send
                    </Button>
                    {this.state.authErrorList ? (
                      <div className="invalid-tooltip">
                        {this.state.authErrorList}
                      </div>
                    ) : null}
                    {this.state.validationErrorList
                      ? ValidationError(this.state.validationErrorList)
                      : null}

                    <Button variant="primary" onClick={this.handleFormReset}>
                      <FaTimesCircle /> Cancel
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
          </Form>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.PlaceholderListFilled) {
      return null;
    } else {
      return this.renderContent();
    }
  }
}

NewSiteRequest.contextType = AppContext;
export default withRouter(NewSiteRequest);
