import { FaFileDownload } from "react-icons/fa";
import { Form, Button } from "react-bootstrap";
import FormRadio from "../../Form/FormRadio";
import FormCheck from "../../Form/FormCheck";
import { LabelColumnAmount, InputColumnAmount } from "../../../libs/Variables";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

let OutputType = "PDF",
  ShowZeros = false;

function ReportSetup() {
  const handleCallback = (name, theData) => {
    // console.log(name, theData);
    if (name === "ShowZeros") {
      ShowZeros = theData;
    } else if (name === "OutputType") {
      OutputType = theData;
    }
  };

  return new Promise((resolve, reject) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className="ConfirmAlert ReportSetup">
            <div className="AlertBody">
              <h1>Report Setup</h1>
              <Form id="ReportSetupForm">
                <FormRadio
                  Label="Output Type"
                  name="OutputType"
                  id="OutputType"
                  valueList={[{ name: "PDF" }, { name: "CSV" }]}
                  initialState={"PDF"}
                  value={handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                />
                <FormCheck
                  type="switch"
                  Label="Show Zeros?"
                  name="ShowZeros"
                  id="ShowZeros"
                  initialState={ShowZeros}
                  value={handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                />
                <div className="ButtonGroup">
                  <Button
                    onClick={() => {
                      resolve({
                        OutputType: OutputType,
                        ShowZeros: ShowZeros,
                      });
                      OutputType = "PDF";
                      ShowZeros = false;
                      onClose();
                    }}
                  >
                    <FaFileDownload /> Download Report
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        );
      },
    });
  });
}

export default ReportSetup;
