import React, { Component } from "react";
import { AppContext } from "../../libs/contextLib";
import moment from "moment";
import { Row, Col, Form } from "react-bootstrap";
import BootstrapTable from "../react-bootstrap-table2";
import filterFactory, {
  multiSelectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import CheckIfValueReturned from "../../libs/CheckIfValueReturned";
import OverlayFormatter from "../../libs/OverlayFormatter";
import paginationFactory from "react-bootstrap-table2-paginator";
import FormCheck from "../Form/FormCheck";
import { SetObserver } from "../../libs/Observer";
import withRouter from "../../libs/withRouter";
import TooltipActionButton from "../TooltipActionButton";
import NoTextFormatter from "../NoTextFormatter";

let StatusDefaultValue = [
    "Awaiting File Download",
    "Awaiting Posting",
    "Committed",
    "Created",
    "Fetching from Sage",
    "Posting in Progress",
  ],
  StatusLabels = [
    "Awaiting File Download",
    "Awaiting Posting",
    "Committed",
    "Created",
    "Fetching from Sage",
    "Posting in Progress",
    "Complete",
  ],
  StatusFilter;

class DDListTable extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = { ShowCompleted: false };
    // this.HandleRemoveListItem = this.HandleRemoveListItem.bind(this);
  }

  componentDidUpdate() {
    SetObserver();
  }

  // HandleRemoveListItem(e, row) {
  //   RemoveListItem(this.props.navigate, row.DDListHeaderID);
  // }

  renderAddNew() {
    if (this.context.SettingsList.length !== 0) {
      if (
        this.context.SettingsList.CanAdd.DDRuns ||
        this.context.SettingsList.IsAdmin
      ) {
        return (
          <TooltipActionButton
            LinkTo={{ pathname: `/DDRunNew`, state: { NewDDRun: true } }}
            ToolTipLabel="Add New Direct Debit Run"
            Label="Add New"
          />
        );
      } else return null;
    } else return null;
  }

  handleCallback = (name, theData) => {
    // console.log(name);
    // console.log(theData);

    if (theData) {
      StatusFilter(StatusLabels);
    } else {
      StatusFilter(StatusDefaultValue);
    }

    this.setState((state) => {
      state[name] = theData;
      return state;
    });
  };

  render() {
    if (this.props.rows && this.props.DDListStatuses) {
      let selectOptions = this.props.DDListStatuses;

      let columns = [
        {
          dataField: "company",
          text: "Company",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
        {
          dataField: "collectionListName",
          text: "DD Run Name",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },
        {
          dataField: "collectionDate",
          text: "Collection Date",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          formatter: (cellContent, row) => {
            return moment(cellContent).format("DD-MM-YYYY");
          },
        },
        {
          dataField: "status",
          text: "Status",
          sort: true,
          filter: multiSelectFilter({
            options: selectOptions,
            defaultValue: StatusDefaultValue,
            getFilter: (filter) => {
              StatusFilter = filter;
            },
          }),
          headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
          ) => {
            return (
              <>
                {column.text}
                {sortElement}
                <div style={{ height: "38px" }}>{filterElement} </div>
              </>
            );
          },
        },

        {
          dataField: "postedToSage",
          text: "Posted To Sage",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          headerClasses: "ButtonCol center",
          classes: "center",
          formatter: (cellContent, row) =>
            CheckIfValueReturned(row.postedToSage),
        },
        {
          dataField: "outputFileGenerated",
          text: "Output File Generated",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          headerClasses: "ButtonCol center",
          classes: "center",
          formatter: (cellContent, row) =>
            CheckIfValueReturned(row.outputFileGenerated),
        },

        {
          dataField: "collectionTotal",
          text: "Collection Total",
          sort: true,
          headerFormatter: NoTextFormatter,
          headerAlign: "right",
          align: "right",
          formatter: (cellContent, row) => {
            return (
              <div className="CollectionTotal">
                <div>
                  {row.currency}
                  {parseFloat(cellContent).toFixed(2)}
                </div>
              </div>
            );
          },
        },
      ];

      // let RemoveAccountColumn = [
      //   {
      //     dataField: "RemoveAccount",
      //     text: "",
      //     editable: false,
      //     headerAlign: "center",
      //     align: "center",
      //     isDummyField: true,
      //     headerClasses: "ButtonCol",
      //     formatter: (cellContent, row) => {
      //       return (
      //         <OverlayTrigger
      //           placement="left"
      //           overlay={
      //             <Tooltip id="RemoveAccountInfoTooltip" className="">
      //               Remove DDRun Item
      //             </Tooltip>
      //           }
      //         >
      //           <Button
      //             className="ButtonRemove"
      //             onClick={(e) => this.HandleRemoveListItem(e, row)}
      //           >
      //             <FaTimesCircle />
      //           </Button>
      //         </OverlayTrigger>
      //       );
      //     },
      //   },
      // ];

      // if (
      //   this.context.SettingsList.CanDelete.DDAccounts ||
      //   this.context.SettingsList.IsAdmin
      // ) {
      //   var ColumnList = columns.concat(RemoveAccountColumn);
      // } else {
      var ColumnList = columns;
      // }

      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {size} Results
        </span>
      );
      let DDListPage = 1;
      if (sessionStorage.getItem("DDListPage")) {
        DDListPage = sessionStorage.getItem("DDListPage");
      }
      const paginationOptions = {
        page: parseInt(DDListPage),
        onPageChange: (page) => {
          sessionStorage.setItem("DDListPage", page);
        },
        hidePageListOnlyOnePage: true,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
          {
            text: "10",
            value: 10,
          },
          {
            text: "50",
            value: 50,
          },
          {
            text: "100",
            value: 100,
          },
          {
            text: "All",
            value: this.props.rows.length,
          },
        ],
      };

      const rowEvents = {
        onClick: (e, row, rowIndex) => {
          let DDRunState = {
            HeaderID: row.ddListHeaderID,
            CompanyID: row.companyID,
          };
          sessionStorage.setItem("DDRun", JSON.stringify(DDRunState));
          this.props.navigate("/DDRun", { state: { NewDDRun: false } });
        },
      };

      const defaultSorted = [
        {
          dataField: "company",
          order: "asc",
        },
      ];

      let AllStatusesAreCompleted = true;
      this.props.rows.forEach((element) => {
        if (element.status !== "Complete") {
          AllStatusesAreCompleted = false;
        }
      });

      return (
        <div className="DDListTable">
          <Form>
            <FormCheck
              type="switch"
              Label="Show Completed"
              name="ShowCompleted"
              id="ShowCompleted"
              initialState={this.state.ShowCompleted}
              value={this.handleCallback}
              LabelColumnAmount={11}
              InputColumnAmount={1}
              errorList={this.state.errorList}
            />
          </Form>
          <BootstrapTable
            classes={
              !this.state.ShowCompleted && AllStatusesAreCompleted
                ? "ShowCompleted"
                : ""
            }
            bootstrap4
            responsive
            hover
            keyField="ddListHeaderID"
            data={this.props.rows}
            rowEvents={rowEvents}
            columns={ColumnList}
            filter={filterFactory()}
            pagination={paginationFactory(paginationOptions)}
            defaultSorted={defaultSorted}
          />
          {this.renderAddNew()}
        </div>
      );
    } else {
      return (
        <Row>
          <Col>
            {this.props.apiReturn[0] === "No Active DD Runs Found" ? (
              <>
                <p>{this.props.apiReturn}</p>
                <div className="noData">{this.renderAddNew()}</div>
              </>
            ) : (
              <p> {this.props.apiReturn}</p>
            )}
          </Col>
        </Row>
      );
    }
  }
}
DDListTable.contextType = AppContext;
export default withRouter(DDListTable);
