import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { FiCircle } from "react-icons/fi";
import { FaRegDotCircle } from "react-icons/fa";

class FormRadio extends Component {
  constructor(props) {
    super(props);
    this.state = { name: this.props.initialState };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ name: e });
    this.props.value(this.props.id, e);
  }
  render() {
    return !this.props.hidden ? (
      <Form.Group className={"FormGroup"} as={Row} controlId={this.props.name}>
        <Form.Label column sm={this.props.LabelColumnAmount}>
          {this.props.Label}
        </Form.Label>
        <Col sm={this.props.InputColumnAmount}>
          <ToggleButtonGroup
            type="radio"
            name="type"
            value={this.state.name}
            onChange={this.handleChange}
          >
            {this.props.valueList.map((radio, i) => (
              <ToggleButton id={i} key={i} value={radio.name}>
                <>
                  {radio.name === this.state.name ? (
                    <FaRegDotCircle />
                  ) : (
                    <FiCircle />
                  )}
                </>
                <>&nbsp;{radio.name}</>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Col>
      </Form.Group>
    ) : null;
  }
}

export default FormRadio;
