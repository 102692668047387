import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaHandPointRight, FaTimesCircle } from "react-icons/fa";
import { Row, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import apiError from "../../../libs/apiError";
import BootstrapTable from "../../react-bootstrap-table2";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import { SetObserver } from "../../../libs/Observer";
import RemoveRuntimeFilter from "./RemoveRuntimeFilter";
import NewRuntimeFilter from "./NewRuntimeFilter";
import RuntimeFilterDetails from "./RuntimeFilterDetails";
import OutputMessage from "../../OutputMessage";
import { handlePrevPage, handleNextPage } from "../../PaginationComponents";

const sizePerPage = 10;

let blankState = {
  open: false,
  errorList: [],
  RemovalSuccess: null,
  updateSuccess: null,
  AddedSuccess: null,
  DisablePopup: false,
};
class RuntimeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = blankState;
  }
  componentDidMount() {
    this.loadData();
    SetObserver();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.AddedSuccess !== null ||
      this.state.RemovalSuccess !== null ||
      this.state.updateSuccess !== null
    ) {
      this.loadData();
    }
  }
  async loadData() {
    this.setState(
      {
        AddedSuccess: null,
        RemovalSuccess: null,
        updateSuccess: null,
      },
      async () => {
        let EventContents = {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.setState({
              open: true,
              rowData: row,
              AddedSuccess: null,
              RemovalSuccess: null,
              updateSuccess: null,
            });
          },
        };
        await trackPromise(
          Promise.all([
            axios
              .get(
                "/api/SysddfilterHeadings/" +
                  this.props.SiteID +
                  "/" +
                  this.props.CompanyID
              )
              .catch((e) => {
                var message = apiError("API IPList Get: ", e);
                this.setState((previousState) => ({
                  errorList: [...previousState.errorList, message],
                  List: null,
                  columns: null,
                  RemovalSuccess: null,
                  updateSuccess: null,
                  AddedSuccess: null,
                }));
              }),
          ]).then((responses) => {
            if (responses[0] && responses[0] !== undefined) {
              let List = responses[0].data;
              let RemoveColumn = [
                {
                  dataField: "RemoveFilter",
                  text: "",
                  editable: false,
                  headerAlign: "center",
                  align: "center",
                  isDummyField: true,
                  headerClasses: "smlButtonCol",
                  formatter: (cellContent, row) => {
                    return (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="RemoveFilterInfoTooltip" className="">
                            Remove This Runtime Filter
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          className="ButtonRemove"
                          onClick={(e) => this.HandleRemoveFilter(e, row)}
                        >
                          <FaTimesCircle />
                        </Button>
                      </OverlayTrigger>
                    );
                  },
                },
              ];
              let columnList = [
                {
                  dataField: "filterSetName",
                  text: "Filter Set Name",
                  sort: true,
                  events: EventContents,
                },
                {
                  dataField: "filterFieldName",
                  text: "Filter Field Name",
                  sort: true,
                  events: EventContents,
                  formatter: (cellcontent, row) => {
                    return cellcontent.toUpperCase();
                  },
                },
              ];
              let SelectColumn = [
                {
                  dataField: "SelectFilter",
                  text: "",
                  editable: false,
                  headerAlign: "center",
                  align: "center",
                  isDummyField: true,
                  headerClasses: "smlButtonCol",
                  formatter: (cellContent, row) => {
                    return (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="SelectFiltertInfoTooltip" className="">
                            Select This Runtime Filter
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          className="ButtonRemove"
                          onClick={(e) => this.HandleSelectFilter(e, row)}
                        >
                          <FaHandPointRight />
                        </Button>
                      </OverlayTrigger>
                    );
                  },
                },
              ];
              let columns = columnList;
              if (List.length >= 1) {
                if (this.props.ShowSelect) {
                  RemoveColumn = RemoveColumn.concat(SelectColumn);
                }
                columns = RemoveColumn.concat(columnList);
              }

              this.setState({
                List,
                columns,
              });
            }
          })
        );
      }
    );
  }

  HandleRemoveFilter(e, row) {
    e.preventDefault();
    RemoveRuntimeFilter(row)
      .then((r) => {
        this.handleCallback("RemovalSuccess", [r]);
      })
      .catch((e) => {
        this.handleCallback("errorList", e);
      });
  }

  HandleSelectFilter(e, row) {
    e.preventDefault();
    this.setState({ DisablePopup: true }, () => {
      let SelectedRow = {
        SYSDDFilterHeadingsID: row.sysddFilterHeadingsID,
        filterFieldName: row.filterFieldName.toUpperCase(),
        filterSetName: row.filterSetName,
        filterValueList: row.filterValueList,
      };
      this.props.RuntimeFilterSelected("RuntimeFilterSelected", SelectedRow);
    });
  }

  handleCallback = (name, theData) => {
    // console.log(name, theData);
    var pattErrorList = new RegExp("errorList"),
      pattRemovalSuccess = new RegExp("RemovalSuccess"),
      pattUpdateSuccess = new RegExp("updateSuccess"),
      pattAddedSuccess = new RegExp("AddedSuccess");

    this.setState((state) => {
      if (pattErrorList.test(name)) {
        state.errorList = [...state.errorList, theData];
      } else {
        state[name] = theData;
      }
      return state;
    });

    if (
      pattRemovalSuccess.test(name) ||
      pattAddedSuccess.test(name) ||
      pattUpdateSuccess.test(name)
    ) {
      this.setState({ [name]: theData });
    }
  };

  RenderPopup() {
    let paginationOptions = {
      custom: true,
      totalSize: this.state.List !== undefined ? this.state.List.length : "",
      hidePageListOnlyOnePage: true,
      sizePerPage: sizePerPage,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
    };
    const defaultSorted = [
      {
        dataField: "filterSetName",
        order: "asc",
      },
    ];
    let totalPages = this.state.List
      ? Math.ceil(this.state.List.length / sizePerPage)
      : 0;
    return (
      <Popup
        className="RuntimeFilter"
        trigger={
          <div className="IconBtn">
            <Button
              variant="primary"
              onClick={() => {
                this.setState({
                  DisablePopup: false,
                });
                this.props.DataUpdated("DataUpdated", true);
              }}
            >
              {this.props.ButtonLabel}
            </Button>
          </div>
        }
        modal
        closeOnDocumentClick={false}
      >
        {(Close) => (
          <div
            className="Modal"
            onMouseMove={this.state.DisablePopup ? Close : null}
          >
            <button className="close" onClick={Close}>
              <FaTimesCircle />
            </button>
            <div className="Title"></div>
            <OutputMessage
              errorList={this.state.errorList}
              updateSuccess={this.state.updateSuccess}
              AddedSuccess={this.state.AddedSuccess}
              PostFeedback={this.state.PostFeedback}
            />
            <div className="content">
              <PaginationProvider
                pagination={paginationFactory(paginationOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <>
                    <BootstrapTable
                      bootstrap4
                      responsive
                      hover
                      keyField="sysddFilterHeadingsID"
                      data={this.state.List}
                      columns={this.state.columns}
                      filter={filterFactory()}
                      {...paginationTableProps}
                      defaultSorted={defaultSorted}
                    />
                    <Row className="PaginationArea">
                      <Col>
                        <PaginationTotalStandalone {...paginationProps} />
                      </Col>
                      <Col sm="2">
                        <NewRuntimeFilter
                          errorList={this.handleCallback}
                          AddedSuccess={this.handleCallback}
                          CurrentFilters={this.state.List}
                        />
                      </Col>
                      {this.state.List.length >= sizePerPage ? (
                        <>
                          <Col sm="1">
                            <Button
                              className="btn btn-primary"
                              onClick={handlePrevPage(paginationProps)}
                            >
                              Back
                            </Button>
                          </Col>
                          <Col sm="1">
                            <Button
                              className="btn btn-primary"
                              onClick={handleNextPage(
                                paginationProps,
                                totalPages
                              )}
                            >
                              Next
                            </Button>
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </>
                )}
              </PaginationProvider>
              {this.state.open ? (
                <RuntimeFilterDetails
                  open={this.state.open}
                  rowData={this.state.rowData}
                  setOpen={this.handleCallback}
                  errorList={this.handleCallback}
                  updateSuccess={this.handleCallback}
                  CurrentFilters={this.state.List}
                />
              ) : null}
            </div>
          </div>
        )}
      </Popup>
    );
  }

  render() {
    return <>{!this.state.List !== undefined ? this.RenderPopup() : null}</>;
  }
}

export default RuntimeFilter;
