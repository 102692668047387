import React, { Component } from "react";
import CompanyListTable from "../components/CompanyList/CompanyListTable";
import axios from "axios";
import apiError from "../libs/apiError";
import { trackPromise } from "react-promise-tracker";
import SetEntity from "../libs/SetEntity";
import { AppContext } from "../libs/contextLib";

class CompanyList extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = { SiteID: sessionStorage.getItem("siteID"), errorList: [] };
    this.loadData();
  }
  async loadData() {
    if (this.state.SiteID) {
      await trackPromise(
        Promise.all([
          axios.get("/api/Syscompanies/" + this.state.SiteID).catch((e) => {
            var message = apiError("apiUrl Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          }),
        ])
          .then((response) => {
            if (response[0]) {
              let CompanyList = response[0].data;
              CompanyList = SetEntity(CompanyList, "entity");
              this.setState({ CompanyList });
            }
          })
          .catch((e) => {
            var message = apiError("CompanyList Get: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          })
      );
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleCallback = (Reload) => {
    if (Reload) {
      this.loadData();
    }
  };

  render() {
    return (
      <div className="CompanyList">
        <h1>Company List</h1>
        <CompanyListTable
          rows={this.state.CompanyList}
          Reload={this.handleCallback}
        />
      </div>
    );
  }
}

CompanyList.contextType = AppContext;

export default CompanyList;
