import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";

class FormDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBlank: this.props.ShowBlank,
      startDate: new Date(this.props.placeholder),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange(event) {
    this.setState({ startDate: event });
    if (event) {
      let date = moment(event).format("YYYY-MM-DD");
      this.props.value(this.props.name, date);
    } else {
      this.props.value(this.props.name, null);
    }
  }
  handleClick(event) {
    var NewDate = moment().toDate();
    this.setState({
      startDate: NewDate,
      ShowBlank: false,
    });
    this.props.value(this.props.name, NewDate);
  }
  renderForm() {
    let name = this.props.name;
    return (
      <Form.Group className={"FormGroup"} as={Row} controlId={name}>
        <Form.Label column sm={this.props.LabelColumnAmount}>
          {this.props.Label}
        </Form.Label>
        <Col sm={this.props.InputColumnAmount}>
          <>
            {this.state.ShowBlank ? (
              <Button
                className="form-control EmptyButton"
                name={name}
                value={""}
                onClick={this.handleClick}
                disabled={this.props.disabled}
              />
            ) : (
              <DatePicker
                className="form-control"
                aria-describedby="inputGroupPrepend"
                selected={this.state.startDate}
                name={name}
                onChange={this.handleChange}
                disabled={this.props.disabled}
                placeholderText=""
                todayButton="Today"
                dateFormat="dd-MM-yyyy"
                onselectstart="return false"
                closeOnScroll={true}
              />
            )}

            {this.props.errorList[name] ? (
              <Form.Control.Feedback type="invalid" tooltip>
                <>{this.props.errorList[name]}</>
              </Form.Control.Feedback>
            ) : null}
          </>
        </Col>
      </Form.Group>
    );
  }

  render() {
    return !this.props.hidden ? (
      this.props.tooltip ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={this.props.name + "Tooltip"} className="">
              {this.props.tooltip}
            </Tooltip>
          }
        >
          {this.renderForm()}
        </OverlayTrigger>
      ) : (
        this.renderForm()
      )
    ) : null;
  }
}

export default FormDatePicker;
