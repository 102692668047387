import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AppContext } from "../../libs/contextLib";
import { FaInfoCircle, FaSync } from "react-icons/fa";
import CheckIfValueReturned from "../../libs/CheckIfValueReturned";
import BootstrapTable from "../react-bootstrap-table2";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import AddNewCompany from "./AddNewCompany";
import SyncEntities from "./SyncEntities";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import withRouter from "../../libs/withRouter";
import apiError from "../../libs/apiError";
import { SetObserver } from "../../libs/Observer";
import OverlayFormatter from "../../libs/OverlayFormatter";
import OutputMessage from "../../components/OutputMessage";
import NoTextFormatter from "../NoTextFormatter";

class CompanyListTable extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      errorList: [],
    };
  }

  componentDidMount() {
    var CompanyListLabel = "Entity";
    let ProductName = sessionStorage.getItem("productName");
    if (ProductName !== "Sage Intacct") {
      CompanyListLabel = "Sage Company";
    }
    this.setState({
      columns: [
        {
          dataField: "companyName",
          text: "Company Name",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },

        {
          dataField: "entity",
          text: CompanyListLabel,
          sort: true,
          filter: textFilter({ placeholder: " " }),
          formatter: (cellContent, row) => {
            return OverlayFormatter(cellContent, row);
          },
        },

        {
          dataField: "isActive",
          text: "Active",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          isDummyField: true,
          headerClasses: "ButtonCol center",
          classes: "center",
          formatter: (cellContent, row) => CheckIfValueReturned(row.isActive),
        },

        {
          dataField: "customerID",
          text: "View Details",
          headerClasses: "ButtonCol center",
          headerFormatter: NoTextFormatter,
          classes: "center",
          events: {
            onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
              sessionStorage.setItem("CompanyID", row.sysCompanyID);
            },
          },
          formatter: (cellContent, row) => (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="ViewInfoTooltip" className="">
                  View Details
                </Tooltip>
              }
            >
              <Link to="/CompanyListDetails">
                <FaInfoCircle />
              </Link>
            </OverlayTrigger>
          ),
        },

        {
          dataField: "CompanyID",
          text: "View Customer List",
          headerClasses: "ButtonCol center",
          classes: "center",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              let CompanyDetails = {
                CompanyID: row.sysCompanyID,
                CompanyName: row.companyName,
              };
              sessionStorage.setItem(
                "CompanyDetails",
                JSON.stringify(CompanyDetails)
              );
            },
          },
          headerFormatter: NoTextFormatter,
          formatter: (cellContent, row) => (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="ViewInfoTooltip" className="">
                  View Details
                </Tooltip>
              }
            >
              <Link to="/CustomerList">
                <FaInfoCircle />
              </Link>
            </OverlayTrigger>
          ),
        },

        {
          dataField: "CompanySync",
          text: "Sync Company Data",
          isDummyField: true,
          headerClasses: "ButtonCol center",
          classes: "center",
          headerFormatter: NoTextFormatter,
          formatter: (cellContent, row) => (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="ViewInfoTooltip" className="">
                  Date of Last Company Sync - {row.lastCustomerListSync}
                </Tooltip>
              }
            >
              <Link
                to="/CompanyList"
                onClick={async () => {
                  this.setState({
                    updateSuccess: false,
                    errorList: [],
                    PostFeedback: [],
                  });
                  let SiteID = sessionStorage.getItem("siteID");

                  await trackPromise(
                    Promise.all([
                      axios
                        .get(
                          this.context.INTACCT_API_BASE_URL +
                            "/api/SyncCompany/?siteID=" +
                            SiteID +
                            "&SYSCompanyID=" +
                            row.sysCompanyID +
                            "&accessToken=" +
                            this.context.SageAccessToken,
                          {
                            crossdomain: true,
                          }
                        )
                        .catch((e) => {
                          var message = apiError("SyncCompany Error: ", e);
                          this.setState((previousState) => ({
                            errorList: [...previousState.errorList, message],
                          }));
                        }),
                    ])
                      .then((responses) => {
                        if (responses[0]) {
                          this.setState(
                            {
                              updateSuccess: true,
                              PostFeedback: [
                                "Company details synched for company " +
                                  row.companyName,
                              ],
                            },
                            () => {
                              this.props.Reload(true);
                            }
                          );
                        }
                      })
                      .catch((e) => {
                        var message = apiError("API Sync Error:", e);
                        this.setState((previousState) => ({
                          errorList: [...previousState.errorList, message],
                        }));
                      })
                  );
                }}
              >
                <FaSync />
              </Link>
            </OverlayTrigger>
          ),
        },

        {
          dataField: "lastCustomerListSync",
          text: "Date of Last Sync",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          // formatter: (cellContent, row) => {
          //    return OverlayFormatter(cellContent, row);
          // },
        },

        // {
        //   dataField: "activeDD",
        //   text: "Active DD?",
        //   headerClasses: "ButtonCol center",
        //   classes: "center",
        //   // sort: true,
        //   // formatter: (cellContent, row) =>
        //   //   CheckIfValueReturned(row.ActiveDD),
        // },
      ],
    });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  componentDidUpdate() {
    SetObserver();
  }

  handleCallback = (name, theData) => {
    this.setState((state) => {
      var pattErrorList = new RegExp("errorList");

      if (pattErrorList.test(name)) {
        state.errorList = [...state.errorList, theData];
      } else {
        state[name] = theData;
      }
      return state;
    });
  };

  render() {
    if (!this.props.rows) {
      return (
        <>
          <OutputMessage
            errorList={this.state.errorList}
            updateSuccess={this.state.updateSuccess}
            AddedSuccess={this.state.AddedSuccess}
            PostFeedback={this.state.PostFeedback}
          />
          <Row>
            <Col>
              No active companies found.
              <div className="noData">
                <AddNewCompany />
                <SyncEntities
                  errorList={this.handleCallback}
                  PostFeedback={this.handleCallback}
                />
              </div>
            </Col>
          </Row>
        </>
      );
    } else {
      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {size} Results
        </span>
      );
      let CompanyListPage = 1;
      if (sessionStorage.getItem("CompanyListPage")) {
        CompanyListPage = sessionStorage.getItem("CompanyListPage");
      }
      const paginationOptions = {
        page: parseInt(CompanyListPage),
        onPageChange: (page) => {
          sessionStorage.setItem("CompanyListPage", page);
        },
        hidePageListOnlyOnePage: true,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
          {
            text: "10",
            value: 10,
          },
          {
            text: "50",
            value: 50,
          },
          {
            text: "100",
            value: 100,
          },
          {
            text: "All",
            value: this.props.rows.length,
          },
        ],
      };
      const defaultSorted = [
        {
          dataField: "companyName",
          order: "asc",
        },
      ];
      return (
        <>
          <OutputMessage
            errorList={this.state.errorList}
            updateSuccess={this.state.updateSuccess}
            AddedSuccess={this.state.AddedSuccess}
            PostFeedback={this.state.PostFeedback}
          />
          <div className="CompanyListTable">
            <BootstrapTable
              bootstrap4
              responsive
              hover
              keyField="sysCompanyID"
              data={this.props.rows}
              columns={this.state.columns}
              filter={filterFactory()}
              pagination={paginationFactory(paginationOptions)}
              defaultSorted={defaultSorted}
            />
            <Row>
              <Col sm={8}></Col>
              <Col className="right" sm={4}>
                <AddNewCompany />
                <SyncEntities
                  errorList={this.handleCallback}
                  PostFeedback={this.handleCallback}
                />
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}
CompanyListTable.contextType = AppContext;
export default withRouter(CompanyListTable);
