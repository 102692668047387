import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../libs/contextLib";
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Collapse,
} from "react-bootstrap";
import {
  FaArrowCircleLeft,
  FaSync,
  FaLock,
  FaFileUpload,
  FaFileDownload,
  FaTrashAlt,
  FaRegEdit,
  FaTimesCircle,
  FaSave,
  FaMailBulk,
} from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { FaAnglesDown, FaAnglesUp } from "react-icons/fa6";
import BootstrapTable from "../react-bootstrap-table2";
import paginationFactory from "react-bootstrap-table2-paginator";
import { BiSelectMultiple } from "react-icons/bi";
import { MdFilterNone } from "react-icons/md";
import { handleValidation } from "../handleValidation";
import { saveAs } from "file-saver";
import * as yup from "yup";
import axios from "axios";
import moment from "moment";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import FormLabelbox from "../Form/FormLabelbox";
import FormInputBox from "../Form/FormInputbox";
import FormDatePicker from "../Form/FormDatePicker";
import CheckConfirm from "../Form/CheckConfirm";
import GetDatetime from "../../libs/GetDatetime";
import { SetObserver } from "../../libs/Observer";
import IsError from "../../components/IsError";
import OutputMessage from "../../components/OutputMessage";
import DDRunDetails from "./DDRunDetails";
import DDReport from "./ReportDownload/DDReport";
import GenerateNotificationLetters from "./NotificationLetters/GenerateNotificationLetters";
import { trackPromise } from "react-promise-tracker";
import RemoveListItem from "./RemoveListItem";
import CollectionDateConfirm from "./CollectionDateConfirm";
import CheckConfirmOK from "../Form/CheckConfirmOK";
import TranslatedBaseAmountError from "./TranslatedBaseAmountError";
import OmittedCustomers from "./OmittedCustomers";
import ErrorLog from "./ErrorLog";
import {
  LabelColumnAmount,
  InputColumnAmount,
  DataNotChanged,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";

const CollectionListNameSchema = yup
    .string()
    .required("Collection List Name is required")
    .matches(inputRegex, "Collection List Name" + inputRegexMessage)
    .max(60, "Collection List Name must be less than 60 characters"),
  NonIntacctCollectionListNameSchema = yup
    .string()
    .required("Collection List Name is required")
    .matches(inputRegex, "Collection List Name" + inputRegexMessage)
    .max(20, "Collection List Name must be less than 20 characters"),
  BaseTotalSchema = yup
    .string()
    .matches(
      /^(\+|-)?([0-9]+(\.[0-9]{1,2})?)$/,
      "Total Collection must be a decimal correct to 2 places."
    );

let blankState = {
    notEditable: true,
    editVisible: true,
    NoData: false,
    contiueElementVisible: true,
    sectionElementIsHidden: true,
    notEditableSectionOne: false,
    continueClicked: false,
    TotalCollectionEditable: 0.0,
    BaseTotal: 0.0,
    BankTotal: 0.0,
    errorList: [],
    Status: "",
    StatusID: 1,
    DateTimeUpdated: "",
    DateTimeCreated: "",
    ShowCollapse: false,
    Message: "",
  },
  IsDev = process.env.NODE_ENV === "development" ? true : false,
  NetworkErrorMessage = "Process is still running, please reload the page.";

class DDRun extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handleSetEdit = this.handleSetEdit.bind(this);
    this.handleShowGenerate = this.handleShowGenerate.bind(this);
    this.handleCommitDDRun = this.handleCommitDDRun.bind(this);
    this.handleCollectAll = this.handleCollectAll.bind(this);
    this.handleCollectNone = this.handleCollectNone.bind(this);
    this.handleBankFile = this.handleBankFile.bind(this);
    this.handlePostDDRun = this.handlePostDDRun.bind(this);
    this.HandleRemoveListItem = this.HandleRemoveListItem.bind(this);
    this.HandleRefresh = this.HandleRefresh.bind(this);
    this.handleReportDownload = this.handleReportDownload.bind(this);
    this.handleGenerateNotificationLetters =
      this.handleGenerateNotificationLetters.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate() {
    SetObserver();
    if (this.state.StatusID <= 5) {
      clearInterval(this.interval);
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  RefreshInterval() {
    if (this.state.StatusID === 6 || this.state.StatusID === 7) {
      return (this.interval = setInterval(() => {
        // console.log("RefreshInterval invoked");
        this.HandleRefresh();
      }, 5000));
    }
  }

  async loadData() {
    let params = this.props.location.state,
      SiteID = sessionStorage.getItem("siteID"),
      ProductName = sessionStorage.getItem("productName"),
      updateSuccess,
      AddedSuccess,
      PostFeedback,
      HeaderID,
      CompanyID;

    if (params !== undefined && params) {
      updateSuccess = params.updateSuccess;
      AddedSuccess = params.AddedSuccess;
      PostFeedback = params.PostFeedback;
      sessionStorage.setItem("NewListItem", false);
    }

    let theDDRun = JSON.parse(sessionStorage.getItem("DDRun"));
    HeaderID = theDDRun.HeaderID;
    CompanyID = theDDRun.CompanyID;

    await trackPromise(
      Promise.all([
        axios.get(
          "/api/DdlistHeaders/GetDdlistGenerateList/" + SiteID + "/" + HeaderID
        ),
        axios.get("/api/BankAccountLists/" + SiteID + "/" + CompanyID),
        axios.get(
          "/api/CurrencyLists/GetBaseCurrencyList/" + SiteID + "/" + CompanyID
        ),
      ]).then(async (responses) => {
        var placeholderArr = responses[0].data[0];

        if (responses[1] && responses[1] !== undefined) {
          var bankAccountList = responses[1].data;
        } else {
          throw new Error("No Active Bank Accounts found");
        }
        var CollectionBankLabelArray = [];
        bankAccountList.forEach((element, i) => {
          CollectionBankLabelArray[element.id] = {
            content: element.content,
            bankCurrency: element.bankCurrency,
            bankCurrencySymbol: element.bankCurrencySymbol,
          };
        });

        let CollectionBankLabel =
            CollectionBankLabelArray[placeholderArr.collectionBank].content,
          BankCurrency =
            CollectionBankLabelArray[placeholderArr.collectionBank]
              .bankCurrency,
          BankCurrencySymbol =
            CollectionBankLabelArray[placeholderArr.collectionBank]
              .bankCurrencySymbol;

        if (responses[2] && responses[2] !== undefined) {
          var BaseCurrencyList = responses[2].data;
        } else {
          throw new Error("No Active Currencies found");
        }

        var BaseCurrencyLabelArray = [];
        BaseCurrencyList.forEach((element, i) => {
          BaseCurrencyLabelArray[element.id] = {
            content: element.content,
            baseCurrencySymbol: element.currencySymbol,
            name: element.name,
          };
        });

        let BaseCurrencyLabel =
            BaseCurrencyLabelArray[placeholderArr.baseCurrency].content,
          BaseCurrencySymbol =
            BaseCurrencyLabelArray[placeholderArr.baseCurrency]
              .baseCurrencySymbol,
          BaseCurrencyName =
            BaseCurrencyLabelArray[placeholderArr.baseCurrency].name;

        if (responses[2] && responses[2] !== undefined) {
          if (!placeholderArr.invoiceCurrency) {
            var index = BaseCurrencyList.findIndex(
              (i) => i.content === BankCurrency
            );
          } else {
            index = BaseCurrencyList.findIndex(
              (i) => i.id === placeholderArr.invoiceCurrency
            );
          }
          var InvoiceCurrency = BaseCurrencyList[index].id;
          var InvoiceCurrencyLabel = BaseCurrencyList[index].content;
          var InvoiceCurrencySymbol = BaseCurrencyList[index].currencySymbol;
        } else {
          BaseCurrencyList = [];
          InvoiceCurrency = null;
        }

        var BaseTotal, BankTotal, TotalCollectionEditable;
        if (InvoiceCurrencyLabel === BaseCurrencyLabel) {
          BaseTotal = placeholderArr.collectionTotal;
          TotalCollectionEditable = placeholderArr.bankTotal;
        } else {
          TotalCollectionEditable = placeholderArr.baseTotal;
          BaseTotal = TotalCollectionEditable;
        }

        if (InvoiceCurrencyLabel === BankCurrency) {
          BankTotal = placeholderArr.collectionTotal;
          TotalCollectionEditable = placeholderArr.baseTotal;
        } else {
          TotalCollectionEditable = placeholderArr.bankTotal;
          BankTotal = TotalCollectionEditable;
        }
        this.setState(
          {
            PlaceholderListFilled: true,
            DDListHeaderID: placeholderArr.ddListHeaderID,
            Company: placeholderArr.company,
            CompanyID: placeholderArr.companyID,
            SiteID,
            ProductName,
            HeaderID,
            IsTopLevel: placeholderArr.isTopLevel,
            UseRuntimeFilters: placeholderArr.useRuntimeFilters,
            IsRuntimeFiltering: placeholderArr.isRuntimeFiltering,
            RuntimeFilterSelected: placeholderArr.runtimeFilterSelected[0],
            bankAccountList: bankAccountList,
            CollectionListName: placeholderArr.collectionListName,
            CollectionBank: placeholderArr.collectionBank,

            TransferCollection: placeholderArr.transferCollection,
            TopLevelEntity: placeholderArr.topLevelEntity,
            JournalSymbol: placeholderArr.journalSymbol,
            TransferLocation: placeholderArr.transferLocationName,
            TransferBankAccountName: placeholderArr.transferBankAccountName,

            CollectionBankLabel: CollectionBankLabel,
            BaseCurrencyList: BaseCurrencyList,
            BankCurrency: BankCurrency,
            BankCurrencySymbol: BankCurrencySymbol,
            CollectionDate: placeholderArr.collectionDate,
            OldCollectionDate: placeholderArr.collectionDate,
            BaseCurrency: placeholderArr.baseCurrency,
            BaseCurrencyLabel: BaseCurrencyLabel,
            BaseCurrencySymbol: BaseCurrencySymbol,
            BaseCurrencyName: BaseCurrencyName,
            InvoiceCurrencyList: BaseCurrencyList,
            InvoiceCurrency: InvoiceCurrency,
            InvoiceCurrencyLabel: InvoiceCurrencyLabel,
            InvoiceCurrencySymbol: InvoiceCurrencySymbol,
            TransactionsFrom: placeholderArr.transactionsFrom,
            TransactionsTo: placeholderArr.transactionsTo,
            CurrentGrandCollectionTotal: placeholderArr.collectionTotal,
            StatusID: placeholderArr.statusID,
            ShowNotificationLettersOnCreated:
              placeholderArr.showNotificationLettersOnCreated,
            Status: placeholderArr.status,
            IsPosted: placeholderArr.isPosted,
            IsFileOutput: placeholderArr.isFileOutput,
            FileFormatExtension: placeholderArr.fileFormatExtension,
            TransactionsListTempPopulated:
              placeholderArr.transactionsListTempPopulated,
            CreatedBy: placeholderArr.createdBy,
            CollectionTotal: placeholderArr.collectionTotal,
            BaseTotal: BaseTotal,
            BankTotal: BankTotal,
            TotalCollectionEditable: TotalCollectionEditable,
            DateTimeUpdated: placeholderArr.dateTimeUpdated,
            DateTimeCreated: placeholderArr.dateTimeCreated,
            sectionElementIsHidden: false,
            notEditableSectionOne: true,
            notEditable: true,
            editVisible: true,

            updateSuccess,
            AddedSuccess,
            PostFeedback,
          },
          async () => {
            if (this.state.StatusID === 6 || this.state.StatusID === 7) {
              this.RefreshInterval();
            } else {
              await Promise.all([
                axios.get(
                  "/api/DdlistOmittedCustomers/GetDdlistOmittedCustomer/" +
                    this.state.CompanyID +
                    "/" +
                    SiteID +
                    "/" +
                    this.state.HeaderID
                ),
                axios.get(
                  "/api/Syscompanies/GetSyscompanies/" + this.state.SiteID
                ),
              ])
                .then((responses) => {
                  if (responses[0] && responses[0] !== undefined) {
                    var OmittedCustomersList = responses[0].data;
                    if (
                      OmittedCustomersList ===
                      "No Omitted Customers found for this Run."
                    ) {
                      var OmittedCustomersFeedback = "";
                      OmittedCustomersList = [];
                    } else {
                      OmittedCustomersFeedback = (
                        <>
                          <p>
                            {OmittedCustomersList.length} customer accounts
                            could not be added to the direct debit run.
                          </p>
                          <p>
                            Please check the omitted customer list for further
                            details.{" "}
                          </p>
                        </>
                      );
                    }
                  } else {
                    OmittedCustomersList = [];
                    OmittedCustomersFeedback = "";
                  }

                  let SetDisableDDCompanySync = false;
                  if (responses[1] && responses[1] !== undefined) {
                    let a = {};
                    responses[1].data.forEach((element) => {
                      a[element.id] = [
                        element.content,
                        element.disableDDCompanySync,
                      ];
                    });
                    SetDisableDDCompanySync = a[this.state.CompanyID][1];
                  }

                  let Message = placeholderArr.message;
                  let ErrorLogJSON = "";
                  let ErrorLogMessage = [];
                  if (this.state.errorList.length >= 1) {
                    ErrorLogMessage = this.state.errorList;
                  }
                  if (placeholderArr.errorLog) {
                    ErrorLogJSON = JSON.parse(placeholderArr.errorLog);
                    if (ErrorLogJSON.length >= 1) {
                      ErrorLogMessage.push(placeholderArr.message);
                    }
                  }
                  let PostFeedback = Message
                    ? Message.includes("Errors")
                      ? ""
                      : [Message]
                    : "";
                  this.setState(
                    {
                      PlaceholderListFilled: true,
                      OmittedCustomersList: OmittedCustomersList,
                      OmittedCustomersFeedback: OmittedCustomersFeedback,
                      DisableDDCompanySync: SetDisableDDCompanySync,
                      Message: Message,
                      PostFeedback: PostFeedback,
                      errorList:
                        ErrorLogMessage.length >= 1 ? ErrorLogMessage : [],
                      ErrorLog: ErrorLogJSON,
                      sectionElementIsHidden: false,
                      notEditableSectionOne: true,
                      notEditable: true,
                      editVisible: true,
                    },
                    () => {
                      if (
                        this.state.StatusID !== 6 &&
                        this.state.StatusID !== 7
                      ) {
                        this.setDDlistCustomerSummary();
                      }
                    }
                  );
                })
                .catch((e) => {
                  var message = apiError("API Get Existing DDRun: ", e);
                  this.setState((previousState) => ({
                    NoData: true,
                    errorList: [...previousState.errorList, message],
                  }));
                });
            }
          }
        );
      })
    ).catch((e) => {
      var message = apiError("API CheckStatusID: ", e);
      this.setState((previousState) => ({
        NoData: true,
        errorList: [...previousState.errorList, message],
      }));
    });
  }

  async HandleRefresh() {
    await trackPromise(
      Promise.all([
        axios.get(
          "/api/DdlistHeaders/CheckStatusID/" +
            this.state.SiteID +
            "/" +
            this.state.HeaderID
        ),
      ]).then((responses) => {
        var StatusID = responses[0].data[0].statusID;

        if (StatusID >= 6) {
          this.setState({ StatusID: StatusID });
        } else {
          this.setState(
            {
              updateSuccess: false,
              AddedSuccess: false,
              DataUpdated: false,
              PostFeedback: "",
              errorList: [],
            },
            () => {
              this.loadData();
            }
          );
        }
      })
    ).catch((e) => {
      var message = apiError("API CheckStatusID: ", e);
      this.setState((previousState) => ({
        errorList: [...previousState.errorList, message],
      }));
    });
  }

  HandleRemoveListItem(e) {
    e.preventDefault();
    RemoveListItem(this.props.navigate, this.state.DDListHeaderID);
  }

  async setDDlistCustomerSummary() {
    await Promise.all([
      axios.get(
        "api/DdlistCustomers/GetDdlistCustomerSummary/" +
          this.state.SiteID +
          "/" +
          this.state.CompanyID +
          "/" +
          this.state.DDListHeaderID
      ),
      axios.get(
        "api/DdlistCustomers/GetDdlistCustomerDetailed/" +
          this.state.SiteID +
          "/" +
          this.state.CompanyID +
          "/" +
          this.state.DDListHeaderID
      ),
    ])
      .then((responses) => {
        if (responses[0] && responses[0] !== undefined) {
          var DDlistCustomerSummary = responses[0].data;
        } else {
          DDlistCustomerSummary = false;
          throw new Error("No Customer Summary data found");
        }
        if (responses[1] && responses[1] !== undefined) {
          var DDlistCustomerDetailed = responses[1].data;
        } else {
          DDlistCustomerDetailed = false;
          throw new Error("No Customer Details data found");
        }
        this.setState({ DDlistCustomerSummary, DDlistCustomerDetailed });
      })
      .catch((e) => {
        var message = apiError("setDDlistCustomerSummary: ", e);
        this.setState((previousState) => ({
          errorList: [...previousState.errorList, message],
        }));
      });
  }

  handleFormReset(e) {
    e.preventDefault();
    this.resetForm();
  }
  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      this.loadData();
    });
  }

  handleSetEdit(e) {
    e.preventDefault();
    this.setState({
      notEditable: false,
      editVisible: false,
      updateSuccess: false,
      AddedSuccess: false,
      DataUpdated: false,
      PostFeedback: "",
    });
  }

  AllCurrenciesMatch() {
    if (
      this.state.BankCurrency === this.state.InvoiceCurrencyLabel &&
      this.state.BaseCurrencyLabel === this.state.InvoiceCurrencyLabel
    ) {
      return true;
    } else return false;
  }

  CheckNegTotalCollections() {
    var NegTransactionsList = [];
    var i = 0;
    const accountReferenceExists = (accountReference) => {
      return NegTransactionsList.some((el) => {
        return el.accountReference === accountReference;
      });
    };

    this.state.DDlistCustomerDetailed.forEach((element) => {
      if (element.collectionAmount != 0) {
        if (accountReferenceExists(element.accountReference)) {
          var ind = NegTransactionsList.findIndex(
            (x) => x.accountReference === element.accountReference
          );
          NegTransactionsList[ind].collectionAmount =
            NegTransactionsList[ind].collectionAmount +
            element.collectionAmount;
        } else {
          NegTransactionsList[i] = {
            accountReference: element.accountReference,
            collectionAmount: element.collectionAmount,
          };
          i++;
        }
      }
    });
    NegTransactionsList = NegTransactionsList.filter(
      (d) => d.collectionAmount <= -0.01
    );

    if (NegTransactionsList.length >= 1) {
      let message = (
        <>
          <p>
            There are Customer Accounts that have a negative total Collection
            Amount. This will cause the Direct Debit Run to fail.
          </p>
          <p>
            Please amend the collection for the following Customers and then
            commit the Run again.
          </p>
          <p>
            {NegTransactionsList.map((r, index, arr) => (
              <span>
                {index + 1 === arr.length
                  ? r.accountReference
                  : `${r.accountReference}, `}
              </span>
            ))}
          </p>
        </>
      );
      this.setState((previousState) => ({
        errorList: [...previousState.errorList, message],
      }));
      return true;
    }
    return false;
  }

  handleCommitDDRun(e) {
    e.preventDefault();

    var OutputMessage = (
      <>
        <h3>Are you sure you wish to commit the run?</h3>
        <p>No further changes can be made against the run once committed.</p>

        {!this.AllCurrenciesMatch() ? (
          <p>
            However you will be able to edit the Total Collection (
            {this.SetCurrencySymbol()}) field.
          </p>
        ) : null}
      </>
    );

    CheckConfirm(OutputMessage, "550px")
      .then(async () => {
        this.setState({
          errorList: [],
          PostFeedback: "",
        });
        if (
          !this.state.DDlistCustomerDetailed &&
          this.state.DDlistCustomerDetailed === undefined
        ) {
          throw new Error("No Customer data found - unable to Commit.");
        }

        if (this.CheckNegTotalCollections()) {
          return;
        }

        let CollectionDate = moment(
          this.state.CollectionDate,
          "YYYY-MM-DD"
        ).format("DD-MM-YYYY");
        var ProblemTransactions = [];
        var i = 0;
        this.state.DDlistCustomerDetailed.forEach((element) => {
          if (
            moment(element.transactionDate, "DD-MM-YYYY").isAfter(
              moment(CollectionDate, "DD-MM-YYYY")
            ) &&
            element.collectionAmount >= 0.01
          ) {
            ProblemTransactions[i] = {
              TransactionDate: moment(element.transactionDate, "DD-MM-YYYY"),
            };
            i++;
          }
        });

        const sorted = ProblemTransactions.sort((a, b) => {
          return moment(a.TransactionDate).diff(b.TransactionDate);
        });

        if (sorted.length >= 1) {
          let latestDate = sorted[sorted.length - 1].TransactionDate._i;
          let message = (
            <>
              <p>
                There are transactions fetched that have a Transaction Date
                after the Collection Date selected. This will cause the Direct
                Debit Run to fail.
              </p>
              <p>The latest Date found was {latestDate}.</p>
              <p>
                Please update the Collection Date to a date on or after{" "}
                {latestDate}
              </p>
            </>
          );
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        } else {
          this.validateForm()
            .then((response) => {
              this.setState(
                {
                  errorList: [],
                  StatusID: 2,
                  DataUpdated: true,
                  updateSuccess: false,
                  PostFeedback: "",
                },
                () => {
                  this.doSubmit(true, true);
                }
              );
            })
            .catch((e) => {
              var message = apiError("CommitDDRun: ", e);
              if (message !== undefined) {
                this.setState((previousState) => ({
                  errorList: [...previousState.errorList, message],
                }));
              }
            });
        }
      })
      .catch((e) => {
        var message = apiError("CommitDDRun: ", e);
        if (message !== undefined) {
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        }
      });
  }

  async handleCollectAll(e) {
    e.preventDefault();

    let OutputText = (
      <>
        <h3>
          This will set the Collection Amount for all transactions to each of
          their individual Outstanding Amounts.
        </h3>
        <p>Do you want to proceed?</p>
      </>
    );
    await CheckConfirm(OutputText, "500px")
      .then(async () => {
        let DDlistCustomerDetailed = this.state.DDlistCustomerDetailed;
        let TransactionScheduleAmountErrorList = [];

        DDlistCustomerDetailed.forEach((element) => {
          let newValue = parseFloat(element.outstandingAmount);
          if (element.transactionType === "Invoice") {
            let currentHistoryCollectionAmount =
              element.currentHistoryCollectionAmount - element.collectionAmount;
            if (
              currentHistoryCollectionAmount + newValue >
              element.outstandingAmount
            ) {
              TransactionScheduleAmountErrorList.push({
                Account: element.accountName,
                TransactionID: element.invoiceNumber,
              });
              newValue =
                element.outstandingAmount - currentHistoryCollectionAmount;
            }
          }
          element.collectionAmount = newValue;
        });
        this.setState({ DDlistCustomerDetailed, errorList: [] }, () => {
          this.doCollectSubmit(false);
        });
        if (TransactionScheduleAmountErrorList.length > 0) {
          var OutputMessage = (
            <>
              <h3>Unable to Fully Update</h3>
              <p>
                The total schedule amount for the follow transactions are
                greater than their individual Outstanding Amounts -
              </p>

              <BootstrapTable
                bootstrap4
                responsive
                hover
                keyField="TransactionID"
                data={TransactionScheduleAmountErrorList}
                columns={[
                  {
                    dataField: "Account",
                    text: "Account",
                  },
                  {
                    dataField: "TransactionID",
                    text: "Transaction",
                  },
                ]}
                pagination={paginationFactory()}
              />
              <p>
                The "To Collect" values have therefore been updated to the
                maximum value that can be collected.
              </p>
            </>
          );
          await CheckConfirmOK(OutputMessage, "500px").then(() => {});
        }
      })
      .catch(() => {});
  }

  async handleCollectNone(e) {
    e.preventDefault();
    let OutputText = (
      <>
        <h3>
          This will set the Collection Amount for all transactions to zero.
        </h3>
        <p>Do you want to proceed?</p>
      </>
    );
    await CheckConfirm(OutputText, "500px")
      .then(() => {
        let DDlistCustomerDetailed = this.state.DDlistCustomerDetailed;
        DDlistCustomerDetailed.forEach((element) => {
          element.collectionAmount = 0.0;
        });
        this.setState({ DDlistCustomerDetailed, errorList: [] }, () => {
          this.doCollectSubmit(true);
        });
      })
      .catch(() => {});
  }

  async doCollectSubmit(isCollectNone) {
    let output = this.state;
    let DateTimeUpdated = GetDatetime();
    var ListLinesToUpdate = [];
    var i = 0;
    output.DDlistCustomerDetailed.forEach((element) => {
      ListLinesToUpdate[i] = {
        ddListLinesID: element.ddListLinesID,
        collectionAmount: element.collectionAmount,
      };
      i++;
    });
    let outputJSON = {
      DDListHeaderID: output.DDListHeaderID,
      ListLinesToUpdate: ListLinesToUpdate,
      GrandCollectionTotal: output.GrandCollectionTotal,
      DateTimeUpdated: DateTimeUpdated,
      isCollectNone: isCollectNone,
    };

    await axios
      .put("api/DdlistCustomers/PutDdlistLinesAll", outputJSON)
      .then(() => {
        this.loadData();
      })
      .catch((e) => {
        var message = e.response.data;
        message = (
          <>
            {message.map((e, i) => {
              return <div key={i}>{e}</div>;
            })}
          </>
        );
        this.setState((previousState) => ({
          errorList: [...previousState.errorList, message],
        }));
        this.loadData();
      });
  }

  async handleReportDownload(e) {
    e.preventDefault();

    let PopulateDDReport = {
      CreatedBy: this.context.usr,
      ReportCreated: moment().format("DD-MM-YYYY HH:mm:ss"),
      Status: this.state.Status,
      SiteID: this.state.SiteID,
      CompanyID: this.state.CompanyID,
      DDListHeaderID: this.state.DDListHeaderID,
      CollectionListName: this.state.CollectionListName,
      Company: this.state.Company,
      CollectionBank: this.state.CollectionBankLabel,
      BankCurrency: this.state.BankCurrency,
      InvoiceCurrency:
        this.state.ProductName === "Sage Intacct" &&
        this.state.InvoiceCurrencyList
          ? this.state.InvoiceCurrencyLabel
          : "",
      BaseCurrency:
        this.state.ProductName === "Sage Intacct" && this.state.BaseCurrencyList
          ? this.state.BaseCurrencyLabel
          : "",
      TransactionsFrom: this.state.TransactionsFrom,
      TransactionsTo: this.state.TransactionsTo,
      InvoiceCurrencySymbol: this.state.InvoiceCurrencySymbol,
      CollectionDate: this.state.CollectionDate,
      GrandCollectionTotal: this.state.GrandCollectionTotal,
      TotalCollectionEditableLabel: null,
      TotalCollectionEditable: null,
    };
    if (!this.AllCurrenciesMatch()) {
      PopulateDDReport[
        "TotalCollectionEditableLabel"
      ] = `Total Collection (${this.SetCurrencySymbol()})`;
      PopulateDDReport["TotalCollectionEditable"] = parseFloat(
        this.state.TotalCollectionEditable
      ).toFixed(2);
    }

    await DDReport(PopulateDDReport)
      .then((reportOutput) => {
        this.setState({ reportOutput });
      })
      .catch((message) => {
        this.setState((previousState) => ({
          errorList: [...previousState.errorList, message],
        }));
      });
  }

  async handleGenerateNotificationLetters(e) {
    e.preventDefault();

    let CustomerLetterData = {
      SiteID: this.state.SiteID,
      CompanyID: this.state.CompanyID,
      DDListHeaderID: this.state.DDListHeaderID,
      CollectionListName: this.state.CollectionListName,
    };
    await GenerateNotificationLetters(CustomerLetterData)
      .then(() => {})
      .catch((message) => {
        this.setState((previousState) => ({
          errorList: [...previousState.errorList, message],
        }));
      });
  }

  handleBankFile(e) {
    e.preventDefault();

    var OutputMessage = (
      <>
        <p>
          Downloading the file will advance the Mandate Status to the next
          stage.
        </p>

        <p>Are you sure you wish to download the bank file?</p>
      </>
    );

    if (this.state.IsFileOutput) {
      OutputMessage = (
        <>
          <p>The bank file has previously been downloaded. </p>
          <p>Are you sure you wish to download it again?</p>
        </>
      );
    }

    CheckConfirm(OutputMessage, "500px")
      .then(async () => {
        var filename = "bankFormatOutput" + this.state.FileFormatExtension;
        let bankFileSetup = {
          SiteID: this.state.SiteID,
          CompanyID: this.state.CompanyID,
          HeaderID: this.state.HeaderID,
          IsAUDDIS: false,
          SageAccessToken: this.context.SageAccessToken,
        };
        await trackPromise(
          Promise.all([
            axios
              .post("api/DdlistHeaders/GenerateBankFileContent/", bankFileSetup)
              .catch((e) => {
                var message = apiError("getBankListContent Error: ", e);
                this.setState((previousState) => ({
                  errorList: [...previousState.errorList, message],
                }));
              }),
          ]).then((responses) => {
            if (responses[0]) {
              var blob = new Blob([responses[0].data], {
                type: "text/plain;charset=utf-8",
              });
              saveAs(blob, filename);
              if (!this.state.IsFileOutput) {
                var StatusID = 4;
                if (this.state.StatusID === 3) {
                  StatusID = 5;
                }
                this.setState(
                  {
                    StatusID: StatusID,
                    IsFileOutput: true,
                    DataUpdated: true,
                  },
                  () => {
                    this.doSubmit(true);
                  }
                );
              }
            }
          })
        ).catch((e) => {
          var message = apiError("API getBankListContent: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        });
      })
      .catch(() => {});
  }

  async handlePostDDRun(e) {
    e.preventDefault();
    if (!this.state.IsPosted) {
      var IsCheckTranslatedBaseAmountNeeded = this.AllCurrenciesMatch();
      TranslatedBaseAmountError(
        this.state.TotalCollectionEditable,
        this.state.GrandCollectionTotal,
        this.state.BaseCurrencyName,
        this.SetCurrencySymbol(),
        IsCheckTranslatedBaseAmountNeeded
      )
        .then(async () => {
          this.setState(
            {
              DataUpdated: true,
              StatusID: 6,
              PostFeedback: "",
            },
            () => {
              this.doSubmit(false).then(async () => {
                this.RefreshInterval();
                let postMessageJSON = {
                  JobName: this.state.CollectionListName,
                  JobURL:
                    this.context.INTACCT_API_BASE_URL +
                    "/api/RunDD/?siteID=" +
                    this.state.SiteID +
                    "&SYSCompanyID=" +
                    this.state.CompanyID +
                    "&DDListHeaderID=" +
                    this.state.HeaderID +
                    "&accessToken=" +
                    this.context.SageAccessToken,
                };

                await (IsDev
                  ? axios.get(postMessageJSON.JobURL)
                  : axios.post("api/AWSSQS/postMessage/", postMessageJSON)
                )
                  .then(async (responses) => {
                    if (responses[0]) {
                      var feedback = responses[0].data;
                      if (responses[0].data.includes("DD Run complete")) {
                        feedback = "DD Run complete";
                      }
                      this.props.navigate("/DDRun", {
                        state: {
                          updateSuccess: false,
                          AddedSuccess: false,
                          PostFeedback: [feedback],
                        },
                      });
                      this.resetForm();
                    }
                  })
                  .catch((e) => {
                    var message = apiError("API RunDDList: ", e);

                    if (
                      e.message.includes(
                        "timeout" || message === "Network Error"
                      )
                    ) {
                      message = NetworkErrorMessage;
                    }
                    this.setState(
                      (previousState) => ({
                        errorList: [...previousState.errorList, message],
                      }),
                      () => {
                        this.props.navigate("/DDRun", {
                          state: {
                            updateSuccess: false,
                            AddedSuccess: false,
                          },
                        });
                        this.resetForm();
                      }
                    );
                  });
              });
            }
          );
        })
        .catch(() => {});
    }
  }

  async handleShowGenerate(e) {
    e.preventDefault();
    if (!this.state.DisableDDCompanySync) {
      await trackPromise(
        Promise.all([
          axios
            .get(
              this.context.INTACCT_API_BASE_URL +
                "/api/SyncCompany/?siteID=" +
                this.state.SiteID +
                "&SYSCompanyID=" +
                this.state.CompanyID +
                "&accessToken=" +
                this.context.SageAccessToken,
              {
                crossdomain: true,
              }
            )
            .catch((e) => {
              var message = apiError("SyncCompany Error: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
        ]).then(() => {
          this.DoARTransactionsRun();
        })
      ).catch((e) => {
        var message = apiError("API SyncDDList: ", e);
        this.setState((previousState) => ({
          errorList: [...previousState.errorList, message],
        }));
      });
    } else {
      this.DoARTransactionsRun();
    }
  }

  DoARTransactionsRun() {
    this.setState(
      {
        DataUpdated: true,
        errorList: [],
        PostFeedback: "",
        StatusID: 7,
        Message: "",
        ErrorLog: "",
      },
      () => {
        this.doSubmit(false).then(async () => {
          this.RefreshInterval();
          var RunType = "Update Run";
          if (
            this.state.errorList === "No Customer Summary data found" ||
            this.state.errorList === "No Customer Details data found"
          ) {
            RunType = "New Run";
          }

          let postMessageJSON = {
            JobName: this.state.CollectionListName,
            JobURL:
              this.context.INTACCT_API_BASE_URL +
              "/api/ARTransactions/?siteID=" +
              this.state.SiteID +
              "&SYSCompanyID=" +
              this.state.CompanyID +
              "&DDListHeaderID=" +
              this.state.HeaderID +
              "&RunType=" +
              RunType +
              "&accessToken=" +
              this.context.SageAccessToken,
          };

          (IsDev
            ? axios.get(postMessageJSON.JobURL)
            : axios.post("api/AWSSQS/postMessage/", postMessageJSON)
          )
            .then((responses) => {
              if (responses[0]) {
                let DDRunState = {
                  HeaderID: this.state.HeaderID,
                  CompanyID: this.state.CompanyID,
                };
                sessionStorage.setItem("DDRun", JSON.stringify(DDRunState));
                var feedback = responses[0].data;
                if (feedback.includes("Company details synched")) {
                  feedback = "Company Details Synced";
                }
                this.props.navigate("/DDRun", {
                  state: {
                    AddedSuccess: false,
                    updateSuccess: false,
                    PostFeedback: [feedback],
                  },
                });
                this.resetForm();
              }
            })
            .catch((e) => {
              var message = apiError(
                "SyncDDList (AWSSQS/postMessage) Error: ",
                e
              );
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            });
        });
      }
    );
  }

  handleCallback = (name, theData) => {
    // console.log(name);
    // console.log(theData);
    const theState = this.state;
    this.setState(async (state) => {
      var pattCompanyID = new RegExp("CompanyID");
      var pattGrandCollectionTotal = new RegExp("GrandCollectionTotal");
      var pattRefreshCollections = new RegExp("RefreshCollections");
      var pattErrorList = new RegExp("errorList");
      if (pattCompanyID.test(name)) {
        let a = {};
        theState.CompanyList.forEach((element) => {
          a[element.id] = [
            element.content,
            element.disableDDCompanySync,
            element.isTopLevel,
            element.entityCurrency,
            element.useRuntimeFilters,
          ];
        });
        let SetCompany = a[theData][0];
        let SetDisableDDCompanySync = a[theData][1];
        let SetIsTopLevel = a[theData][2];
        let SetEntityCurrency = a[theData][3];
        let SetUseRuntimeFilters = a[theData][4];
        sessionStorage.setItem("CompanyID", theData);
        state.Company = SetCompany;
        state.DisableDDCompanySync = SetDisableDDCompanySync;
        state.IsTopLevel = SetIsTopLevel;
        state.EntityCurrency = SetEntityCurrency;
        state.UseRuntimeFilters = SetUseRuntimeFilters;
        state["NoUpdateRequired"] = false;
        state["DataUpdated"] = true;
        state[name] = theData;
      } else if (pattErrorList.test(name)) {
        if (theData === "clear") {
          state.errorList = [];
        } else {
          state.errorList = [...state.errorList, theData];
        }
      } else if (pattRefreshCollections.test(name)) {
        this.loadData();
      } else if (pattGrandCollectionTotal.test(name)) {
        theData = parseFloat(theData).toFixed(2);
        state[name] = theData;

        let outputJSON = this.setOutputJSON();
        outputJSON["DDListHeaderID"] = this.state.DDListHeaderID;
        outputJSON["DateTimeUpdated"] = this.state.DateTimeUpdated;

        await axios
          .put("api/DdlistHeaders/", outputJSON)
          .then((response) => {})
          .catch((e) => {
            var message = apiError("PutAPI: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          });
      } else {
        state["NoUpdateRequired"] = false;
        state["DataUpdated"] = true;

        state[name] = theData;
      }
      // console.log(state);
      return state;
    });
  };

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "CollectionListName",
          data: this.state.CollectionListName,
          schema: CollectionListNameSchema,
        },
        {
          name: "TotalCollectionEditable",
          data: this.state.TotalCollectionEditable,
          schema: BaseTotalSchema,
        },
      ];
      if (this.state.ProductName !== "Sage Intacct") {
        items = [
          {
            name: "CollectionListName",
            data: this.state.CollectionListName,
            schema: NonIntacctCollectionListNameSchema,
          },
          {
            name: "TotalCollectionEditable",
            data: this.state.TotalCollectionEditable,
            schema: BaseTotalSchema,
          },
        ];
      }

      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.validateForm()
      .then((response) => {
        this.setState({ errorList: "", validationErrorList: "" }, () => {
          if (this.state.StatusID === 1) {
            CollectionDateConfirm(this.state.CollectionDate)
              .then(async () => {
                this.doSubmit(true);
              })
              .catch(() => {
                this.setState({ CollectionDate: this.state.OldCollectionDate });
              });
          } else {
            if (
              this.state.UseRuntimeFilters &&
              !this.state.RuntimeFilterSelected
            ) {
              let OutputNoFilterText = (
                <>
                  <h3>
                    Are you sure you wish to generate a direct debit run with no
                    filtering?
                  </h3>
                </>
              );
              CheckConfirm(OutputNoFilterText, "500px")
                .then(() => {
                  this.doSubmit(true);
                })
                .catch(() => {});
            } else {
              this.doSubmit(true);
            }
          }
        });
      })
      .catch((e) => {
        this.setState({
          validationErrorList: JSON.stringify(e),
        });
      });
  }

  setOutputJSON() {
    let output = this.state,
      DateTimeUpdated = GetDatetime(),
      outputJSON = {
        CollectionListName: output.CollectionListName,
        CollectionDate: output.CollectionDate,
        TransactionsFrom: output.TransactionsFrom,
        TransactionsTo: output.TransactionsTo,
        StatusID: output.StatusID,
        DateTimeUpdated: DateTimeUpdated,
        IsFileOutput: output.IsFileOutput,
        Message: output.Message.includes("Errors") ? output.Message : "",
        ErrorLog: output.ErrorLog
          ? JSON.stringify(output.ErrorLog)
          : output.ErrorLog,
      };

    if (output.GrandCollectionTotal) {
      outputJSON["CollectionTotal"] = output.GrandCollectionTotal;
    } else if (output.CurrentGrandCollectionTotal) {
      outputJSON["CollectionTotal"] = output.CurrentGrandCollectionTotal;
    } else {
      outputJSON["CollectionTotal"] = 0;
    }

    if (output.InvoiceCurrencyLabel === output.BaseCurrencyLabel) {
      outputJSON["BaseTotal"] = outputJSON["CollectionTotal"];
    } else {
      outputJSON["BaseTotal"] = output.TotalCollectionEditable;
    }

    if (output.InvoiceCurrencyLabel === output.BankCurrency) {
      outputJSON["BankTotal"] = outputJSON["CollectionTotal"];
    } else {
      outputJSON["BankTotal"] = output.TotalCollectionEditable;
    }

    return outputJSON;
  }

  async doSubmit(DoReset, FromCommit) {
    let output = this.state;
    let outputJSON = this.setOutputJSON();

    outputJSON["DDListHeaderID"] = output.DDListHeaderID;

    if (this.state.DataUpdated) {
      axios
        .put("api/DdlistHeaders/", outputJSON)
        .then((response) => {
          if (DoReset) {
            this.props.navigate("/DDRun", {
              state: {
                AddedSuccess: false,
                updateSuccess: FromCommit ? false : true,
              },
            });
          }
          this.resetForm();
        })
        .catch((e) => {
          var message = apiError("PutAPI: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
        });
    } else {
      this.setState({ NoUpdateRequired: true });
    }
  }

  SetCurrencySymbol() {
    if (this.state.BankCurrency === this.state.InvoiceCurrencyLabel) {
      return this.state.BaseCurrencyLabel;
    } else {
      return this.state.BankCurrencySymbol;
    }
  }

  RemovalButton() {
    return (
      <div className="right TooltipActionButton">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ReloadInfoTooltip" className="">
              Remove DD Run
            </Tooltip>
          }
        >
          <Button variant="primary" onClick={this.HandleRemoveListItem}>
            <FaTrashAlt /> Remove
          </Button>
        </OverlayTrigger>
      </div>
    );
  }
  RuntimeFilterSelected = () => {
    return (
      <div className="form-control-plaintext">
        {this.state.RuntimeFilterSelected.filterSetName} with Values:{" "}
        {this.state.RuntimeFilterSelected.filterValueList.map(
          (Element, i, { length }) => {
            return (
              <span key={i}>
                {Element.filterValue}
                {length - 1 === i ? "" : <>, </>}
              </span>
            );
          }
        )}
      </div>
    );
  };

  RenderReportButton = () => {
    return (
      <>
        {this.state.StatusID >= 1 && this.state.StatusID <= 5 ? (
          <div className="right TooltipActionButton">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="CommitInfoTooltip" className="">
                  Download Direct Debit Report
                </Tooltip>
              }
            >
              <Button variant="primary" onClick={this.handleReportDownload}>
                <HiDocumentText />
                Report
              </Button>
            </OverlayTrigger>
          </div>
        ) : null}
      </>
    );
  };

  RenderNotificationLetters = (e) => {
    let NotificationLettersCode = (
      <>
        <div className="right TooltipActionButton">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="CommitInfoTooltip" className="">
                Generate Notification Letters
              </Tooltip>
            }
          >
            <Button
              variant="primary"
              onClick={this.handleGenerateNotificationLetters}
            >
              <FaMailBulk />
              Letters
            </Button>
          </OverlayTrigger>
        </div>
      </>
    );
    if (
      this.state.StatusID === 1 &&
      this.state.ShowNotificationLettersOnCreated
    ) {
      return NotificationLettersCode;
    }
    if (this.state.StatusID >= 2 && this.state.StatusID <= 5) {
      return NotificationLettersCode;
    }
  };

  renderContent() {
    const notEditable = this.state.notEditable;
    const sectionElementIsHidden = this.state.sectionElementIsHidden;
    const editVisible = this.state.editVisible;

    return (
      <>
        <Row>
          <Col>
            <Link to="/DDList">
              <FaArrowCircleLeft /> Back to Direct Debit List
            </Link>
          </Col>
          {editVisible &&
          (this.state.StatusID === 1 ||
            (this.state.StatusID === 2 && !this.AllCurrenciesMatch()) ||
            this.state.StatusID === 4) ? (
            <Col className="right TooltipActionButton">
              <Button
                variant="primary"
                type="button"
                onClick={this.handleSetEdit}
              >
                <FaRegEdit /> Edit
              </Button>
            </Col>
          ) : null}
        </Row>

        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
          PostFeedback={this.state.PostFeedback}
          OmittedCustomersFeedback={this.state.OmittedCustomersFeedback}
          DDStatus={this.state.Status}
        />

        <div className="detailsContent">
          <Form
            id="GenerateDDForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <div id="sectionOne">
              <FormLabelbox
                type="text"
                Label="Company"
                name="Company"
                placeholder={this.state.Company}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
              />
            </div>
            <div id="sectionTwo">
              <FormInputBox
                type="text"
                Label="Collection List Name"
                name="CollectionListName"
                placeholder={this.state.CollectionListName}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={4}
                disabled={
                  !notEditable && this.state.StatusID === 1 ? false : true
                }
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
                schema={
                  this.state.ProductName === "Sage Intacct"
                    ? CollectionListNameSchema
                    : NonIntacctCollectionListNameSchema
                }
              />
              <Collapse in={this.state.ShowCollapse}>
                <div>
                  <FormLabelbox
                    type="text"
                    Label="Collection Bank"
                    name="CollectionBank"
                    placeholder={this.state.CollectionBankLabel}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                  />

                  {this.state.TransferCollection ? (
                    <>
                      <FormLabelbox
                        id="TransferBankAccount"
                        Label="Transfer Bank Account"
                        name="TransferBankAccount"
                        placeholder={this.state.TransferBankAccountName}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                      />
                      {this.state.ProductName === "Sage Intacct" ? (
                        <FormLabelbox
                          id="JournalSymbol"
                          Label="Journal Type"
                          name="JournalSymbol"
                          placeholder={this.state.JournalSymbol}
                          LabelColumnAmount={LabelColumnAmount}
                          InputColumnAmount={InputColumnAmount}
                        />
                      ) : null}
                      {this.state.ProductName === "Sage Intacct" &&
                      this.state.TopLevelEntity ? (
                        <FormLabelbox
                          id="TransferLocation"
                          Label={"Transfer Location"}
                          name="TransferLocation"
                          placeholder={this.state.TransferLocation}
                          LabelColumnAmount={LabelColumnAmount}
                          InputColumnAmount={InputColumnAmount}
                        />
                      ) : null}
                    </>
                  ) : null}

                  <FormLabelbox
                    type="text"
                    Label="Bank Currency"
                    name="BankCurrency"
                    placeholder={this.state.BankCurrency}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                  />
                  {this.state.ProductName === "Sage Intacct" &&
                  this.state.InvoiceCurrencyList ? (
                    <FormLabelbox
                      type="text"
                      Label="Invoice Currency"
                      name="InvoiceCurrency"
                      placeholder={this.state.InvoiceCurrencyLabel}
                      LabelColumnAmount={LabelColumnAmount}
                      InputColumnAmount={InputColumnAmount}
                    />
                  ) : null}
                  {this.state.ProductName === "Sage Intacct" &&
                  this.state.BaseCurrencyList ? (
                    <>
                      <FormLabelbox
                        type="text"
                        Label="Base Currency"
                        name="BaseCurrency"
                        placeholder={this.state.BaseCurrencyLabel}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                      />
                    </>
                  ) : null}

                  <FormLabelbox
                    type="text"
                    Label="Transactions From"
                    name="TransactionsFrom"
                    placeholder={this.state.TransactionsFrom}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                  />
                  <FormLabelbox
                    type="text"
                    Label="Transactions To"
                    name="TransactionsTo"
                    placeholder={this.state.TransactionsTo}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                  />
                </div>
              </Collapse>

              <FormDatePicker
                Label="Collection Date"
                name="CollectionDate"
                placeholder={this.state.CollectionDate}
                value={this.handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={2}
                disabled={
                  !notEditable && this.state.StatusID === 1 ? false : true
                }
                hidden={sectionElementIsHidden}
                errorList={this.state.errorList}
                ShowBlank={false}
              />

              {this.state.GrandCollectionTotal ? (
                <FormLabelbox
                  type="text"
                  Label={
                    "Total Collection (" +
                    this.state.InvoiceCurrencySymbol +
                    ")"
                  }
                  name="CollectionTotal"
                  placeholder={this.state.GrandCollectionTotal}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                />
              ) : null}

              {!this.AllCurrenciesMatch() ? (
                <FormInputBox
                  type="text"
                  Label={"Total Collection (" + this.SetCurrencySymbol() + ")"}
                  name="TotalCollectionEditable"
                  placeholder={parseFloat(
                    this.state.TotalCollectionEditable
                  ).toFixed(2)}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  hidden={sectionElementIsHidden}
                  errorList={this.state.errorList}
                  schema={BaseTotalSchema}
                />
              ) : null}

              <Collapse in={this.state.ShowCollapse}>
                <div>
                  {this.state.IsRuntimeFiltering &&
                  this.state.RuntimeFilterSelected ? (
                    <>
                      <Row className={"FormGroup"}>
                        <Form.Label column sm={LabelColumnAmount}>
                          Runtime Filters
                        </Form.Label>
                        <Col sm={InputColumnAmount}>
                          {this.RuntimeFilterSelected()}
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  <FormLabelbox
                    type="text"
                    Label="Status"
                    name="Status"
                    placeholder={this.state.Status}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                  />
                </div>
              </Collapse>

              {!notEditable ? (
                <Row>
                  <Col sm={LabelColumnAmount}></Col>
                  <Col sm={4} className="IconBtn">
                    <Button variant="primary" type="submit">
                      <FaSave />

                      {this.state.NewDDRun ? "Generate" : "Update"}
                    </Button>
                    {this.state.NoUpdateRequired ? DataNotChanged : null}
                    {this.state.validationErrorList
                      ? ValidationError(this.state.validationErrorList)
                      : null}

                    <Button variant="primary" onClick={this.handleFormReset}>
                      <FaTimesCircle /> Cancel
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Form>
          {!this.state.NewDDRun ? (
            <Row>
              <Col>
                {this.state.OmittedCustomersList !== undefined ? (
                  this.state.OmittedCustomersList.length >= 1 &&
                  this.state.StatusID !== 7 ? (
                    <div className="floatLeft">
                      <OmittedCustomers
                        CustomerList={this.state.OmittedCustomersList}
                      />
                    </div>
                  ) : null
                ) : null}
                {this.state.ErrorLog ? (
                  this.state.ErrorLog.length >= 1 ? (
                    <div className="floatLeft">
                      <ErrorLog
                        ProductName={this.state.ProductName}
                        ErrorLog={this.state.ErrorLog[0]}
                      />
                    </div>
                  ) : null
                ) : null}
                {/* Only show on Dev */}
                {IsDev ? this.state.reportOutput : null}
                {/* */}
                {this.state.StatusID === 1 ? (
                  <>
                    {this.context.SettingsList.CanDelete.DDRuns ||
                    this.context.SettingsList.IsAdmin
                      ? this.RemovalButton()
                      : null}
                    <div className="right TooltipActionButton">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="ReloadInfoTooltip" className="">
                            Reload DD List
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          onClick={this.handleShowGenerate}
                        >
                          <FaSync /> Reload
                        </Button>
                      </OverlayTrigger>
                    </div>

                    {!this.state.ErrorLog ||
                    this.state.errorList.length <= 0 ? (
                      <>
                        <div className="right TooltipActionButton">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="CommitInfoTooltip" className="">
                                Commit
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="primary"
                              onClick={this.handleCommitDDRun}
                            >
                              <FaLock /> Commit
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </>
                    ) : null}

                    <div className="right TooltipActionButton">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="CommitInfoTooltip" className="">
                            Collect All
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          onClick={this.handleCollectAll}
                        >
                          <BiSelectMultiple /> Collect All
                        </Button>
                      </OverlayTrigger>
                    </div>

                    <div className="right TooltipActionButton">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="CommitInfoTooltip" className="">
                            Collect None
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          onClick={this.handleCollectNone}
                        >
                          <MdFilterNone /> Collect None
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </>
                ) : (
                  <>
                    {(this.context.SettingsList.CanGenerateOutputFiles ||
                      this.context.SettingsList.IsAdmin) &&
                    this.state.StatusID >= 2 &&
                    this.state.StatusID <= 5 ? (
                      <>
                        <div className="right TooltipActionButton">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="CommitInfoTooltip" className="">
                                Download Bank File
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="primary"
                              onClick={this.handleBankFile}
                            >
                              <FaFileDownload /> Bank File
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </>
                    ) : null}

                    {!this.state.IsPosted &&
                    this.state.StatusID >= 2 &&
                    this.state.StatusID <= 4 &&
                    notEditable &&
                    (this.context.SettingsList.CanPost.DDRuns ||
                      this.context.SettingsList.IsAdmin) ? (
                      <div className="right TooltipActionButton">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="PostDDInfoTooltip" className="">
                              Post Direct Debit Run
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="primary"
                            onClick={this.handlePostDDRun}
                          >
                            <FaFileUpload /> Post
                          </Button>
                        </OverlayTrigger>
                      </div>
                    ) : null}

                    {this.state.StatusID >= 6 && this.state.StatusID <= 7 ? (
                      <div className="right TooltipActionButton">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="ReloadInfoTooltip" className="">
                              Refresh Page
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="primary"
                            onClick={this.HandleRefresh}
                          >
                            <FaSync /> Refresh
                          </Button>
                        </OverlayTrigger>
                      </div>
                    ) : null}
                  </>
                )}

                {!this.state.ErrorLog || this.state.errorList.length <= 0 ? (
                  <>
                    {this.RenderReportButton()}
                    {this.RenderNotificationLetters()}
                  </>
                ) : null}

                <div className="right TooltipActionButton">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="CommitInfoTooltip" className="">
                        {this.state.ShowCollapse
                          ? "Show Summarised Header"
                          : "Show Full Header"}
                      </Tooltip>
                    }
                  >
                    <Button
                      aria-expanded={this.state.ShowCollapse}
                      onClick={() => {
                        this.setState({
                          ShowCollapse: !this.state.ShowCollapse,
                        });
                      }}
                    >
                      {this.state.ShowCollapse ? (
                        <>
                          <FaAnglesUp />
                          Show Summarised Header
                        </>
                      ) : (
                        <>
                          <FaAnglesDown />
                          Show Full Header
                        </>
                      )}
                    </Button>
                  </OverlayTrigger>
                </div>
              </Col>
              {this.state.DDlistCustomerSummary &&
              this.state.DDlistCustomerDetailed ? (
                <DDRunDetails
                  GrandCollectionTotal={this.handleCallback}
                  RefreshCollections={this.handleCallback}
                  CompanyID={this.state.CompanyID}
                  StatusID={this.state.StatusID}
                  DDListHeaderID={this.state.DDListHeaderID}
                  DDlistCustomerSummary={this.state.DDlistCustomerSummary}
                  DDlistCustomerDetailed={this.state.DDlistCustomerDetailed}
                  errorList={this.handleCallback}
                />
              ) : null}
            </Row>
          ) : null}
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="DDRun">
        <h1>Generate List</h1>
        {this.state.NoData ? (
          <IsError errorList={this.state.errorList} />
        ) : !this.state.PlaceholderListFilled ? null : (
          this.renderContent()
        )}
      </div>
    );
  }
}

DDRun.contextType = AppContext;
export default withRouter(DDRun);
