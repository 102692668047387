import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../libs/contextLib";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  FaArrowCircleLeft,
  FaRegEdit,
  FaTimesCircle,
  FaSave,
} from "react-icons/fa";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import UkModulusChecking from "uk-modulus-checking";
import apiError from "../../libs/apiError";
import withRouter from "../../libs/withRouter";
import FormLabelbox from "../Form/FormLabelbox";
import FormInputBox from "../Form/FormInputbox";
import FormSortCodeInput from "../Form/FormSortCodeInput";
import FormDropdown from "../Form/FormDropdown";
import FormDatePicker from "../Form/FormDatePicker";
import CheckConfirm from "../Form/CheckConfirm";
import GetDatetime from "../../libs/GetDatetime";
import IsError from "../../components/IsError";
import OutputMessage from "../../components/OutputMessage";
import {
  LabelColumnAmount,
  InputColumnAmount,
  DataNotChanged,
  ValidationError,
} from "../../libs/Variables";
import {
  AccountNameSchema,
  AccountNumberSchema,
  SortCodeSchema,
  BankPaymentReferenceSchema,
  IBANSchema,
  BicSwiftNumberSchema,
  MandateReferenceSchema,
} from "../../libs/ValidationConsts";
import { handleValidation } from "../handleValidation";
import { Encrypt, Decrypt } from "../../libs/Crypto";
// import BankArchiveDetails from "./BankArchiveDetails";

let blankState = {
  notEditable: true,
  editVisible: true,
  NoData: false,
  updateSuccess: false,
  AddedSuccess: false,
  ShowBlank: true,

  errorList: [],

  CompanyID: "",
  CustomerListID: "",
  CustomerBankDetailID: "",
  CustomerBankMandateID: "",
  NewBankAccount: "",

  MandateRecord: 1,
  BankType: 1,
  AccountNumber: "",
  SortCode: "",
  AdditionalReference: "",
  IBAN: "",
  BicSwiftNumber: "",
  MandateReference: "",
  // MandateStatus: "",
  IsArchived: false,
  IsDefault: true,
  CreatedBy: "",
  MandateSignatureDate: null,

  DateTimeUpdated: "",
  DateTimeCreated: "",
};

class BankDetails extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);
    this.handleSetEdit = this.handleSetEdit.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  async loadData() {
    let CompanyDetails = JSON.parse(sessionStorage.getItem("CompanyDetails"));
    let CompanyID = CompanyDetails.CompanyID;
    let CompanyName = CompanyDetails.CompanyName;
    let SiteID = sessionStorage.getItem("siteID");
    let theBankDetails = JSON.parse(sessionStorage.getItem("BankDetails"));

    if (theBankDetails) {
      // this.state.NoData = false;

      let NewBankAccount = theBankDetails.NewBankAccount;
      let updateSuccess = theBankDetails.updateSuccess;
      let AddedSuccess = theBankDetails.AddedSuccess;

      if (NewBankAccount) {
        await trackPromise(
          Promise.all([
            axios.get("/api/SysmandateStatusLists/").catch((e) => {
              var message = apiError("API Placeholder Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
            axios.get("/api/SysbankTypes/").catch((e) => {
              var message = apiError("API Placeholder Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
            axios
              .get(
                "/api/SyscompanySettings/BankDetailsNeeded/" +
                  SiteID +
                  "/" +
                  CompanyID
              )
              .catch((e) => {
                var message = apiError("API Placeholder Get: ", e);
                this.setState((previousState) => ({
                  errorList: [...previousState.errorList, message],
                }));
              }),
          ]).then((responses) => {
            let MandateStatusLists, BankTypeLists, BankDetailsNeeded;
            if (responses[0] && responses[0] !== undefined) {
              MandateStatusLists = responses[0].data;
            } else {
              throw new Error("Problem Fetching Mandate Status List.");
            }
            if (responses[1] && responses[1] !== undefined) {
              BankTypeLists = responses[1].data;
            } else {
              throw new Error("Problem Fetching Bank Type List.");
            }
            if (responses[2] && responses[2] !== undefined) {
              BankDetailsNeeded = responses[2].data;

              if (BankDetailsNeeded) {
                BankTypeLists = BankTypeLists.filter(
                  (e) => e.content !== "NoBankDetailsRequired"
                );
              }
            } else {
              throw new Error("Problem checking if Bank Checking needed.");
            }
            var bankTypeIndex = 0;
            if (!BankDetailsNeeded) {
              bankTypeIndex = 2;
            }
            let SetMandateRecord = MandateStatusLists[0].id,
              SetRequiresSetupFile = MandateStatusLists[0].requiresSetupFile,
              SetBankType = BankTypeLists[bankTypeIndex].id,
              SetIsSEPA = BankTypeLists[bankTypeIndex].isSEPA,
              SetIsUK = BankTypeLists[bankTypeIndex].isUK;

            this.setState(
              {
                BankDetailsNeeded: BankDetailsNeeded,
                updateSuccess: updateSuccess,
                AddedSuccess: AddedSuccess,

                NewBankAccount: NewBankAccount,
                CompanyName: CompanyName,
                SiteID: SiteID,
                CompanyID: CompanyID,

                PlaceholderListFilled: true,
                MandateStatusLists: MandateStatusLists,
                MandateRecord: SetMandateRecord,
                RequiresSetupFile: SetRequiresSetupFile,

                BankTypeLists: BankTypeLists,
                BankType: SetBankType,
                IsSEPA: SetIsSEPA,
                IsUK: SetIsUK,

                MandateSignatureDate: GetDatetime(true),

                AccountReference: theBankDetails.AccountReference,
                CustomerAccountName: theBankDetails.AccountName,
                AccountName: theBankDetails.AccountName,
                CustomerListID: theBankDetails.CustomerListID,
                MustPassModulusCheck: theBankDetails.MustPassModulusCheck,

                notEditable: !this.state.notEditable,
                editVisible: !this.state.editVisible,
              },
              () => {
                // console.log(this.state);
              }
            );
          })
        ).catch((e) => {
          var message = apiError("API Get: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
            NoData: true,
          }));
        });
      } else {
        await trackPromise(
          Promise.all([
            axios
              .get(
                "/api/CustomerBankDetails/" +
                  SiteID +
                  "/" +
                  CompanyID +
                  "/" +
                  theBankDetails.AccountReference
              )
              .catch((e) => {
                var message = apiError("API Placeholder Get: ", e);
                this.setState((previousState) => ({
                  errorList: [...previousState.errorList, message],
                }));
              }),
            axios.get("/api/SysmandateStatusLists/").catch((e) => {
              var message = apiError("API Mandate Status List Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
            axios.get("/api/SysbankTypes/").catch((e) => {
              var message = apiError("API BankTypes Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            }),
            axios
              .get(
                "/api/SyscompanySettings/BankDetailsNeeded/" +
                  SiteID +
                  "/" +
                  CompanyID
              )
              .catch((e) => {
                var message = apiError("API BankDetailsNeeded Get: ", e);
                this.setState((previousState) => ({
                  errorList: [...previousState.errorList, message],
                }));
              }),
          ])
            .then((responses) => {
              let placeholderArr,
                MandateStatusLists,
                BankTypeLists,
                BankDetailsNeeded;
              if (responses[0] && responses[0] !== undefined) {
                placeholderArr = responses[0].data[0];
              } else {
                throw new Error("Unable to Populate Page.");
              }
              if (responses[1] && responses[1] !== undefined) {
                MandateStatusLists = responses[1].data;
              } else {
                throw new Error("Problem Fetching Mandate Status List.");
              }
              if (responses[2] && responses[2] !== undefined) {
                BankTypeLists = responses[2].data;
              } else {
                throw new Error("Problem Fetching Bank Type List.");
              }
              if (responses[3] && responses[3] !== undefined) {
                BankDetailsNeeded = responses[3].data;
              } else {
                throw new Error("Problem checking if Bank Checking needed.");
              }

              var a = {};
              MandateStatusLists.forEach((element) => {
                a[element.id] = [element.id, element.requiresSetupFile];
              });
              let SetMandateRecord = a[placeholderArr.mandateStatusListId][0],
                SetRequiresSetupFile = a[placeholderArr.mandateStatusListId][1];
              var bankType = placeholderArr.bankType;

              if (BankDetailsNeeded && bankType === 3) {
                bankType = 1;
              }
              if (!BankDetailsNeeded && bankType !== 3) {
                bankType = 3;
              }
              var b = {};
              BankTypeLists.forEach((element) => {
                b[element.id] = [element.id, element.isSEPA, element.isUK];
              });
              let SetBankType = b[placeholderArr.bankType][0],
                SetIsSEPA = b[placeholderArr.bankType][1],
                SetIsUK = b[placeholderArr.bankType][2],
                SetMandateSignatureDate = placeholderArr.mandateSignatureDate;

              if (placeholderArr.mandateSignatureDate !== null) {
                var SetShowBlank = false;
              } else {
                SetShowBlank = true;
                SetMandateSignatureDate = GetDatetime(true);
              }

              this.setState(
                {
                  BankDetailsNeeded,
                  updateSuccess: updateSuccess,
                  AddedSuccess: AddedSuccess,
                  CompanyName: CompanyName,
                  SiteID: SiteID,
                  PlaceholderListFilled: true,

                  MandateStatusLists: MandateStatusLists,
                  BankTypeLists: BankTypeLists,
                  MandateRecord: SetMandateRecord,
                  RequiresSetupFile: SetRequiresSetupFile,
                  BankType: SetBankType,
                  IsSEPA: SetIsSEPA,
                  IsUK: SetIsUK,
                  ShowBlank: SetShowBlank,
                  MustPassModulusCheck: theBankDetails.MustPassModulusCheck,
                  CompanyID: placeholderArr.companyID,
                  CustomerListID: placeholderArr.customerListID,
                  CustomerBankDetailID: placeholderArr.customerBankDetailID,
                  CustomerBankMandateID: placeholderArr.customerBankMandateID,
                  AccountReference: placeholderArr.accountReference,
                  CustomerAccountName: placeholderArr.customerAccountName,
                  AccountName: placeholderArr.accountName,
                  AccountNumber: Decrypt(placeholderArr.accountNumber),
                  SortCode: Decrypt(placeholderArr.sortCode),
                  IBAN: Decrypt(placeholderArr.iban),
                  BicSwiftNumber: Decrypt(placeholderArr.bicSwiftNumber),
                  AdditionalReference: placeholderArr.bankPaymentReference,
                  MandateReference:
                    placeholderArr.mandateReference !== ""
                      ? placeholderArr.mandateReference
                      : placeholderArr.accountReference,
                  IsArchived: placeholderArr.isArchived,
                  IsDefault: placeholderArr.isDefault,
                  CreatedBy: placeholderArr.createdBy,
                  ArchiveData: placeholderArr.archiveData,
                  MandateSignatureDate: SetMandateSignatureDate,

                  DateTimeUpdated: placeholderArr.dateTimeUpdated,
                  DateTimeCreated: placeholderArr.dateTimeCreated,
                },
                () => {
                  // console.log(this.state);
                }
              );
            })
            .catch((e) => {
              var message = apiError("API Get: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
                NoData: true,
              }));
            })
        );
      }
    } else {
      this.setState({ NoData: true });
    }
  }

  handleFormReset(e) {
    e.preventDefault();
    if (this.state.NewBankAccount) {
      this.props.navigate("/CustomerList", {
        state: { CompanyID: this.state.CompanyID },
      });
    } else {
      this.resetForm();
    }
  }

  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      this.loadData();
    });
  }

  handleSetEdit() {
    this.setState({
      notEditable: false,
      editVisible: false,
      updateSuccess: false,
      AddedSuccess: false,
      DataUpdated: false,
    });
  }

  handleCallback = (name, theData) => {
    const theState = this.state;
    this.setState(
      (state) => {
        var pattMandateRecord = new RegExp("MandateRecord");
        var pattBankType = new RegExp("BankType");
        let a = {};
        theState.MandateStatusLists.forEach((element) => {
          a[element.id] = [element.id, element.requiresSetupFile];
        });

        if (pattMandateRecord.test(name)) {
          let SetMandateRecord = a[theData][0],
            SetRequiresSetupFile = a[theData][1];

          state.MandateRecord = SetMandateRecord;
          state.RequiresSetupFile = SetRequiresSetupFile;
        }
        if (pattBankType.test(name)) {
          let b = {};
          theState.BankTypeLists.forEach((element) => {
            b[element.id] = [element.id, element.isSEPA, element.isUK];
          });

          let SetBankType = b[theData][0],
            SetIsSEPA = b[theData][1],
            SetIsUK = b[theData][2];

          var MandateIndex = state.MandateRecord;

          if (
            SetIsUK &&
            (state.MandateRecord === 2 || state.MandateRecord === 1)
          ) {
            MandateIndex = 1;
          }
          if (
            SetIsSEPA &&
            (state.MandateRecord === 2 || state.MandateRecord === 1)
          ) {
            MandateIndex = 2;
          }

          let SetMandateRecord = a[MandateIndex][0],
            SetRequiresSetupFile = a[MandateIndex][1];
          state.MandateRecord = SetMandateRecord;
          state.RequiresSetupFile = SetRequiresSetupFile;
          state.BankType = SetBankType;
          state.IsSEPA = SetIsSEPA;
          state.IsUK = SetIsUK;
        } else {
          state[name] = theData;
        }
        state["NoUpdateRequired"] = false;
        state["DataUpdated"] = true;
        return state;
      },
      () => {
        // console.log(this.state);
      }
    );
  };

  SortCodeCheck() {
    var ModulusCheck = new UkModulusChecking({
      accountNumber: this.state.AccountNumber,
      sortCode: this.state.SortCode,
    });
    const checks = [];
    const sortCode = parseInt(ModulusCheck.sortCode, 10);

    for (const check of ModulusCheck.weightTable) {
      if (sortCode >= check.start && sortCode <= check.end) {
        checks.push(check);
      }
    }

    if (checks.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  async validateForm() {
    return new Promise(async (resolve, reject) => {
      if (this.state.IsUK) {
        let items = [
          {
            name: "AccountName",
            data: this.state.AccountName,
            schema: AccountNameSchema,
          },
          {
            name: "AdditionalReference",
            data: this.state.AdditionalReference,
            schema: BankPaymentReferenceSchema,
          },
          {
            name: "MandateReference",
            data: this.state.MandateReference,
            schema: MandateReferenceSchema,
          },
          {
            name: "SortCode",
            data: this.state.SortCode,
            schema: SortCodeSchema,
          },
          {
            name: "AccountNumber",
            data: this.state.AccountNumber,
            schema: AccountNumberSchema,
          },
        ];

        var Status = [];

        items.forEach(async (element, i) => {
          // Status[i] = false;
          await handleValidation(element)
            .then(() => {
              Status[i] = true;
            })
            .catch((e) => {
              reject(e);
            });
        });
        var ModulusCheck = new UkModulusChecking({
          accountNumber: this.state.AccountNumber,
          sortCode: this.state.SortCode,
        }).isValid();

        if (ModulusCheck) {
          Status.push(true);
        } else {
          if (this.state.MustPassModulusCheck) {
            reject("Modulus Check Failed.");
            Status.push(false);
          } else {
            let OutputText = (
              <>
                <h3>
                  The Sortcode and Account Number supplied has been flagged as
                  invalid by our validation checks.
                </h3>
                <p> Do you want to proceed with the data as submitted?</p>
              </>
            );
            await CheckConfirm(OutputText, "710px")
              .then(() => {
                Status.push(true);
              })
              .catch(() => {
                // reject("Modulus Check Failed.");
                Status.push(false);
              });
          }
        }
        if (this.SortCodeCheck()) {
          Status.push(true);
        } else {
          if (this.state.MustPassModulusCheck) {
            reject("SortCode Check Failed.");
            Status.push(false);
          } else {
            let OutputText = (
              <>
                <h3>
                  The Sortcode supplied has been flagged as invalid by our
                  validation checks.
                </h3>
                <p> Do you want to proceed with the data as submitted?</p>
              </>
            );
            await CheckConfirm(OutputText, "710px")
              .then(() => {
                Status.push(true);
              })
              .catch(() => {
                //reject("Modulus Check Failed.");
                Status.push(false);
              });
          }
        }

        if (!Status.includes(false) && Status.length > 0) {
          resolve(true);
        }
      }
      if (this.state.IsSEPA) {
        let items = [
          {
            name: "AccountName",
            data: this.state.AccountName,
            schema: AccountNameSchema,
          },
          {
            name: "AdditionalReference",
            data: this.state.AdditionalReference,
            schema: BankPaymentReferenceSchema,
          },
          {
            name: "MandateReference",
            data: this.state.MandateReference,
            schema: MandateReferenceSchema,
          },
          {
            name: "IBAN",
            data: this.state.IBAN,
            schema: IBANSchema,
          },
          {
            name: "BicSwiftNumber",
            data: this.state.BicSwiftNumber,
            schema: BicSwiftNumberSchema,
          },
        ];
        Status = [];

        items.forEach(async (element, i) => {
          Status[i] = false;
          await handleValidation(element)
            .then(() => {
              Status[i] = true;
            })
            .catch((e) => {
              reject(e);
            });
          if (!Status.includes(false)) {
            resolve(true);
          }
        });
      }
      if (
        !this.state.IsUK &&
        !this.state.IsSEPA &&
        !this.state.BankDetailsNeeded
      ) {
        let items = [
          {
            name: "AccountName",
            data: this.state.AccountName,
            schema: AccountNameSchema,
          },
          {
            name: "AdditionalReference",
            data: this.state.AdditionalReference,
            schema: BankPaymentReferenceSchema,
          },
          {
            name: "MandateReference",
            data: this.state.MandateReference,
            schema: MandateReferenceSchema,
          },
        ];

        Status = [];
        items.forEach(async (element, i) => {
          await handleValidation(element)
            .then(() => {
              Status[i] = true;
            })
            .catch((e) => {
              reject(e);
            });
        });

        if (!Status.includes(false) && Status.length === 0) {
          resolve(true);
        }
      }
      if (
        !this.state.IsUK &&
        !this.state.IsSEPA &&
        this.state.BankDetailsNeeded
      ) {
        reject(
          "The DD File Format selected for this company requires Bank Details to be supplied."
        );
      }
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.validateForm()
      .then(() => {
        this.setState({ errorList: "" }, () => {
          this.doSubmit();
        });
      })
      .catch((e) => {
        this.setState({
          validated: false,
          validationErrorList: JSON.stringify(e),
        });
      });
  }

  async doSubmit() {
    let output = this.state;

    let DateTimeUpdated = GetDatetime();

    // console.log(output);

    let outputJSON = {
      CompanyID: output.CompanyID,
      CustomerListID: output.CustomerListID,
      AccountReference: output.AccountReference,
      CustomerAccountName: output.CustomerAccountName,
      AccountName: output.AccountName,
      AccountNumber: Encrypt(output.AccountNumber),
      SortCode: Encrypt(output.SortCode),
      IBAN: Encrypt(output.IBAN),
      BicSwiftNumber: Encrypt(output.BicSwiftNumber),
      BankPaymentReference: output.AdditionalReference,
      MandateStatusListId: output.MandateRecord,
      BankType: output.BankType,
      MandateReference:
        output.MandateReference === ""
          ? output.AccountReference
          : output.MandateReference,
      MandateSignatureDate: output.MandateSignatureDate,
      IsDefault: output.IsDefault,
      IsArchived: output.IsArchived,

      DateTimeUpdated: DateTimeUpdated,
      SiteID: this.state.SiteID,
    };

    if (this.state.NewBankAccount) {
      // console.log("post");
      outputJSON["CreatedBy"] = this.context.usr;
      let DateTimeCreated = GetDatetime();
      outputJSON["DateTimeCreated"] = DateTimeCreated;

      // console.log(outputJSON);
      await trackPromise(
        axios
          .post("api/CustomerBankDetails/", outputJSON)
          .then((results) => {
            // console.log(results);

            var postResponse = results.data;

            let BankDetailState = {
              AccountReference: postResponse.accountReference,
              CustomerAccountName: postResponse.accountName,
              AccountName: postResponse.accountName,
              CustomerListID: postResponse.customerListID,
              AddedSuccess: true,
              NewBankAccount: false,
            };
            sessionStorage.setItem(
              "BankDetails",
              JSON.stringify(BankDetailState)
            );

            this.props.navigate("/BankDetails");
            this.resetForm();
          })
          .catch((e) => {
            var message = apiError("PutAPI: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          })
      );
    } else {
      // console.log("put");
      outputJSON["CreatedBy"] = output.CreatedBy;
      outputJSON["DateTimeCreated"] = output.DateTimeCreated;
      outputJSON["CustomerBankDetailID"] = output.CustomerBankDetailID;
      outputJSON["CustomerBankMandateID"] = output.CustomerBankMandateID;

      // console.log(outputJSON);
      if (this.state.DataUpdated) {
        await trackPromise(
          axios
            .put(
              "api/CustomerBankDetails/" +
                output.CustomerBankDetailID +
                "/" +
                output.CustomerBankMandateID,
              outputJSON
            )
            .then((response) => {
              // console.log(response);
              let BankDetailState = {
                NewBankAccount: false,
                AccountName: this.state.AccountName,
                AccountReference: this.state.AccountReference,
                CustomerListID: this.state.CustomerListID,
                updateSuccess: true,
              };
              sessionStorage.setItem(
                "BankDetails",
                JSON.stringify(BankDetailState)
              );

              this.props.navigate("/BankDetails");
              this.resetForm();
            })
            .catch((e) => {
              var message = apiError("PutAPI: ", e);
              this.setState((previousState) => ({
                errorList: [...previousState.errorList, message],
              }));
            })
        );
      } else {
        this.setState({ NoUpdateRequired: true });
      }
    }
  }

  renderContent() {
    const notEditable = this.state.notEditable;
    const editVisible = this.state.editVisible;
    return (
      <div className="BankDetails">
        <h1>Bank Details</h1>

        <Row>
          <Col>
            <Link
              to="/CustomerList"
              state={{ CompanyID: this.state.CompanyID }}
            >
              <FaArrowCircleLeft /> Back to Customer List
            </Link>
          </Col>
          {editVisible &&
          (this.context.SettingsList.CanEdit.BankDetails ||
            this.context.SettingsList.IsAdmin) ? (
            <Col className="right TooltipActionButton">
              <Button
                variant="primary"
                type="button"
                onClick={this.handleSetEdit}
              >
                <FaRegEdit /> Edit
              </Button>
            </Col>
          ) : null}
        </Row>
        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
        />
        <div className="detailsContent">
          <Form
            id="BankDetailsForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleFormReset}
          >
            <FormLabelbox
              type="text"
              Label="Company Name"
              name="CompanyName"
              placeholder={this.state.CompanyName}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />
            <FormLabelbox
              type="text"
              Label="Account Reference"
              name="AccountReference"
              placeholder={this.state.AccountReference}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />
            <FormLabelbox
              type="text"
              Label="Account Name"
              name="CustomerAccountName"
              placeholder={this.state.CustomerAccountName}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />
            <FormDropdown
              id="BankType"
              Label="Bank Type"
              name="BankType"
              placeholder={this.state.BankType}
              itemContent={this.state.BankTypeLists}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
            />
            <FormInputBox
              type="text"
              Label="Account Name"
              name="AccountName"
              placeholder={this.state.AccountName}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
              schema={AccountNameSchema}
            />
            {this.state.IsUK ? (
              <>
                <FormSortCodeInput
                  type="text"
                  Label="Sort Code"
                  name="SortCode"
                  placeholder={this.state.SortCode}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={2}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                  schema={SortCodeSchema}
                />
                <FormInputBox
                  type="text"
                  Label="Account Number"
                  name="AccountNumber"
                  placeholder={this.state.AccountNumber}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                  schema={AccountNumberSchema}
                />
              </>
            ) : null}
            {this.state.IsSEPA ? (
              <>
                <FormInputBox
                  type="text"
                  Label="IBAN"
                  name="IBAN"
                  placeholder={this.state.IBAN}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                  schema={IBANSchema}
                />
                <FormInputBox
                  type="text"
                  Label="BIC / Swift Number"
                  name="BicSwiftNumber"
                  placeholder={this.state.BicSwiftNumber}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={this.state.errorList}
                  schema={BicSwiftNumberSchema}
                />
              </>
            ) : null}
            <FormInputBox
              type="text"
              Label="Additional Reference"
              name="AdditionalReference"
              placeholder={this.state.AdditionalReference}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
              schema={BankPaymentReferenceSchema}
            />
            <FormDropdown
              id="MandateRecord"
              Label="Mandate Record"
              name="MandateRecord"
              placeholder={this.state.MandateRecord}
              itemContent={this.state.MandateStatusLists}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
            />
            <FormInputBox
              type="text"
              Label="Mandate Reference"
              name="MandateReference"
              placeholder={this.state.MandateReference}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={this.state.errorList}
              schema={MandateReferenceSchema}
            />
            <FormDatePicker
              Label="Mandate Signature Date"
              name="MandateSignatureDate"
              placeholder={this.state.MandateSignatureDate}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={2}
              disabled={notEditable}
              errorList={this.state.errorList}
              ShowBlank={this.state.ShowBlank}
            />

            {!notEditable ? (
              <Row>
                <Col sm={LabelColumnAmount}></Col>
                <Col sm={8} className="IconBtn">
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ marginRight: "20px", float: "left" }}
                  >
                    <FaSave /> Save
                  </Button>
                  {this.state.NoUpdateRequired ? DataNotChanged : null}
                  {this.state.validationErrorList
                    ? ValidationError(this.state.validationErrorList)
                    : null}

                  <Button variant="primary" onClick={this.handleFormReset}>
                    <FaTimesCircle /> Cancel
                  </Button>
                </Col>
              </Row>
            ) : null}

            {/* {!this.state.NewBankAccount &&
            this.state.ArchiveData.length >= 2 ? (
              <BankArchiveDetails rows={this.state.ArchiveData} />
            ) : null} */}
          </Form>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.NoData) {
      return <IsError errorList={this.state.errorList} />;
    } else {
      if (!this.state.PlaceholderListFilled) {
        return null;
      } else {
        return this.renderContent();
      }
    }
  }
}
BankDetails.contextType = AppContext;
export default withRouter(BankDetails);
