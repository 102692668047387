import React, { Component } from "react";
import UserListTable from "../components/UserList/UserListTable";
import axios from "axios";
import { AppContext } from "../libs/contextLib";
import apiError from "../libs/apiError";
import { trackPromise } from "react-promise-tracker";
import IsError from "../components/IsError";

class UserList extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = { errorList: [] };
  }

  componentDidMount() {
    this.loadData();
  }
  async loadData() {
    let theSiteID = sessionStorage.getItem("siteID");
    this.setState({
      siteID: theSiteID,
    });
    if (theSiteID) {
      await trackPromise(
        Promise.all([
          axios.get("/api/UserSites/GetUserList/" + theSiteID).catch((e) => {
            apiError("apiUrl Get: ", e);
          }),
        ])
          .then((responses) => {
            if (responses[0] && responses[0] !== undefined) {
              let List = responses[0].data;
              this.setState({ List });
            } else {
              throw new Error("No Active Users found");
            }
          })
          .catch((e) => {
            var message = apiError("UserList: ", e);
            this.setState((previousState) => ({
              errorList: [...previousState.errorList, message],
            }));
          })
      );
    }
  }

  render() {
    return (
      <div className="UserList">
        <h1>User List</h1>
        {this.state.errorList.length >= 1 ? (
          <IsError errorList={this.state.errorList} />
        ) : this.state.List ? (
          <UserListTable rows={this.state.List} />
        ) : null}
      </div>
    );
  }
}
UserList.contextType = AppContext;
export default UserList;
