import React, { Component } from "react";
import axios from "axios";
import { AppContext } from "../libs/contextLib";
import { FaTimesCircle } from "react-icons/fa";
import apiError from "../libs/apiError";
import { trackPromise } from "react-promise-tracker";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import BootstrapTable from "../components/react-bootstrap-table2";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import NewIP from "../components/IP/NewIP";
import IPDetails from "../components/IP/IPDetails";
import IPRemoval from "../components/IP/IPRemoval";
import OutputMessage from "../components/OutputMessage";
import { SetObserver } from "../libs/Observer";

let setSiteName = sessionStorage.getItem("siteName");
let blankState = {
  siteName: setSiteName,
  open: false,
  openNew: false,
  errorList: [],
  RemovalSuccess: null,
  updateSuccess: null,
  AddedSuccess: null,
};
class IPWhiteList extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = blankState;
  }

  componentDidMount() {
    this.loadData();
    SetObserver();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  async resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }));
    this.setState({ ...stateReset, ...blankState }, () => {
      this.loadData();
    });
  }
  async loadData() {
    this.setState({
      errorList: [],
    });

    if (!this.context.IPWhitelistSetting) {
      this.setState((previousState) => ({
        errorList: [
          ...previousState.errorList,
          "The 'Use IP Whitelist' setting has not been set, any IP restrictions listed on this page will not take effect until this has been switched on.",
        ],
      }));
    }

    let EventContents = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        if (row.cidrRange !== null) {
          row["IPType"] = "CIDR";
        } else if (row.ipAddressTo !== null) {
          row["IPType"] = "Group";
        } else {
          row["IPType"] = "Single";
        }
        this.setState({
          open: true,
          rowData: row,
          AddedSuccess: null,
          RemovalSuccess: null,
          updateSuccess: null,
        });
      },
    };
    let theSiteID = await sessionStorage.getItem("siteID"),
      setSiteName = await sessionStorage.getItem("siteName");
    this.setState(
      {
        siteID: theSiteID,
        siteName: setSiteName,
      },
      async () => {
        if (theSiteID && setSiteName) {
          await trackPromise(
            Promise.all([
              axios
                .get("/api/Ipwhitelists/" + this.state.siteName)
                .catch((e) => {
                  var message = apiError("API IPList Get: ", e);
                  this.setState((previousState) => ({
                    errorList: [...previousState.errorList, message],
                    List: null,
                    columns: null,
                    RemovalSuccess: null,
                    updateSuccess: null,
                    AddedSuccess: null,
                  }));
                }),
            ]).then((responses) => {
              if (responses[0] && responses[0] !== undefined) {
                let List = responses[0].data;
                let RemoveIPColumn = [
                  {
                    dataField: "RemoveIP",
                    text: "",
                    editable: false,
                    headerAlign: "center",
                    align: "center",
                    isDummyField: true,
                    headerClasses: "smlButtonCol",
                    formatter: (cellContent, row) => {
                      return (
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip id="RemoveAccountInfoTooltip" className="">
                              Remove IP Address
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="primary"
                            className="ButtonRemove"
                            onClick={(e) => this.HandleRemoveAccount(e, row)}
                          >
                            <FaTimesCircle />
                          </Button>
                        </OverlayTrigger>
                      );
                    },
                  },
                ];
                let columnList = [
                  {
                    dataField: "ipid",
                    text: "IP Address",
                    events: EventContents,
                    filter: textFilter({ placeholder: " " }),
                    formatter: (cellContent, row) => {
                      var ip;
                      var type;
                      if (row.cidrRange !== null) {
                        ip = row.cidrRange;
                        type = "CIDR";
                      } else if (row.ipAddressTo !== null) {
                        ip = row.ipAddressFrom + " - " + row.ipAddressTo;
                        type = "Range";
                      } else {
                        ip = row.ipAddressFrom;
                        type = "Single IP";
                      }
                      let overlay = (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="ViewInfoTooltip" className="">
                              <span>{type}</span>
                            </Tooltip>
                          }
                        >
                          <span>{ip}</span>
                        </OverlayTrigger>
                      );
                      return overlay;
                    },
                  },
                  {
                    dataField: "description",
                    text: "Description",
                    filter: textFilter({ placeholder: " " }),
                    events: EventContents,
                  },
                ];

                let columns = columnList;
                if (List.length >= 1) {
                  columns = RemoveIPColumn.concat(columnList);
                }

                this.setState({
                  List,
                  columns,
                });
              }
            })
          );
        }
      }
    );
  }

  handleCallback = (name, theData) => {
    // console.log(name, theData);
    var pattErrorList = new RegExp("errorList"),
      pattRemovalSuccess = new RegExp("RemovalSuccess"),
      pattUpdateSuccess = new RegExp("updateSuccess"),
      pattAddedSuccess = new RegExp("AddedSuccess");

    this.setState((state) => {
      if (pattErrorList.test(name)) {
        state.errorList = [...state.errorList, theData];
      } else {
        state[name] = theData;
      }
      return state;
    });

    if (
      pattRemovalSuccess.test(name) ||
      pattAddedSuccess.test(name) ||
      pattUpdateSuccess.test(name)
    ) {
      this.setState({ [name]: theData }, () => {
        this.loadData();
      });
    }
  };

  HandleRemoveAccount(e, row) {
    e.preventDefault();
    IPRemoval(row)
      .then((r) => {
        this.handleCallback("RemovalSuccess", [r]);
      })
      .catch((e) => {
        this.handleCallback("errorList", e);
      });
  }
  renderData() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
    let IPListPage = 1;
    if (sessionStorage.getItem("IPListPage")) {
      IPListPage = sessionStorage.getItem("IPListPage");
    }
    const paginationOptions = {
      page: parseInt(IPListPage),
      onPageChange: (page) => {
        sessionStorage.setItem("IPListPage", page);
      },
      hidePageListOnlyOnePage: true,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "All",
          value: this.state.List.length,
        },
      ],
    };

    const defaultSorted = [
      {
        dataField: "userName",
        order: "asc",
      },
    ];
    return (
      <>
        <OutputMessage
          errorList={this.state.errorList}
          updateSuccess={this.state.updateSuccess}
          AddedSuccess={this.state.AddedSuccess}
          PostFeedback={this.state.RemovalSuccess}
        />
        <div className="IPListTable">
          <BootstrapTable
            bootstrap4
            responsive
            hover
            keyField="ipid"
            data={this.state.List}
            columns={this.state.columns}
            filter={filterFactory()}
            pagination={paginationFactory(paginationOptions)}
            defaultSorted={defaultSorted}
          />
          {this.state.open ? (
            <IPDetails
              open={this.state.open}
              rowData={this.state.rowData}
              setOpen={this.handleCallback}
              errorList={this.handleCallback}
              updateSuccess={this.handleCallback}
            />
          ) : null}
        </div>
      </>
    );
  }
  render() {
    return (
      <div className="IPList">
        <h1>IP White List</h1>
        {this.state.List ? (
          this.renderData()
        ) : (
          <OutputMessage
            errorList={this.state.errorList}
            updateSuccess={this.state.updateSuccess}
            AddedSuccess={this.state.AddedSuccess}
            PostFeedback={this.state.RemovalSuccess}
          />
        )}
        <NewIP
          errorList={this.handleCallback}
          AddedSuccess={this.handleCallback}
          open={this.state.openNew}
          setOpen={this.handleCallback}
        />
      </div>
    );
  }
}
IPWhiteList.contextType = AppContext;
export default IPWhiteList;
