import React, { useState, useEffect, useContext, useCallback } from "react";
import { AppContext } from "../../libs/contextLib";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { FaTimesCircle, FaSave } from "react-icons/fa";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import GetDatetime from "../../libs/GetDatetime";
import apiError from "../../libs/apiError";
import { SetObserver } from "../../libs/Observer";
import ARUDDImportTable from "./ARUDDImportTable";
import { ValidationError } from "../../libs/Variables";

let ReverseStatus = "Reverse?",
  SelectionsNotPossible = true;

const ARUDDConsoleSelection = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [ValidationErrorList, setValidationErrorList] = useState();
  const [OutputList, setOutputList] = useState([]);
  const [NoSelections, setNoSelections] = useState(false);
  const [NoSelectionsMessage, setSelectionsMessage] = useState();

  const loadData = useCallback(async () => {
    try {
      let CustomerList = props.CustomerList,
        DDRejectionHeaderInfo = props.DDRejectionHeaderInfo;

      if (CustomerList) {
        let TableJSON = [];
        let TableKey = 0;
        let ProductName = sessionStorage.getItem("productName");

        for (let CustomerElement of CustomerList) {
          let Status = ReverseStatus;
          if (
            (ProductName === "Sage Intacct" &&
              (CustomerElement.postedTransactionID === null ||
                CustomerElement.postedTransactionID === false)) ||
            (ProductName === "Sage 200c Standard" &&
              (CustomerElement.sageURN === null ||
                CustomerElement.sageURN === false))
          ) {
            Status = "Not posted to Sage";
          }
          if (CustomerElement.isReversed) {
            Status = "Has been marked as Reversed";
          }
          if (CustomerElement.value !== 0) {
            TableJSON.push({
              TableKey: TableKey++,
              Tick: Status === ReverseStatus ? false : true,
              Status: Status,
              ACRef: CustomerElement.acRef,
              ACName: CustomerElement.acName,
              TransactionID: CustomerElement.transactionID,
              Ref: "",
              ReturnCode: "",
              ReturnDescription: "",
              Date: DDRejectionHeaderInfo.OriginalProcessingDate,
              Value: CustomerElement.value,

              CurrencySymbol: DDRejectionHeaderInfo.CurrencySymbol,
              DDListCustomersID: CustomerElement.ddListCustomersID,
              OriginalProcessingDate:
                DDRejectionHeaderInfo.OriginalProcessingDate,
              OriginalCurrency: "",
              PayerAccountNumber: CustomerElement.accountNumber,
              PayerSortCode: CustomerElement.sortCode,
              BankName: "",
              BranchName: "",
            });
          }
        }

        setOutputList(TableJSON);
      }
    } catch (e) {
      var message = apiError("API Get: ", e);
      props.errorList(message);
    }
  }, [props]);

  useEffect(() => {
    loadData();
    SetObserver();
  }, [loadData]);

  const handleFormReset = (e) => {
    e.preventDefault();
    props.FormReset("FormReset", true);
  };

  const resetForm = useCallback(async () => {
    props.errorList([]);
    loadData();
  }, [loadData, props]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let DDRejectionHeaderInfo = props.DDRejectionHeaderInfo;
    let SiteID = sessionStorage.getItem("siteID");
    let DateTime = GetDatetime();
    let outputJSON = {};
    let ListItems = [];
    let ListTransactionIDs = [];
    let RejectionTotal = 0;

    outputJSON["CompanyID"] = DDRejectionHeaderInfo.CompanyID;
    outputJSON["DDListHeaderID"] = DDRejectionHeaderInfo.DDListHeaderID;
    outputJSON["FileName"] = DDRejectionHeaderInfo.RunSelected;
    outputJSON["RejectionDate"] = DDRejectionHeaderInfo.RejectionDate;
    outputJSON["DateTimeUpdated"] = DateTime;
    outputJSON["DateTimeCreated"] = DateTime;
    outputJSON["SiteID"] = SiteID;

    OutputList.forEach((element) => {
      if (element.Status === ReverseStatus) {
        SelectionsNotPossible = false;
      }
      if (element.Tick) {
        let Value = parseFloat(element.Value);
        RejectionTotal = parseFloat(RejectionTotal) + Value;
        ListItems.push({
          DDListCustomersID: element.DDListCustomersID,
          RejectionReasonCode: element.ReturnCode,
          RejectionReasonName: element.ReturnDescription,
          OriginalProcessingDate: element.OriginalProcessingDate,
          OriginalValue: Value,
          OriginalCurrency: element.OriginalCurrency,
          PayerAccountNumber: element.PayerAccountNumber,
          PayerSortCode: element.PayerSortCode,
          Reference: element.Ref,
          BankName: element.BankName,
          BranchName: element.BranchName,
        });
        element.TransactionID.forEach((ID) => {
          ListTransactionIDs.push(ID);
        });
      }
    });
    outputJSON["RejectionTotal"] = RejectionTotal.toFixed(2);
    outputJSON["ListItems"] = ListItems;
    outputJSON["ListTransactionIDs"] = ListTransactionIDs;
    // console.log(outputJSON);

    if (ListItems.length <= 0) {
      var message = "You must select at least one transaction to reverse.";
      if (SelectionsNotPossible) {
        message = "No possible transactions found to reverse.";
      }
      setSelectionsMessage(message);
      setNoSelections(true);
      return;
    }

    await trackPromise(
      axios
        .post("api/DdrejectionHeaders", outputJSON)
        .then(async (response) => {
          let newDDrejectionHeaderId = response.data.ddrejectionHeaderId;

          await trackPromise(
            Promise.all([
              axios.get(
                context.INTACCT_API_BASE_URL +
                  "/api/ARReverse/?siteID=" +
                  SiteID +
                  "&SYSCompanyID=" +
                  DDRejectionHeaderInfo.CompanyID +
                  "&DDRejectionHeaderID=" +
                  newDDrejectionHeaderId +
                  "&accessToken=" +
                  context.SageAccessToken,
                {
                  crossdomain: true,
                }
              ),
            ]).then((responses) => {
              if (responses[0] && responses[0] !== undefined) {
                navigate("/ARUDDResults", {
                  state: {
                    PostFeedback: [
                      `The Rejection(s) selected has been processed for the Direct Debit run ${DDRejectionHeaderInfo.RunSelected}.`,
                    ],
                  },
                });
                resetForm();
                props.FormReset("FormReset", true);
              }
            })
          ).catch((e) => {
            var message = apiError("API SyncDDList: ", e);
            throw new Error(message);
          });
        })
        .catch((e) => {
          var message = apiError("PostAPI: ", e);
          props.errorList(message);
        })
    );
  };

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name);
    // console.log(theData);
    setValidationErrorList();
    var pattForSubmit = new RegExp("ForSubmit"),
      pattValidationErrorList = new RegExp("ValidationErrorList");
    if (pattForSubmit.test(name)) {
      setOutputList(theData);
      setNoSelections(true);
    } else if (pattValidationErrorList.test(name)) {
      setValidationErrorList(JSON.stringify(theData));
    } else {
      setter(theData);
    }
    setNoSelections(false);
  };

  return (
    <div className="ARUDDConsoleSelection">
      {OutputList.length >= 1 ? (
        <>
          <ARUDDImportTable
            IsConsoleImport={true}
            rows={OutputList}
            ForSubmit={handleCallback(setOutputList)}
            ValidationErrorList={handleCallback(setValidationErrorList)}
          />
          <div className="row BlankMargin">
            {ValidationErrorList ? ValidationError(ValidationErrorList) : null}
          </div>
          <div className={OutputList.length >= 5 ? "FormSave" : ""}>
            <Row>
              <Col sm={5}></Col>
              <Col sm={7} className="IconBtn">
                {NoSelections ? (
                  <div className="invalid-tooltip">{NoSelectionsMessage}</div>
                ) : null}
                <Button variant="primary" onClick={handleSubmit}>
                  <FaSave /> Save
                </Button>
                <Button variant="primary" onClick={handleFormReset}>
                  <FaTimesCircle /> Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ARUDDConsoleSelection;
