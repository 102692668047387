import React, { useContext } from "react";
import { AppContext } from "../../libs/contextLib";
import TooltipActionButton from "../TooltipActionButton";

export default function AddNewCompany() {
  const context = useContext(AppContext);
  if (
    context.SettingsList.CanAdd.NewCompanies ||
    context.SettingsList.IsAdmin
  ) {
    return (
      <TooltipActionButton
        LinkTo={{ pathname: "/CompanyListAddNew" }}
        ToolTipLabel="Add New Company"
        Label="Add New"
      />
    );
  } else return null;
}
