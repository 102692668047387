import React, { Component } from "react";
import { Container, Nav, Navbar, Row, Col } from "react-bootstrap";
import { AppContext } from "../libs/contextLib";
import { Auth } from "aws-amplify";
import withRouter from "../libs/withRouter";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "react-bootstrap";
import { GetSageLogin } from "./Login/SageLogin";

const LoginExpireLength = 480;
class Header extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {};
    this.GoSageLogin = this.GoSageLogin.bind(this);
  }

  componentDidMount() {
    let SageAccessToken = sessionStorage.getItem("SageAccessToken");
    this.context.setSageAccessToken(SageAccessToken);
  }

  componentDidUpdate() {
    var LoggedInTime = sessionStorage.getItem("LoggedInTime");
    var date = moment(LoggedInTime, "DD-MM-YYYY HH:mm:ss");
    var LoggedInTimePlusExpireLength = date
      .add(LoginExpireLength, "m")
      .format("DD-MM-YYYY HH:mm:ss");
    var dateToCompare = moment(
      LoggedInTimePlusExpireLength,
      "DD-MM-YYYY HH:mm:ss"
    );
    let now = moment(moment(), "DD-MM-YYYY HH:mm:ss");
    if (now.isSameOrAfter(dateToCompare)) {
      this.SignOut();
    }
  }

  SignOut = (SelfLoggedOut) => {
    this.context.setIsAuthenticated(false);
    this.context.setSettingsList([]);
    this.context.setSageAccessToken("");
    sessionStorage.clear();
    Auth.signOut({ global: true })
      .then(() => {
        var Feedback =
          "You have been logged out as your authentication token needs to be refreshed. Please login again.";
        if (SelfLoggedOut) {
          Feedback = "You have been succcesfully logged out.";
        }
        this.props.navigate("/login", { state: { PostFeedback: [Feedback] } });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleLogout = () => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className="ConfirmAlert">
            <div className="AlertBody">
              <h1>Do you want to log out?</h1>
              <p>You will lose all unsaved data if you do.</p>
              <div className="ButtonGroup">
                <Button
                  onClick={() => {
                    this.SignOut(true);

                    onClose();
                  }}
                >
                  Yes
                </Button>
                <Button onClick={onClose}>No</Button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  GoSageLogin(e) {
    e.preventDefault();
    GetSageLogin(this.context.SAGE_CLIENT_ID);
  }

  render() {
    let SiteName = sessionStorage.getItem("siteName"),
      ProductName = sessionStorage.getItem("productName");

    return (
      <div className="header">
        <Container fluid>
          <div className="headerContainer">
            <Row>
              <Col>
                <Navbar>
                  {this.context.isAuthenticated ? (
                    <Nav>
                      <span className="welcome">
                        Welcome, {this.context.usr}.
                      </span>
                      <span className="welcome">
                        You are on site - {SiteName}
                      </span>
                      {ProductName === "Sage 200c Standard" ? (
                        !this.context.SageAccessToken ? (
                          <Button variant="primary" onClick={this.GoSageLogin}>
                            Login into Sage
                          </Button>
                        ) : (
                          <span className="welcome">Sage Login Successful</span>
                        )
                      ) : null}
                      <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
                    </Nav>
                  ) : null}
                </Navbar>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
Header.contextType = AppContext;

export default withRouter(Header);
