import { Document, Page, View, StyleSheet, Font } from "@react-pdf/renderer";
import DDHeaderSection from "./DDHeaderSection";
import DDTimeStampSection from "./DDTimeStampSection";
import TableSection from "./TableSection";
// import Colours from "./Variables/Colours";
// import SegoeUI from "./Fonts/SegoeUI.woff";
// import SegoeUIBold from "./Fonts/SegoeUIBold.woff";
// import SegoeUIItalic from "./Fonts/SegoeUIItalic.woff";
// import SegoeUIBoldItalic from "./Fonts/SegoeUIBoldItalic.woff";

// Font.register({
//   family: "Segoe UI",
//   fonts: [
//     {
//       src: SegoeUI,
//     },
// {
//   src: SegoeUIBold,
//   fontWeight: 700,
// },
// {
//   src: SegoeUIItalic,
//   fontStyle: "italic",
// },
// {
//   src: SegoeUIBoldItalic,
//   fontWeight: 700,
//   fontStyle: "italic",
// },
//   ],
// });

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    lineHeight: 1.5,
    padding: "16px 20px 40px 20px",
  },
  mainContainer: {
    padding: "20px",
    borderRadius: "25px",
    maxHeight: "1800px",
    // backgroundColor: Colours.grey,
  },
});

const PDFTemplate = ({ PDFTemplateData }) => {
  return (
    <Document pageLayout={"twoColumnLeft"}>
      <Page wrap size="A4" style={{ ...styles.page }}>
        <View style={{ ...styles.mainContainer }}>
          <DDHeaderSection DDReportData={PDFTemplateData} />
          <TableSection TableData={PDFTemplateData} />
        </View>
        <DDTimeStampSection DDReportData={PDFTemplateData} />
      </Page>
    </Document>
  );
};

export default PDFTemplate;
