import React, { Component } from "react";
import { AppContext } from "../../libs/contextLib";
import CheckIfValueReturned from "../../libs/CheckIfValueReturned";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TooltipActionButton from "../TooltipActionButton";
import BootstrapTable from "../react-bootstrap-table2";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SetObserver } from "../../libs/Observer";
import withRouter from "../../libs/withRouter";

class UserListTable extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "userName",
          text: "User Name",
          sort: true,
          filter: textFilter({ placeholder: " " }),
        },
        {
          dataField: "firstName",
          text: "First Name",
          sort: true,
          filter: textFilter({ placeholder: " " }),
        },
        {
          dataField: "lastName",
          text: "Last Name",
          sort: true,
          filter: textFilter({ placeholder: " " }),
        },

        {
          dataField: "isActive",
          text: "Is Active?",
          sort: true,
          filter: textFilter({ placeholder: " " }),
          headerClasses: "ButtonCol center",
          classes: "center",
          formatter: (cellContent, row) => CheckIfValueReturned(row.isActive),
        },
      ],
    };
  }

  componentDidMount() {
    SetObserver();
  }

  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
    let UserListPage = 1;
    if (sessionStorage.getItem("UserListPage")) {
      UserListPage = sessionStorage.getItem("UserListPage");
    }
    const paginationOptions = {
      page: parseInt(UserListPage),
      onPageChange: (page) => {
        sessionStorage.setItem("UserListPage", page);
      },
      hidePageListOnlyOnePage: true,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "All",
          value: this.props.rows.length,
        },
      ],
    };

    const rowEvents = {
      onMouseEnter: (e, row, rowIndex) => {
        return (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="AddNewTooltip" className="">
                Add New
              </Tooltip>
            }
          >
            test
          </OverlayTrigger>
        );
      },
      onClick: (e, row, rowIndex) => {
        sessionStorage.setItem("UserNameState", JSON.stringify(row.userName));
        this.props.navigate("/UserDetails");
      },
    };

    const defaultSorted = [
      {
        dataField: "userName",
        order: "asc",
      },
    ];

    if (!this.props.rows) {
      return null;
    } else {
      return (
        <div className="UserListTable">
          <BootstrapTable
            bootstrap4
            responsive
            hover
            keyField="userName"
            data={this.props.rows}
            rowEvents={rowEvents}
            columns={this.state.columns}
            filter={filterFactory()}
            pagination={paginationFactory(paginationOptions)}
            defaultSorted={defaultSorted}
          />

          <TooltipActionButton
            LinkTo={{
              pathname: `/NewUser`,
            }}
            ToolTipLabel="Add New User"
            Label="Add New"
          />
        </div>
      );
    }
  }
}

UserListTable.contextType = AppContext;
export default withRouter(UserListTable);
