import React, { Component } from "react";
import Popup from "reactjs-popup";
import { FaTimesCircle, FaPlus, FaSave } from "react-icons/fa";
import { Form, Row, Col, Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import * as yup from "yup";
import FormInputBox from "../Form/FormInputbox";
import FormRadio from "../Form/FormRadio";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";
import apiError from "../../libs/apiError";
import GetDatetime from "../../libs/GetDatetime";
import { handleValidation } from "../handleValidation";

const CIDRSchema = yup
    .string()
    .nullable()
    .max(60, "CIDR must be less than 60 characters"),
  IPSchema = yup
    .string()
    .nullable()
    .max(50, "IP Address must be less than 50 characters"),
  DescriptionSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, "Description" + inputRegexMessage)
    .max(100, "Description must be less than 100 characters");

let blankState = {
  SiteID: sessionStorage.getItem("siteID"),
  IPType: "Single",
  errorList: [],
  validationErrorList: "",
  AddedSuccess: null,
};

class NewIP extends Component {
  constructor(props) {
    super(props);
    this.state = blankState;

    this.state.IPAddressFrom = null;
    this.state.IPAddressTo = null;
    this.state.CIDRRange = null;
    this.state.Description = null;
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleCallback = (name, theData) => {
    this.setState((state) => {
      state[name] = theData;
      return state;
    });
  };

  async validateForm() {
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "IPAddressTo",
          data: this.state.IPAddressTo,
          schema: IPSchema,
        },
        {
          name: "IPAddressFrom",
          data: this.state.IPAddressFrom,
          schema: IPSchema,
        },
        {
          name: "CIDRRange",
          data: this.state.CIDRRange,
          schema: CIDRSchema,
        },
        {
          name: "Description",
          data: this.state.Description,
          schema: DescriptionSchema,
        },
      ];
      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    return new Promise((resolve) => {
      if (
        (this.state.IPAddressTo === null || this.state.IPAddressTo === "") &&
        (this.state.IPAddressFrom === null ||
          this.state.IPAddressFrom === "") &&
        (this.state.CIDRRange === null || this.state.CIDRRange === "")
      ) {
        this.setState({
          validationErrorList:
            "At least one Valid IP address must be supplied.",
        });
      } else {
        this.validateForm()
          .then(() => {
            this.setState({ validationErrorList: "" }, () => {
              this.DoSubmit();
              resolve(true);
            });
          })
          .catch((e) => {
            this.setState({
              validationErrorList: JSON.stringify(e),
            });
          });
      }
    });
  }

  handleReset(e) {
    e.preventDefault();
    this.resetForm();
  }
  resetForm() {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce(
      (acc, v) => ({ ...acc, [v]: undefined }),
      {}
    );
    this.props.setOpen("open", false);
    this.setState({
      ...stateReset,
      ...blankState,
      IPAddressFrom: null,
      IPAddressTo: null,
      CIDRRange: null,
      Description: null,
    });
  }

  async DoSubmit() {
    let output = this.state;

    let DateTimeUpdated = GetDatetime();
    let DateTimeCreated = GetDatetime();

    let outputJSON = {
      SiteID: sessionStorage.getItem("siteID"),
      Description: output.Description,
      DateTimeUpdated: DateTimeUpdated,
      DateTimeCreated: DateTimeCreated,
    };
    switch (output.IPType) {
      case "Group":
        outputJSON["IPAddressTo"] = output.IPAddressTo;
        outputJSON["IPAddressFrom"] = output.IPAddressFrom;
        outputJSON["CIDRRange"] = null;
        break;
      case "CIDR":
        outputJSON["CIDRRange"] = output.CIDRRange;
        outputJSON["IPAddressTo"] = null;
        outputJSON["IPAddressFrom"] = null;
        break;

      default:
        outputJSON["IPAddressFrom"] = output.IPAddressFrom;
        outputJSON["IPAddressTo"] = null;
        outputJSON["CIDRRange"] = null;
        break;
    }

    // console.log(outputJSON);
    await trackPromise(
      axios
        .post("api/Ipwhitelists/", outputJSON)
        .then((results) => {
          this.props.AddedSuccess("AddedSuccess", true);
          this.resetForm();
        })
        .catch((e) => {
          var message = apiError("PostAPI: ", e);
          this.setState((previousState) => ({
            errorList: [...previousState.errorList, message],
          }));
          this.props.errorList("errorList", message);
        })
    );
  }

  render() {
    return (
      <div className="TooltipActionButton">
        <Popup
          className="IPPopup"
          trigger={
            this.props.FromNewUser ? (
              ""
            ) : (
              <Button variant="primary">
                <FaPlus /> Add New
              </Button>
            )
          }
          open={this.props.open}
          modal
          nested
          closeOnDocumentClick={false}
        >
          {(Close) => (
            <div className="Modal">
              <button
                className="close"
                onClick={(e) => {
                  this.handleReset(e);
                  Close();
                  this.props.setOpen("open", false);
                }}
              >
                <FaTimesCircle />
              </button>
              <div className="Title">
                <h2>Add New IP Address</h2>
              </div>

              <div className="content">
                <Form id="NewIPForm">
                  <FormRadio
                    Label="IP Type"
                    name="IPType"
                    id="IPType"
                    valueList={[
                      { name: "Single" },
                      { name: "Group" },
                      { name: "CIDR" },
                    ]}
                    initialState={this.state.IPType}
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    errorList={this.state.errorList}
                  />

                  {this.state.IPType === "Single" ? (
                    <>
                      <FormInputBox
                        type="text"
                        Label="IP address"
                        name="IPAddressFrom"
                        placeholder={this.state.IPAddressFrom}
                        value={this.handleCallback}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                        required={false}
                        errorList={this.state.errorList}
                        tooltip={"Example Format: 10.0.0.0"}
                        schema={IPSchema}
                      />
                    </>
                  ) : null}

                  {this.state.IPType === "Group" ? (
                    <>
                      <FormInputBox
                        type="text"
                        Label="Start IP address"
                        name="IPAddressFrom"
                        placeholder={this.state.IPAddressFrom}
                        value={this.handleCallback}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                        required={false}
                        errorList={this.state.errorList}
                        tooltip={"Example Format: 10:0:0:0"}
                        schema={IPSchema}
                      />
                      <FormInputBox
                        type="text"
                        Label="End IP address"
                        name="IPAddressTo"
                        placeholder={this.state.IPAddressTo}
                        value={this.handleCallback}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                        required={false}
                        errorList={this.state.errorList}
                        tooltip={"Example Format: 10.0.0.255"}
                        schema={IPSchema}
                      />
                    </>
                  ) : null}

                  {this.state.IPType === "CIDR" ? (
                    <>
                      <FormInputBox
                        type="text"
                        Label="CIDR"
                        name="CIDRRange"
                        placeholder={this.state.CIDRRange}
                        value={this.handleCallback}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                        required={false}
                        errorList={this.state.errorList}
                        tooltip={"Example Format: 10.0.0.0/24"}
                        schema={CIDRSchema}
                      />
                    </>
                  ) : null}

                  <FormInputBox
                    type="text"
                    Label="Description"
                    name="Description"
                    placeholder={this.state.Description}
                    value={this.handleCallback}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    required={false}
                    errorList={this.state.errorList}
                    schema={DescriptionSchema}
                  />
                </Form>
                <Row>
                  <Col sm={LabelColumnAmount}></Col>
                  <Col sm={4} className="IconBtn">
                    <Button
                      variant="primary"
                      onClick={async (e) => {
                        await this.handleSubmit(e).then(() => {
                          Close();
                        });
                      }}
                      type="submit"
                    >
                      <FaSave /> Save
                    </Button>

                    {this.state.validationErrorList
                      ? ValidationError(this.state.validationErrorList)
                      : null}

                    <Button
                      variant="primary"
                      onClick={(e) => {
                        this.handleReset(e);
                        Close();
                        this.props.setOpen("open", false);
                      }}
                    >
                      <FaTimesCircle /> Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Popup>
      </div>
    );
  }
}

export default NewIP;
