export default function DuplicateRuntimeFilterChecker(
  CurrentFilters,
  NewFilter,
  NewRun
) {
  return new Promise((resolve, reject) => {
    // console.log(CurrentFilters, NewFilter);
    var Status = [],
      ValueList = [];

    // for (let element of CurrentFilters) {
    //   if (element.filterFieldName === NewFilter.FilterFieldName) {
    //     Status.push("The Filter Field Name entered has already been used.");
    //     break;
    //   }
    // }

    let count = 0;
    var countAmount = NewRun ? 0 : 1;

    for (let element of CurrentFilters) {
      if (element.filterSetName === NewFilter.filterSetName) {
        count++;
        if (count > countAmount) {
          Status.push("The Filter Set Name entered has already been used.");
          break;
        }
      }
    }

    for (let element of NewFilter.filterValue) {
      if (element !== undefined) {
        if (element.filterValue !== undefined) {
          ValueList.push(element.filterValue);
        }
      }
    }

    for (let element of NewFilter.filterValue) {
      if (element !== undefined) {
        if (countOccurrences(ValueList, element.filterValue) > 1) {
          //       //   console.log("Start");
          //       //   console.log(element);
          //       //   var Index = element.replace("filterValue", "");
          //       //   console.log(Index);
          //       //   Index = Number(Index);
          //       //   console.log(Index);
          //       //   var nextIndex = Index + 1;
          //       //   console.log(nextIndex);
          Status.push(
            // `The Filter value entered in Value ${nextIndex} has already been used in Value ${Index}.`
            `Duplicate filter values have been used.`
          );
          break;
        }
      }
    }

    if (Status.length > 0) {
      reject(Status);
    } else {
      resolve(true);
    }
  });
}

const countOccurrences = (arr, val) =>
  arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
