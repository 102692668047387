import React, { Component } from "react";
import { FaTimes, FaPlus, FaRegEdit } from "react-icons/fa";
import { AppContext } from "../../libs/contextLib";
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

// const ItemsPerRow = 7;

// let theFormatList = [
//   "AccountName",
//   "AccountNumber",
//   "AdhockType",
//   "Blank",
//   "CollectionTotal",
//   "CompanyName",
//   "Date(dd.mm.yy)",
//   "Date(dd/mm/yyyy)",
//   "Date(ddmmyy)",
//   "Date(ddmmyyyy)",
//   "Date(yyyy/mm/dd)",
//   "Date(yyyymmdd)",
//   "Date(yyyy-mm-dd)",
//   "InvoiceNo",
//   "JulianDate",
//   "MandateRecord",
//   "MandateReference",
//   "SageCompany",
//   "SequentialNo",
//   "SortCode",
//   "SortCode(withHyphens)",
//   "Zero",
// ];
let FormatListDetailed = [
  { Name: "AccountName", Example: "Mr J Smith" },
  { Name: "AccountNumber", Example: "12345678" },
  { Name: "Blank", Example: " " },
  { Name: "CollectionTotal", Example: "123.25" },
  { Name: "CompanyName", Example: "MyCompany" },
  { Name: "Date(dd.MM.yy)", Example: "06.07.21" },
  { Name: "Date(dd/MM/yyyy)", Example: "06/07/2021" },
  { Name: "Date(ddMMyy)", Example: "060721" },
  { Name: "Date(ddMMyyyy)", Example: "06072021" },
  { Name: "Date(yyyy/MM/dd)", Example: "2021/07/06" },
  { Name: "Date(yyyyMMdd)", Example: "20210706" },
  { Name: "Date(yyyy-MM-dd)", Example: "2021-07-06" },
  // { Name: "InvoiceNo", Example: "140518" },
  { Name: "JulianDate", Example: "21365" },
  { Name: "MandateRecord", Example: "0N" },
  { Name: "MandateReference", Example: "ABS001" },
  // { Name: "SageCompany", Example: "MySageCompany" },
  { Name: "SortCode", Example: "123456" },
  { Name: "SortCode(withHyphens)", Example: "12-34-56" },
  { Name: "Zero", Example: "0" },
  { Name: "AdditionalReference", Example: "MyRef" },
  { Name: "CompanyAccountName", Example: "MyCompanyAccount" },
  { Name: "CompanyAccountNumber", Example: "87654321" },
  { Name: "CompanySortCode", Example: "654321" },
  { Name: "Email", Example: "jsmith@mycompany.com" },
  { Name: "Firstname", Example: "John" },
  { Name: "Fullname", Example: "John Smith" },
  { Name: "Surname", Example: "Smith" },
  { Name: "CurrencyISOCode", Example: "GBP" },
  { Name: "SequentialNo", Example: " " },
  { Name: "SvcUserNo", Example: " " },
  { Name: "ReferenceText", Example: " " },
  { Name: "AdhockType", Example: " " },
];
let FormatList = [];
FormatListDetailed.forEach((element, i) => {
  FormatList[i] = element.Name;
});
class GenerateCustomFormat extends Component {
  static contextState = AppContext;
  constructor(props) {
    super(props);
    let data = this.props.placeholder;
    if (this.props.placeholder === null) {
      data = this.props.DefaultFormat;
    }
    this.state = {
      data: data,
      // data: "",
      delimiter: ",",
      ToggleQuotes: false,
      FormatList: FormatList,
      RemovedList: [],
      ElementOrderHeight: 170,
      NotInListHeight: 170,
    };
    this.handleToggleItem = this.handleToggleItem.bind(this);
    this.HandleOnChange = this.HandleOnChange.bind(this);
    this.updateHeight = this.updateHeight.bind(this);

    // this.setData();
  }
  componentDidMount() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
    this.setData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeight);
  }
  componentDidUpdate(prevProps) {
    if (this.props.placeholder !== prevProps.placeholder) {
      this.setData();
    }
  }

  updateHeight() {
    let windowWidth = window.innerWidth;

    if (windowWidth > 1780) {
      var ItemsPerRow = 6;
    }
    if (windowWidth <= 1780 && windowWidth > 1440) {
      ItemsPerRow = 5;
    }
    if (windowWidth <= 1440 && windowWidth > 1100) {
      ItemsPerRow = 4;
    }
    if (windowWidth <= 1100 && windowWidth > 833) {
      ItemsPerRow = 3;
    }
    if (windowWidth <= 833 && windowWidth > 580) {
      ItemsPerRow = 2;
    }
    if (windowWidth <= 580) {
      ItemsPerRow = 1;
    }

    let FormatListCount = Object.keys(this.state.FormatList).length;
    let NotInListCount = Object.keys(this.state.RemovedList).length;
    let FormatListHeight = Math.ceil(FormatListCount / ItemsPerRow) * 70;
    let NotInListHight = Math.ceil(NotInListCount / ItemsPerRow) * 70;
    this.setState({
      ElementOrderHeight: FormatListHeight,
      NotInListHeight: NotInListHight,
      ItemsPerRow: ItemsPerRow,
    });
  }

  setData() {
    let data = this.props.placeholder;
    if (this.props.placeholder === null) {
      data = this.props.DefaultFormat;
    }
    let placeholder = data.replaceAll("{", "").replaceAll("}", "");

    var theDelimiter;
    if (placeholder.includes("|")) {
      theDelimiter = "|";
    } else if (placeholder.includes(" ")) {
      theDelimiter = " ";
    } else {
      theDelimiter = ",";
    }

    var ToggleQuotes = false;
    if (placeholder.includes('"')) {
      ToggleQuotes = true;
    }

    var placeholderFormatList = placeholder.split(theDelimiter);
    var RemovedList = FormatList;
    var index;
    for (var i = 0; i < placeholderFormatList.length; i++) {
      index = RemovedList.indexOf(placeholderFormatList[i]);
      if (index > -1) {
        RemovedList.splice(index, 1);
      }
    }

    this.setState(
      {
        data: data,
        delimiter: theDelimiter,
        ToggleQuotes: ToggleQuotes,
        FormatList: placeholderFormatList,
        RemovedList: RemovedList,
      },
      () => {
        this.updateHeight();
        this.props.value(this.props.name, this.state.data);
      }
    );
  }

  handleToggleItem() {
    let dataLength = this.state.FormatList.length - 1;
    var output = "";
    var i = 0;

    this.state.FormatList.forEach((element) => {
      output += "{" + element + "}";
      if (i < dataLength) {
        output += this.state.delimiter;
      }
      i++;
    });

    this.setState(
      {
        data: output,
      },
      () => {
        this.props.value(this.props.name, this.state.data);
      }
    );
  }

  HandleOnChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(this.state.FormatList, sourceIndex, targetIndex);
    this.setState({ ...this.state.FormatList, FormatList: nextState }, () => {
      this.updateHeight();
      this.changeDelimiter();
    });
  }
  HandleRemoveItem(name) {
    var array = [...this.state.FormatList];

    var index = array.indexOf(name);

    if (index !== -1 || array.includes(name)) {
      array.splice(index, 1);

      this.setState(
        { FormatList: array, RemovedList: [...this.state.RemovedList, name] },
        () => {
          // console.log(this.state);
          this.updateHeight();
          this.changeDelimiter();
        }
      );
    }
  }

  HandleAddItem(name) {
    var array = [...this.state.RemovedList];

    var index = array.indexOf(name);

    if (index !== -1 || array.includes(name)) {
      array.splice(index, 1);

      this.setState(
        { RemovedList: array, FormatList: [...this.state.FormatList, name] },
        () => {
          this.updateHeight();
          this.changeDelimiter();
          // console.log(this.state);
        }
      );
    }
  }

  handleToggleQuotes() {
    this.setState({ ToggleQuotes: !this.state.ToggleQuotes }, () => {
      this.changeDelimiter();
    });
  }

  changeDelimiter(delimiter) {
    let data = Object.keys(this.state.FormatList).map((key) =>
      this.state.ToggleQuotes
        ? ['"{' + this.state.FormatList[key] + '}"']
        : ["{" + this.state.FormatList[key] + "}"]
    );
    let dataLength = Object.keys(data).length - 1;
    if (delimiter === undefined) {
      delimiter = this.state.delimiter;
    }

    var output = "";
    data.forEach((element, i) => {
      if (i < dataLength) {
        output += element + delimiter;
      } else {
        output += element;
      }
    });

    this.setState({ data: output, delimiter: delimiter });
  }

  renderExample() {
    var ExampleString = "";
    let dataLength = Object.keys(this.state.FormatList).length - 1;
    var Quotes = this.state.ToggleQuotes ? '"' : "";
    this.state.FormatList.forEach((FormatListItem, i) => {
      FormatListDetailed.forEach((element) => {
        if (FormatListItem === element.Name) {
          if (i < dataLength) {
            ExampleString +=
              Quotes + element.Example + this.state.delimiter + Quotes;
          } else {
            ExampleString += Quotes + element.Example + Quotes;
          }
        }
      });
    });
    return ExampleString;
  }

  renderContent() {
    let name = this.props.name;
    let ElementOrderStyle = { height: this.state.ElementOrderHeight + "px" };
    let NotInListStyle = { height: this.state.NotInListHeight + "px" };
    return (
      <Form.Group className={"FormGroup"} as={Row} controlId={name}>
        <Form.Label column sm={this.props.LabelColumnAmount}>
          Custom Format
        </Form.Label>
        <Col sm={this.props.InputColumnAmount}>
          <>
            <Col sm={12} className="form-control-plaintext">
              {this.state.data !== null
                ? this.state.data
                : this.props.DefaultFormat}
            </Col>
          </>
          {this.props.errorList[name] ? (
            <Form.Control.Feedback type="invalid" tooltip>
              <>{this.props.errorList[name]}</>
            </Form.Control.Feedback>
          ) : null}
        </Col>
        {this.props.DefaultHeader !== null ? (
          <>
            <Form.Label column sm={this.props.LabelColumnAmount}>
              Header Line
            </Form.Label>
            <Col sm={this.props.InputColumnAmount}>
              <Col sm={12} className="form-control-plaintext">
                {this.props.DefaultHeader}
              </Col>
            </Col>
          </>
        ) : null}
        <Form.Label column sm={this.props.LabelColumnAmount}>
          Example of the Custom Format
        </Form.Label>
        <Col sm={this.props.InputColumnAmount}>
          <>
            {this.state.data !== null ? (
              <>
                <Col sm={12} className="form-control-plaintext">
                  {this.renderExample()}
                </Col>
              </>
            ) : null}
            {!this.props.disabled ? (
              <Col sm={12}>
                {!this.props.DefaultHeader ? (
                  <Popup
                    className="CustomFormat"
                    trigger={
                      <div className="IconBtn">
                        <Button variant="primary">
                          <FaRegEdit /> Edit Custom Format
                        </Button>
                      </div>
                    }
                    modal
                    nested
                    closeOnDocumentClick={false}
                  >
                    {(Close) => (
                      <GridContextProvider onChange={this.HandleOnChange}>
                        <div className="Modal">
                          {/* <button className="close" onClick={Close}>
                          <FaTimesCircle />
                        </button> */}
                          <div className="Title">
                            <h2>Custom Format</h2>
                          </div>

                          <div className="content">
                            <div className="GenerateCustomFormat">
                              <h3>Element Order</h3>
                              <GridDropZone
                                className="row ElementOrder dropzone"
                                id="CustomFormatItems"
                                boxesPerRow={this.state.ItemsPerRow}
                                rowHeight={60}
                                style={ElementOrderStyle}
                              >
                                {this.state.FormatList.map((ListItem, i) => (
                                  <GridItem className="GridItem" key={i}>
                                    <div
                                      className="grid-item"
                                      style={{ cursor: "grab" }}
                                    >
                                      <div className="grid-item-content">
                                        {ListItem}
                                        <button
                                          className="close RemoveElement"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            // console.log(ListItem);
                                            this.HandleRemoveItem(ListItem);
                                          }}
                                        >
                                          <FaTimes />
                                        </button>
                                      </div>
                                    </div>
                                  </GridItem>
                                ))}
                              </GridDropZone>
                            </div>

                            <div className="GenerateCustomFormat">
                              <h3>Items not in List</h3>
                              <GridDropZone
                                className="row NotInList dropzone"
                                id="RemovedList"
                                boxesPerRow={this.state.ItemsPerRow}
                                rowHeight={60}
                                style={NotInListStyle}
                              >
                                {this.state.RemovedList.map((ListItem, i) => (
                                  <div className=" GridItem" key={i}>
                                    <div className="grid-item">
                                      <div className="grid-item-content">
                                        {ListItem}
                                        <button
                                          className="close RemoveElement"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            // console.log(ListItem);
                                            this.HandleAddItem(ListItem);
                                          }}
                                        >
                                          <FaPlus />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </GridDropZone>
                            </div>

                            <div className="DelimiterSection">
                              <h3>Toggle Quotes</h3>
                              <Row>
                                <Col className="IconBtn">
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleToggleQuotes();
                                    }}
                                  >
                                    {this.state.ToggleQuotes
                                      ? "Quotes On"
                                      : "Quotes Off"}
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                            <div className="DelimiterSection">
                              <h3>Delimiter</h3>
                              <Row>
                                <Col className="IconBtn">
                                  <Button
                                    value=","
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.changeDelimiter(e.target.value);
                                    }}
                                  >
                                    Comma ( , )
                                  </Button>

                                  <Button
                                    value="|"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.changeDelimiter(e.target.value);
                                    }}
                                  >
                                    Pipes ( | )
                                  </Button>

                                  <Button
                                    value=" "
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.changeDelimiter(e.target.value);
                                    }}
                                  >
                                    Tab
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                            <div className="FinalCustomFormat">
                              <h3>Final Custom Format</h3> {this.state.data}
                            </div>
                            <div className="FinalCustomFormat">
                              <h3>Example of the Final Custom Format</h3>
                              {this.renderExample()}
                            </div>
                          </div>
                          <div className="FinishArea">
                            <Button
                              onClick={() => {
                                this.handleToggleItem();
                                Close();
                              }}
                            >
                              Finish Editing
                            </Button>
                          </div>
                        </div>
                      </GridContextProvider>
                    )}
                  </Popup>
                ) : (
                  <Badge bg="danger">This Bank Format cannot be edited.</Badge>
                )}
              </Col>
            ) : null}
          </>
          {this.props.errorList[name] ? (
            <Form.Control.Feedback type="invalid" tooltip>
              <>{this.props.errorList[name]}</>
            </Form.Control.Feedback>
          ) : null}
        </Col>
      </Form.Group>
    );
  }
  render() {
    return !this.props.hidden ? (
      this.props.tooltip ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={this.props.name + "Tooltip"} className="">
              {this.props.tooltip}
            </Tooltip>
          }
        >
          {this.renderContent()}
        </OverlayTrigger>
      ) : (
        this.renderContent()
      )
    ) : null;
  }
}
GenerateCustomFormat.contextType = AppContext;
export default GenerateCustomFormat;
