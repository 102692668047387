import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    marginBottom: "10px",
  },
  RowTitle: {
    width: "30%",
  },
  RowData: {
    width: "70%",
  },
});

const DDHeaderSection = ({ DDReportData }) => {
  return (
    <>
      <View style={{ ...styles.row }}>
        <Text style={{ ...styles.RowTitle }}>Collection List Name</Text>
        <Text style={{ ...styles.RowData }}>
          {DDReportData.CollectionListName}
        </Text>
      </View>
      <View style={{ ...styles.row }}>
        <Text style={{ ...styles.RowTitle }}>Company</Text>
        <Text style={{ ...styles.RowData }}>{DDReportData.Company}</Text>
      </View>
      <View style={{ ...styles.row }}>
        <Text style={{ ...styles.RowTitle }}>Collection Bank</Text>
        <Text>{DDReportData.CollectionBank}</Text>
      </View>
      {DDReportData.InvoiceCurrency ? (
        <View style={{ ...styles.row }}>
          <Text style={{ ...styles.RowTitle }}>Invoice Currency </Text>
          <Text style={{ ...styles.RowData }}>
            {DDReportData.InvoiceCurrency}
          </Text>
        </View>
      ) : null}
      {DDReportData.BaseCurrency ? (
        <View style={{ ...styles.row }}>
          <Text style={{ ...styles.RowTitle }}>Base Currency </Text>
          <Text style={{ ...styles.RowData }}>{DDReportData.BaseCurrency}</Text>
        </View>
      ) : null}
      {DDReportData.TransactionsFrom ? (
        <View style={{ ...styles.row }}>
          <Text style={{ ...styles.RowTitle }}>Transactions From</Text>
          <Text style={{ ...styles.RowData }}>
            {DDReportData.TransactionsFrom}
          </Text>
        </View>
      ) : null}
      <View style={{ ...styles.row }}>
        <Text style={{ ...styles.RowTitle }}>Transactions To </Text>
        <Text style={{ ...styles.RowData }}>{DDReportData.TransactionsTo}</Text>
      </View>
      <View style={{ ...styles.row }}>
        <Text style={{ ...styles.RowTitle }}>Collection Date</Text>
        <Text style={{ ...styles.RowData }}>
          {moment(DDReportData.CollectionDate, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          )}
        </Text>
      </View>
      <View style={{ ...styles.row }}>
        <Text style={{ ...styles.RowTitle }}>Status</Text>
        <Text style={{ ...styles.RowData }}>{DDReportData.Status}</Text>
      </View>
      <View style={{ ...styles.row }}>
        <Text style={{ ...styles.RowTitle }}>
          Total Collection ({DDReportData.InvoiceCurrencySymbol})
        </Text>
        <Text style={{ ...styles.RowData }}>
          {DDReportData.GrandCollectionTotal}
        </Text>
      </View>
      {DDReportData.TotalCollectionEditableLabel &&
      DDReportData.TotalCollectionEditable ? (
        <View style={{ ...styles.row }}>
          <Text style={{ ...styles.RowTitle }}>
            {DDReportData.TotalCollectionEditableLabel}
          </Text>
          <Text style={{ ...styles.RowData }}>
            {DDReportData.TotalCollectionEditable}
          </Text>
        </View>
      ) : null}
    </>
  );
};

export default DDHeaderSection;
