import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import CheckConfirm from "./CheckConfirm";

const FormCheck = (props) => {
  const [checked, setchecked] = useState(props.initialState);

  let handleClick = (e) => {
    let messageMaxWidth = props.messageMaxWidth
      ? props.messageMaxWidth
      : "500px";
    if (!props.disabled) {
      if (props.alertMessage) {
        if (!props.useWarningMessage) {
          if (!checked) {
            CheckConfirm(props.alertMessage, messageMaxWidth)
              .then(() => {
                toggleChecked(true);
              })
              .catch(() => {
                toggleChecked(false);
              });
          } else {
            toggleChecked();
          }
        } else {
          CheckConfirm(props.alertMessage, messageMaxWidth)
            .then(() => {
              toggleChecked();
            })
            .catch(() => {});
        }
      } else {
        toggleChecked();
      }
    }
  };

  let toggleChecked = (toggleChecked = !checked) => {
    setchecked(toggleChecked);
    props.value(props.id, toggleChecked);
  };

  return !props.hidden ? (
    <Form.Group className={"FormGroup"} as={Row} controlId={props.name}>
      <Form.Label column sm={props.LabelColumnAmount}>
        {props.Label}
      </Form.Label>
      <Col sm={props.InputColumnAmount}>
        <div
          className="btnToggle"
          id={props.id}
          onClick={handleClick}
          disabled={props.disabled}
          aria-label={props.Label}
        >
          {checked ? <FaToggleOn /> : <FaToggleOff />}
        </div>
      </Col>
    </Form.Group>
  ) : null;
};

export default FormCheck;
