import moment from "moment";
function CSVHeaderSection(DDReportData) {
  var output = "";
  output +=
    "Report created by " +
    DDReportData.CreatedBy +
    " at " +
    DDReportData.ReportCreated +
    ",";
  output += "\r\n";
  output += "\r\n";

  output += "Company,";
  output += "Collection List Name,";
  output += "Collection Bank,";
  output += DDReportData.InvoiceCurrency ? "Invoice Currency," : "";
  output += DDReportData.BaseCurrency ? "Base Currency," : "";
  output += DDReportData.TransactionsFrom ? "Transactions From," : "";
  output += "Transactions To,";
  output += "Collection Date,";
  output += "Status,";
  output += `Total Collection (${DDReportData.InvoiceCurrencySymbol})`;
  if (
    DDReportData.TotalCollectionEditableLabel &&
    DDReportData.TotalCollectionEditable
  ) {
    output += "," + DDReportData.TotalCollectionEditableLabel + ",";
  }
  output += "\r\n";

  output += DDReportData.Company + ",";
  output += DDReportData.CollectionListName + ",";
  output += DDReportData.CollectionBank + ",";
  output += DDReportData.InvoiceCurrency
    ? DDReportData.InvoiceCurrency + ","
    : "";
  output += DDReportData.BaseCurrency ? DDReportData.BaseCurrency + "," : "";
  output += DDReportData.TransactionsFrom
    ? DDReportData.TransactionsFrom + ","
    : "";
  output += DDReportData.TransactionsTo + ",";
  output +=
    moment(DDReportData.CollectionDate, "YYYY-MM-DD").format("DD-MM-YYYY") +
    ",";
  output += DDReportData.Status + ",";
  output += DDReportData.GrandCollectionTotal;
  if (
    DDReportData.TotalCollectionEditableLabel &&
    DDReportData.TotalCollectionEditable
  ) {
    output += "," + DDReportData.TotalCollectionEditable;
  }

  output += "\r\n";
  output += "\r\n";

  return output;
}

export default CSVHeaderSection;
